import './common/extensions';
import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './http-interceptors';
import { TextMaskModule } from 'angular2-text-mask';
import {
  MainComponent,
  resolves,
  EducationComponent,
  ReferenceQuestionnaireComponent,
  MenuListItemComponent,
  QuestionnaireComponent,
  components,
  SpecificJobComponent,
  SavedJobsComponent,
  DocumentsComponent,
  MatchedJobsComponent,
  AutoOfferConfirmationComponent,
  InternalLoginComponent,
  LoginRedirectComponent,
  CovidVaccineComponent,
} from './components';
import { FormHeaderService, services } from './services';
import { guards } from './guards';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ExpiringPopupComponent } from './components/shared/expiring-popup/expiring.popup';
import { ConfirmationPopup } from './components/shared/confirmation-popup/confirmation-popup.component';
import { SkillsCheckListComponent } from './components/skills/skills-check-list/skills-check-list.component';
import { BottomSheetComponent } from './components/shared/bottom-sheet/bottom-sheet.component';
import { DeleteConfirmationComponent } from './components/shared/delete-confirmation/delete-confirmation.component';
import { CustomSnackBarComponent } from './components/shared/snack-bar/custom-snack-bar/custom-snack-bar.component';
import { BasicSnackBarComponent } from './components/shared/snack-bar/basic-snack-bar/basic-snack-bar.component';
import { EmptyJobsComponent } from './components/shared/empty-jobs/empty-jobs.component';
import { NotFoundComponent } from './components/external-components/not-found/not-found.component';
import { Angulartics2Module } from 'angulartics2';
import { MainJobAreaComponent } from './components/jobs/main-job-area/main-job-area.component';
import { FilterBottomSheetComponent } from './components/shared/filter-bottom-sheet/filter-bottom-sheet.component';
import { InterestedPopup } from './components/shared/interesed-pop-up/interested.popup';
import { TasksListComponent } from './components/tasks/tasks-list/tasks-list.component';
import { BulkUploadPopupComponent } from './components/bulk-upload/bulk-upload-popup/bulk-upload-popup.component';
import { DeclinationFeedbackPopUpComponent } from './components/shared/declination-feedback-pop-up/declination-feedback-pop-up.component';
import { ProfileMenuComponent } from './components/main-components/profile-menu/profile-menu.component';
// eslint-disable-next-line max-len
import { AddFileBottomSheetComponent } from './components/shared/add-file-bottom-sheet/add-file-bottom-sheet.component';
import { SupportComponent } from './components/main-components/support/support.component';
import { IssueConfirmPopup } from './components/main-components/support/issue-confirmation/issue-confirm.popup';
import { pipes } from './custom-pipes';
import { AboutMeComponent } from './components/about-me/about-me.component';
import { SharedModule } from './shared/shared.module';
import { MultipleFoundComponent } from './components/main-components/registration/multiple-found/multiple-found.component';
import { DeleteDocumentPopUpComponent } from './components/shared/delete-document-pop-up/delete-document-pop-up.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { JobsInfoCardComponent } from './components/shared/jobs-info-card/jobs-info-card.component';
import { TaskCompletionPopUpComponent } from './components/shared/task-completion-pop-up/task-completion.pop-up';
import { BadExtensionComponent } from './components/shared/bad-extension-popup/bad-extension-popup.component';
import { BadProfileExtensionComponent } from './components/shared/bad-extension-profile-popup/bad-extension-profile-popup.component';
import { ClearAutoCompleteComponent } from './components/shared/clear-auto-complete/clear-auto-complete.component';
import { CrisisCardComponent } from './components/shared/crisis-card/crisis-card.component';
import { CrisisPopupComponent } from './components/shared/crisis-popup/crisis-popup.component';
import { ContactRecruiterPopUpComponent } from './components/shared/contact-recruiter-pop-up/contact-recruiter-pop-up.component';
import { ContactRecruiterTrayComponent } from './components/shared/contact-recruiter-tray/contact-recruiter-tray.component';
import { ApplyPopUpComponent } from './components/shared/apply-pop-up/apply-pop-up.component';
import { DisclaimerPopupComponent } from './components/jobs/available-jobs/specific-job/disclaimer-popup/disclaimer-popup.component';
import { ApplicationSuccessPopupComponent } from './components/jobs/application-review/application-success-popup/application-success-popup.component';
import { InactiveUserComponent } from './components/main-components/registration/inactive-user/inactive-user.component';
import { SnackBarErrorComponent } from './components/shared/snack-bar-error/snack-bar-error.component';
import { ZipCodeLookupComponent } from './components/shared/zip-code-lookup/zip-code-lookup.component';
import { ProfessionalOverviewComponent } from './components/about-me/professional-overview/professional-overview.component';
import { SettingsComponent } from './components/main-components/settings/settings.component';
import { SimilarJobsListComponent } from './components/jobs/available-jobs/similar-jobs-list/similar-jobs-list.component';
import { CardTemplateComponent } from './components/jobs/cards/card-template/card-template.component';
import { JobCardComponent } from './components/jobs/cards/job-card/job-card.component';
import { SubmittalCardComponent } from './components/jobs/cards/submittal-card/submittal-card.component';
import { CheckboxFormArrayComponent } from './components/shared/checkbox-form-array/checkbox-form-array.component';
import { Router } from '@angular/router';
import { HcOnAssignmentLibModule } from 'hc-on-assignment-lib';
import { SegmentTrackLinkClickDirective } from 'src/app/shared/segment/track-link-click.directive';
import { StoreModule } from '@ngrx/store';
import { appReducers } from 'src/app/store/app/app.reducers';
import { EffectsModule } from 'src/app/store/app/effects.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FluVaccineComponent } from './components/experience-components/flu-vaccine/flu-vaccine.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { EnrollComponent } from './components/enroll/enroll.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NewSubmittalsComponent } from './components/jobs/new-submittals/new-submittals.component';
import { NewSubmittalCardComponent } from './components/jobs/cards/new-submittal-card/new-submittal-card.component';
import { SkillAssessmentRedesignComponent } from './components/skills/skill-assessment/redesign/skill-assessment-redesign.component';
import { DialogComponent, HcDesignSystemLibModule } from 'hc-design-system-lib';
import { StartApplicationDialogComponent } from './components/jobs/available-jobs/specific-job/start-application-dialog/start-application-dialog.component';
import { ApplicationTasksComponent } from './components/jobs/application-tasks/application-tasks.component';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { SpecialtyPopupComponent } from './components/main-components/specialty-popup/specialty-popup.component';
import { NoConnectionComponent } from './components/no-connection/no-connection.component';
import { LogoutComponent } from './components/logout/logout.component';
import { FacilitiesCarouselComponent } from './components/jobs/facilities-carousel/facilities-carousel.component';
import { JobSearchFormComponent } from './components/jobs/job-search-v2/job-search-form/job-search-form.component';
import { JobSearchLayoutComponent } from './components/jobs/job-search-v2/job-search-layout/job-search-layout.component';
import { JobSearchResultsV2Component } from './components/jobs/job-search-v2/job-search-results/job-search-results.component';
import { LoginErrorComponent } from './components/login-error/login-error.component';

@NgModule({
  declarations: [
    components,
    pipes,
    AppComponent,
    MainComponent,
    SpecialtyPopupComponent,
    SpecificJobComponent,
    EducationComponent,
    SkillsCheckListComponent,
    SkillAssessmentRedesignComponent,
    ReferenceQuestionnaireComponent,
    ExpiringPopupComponent,
    MenuListItemComponent,
    QuestionnaireComponent,
    BottomSheetComponent,
    SavedJobsComponent,
    MatchedJobsComponent,
    ConfirmationPopup,
    DeleteConfirmationComponent,
    CustomSnackBarComponent,
    BasicSnackBarComponent,
    EmptyJobsComponent,
    NotFoundComponent,
    DocumentsComponent,
    MainJobAreaComponent,
    FilterBottomSheetComponent,
    InterestedPopup,
    DeclinationFeedbackPopUpComponent,
    TasksListComponent,
    NewSubmittalsComponent,
    BulkUploadPopupComponent,
    ProfileMenuComponent,
    AddFileBottomSheetComponent,
    SupportComponent,
    IssueConfirmPopup,
    AboutMeComponent,
    MultipleFoundComponent,
    DeleteDocumentPopUpComponent,
    JobsInfoCardComponent,
    BadExtensionComponent,
    BadProfileExtensionComponent,
    ClearAutoCompleteComponent,
    TaskCompletionPopUpComponent,
    CrisisCardComponent,
    CrisisPopupComponent,
    ContactRecruiterPopUpComponent,
    ContactRecruiterTrayComponent,
    ProfessionalOverviewComponent,
    ApplyPopUpComponent,
    DisclaimerPopupComponent,
    ApplicationSuccessPopupComponent,
    InactiveUserComponent,
    SnackBarErrorComponent,
    ZipCodeLookupComponent,
    AutoOfferConfirmationComponent,
    SettingsComponent,
    SimilarJobsListComponent,
    CardTemplateComponent,
    JobCardComponent,
    SubmittalCardComponent,
    NewSubmittalCardComponent,
    CheckboxFormArrayComponent,
    InternalLoginComponent,
    InternalLoginComponent,
    CovidVaccineComponent,
    SegmentTrackLinkClickDirective,
    FluVaccineComponent,
    ResetPasswordComponent,
    FluVaccineComponent,
    EnrollComponent,
    LoginRedirectComponent,
    LogoutComponent,
    StartApplicationDialogComponent,
    ApplicationTasksComponent,
    NoConnectionComponent,
    JobSearchLayoutComponent,
    JobSearchFormComponent,
    JobSearchResultsV2Component,
    ApplicationTasksComponent,
    FacilitiesCarouselComponent,
    LoginErrorComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    EffectsModule,
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    HcDesignSystemLibModule,
    NgProgressModule.withConfig({
      spinner: false,
    }),
    NgProgressHttpModule,
    LayoutModule,
    AppRoutingModule,
    TextMaskModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    Angulartics2Module.forRoot(),
    SharedModule.forRoot(),
    ScrollingModule,
    HcOnAssignmentLibModule.forRoot({
      assignmentBaseUrl: environment.timeBaseUrl,
      timeBaseUrl: environment.timeBaseUrl,
      expenseApiBaseUrl: environment.expenseManagementUrl,
      formHeaderService: FormHeaderService,
      onDemandBaseUrl: environment.onDemandBaseUrl,
      credentiallySSOBaseUrl: environment.credentiallySSOUrl,
      nurseSentimentApiBaseUrl: environment.nurseSentimentApiBaseUrl,
    }),
    IonicModule.forRoot(),
  ],
  entryComponents: [
    AppComponent,
    ExpiringPopupComponent,
    BottomSheetComponent,
    BasicSnackBarComponent,
    CustomSnackBarComponent,
    DeleteConfirmationComponent,
    FilterBottomSheetComponent,
    InterestedPopup,
    DeclinationFeedbackPopUpComponent,
    ConfirmationPopup,
    IssueConfirmPopup,
    BulkUploadPopupComponent,
    AddFileBottomSheetComponent,
    DeleteDocumentPopUpComponent,
    BadExtensionComponent,
    BadProfileExtensionComponent,
    ClearAutoCompleteComponent,
    TaskCompletionPopUpComponent,
    CrisisCardComponent,
    CrisisPopupComponent,
    ContactRecruiterPopUpComponent,
    ContactRecruiterTrayComponent,
    ApplyPopUpComponent,
    DisclaimerPopupComponent,
    ApplicationSuccessPopupComponent,
    LoginRedirectComponent,
    LogoutComponent,
    DialogComponent,
    LoginErrorComponent,
  ],
  providers: [
    FileOpener,
    httpInterceptorProviders,
    services,
    guards,
    resolves,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {
    console.log('APP Module Constructor!');
  }
}
