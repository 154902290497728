<ng-container
  *ngIf="{
    skillDictionary: skillDictionary$ | async,
    skillDictionaryAccordionFiltered: skillDictionaryAccordionFiltered$ | async,
    skillDictionaryLoading: skillDictionaryLoading$ | async
  } as obs"
>
  <app-loading-screen-spinner
    [show]="obs.skillDictionaryLoading"
  ></app-loading-screen-spinner>

  <div class="tasks-container">
    <hc-body size="small">
      Skills checklists help you and your recruiter identify your skills and
      experience level.
    </hc-body>
    <hc-body [size]="'small'">
      <span class="bolded">Note:</span> Skills Checklists must be filled out
      within the last year.
    </hc-body>
    <hc-heading size="h5" *ngIf="obs.skillDictionary?.checklists?.length">My Checklists</hc-heading>
    <div class="record-container" *ngIf="obs.skillDictionary?.checklists?.length">
      <hc-record-card
        *ngFor="let skill of obs.skillDictionary?.checklists"
        [recordCardConfig]="skill.config"
        (cardClicked)="navToSkill(skill.id)"
      ></hc-record-card>
    </div>
    <div class="search-container">
      <hc-heading size="h5">Search or Browse Checklists</hc-heading>
      <hc-input id="skillFilterInput" [control]="skillFilterControl" placeholder="Search" (focused)="scrollSkillInput()"></hc-input>
      <div class="accordion-container">
        <hc-accordion
          *ngFor="let letter of obs.skillDictionaryAccordionFiltered | keyvalue"
          [title]="letter.key"
          [expanded]="true"
          isList="true"
        >
          <div class="skill-link-list">
            <hc-link
              *ngFor="let skill of letter.value"
              [routerLink]="['/skills', skill.id]"
              linkType="linkButton"
              size="narrow"
            >
              {{ skill.config.name }}
            </hc-link>
          </div>
        </hc-accordion>
      </div>
    </div>
    <app-flow-handler
      [showRegularSaveButton]="false"
      [isListPage]="true"
    ></app-flow-handler>
  </div>
</ng-container>
