<ng-container
  *ngIf="{
    isMobile: isMobile$ | async,
    professionalHierarchy: professionalHierarchy$ | async,
    specialtyHierarchy: specialtyHierarchy$ | async,
    locationsData: locationsData$ | async,
    facilitiesData: facilitiesData$ | async,
    jobFilter: jobFilter$ | async,
    lookups: lookups$ | async
  } as obs"
>
  <div *ngIf="!obs.jobFilter" class="create-search">
    <hc-card class="search-card">
      <ng-container *ngTemplateOutlet="searchCriteria"></ng-container>
    </hc-card>
  </div>

  <div *ngIf="obs.jobFilter" class="edit-search">
    <ng-container *ngIf="!obs.isMobile; else mobileEditFilters">
      <div class="search-criteria-section">
        <hc-heading [size]="h4">Edit Search</hc-heading>
        <hc-accordion
          [headerTemplate]="searchCriteriaHeader"
          [bodyTemplate]="searchCriteria"
        >
        </hc-accordion>
      </div>
      <div class="advanced-filters-section">
        <hc-heading [size]="h4">Advanced Filters</hc-heading>
        <ng-container *ngTemplateOutlet="advancedFilters"></ng-container>
        <ng-container *ngTemplateOutlet="advancedFiltersButtons"></ng-container>
      </div>
    </ng-container>
    <ng-template #mobileEditFilters>
      <hc-card>
        <div class="mobile-edit-filter-buttons">
          <hc-button
            icon="edit"
            [appearance]="secondaryButtonAppearance"
            [size]="narrow"
            (buttonClick)="showMobileSearch()"
          >
            Edit Search
          </hc-button>
          <hc-button
            icon="filter_list"
            [appearance]="secondaryButtonAppearance"
            [size]="narrow"
            class="mobile-all-filters-button"
            (buttonClick)="showMobileFilters()"
          >
            All filters
            <hc-badge
              *ngIf="
                filterHasValues([
                  'minimumPay',
                  'shift',
                  'hoursPerWeek',
                  'radius',
                  'multiStateLicensure',
                  'firstTimeTraveler',
                  'anccMagnetHospitalCode',
                  'exclusiveDiverseProgram',
                  'startDate',
                  'assignmentLength'
                ]);
                let filterNum
              "
              class="mobile-all-filters-badge"
              [color]="purple"
              [isCircle]="true"
            >
              <hc-body [size]="micro">{{ filterNum }}</hc-body>
            </hc-badge>
          </hc-button>
        </div>
      </hc-card>
    </ng-template>
  </div>

  <ng-template #searchCriteriaFormControls>
    <div class="search-form-controls">
      <hc-hierarchical-multi-select-checkbox
        id="professionInput"
        #profesionalHierarchical
        [control]="form?.controls?.profession"
        [depth]="3"
        label="Profession"
        title="Select a profession"
        placeholder="Search Professions"
        [selectMultiple]="false"
        [showClearButton]="true"
        [data]="obs.professionalHierarchy"
        (selectionChanged)="professionChanged($event)"
        errorMessage="Profession, Location or Facility are required."
      >
      </hc-hierarchical-multi-select-checkbox>

      <hc-hierarchical-multi-select-checkbox
        id="specialtiesInput"
        *ngIf="obs.specialtyHierarchy?.length"
        [control]="form?.controls?.specialties"
        [depth]="1"
        label="Specialty"
        title="Select specialties"
        placeholder="Search Specialties"
        [selectMultiple]="true"
        [data]="obs.specialtyHierarchy"
      >
      </hc-hierarchical-multi-select-checkbox>

      <hc-hierarchical-multi-select-checkbox
        #locationInput
        id="locationInput"
        [control]="form?.controls?.locations"
        [depth]="1"
        label="Location"
        title="Select a location"
        placeholder="Search Locations"
        [selectMultiple]="false"
        [useDataFilter]="false"
        [showClearButton]="true"
        [data]="locationsData"
        (searchInputChanged)="locationSearch.next($event.eventValue)"
        errorMessage="Profession, Location or Facility are required."
      >
      </hc-hierarchical-multi-select-checkbox>

      <hc-hierarchical-multi-select-checkbox
        #facilityInput
        id="facilityInput"
        [control]="form?.controls?.facilities"
        [depth]="1"
        label="Facility"
        title="Select a facility"
        placeholder="Search Facilities"
        [selectMultiple]="false"
        [useDataFilter]="false"
        [showClearButton]="true"
        [data]="facilitiesData"
        (searchInputChanged)="facilitySearch.next($event.eventValue)"
        errorMessage="Profession, Location or Facility are required."
      >
      </hc-hierarchical-multi-select-checkbox>

      <hc-hierarchical-multi-select-checkbox
        id="contractTypeInput"
        [control]="form?.controls?.contractType"
        [depth]="1"
        label="Contract Type"
        title="Choose a Contract Type"
        placeholder="Choose a Contract Type"
        [selectMultiple]="false"
        [data]="contractTypes"
      >
      </hc-hierarchical-multi-select-checkbox>
    </div>
  </ng-template>

  <ng-template #advancedFilters>
    <div class="advanced-filters">
      <hc-accordion
        [headerTemplate]="shiftPayDetailsHeader"
        [bodyTemplate]="shiftPayDetails"
        (accordionClicked)="toggleBadge($event, 'showShiftPayBadge')"
      ></hc-accordion>
      <hc-accordion
        [headerTemplate]="locationDetailsHeader"
        [bodyTemplate]="locationDetails"
        (accordionExpanded)="toggleBadge($event, 'showLocationBadge')"
      ></hc-accordion>
      <hc-accordion
        [headerTemplate]="facilityDetailsHeader"
        [bodyTemplate]="facilityDetails"
        (accordionExpanded)="toggleBadge($event, 'showFacilityBadge')"
      ></hc-accordion>
      <hc-accordion
        [headerTemplate]="assignmentDetailsHeader"
        [bodyTemplate]="assignmentDetails"
        (accordionExpanded)="toggleBadge($event, 'showAssignmentBadge')"
      ></hc-accordion>
    </div>
  </ng-template>
  <ng-template #advancedFiltersButtons>
    <div class="advanced-filters-buttons">
      <hc-button
        id="reset-filter-button"
        [appearance]="textButtonAppearance"
        [alwaysDisplayFullWidth]="true"
        (buttonClick)="
          clearValues([
            'minimumPay',
            'shift',
            'hoursPerWeek',
            'radius',
            'multiStateLicensure',
            'firstTimeTraveler',
            'anccMagnetHospitalCode',
            'exclusiveDiverseProgram',
            'startDate',
            'assignmentLength'
          ])
        "
      >
        Reset filters
      </hc-button>
      <hc-button
        id="apply-filter-button"
        [appearance]="secondaryButtonAppearance"
        [alwaysDisplayFullWidth]="true"
        (buttonClick)="baseForm?.saveCheck() ?? submitSearch(true)"
      >
        Apply filters
      </hc-button>
    </div>
  </ng-template>
  <ng-template #advancedFiltersButtonsMobile>
    <div class="mobile-search-buttons">
      <hc-button
        id="apply-filter-button"
        [alwaysDisplayFullWidth]="true"
        (buttonClick)="submitSearch(true)"
      >
        Apply filters
      </hc-button>
      <hc-button
        id="reset-filter-button"
        [appearance]="secondaryButtonAppearance"
        [alwaysDisplayFullWidth]="true"
        (buttonClick)="
          clearValues([
            'minimumPay',
            'shift',
            'hoursPerWeek',
            'radius',
            'multiStateLicensure',
            'firstTimeTraveler',
            'anccMagnetHospitalCode',
            'exclusiveDiverseProgram',
            'startDate',
            'assignmentLength'
          ])
        "
      >
        Reset filters
      </hc-button>
    </div>
  </ng-template>

  <ng-template #searchCriteriaHeader>
    <hc-heading class="accordion-header" [size]="h6">Search Criteria</hc-heading>
  </ng-template>
  <ng-template #searchCriteria>
    <app-base-form
      *ngIf="form"
      #baseForm
      class="search-base-form"
      [form]="form"
      [isLoadingObs]="formLoading$"
      [isSavingObs]="formLoading$"
      [formHeaderAttributes]="formHeaderAttributes"
      (saveableEmitter)="submitSearch($event)"
    >
      <ng-container ngProjectAs="[formControls]">
        <ng-container
          *ngTemplateOutlet="searchCriteriaFormControls"
        ></ng-container>
      </ng-container>

      <ng-container ngProjectAs="[formButtons]">
        <div class="search-button">
          <hc-button
            [alwaysDisplayFullWidth]="true"
            (buttonClick)="baseForm.saveCheck()"
          >Search
          </hc-button
          >
        </div>
      </ng-container>
    </app-base-form>

    <div *ngIf="!form">
      <div class="search-form-loading">
        <hc-skeleton-loading-bar
          [length]="large"
        ></hc-skeleton-loading-bar>
        <hc-skeleton-loading-bar
          [length]="large"
        ></hc-skeleton-loading-bar>
        <hc-skeleton-loading-bar
          [length]="large"
        ></hc-skeleton-loading-bar>
        <hc-skeleton-loading-bar
          [length]="large"
        ></hc-skeleton-loading-bar>
      </div>
    </div>
  </ng-template>

  <ng-template #shiftPayDetailsHeader>
    <div class="adv-filter-header">
      <hc-heading class="accordion-header" [size]="h6"
      >Shift and pay details
      </hc-heading
      >
      <hc-badge
        #shiftPayBadge
        [isCircle]="true"
        [color]="headerBadgeColor"
        *ngIf="
          showShiftPayBadge &&
            filterHasValues(['minimumPay', 'shift', 'hoursPerWeek']);
          let filterNum
        "
      >
        {{ filterNum }}
      </hc-badge>
    </div>
  </ng-template>
  <ng-template #shiftPayDetails>
    <div class="advanced-search-form-controls">
      <div class="slider">
        <hc-body [size]="small">Minimum weekly pay</hc-body>
        <mat-slider
          thumbLabel
          aria-label="radius"
          [displayWith]="displaySliderOptions(minimumPayOptions)"
          [max]="minimumPayOptions.length - 1"
          [min]="0"
          [step]="1"
          [formControl]="form?.controls?.minimumPay"
        ></mat-slider>
      </div>
      <div class="checkboxes-container">
        <hc-body [size]="small">Rotation</hc-body>
        <div class="checkbox-array" *ngIf="shifts">
          <app-checkbox-form-array
            [newStyling]="true"
            [checkboxFormArray]="form?.controls?.shift"
            [lookup]="shifts"
            [arrayValues]="obs.jobFilter?.shift ?? []"
          ></app-checkbox-form-array>
        </div>
      </div>
      <div class="checkboxes-container">
        <hc-body [size]="small">Hours per week</hc-body>
        <div class="checkbox-array" *ngIf="hoursPerWeek">
          <app-checkbox-form-array
            [newStyling]="true"
            [checkboxFormArray]="form?.controls?.hoursPerWeek"
            [lookup]="hoursPerWeek"
            [arrayValues]="obs.jobFilter?.hoursPerWeek ?? []"
          ></app-checkbox-form-array>
        </div>
      </div>
      <div class="clear-button">
        <hc-button
          class='hc-button'
          [appearance]="textButtonAppearance"
          [size]="buttonNarrow"
          icon="clear"
          (buttonClick)="clearValues(['minimumPay', 'shift', 'hoursPerWeek'])"
        >
          Clear shift details
        </hc-button>
      </div>
    </div>
  </ng-template>

  <ng-template #locationDetailsHeader>
    <div class="adv-filter-header">
      <hc-heading class="accordion-header" [size]="h6"
      >Location details
      </hc-heading
      >
      <hc-badge
        #locationBadge
        [isCircle]="true"
        [color]="headerBadgeColor"
        *ngIf="showLocationBadge && filterHasValues(['radius']); let filterNum"
      >{{ filterNum }}</hc-badge
      >
    </div>
  </ng-template>
  <ng-template #locationDetails>
    <div class="advanced-search-form-controls">
      <div id="radius-slider" class="slider">
        <hc-body [size]="small">Radius (miles)</hc-body>
        <mat-slider
          *ngIf="!locationLocked()"
          thumbLabel
          aria-label="radius"
          [displayWith]="displaySliderOptions(radiusOptions)"
          [max]="radiusOptions.length - 1"
          [min]="0"
          [step]="1"
          [formControl]="form?.controls?.radius"
        ></mat-slider>
        <hc-body [size]="small" *ngIf="locationLocked()">
          Radius is not available for Multi-State Licensure and Temporary
          License States.
        </hc-body>
      </div>
      <div class="clear-button">
        <hc-button
          class='hc-button'
          [appearance]="textButtonAppearance"
          [size]="buttonNarrow"
          icon="clear"
          (buttonClick)="clearValues(['radius'])"
        >
          Clear location details
        </hc-button>
      </div>
    </div>
  </ng-template>

  <ng-template #facilityDetailsHeader>
    <div class="adv-filter-header">
      <hc-heading class="accordion-header" [size]="h6"
      >Facility details
      </hc-heading
      >
      <hc-badge
        #facilityBadge
        [isCircle]="true"
        [color]="headerBadgeColor"
        *ngIf="
          showFacilityBadge &&
            filterHasValues([
              'firstTimeTraveler',
              'anccMagnetHospitalCode',
              'exclusiveDiverseProgram',
              'multiStateLicensure'
            ]);
          let filterNum
        "
      >{{ filterNum }}</hc-badge
      >
    </div>
  </ng-template>
  <ng-template #facilityDetails>
    <div class="advanced-search-form-controls">
      <div class="checkboxes-container">
        <div class="facility-criteria-container">
          <hc-body [size]="small">Facility Criteria</hc-body>
          <div class="reverse-icon-button">
            <hc-button
              [appearance]="textButtonAppearance"
              [size]="buttonNarrow"
              icon="info"
              [iconType]="round"
              (buttonClick)="showFacilityDetailsInfo()"
            >
              Learn more
            </hc-button>
          </div>
        </div>
        <div class="checkbox-array facility-checkboxes">
          <hc-checkbox
            text="Multistate (compact) licensure"
            [control]="form.controls.multiStateLicensure"
          ></hc-checkbox>
          <hc-checkbox
            text="Accepts first timers"
            [control]="form.controls.firstTimeTraveler"
          ></hc-checkbox>
          <hc-checkbox
            text="Magnet hospital"
            [control]="form.controls.anccMagnetHospitalCode"
          ></hc-checkbox>
          <hc-checkbox
            text="Exclusive diverse"
            [control]="form.controls.exclusiveDiverseProgram"
          ></hc-checkbox>
        </div>
      </div>

      <div class="clear-button">
        <hc-button
          class='hc-button'
          [appearance]="textButtonAppearance"
          [size]="buttonNarrow"
          icon="clear"
          (buttonClick)="
            clearValues([
              'multiStateLicensure',
              'firstTimeTraveler',
              'anccMagnetHospitalCode',
              'exclusiveDiverseProgram'
            ])
          "
        >
          Clear facility details
        </hc-button>
      </div>
    </div>
  </ng-template>

  <ng-template #assignmentDetailsHeader>
    <div class="adv-filter-header">
      <hc-heading class="accordion-header" [size]="h6"
      >Assignment details
      </hc-heading
      >
      <hc-badge
        id="assignmentBadge"
        [isCircle]="true"
        [color]="headerBadgeColor"
        *ngIf="
          showAssignmentBadge &&
            filterHasValues(['startDate', 'assignmentLength']);
          let filterNum
        "
      >{{ filterNum }}</hc-badge
      >
    </div>
  </ng-template>
  <ng-template #assignmentDetails>
    <div class="advanced-search-form-controls">
      <div class="checkboxes-container">
        <hc-body [size]="small">Start Date</hc-body>
        <div class="checkbox-array" *ngIf="startDates">
          <app-checkbox-form-array
            [newStyling]="true"
            [checkboxFormArray]="form?.controls?.startDate"
            [lookup]="startDates"
            [arrayValues]="obs.jobFilter?.startDate ?? []"
          ></app-checkbox-form-array>
        </div>
      </div>
      <div class="checkboxes-container">
        <hc-body [size]="small">Assignment Length</hc-body>
        <div class="checkbox-array" *ngIf="assignmentLengths">
          <app-checkbox-form-array
            [newStyling]="true"
            [checkboxFormArray]="form?.controls?.assignmentLength"
            [lookup]="assignmentLengths"
            [arrayValues]="obs.jobFilter?.assignmentLength ?? []"
          ></app-checkbox-form-array>
        </div>
      </div>

      <div class="clear-button">
        <hc-button
          class='hc-button'
          [appearance]="textButtonAppearance"
          [size]="buttonNarrow"
          icon="clear"
          (buttonClick)="clearValues(['startDate', 'assignmentLength'])"
        >
          Clear assignment details
        </hc-button>
      </div>
    </div>
  </ng-template>

  <ng-template #facilityDetailsModalTemplate>
    <div class="facility-details-modal">
      <hc-accordion [isList]="true" title="Exclusive Diverse Program">
        <hc-body>
          A one-of-a-kind opportunity to bridge the gap between the facility’s immediate needs and the arrival of global healthcare professionals from our sister company, Heath Carousel International.
        </hc-body>
      </hc-accordion>
      <hc-accordion [isList]="true" title="Magnet hospital">
        <hc-body>
          Organizations designated by the ANCC as demonstrating the highest
          standard of excellence in nursing and patient care as well as
          innovation in professional nursing practice. Only 8.28% of all U.S.
          hospitals have Magnet status. To nurses, this means education and
          development through every career stage, which leads to greater
          autonomy at the bedside.
        </hc-body>
      </hc-accordion>
      <hc-accordion [isList]="true" title="Nurse Licensure Compact (NLC) States">
        <hc-body>
          With an NLC (aka "Compact License" or "Multi-State"), nurses can practice in any of the NLC states without having to obtain additional licenses. This increases access to care while maintaining public protection
          at the state level. To be eligible for an NLC, you must live in a compact state and meet the uniform licensure requirements.
        </hc-body>
      </hc-accordion>
    </div>
  </ng-template>

  <ng-template #mobileSearch>
    <div class="mobile-sheet-header">
      <hc-heading [size]="h5">Edit search</hc-heading>
      <hc-icon-prompt-button
        icon="close"
        (promptClick)="matSheet.dismiss()"
      ></hc-icon-prompt-button>
    </div>
    <div class="mobile-sheet-filters">
      <app-base-form
        *ngIf="form"
        #baseForm
        class="search-base-form"
        [form]="form"
        [isLoadingObs]="formLoading$"
        [isSavingObs]="formLoading$"
        [formHeaderAttributes]="formHeaderAttributes"
        (saveableEmitter)="submitSearch($event)"
      >
        <ng-container ngProjectAs="[formControls]">
          <div class="mobile-search-controls-container">
            <ng-container
              *ngTemplateOutlet="searchCriteriaFormControls"
            ></ng-container>
          </div>
        </ng-container>

        <ng-container ngProjectAs="[formButtons]">
          <div class="mobile-search-buttons">
            <div class="search-button">
              <hc-button
                [alwaysDisplayFullWidth]="true"
                (buttonClick)="baseForm.saveCheck()"
              >
                Save
              </hc-button>
            </div>
            <div class="search-button">
              <hc-button
                [alwaysDisplayFullWidth]="true"
                [appearance]="secondaryButtonAppearance"
                (buttonClick)="matSheet.dismiss()"
              >
                Cancel
              </hc-button>
            </div>
          </div>
        </ng-container>
      </app-base-form>
    </div>
  </ng-template>

  <ng-template #mobileAdvancedFilters>
    <div class="mobile-sheet-header">
      <hc-heading [size]="h5">All filters</hc-heading>
      <hc-icon-prompt-button
        icon="close"
        (promptClick)="matSheet.dismiss()"
      ></hc-icon-prompt-button>
    </div>
    <div class="mobile-sheet-filters advanced-sheet-filters">
      <div class="mobile-advanced-controls-container advanced-filters-section">
        <ng-container *ngTemplateOutlet="advancedFilters"></ng-container>
      </div>
      <ng-container
        *ngTemplateOutlet="advancedFiltersButtonsMobile"
      ></ng-container>
    </div>
  </ng-template>
</ng-container>
