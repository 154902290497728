export abstract class QueryParams {
  public static readonly MERLIN_ID = 'merlinId';
  public static readonly TEAM_ID = 'teamid';
  public static readonly USER_NAME = 'username';
  public static readonly FIRST_NAME = 'firstname';
  public static readonly LAST_NAME = 'lastname';
  public static readonly PHONE_NUMBER = 'phonenumber';
  public static readonly EMAIL = 'email';
  public static readonly BUSINESS_UNIT = 'buId';
  public static readonly LOCATION = 'location';
  public static readonly PROFESSION = 'profession';
  public static readonly SPECIALTY = 'specialty';
  public static readonly SUBSPECIALTY = 'subspeciality';
  public static readonly PAST_EXPERIENCE = 'pastexperience';
  public static readonly IS_REFERRED = 'isreferred';
  public static readonly REFERRED_BY = 'referredby';
  public static readonly CRISIS = 'crisis';
  public static readonly RADIUS = 'radius';
  public static readonly STARTDATE = 'startdate';
  public static readonly STARTDATE_NEXT_30_DAYS = 'startdate[1]';
  public static readonly STARTDATE_31_60_DAYS = 'startdate[2]';
  public static readonly STARTDATE_60_PLUS_DAYS = 'startdate[3]';
  public static readonly LENGTH_0_6_WEEKS = 'length[1]';
  public static readonly LENGTH_7_13_WEEKS = 'length[2]';
  public static readonly LENGTH_14_20_WEEKS = 'length[3]';
  public static readonly LENGTH_21_26_WEEKS = 'length[4]';
  public static readonly LENGTH_OVER_26_WEEKS = 'length[5]';
  public static readonly SHIFT_DAYS = 'shift[948050000]';
  public static readonly SHIFT_MIDS = 'shift[948050002]';
  public static readonly SHIFT_NIGHTS = 'shift[948050003]';
  public static readonly SHIFT_ROTATING = 'shift[948050004]';
  public static readonly WEEKLYHOURS_LESS_36 = 'weeklyhours[948050000]';
  public static readonly WEEKLYHOURS_36 = 'weeklyhours[948050001]';
  public static readonly WEEKLYHOURS_40 = 'weeklyhours[948050002]';
  public static readonly WEEKLYHOURS_48 = 'weeklyhours[948050003]';
  public static readonly WEEKLYHOURS_48_PLUS = 'weeklyhours[948050004]';
  public static readonly ADVANCED_JOBS = 'advanced_jobs';
  public static readonly WALKTHROUGH_STATES = 'walkthrough_states';
  public static readonly SORT_BY = 'SortBy';
  public static readonly NEXT_PAGE = 'NextPage';
  public static readonly CURRENT_PAGE = 'CurrentPage';
  public static readonly REDIRECT_TO_MARKETING = 'rtm';
  public static readonly CONTRACT_TYPE = 'contracttype';
  public static readonly RECRUITER_ID = 'recruiterid';
}

export const DATE_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export abstract class TeamIds {
  public static readonly MSO = '7C04A655-7BED-E611-8103-E0071B6A1051';
  public static readonly NEXT = '1517DDC9-4066-E711-8113-E0071B6A1051';
  public static readonly THS = 'EED3552C-407F-E611-80F1-5065F38A5961';
  public static readonly HCTN = '51E7175A-3100-E711-8104-E0071B66CFE1';
  public static readonly PPUSA = 'ff405532-407f-e611-80f1-5065f38a5961';
}

export abstract class AppVariables {
  public static readonly PRESIGNINNAV = 'preSignInNav';
}
