import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Params, ActivatedRoute } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { AppState } from 'src/app/services';

@Injectable()
export class NotFoundResolve implements Resolve<any> {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _appState: AppState
  ) { }

  referenceId: string;

  resolve(
  ): Observable<any> | Promise<any> | any {
    this._appState.isLoaded = true;
    return of(1);
  }

}
