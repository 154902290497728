import { FormControl } from '@angular/forms';
import { IDropdownData } from 'hc-design-system-lib/lib/components/form/form.interfaces';

export class License {
  id: string;
  candidateId: string;
  certification: string;
  certificationId: string;
  licenseNumber: string;
  type: number;
  description: string;
  stateId: string;
  issuingBody: string;
  compactLicense: number;
  primary: number;
  licenseName: string;
  expirationDate: Date;
  statusId: number;
  sharepointURL: string;
  qualificationId?: string;
  hasNursysLink: boolean;
  issuingBodyId: string;
  issuingDate: Date;
  qualificationStatusId?: string;
}

export class LicenseForm {
  licenseNumber: FormControl<string>;
  licenseState: FormControl<IDropdownData>;
  dateOfBirth?: FormControl<Date | null>;
  ssnLast4?: FormControl<string | null>;
  issuingDate?: FormControl<Date | null>;
  expirationDate?: FormControl<Date | null>;
  fileUpload?: FormControl<FileList | null>;
}

export class NursysSetup {
  licenseNumber: string;
  stateCode: string;
  dateOfBirth: Date;
  ssnLast4: number;
}
