import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NavHelper } from '../services';
import { API_ROUTES } from '../common';
import { NotificationService } from 'hc-design-system-lib';
import { ErrorMessages } from 'src/app/common/error-messages';

@Injectable()
export class BadEntityInterceptor implements HttpInterceptor {

  constructor(
    private _navHelper: NavHelper,
    private _notificationService: NotificationService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.isNotExcludedRoute(req) ?
      next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404 || error.status === 400) {
            this._navHelper.goToDashBoard();
            this._notificationService.showNotification(
              ErrorMessages.pageNotFound,
              'error');
            return EMPTY;
          }
          return next.handle(req);
        })) : next.handle(req);
  }

  isNursePortalApiReq(req: HttpRequest<any>): boolean {
    return req.url.includes(environment.apiBaseUrl);
  }

  isFileReq(req: HttpRequest<any>): boolean {
    return req.url.includes(environment.apiBaseUrl + API_ROUTES.files);
  }

  isApplicationReq(req: HttpRequest<any>): boolean {
    return req.url.includes(environment.apiBaseUrl + API_ROUTES.job + API_ROUTES.apply)
      || req.url.includes(environment.apiBaseUrl + API_ROUTES.job + API_ROUTES.updateApplication);
  }

  isNotSimilarJobs(req: HttpRequest<any>): boolean {
    return req.url.includes(environment.apiBaseUrl + API_ROUTES.job + API_ROUTES.similarJob);
  }

  isNotExcludedRoute(req: HttpRequest<any>): boolean {
    return this.isNursePortalApiReq(req) && !this.isFileReq(req) && !this.isApplicationReq(req) && !this.isNotSimilarJobs(req);
  }
}


