<ng-container
  *ngIf="{
    pendingReferences: pendingReferences$ | async,
    completedReferences: completedReferences$ | async,
    allReferences: allReferences$ | async,
    referencesLoading: referencesLoading$ | async,
    pendingNonChargeNurseReferences: pendingNonChargeNurseReferences$ | async,
    stateLookup: stateLookup$ | async,
    positionTitleLookup: positionTitleLookup$ | async
  } as observables"
>
  <app-loading-screen-spinner *ngIf="observables.referencesLoading; else referencesContent"></app-loading-screen-spinner>
  <ng-template #referencesContent>
    <div class="tasks-container">
      <div class="tasks-desc-section">
        <hc-body>
          At least two <b>confirmed</b> references are required for your job application to be submitted to a facility.
          <b>References must be charge nurses or higher at a facility from your Work History.</b>
          <br />
          <br />
          Once you add a reference, they’ll be notified via email and will need to confirm they are a valid reference. We recommend adding at least four references to increase
          the chance of a response.
        </hc-body>
      </div>

      <div *ngIf="observables.pendingNonChargeNurseReferences?.length < maxRefsAllowed" class="tasks-add-button">
        <hc-icon-prompt-button icon="add_circle" (click)="openReferenceFormDialog()">Add a Reference</hc-icon-prompt-button>
      </div>

      <div class="tasks-list-section">
        <hc-record-card
          *ngFor="let reference of observables.pendingReferences"
          [recordCardConfig]="fillCardDetails(reference, false)"
          (cardClicked)="openReferenceFormDialog(reference)"
        >
        </hc-record-card>
        <hc-record-card *ngFor="let reference of observables.completedReferences" [recordCardConfig]="fillCardDetails(reference, true)"> </hc-record-card>
      </div>

      <app-flow-handler
        [isListPage]="true"
        [showRegularSaveButton]="false"
      >
      </app-flow-handler>
    </div>
  </ng-template>

  <ng-template #addReferenceForm>
    <app-reference-modal [showSaveAndAddAnother]="observables.pendingNonChargeNurseReferences?.length < maxRefsAllowed - 1"></app-reference-modal>
  </ng-template>

  <ng-template #editReferenceForm>
    <app-reference-modal [reference]="currentlyEditingReference"></app-reference-modal>
  </ng-template>
</ng-container>