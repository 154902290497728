import { createSelector } from '@ngrx/store';
import { IAppState } from '../app/app.state';
import { ICertificationsState } from './certifications.state';

const selectCertificationsState = (state: IAppState) => state.certifications;

export const selectCertifications = createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.certifications?.data
);

export const selectCertificationsLoading = createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.certifications?.loading
);

export const selectCertificationsError = createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.certifications?.error
);

export const selectCertificationsExpiration = createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.certifications?.expirationDate
);

export const selectCertificationById = (id: string) => createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.certifications?.data.find(cert => cert.id === id)
);

export const selectCertificationPopulate = createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.populatedCertification?.data
);

export const selectCertificationPopulateId = createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.populatedCertification?.requestId
);

export const selectCertificationPopulateLoading = createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.populatedCertification?.loading
);

export const selectCertificationPopulateError = createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.populatedCertification?.error
);

export const selectCertificationAction = createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.certificationAction
);

export const selectCertificationActionData = createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.certificationAction?.data
);

export const selectCertificationActionRequestId = createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.certificationAction?.requestId
);

export const selectCertificationActionLoading = createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.certificationAction?.loading
);

export const selectCertificationActionError = createSelector(
  selectCertificationsState,
  (state: ICertificationsState) => state.certificationAction?.error
);

