<ng-container
  *ngIf="{
    isMobile: isMobile$ | async,
    nurseData: nurse$ | async
  } as obs"
>
  <hc-tile-card
    [cardConfig]="tileConfig"
    [templateReference]="quickLinksTemplate"
  ></hc-tile-card>

  <ng-template #quickLinksTemplate>
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap.gt-sm="68px"
      fxLayoutGap="16px"
    >
      <div class="quick-links">
        <ng-container
          *ngFor="let quickLink of quickLinks; count as count; even as even"
        >
          <div class="a quick-link" (click)="quickLink.action()">
            <div class="quick-link-icon-button">
              <mat-icon class="quick-link-icon">{{ quickLink.icon }}</mat-icon>
            </div>
            <hc-body [color]="bodyPurple">{{ quickLink.text }}</hc-body>
          </div>

          <mat-divider
            [vertical]="true"
            *ngIf="even && count != 1"
          ></mat-divider>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>
