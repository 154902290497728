 <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="empty-background" fxLayoutGap="24px">
  <ng-content>

  </ng-content>
  <div class="empty-area" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
    <div class="empty-text">
      {{text}}
    </div>
    <div> 
      <button segmentTrackCTA ctaText="Browse Jobs" destinationUrl="/job-search" (click)="switchToJobSearch()">
        <div>
          Browse&nbsp;Jobs
        </div>
        <img src="/assets/img/arrow-right.svg" />
      </button>
    </div>
  </div>
</div>
