import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-empty-jobs',
  templateUrl: './empty-jobs.component.html',
  styleUrls: ['./empty-jobs.component.scss']
})
export class EmptyJobsComponent implements OnInit {
  @Input() text: string;
  @Input() setTabIndex: (index: any) => void;

  constructor() { }

  ngOnInit() {
  }

  switchToJobSearch() {
    this.setTabIndex(0);
  }
}
