<div class="content-container">
  <div class="dialog-image">
    <img alt="THIS Logo" src="/assets/img/value-carousel/FIL.png" />
  </div>
  <div class="dialog-content">
    <hc-heading size="h5" class="dialog-header"> {{ job.applied || job.hasStarted ? 'Welcome back!' : "Let's get started!" }} </hc-heading>
    <hc-body>
      We need a few {{ job.applied || job.hasStarted ? 'more' : '' }} details from you to complete this application. The information you provide will be saved to your
      <b>Profile</b>. You can edit your Profile at any time.
    </hc-body>
    <hc-body> For future applications, the details will be pulled from your Profile, so you only have to enter them once! </hc-body>
    <div class="submit-buttons">
      <hc-button
        id="start-application"
        [appearance]="primaryButtonAppearance"
        segmentTrackCTA
        (buttonClick)="apply()"
        [alwaysDisplayFullWidth]="true"
      >
        {{ job.applied || job.hasStarted ? 'Continue Application' : 'Start Application' }}
      </hc-button>
      <hc-button [appearance]="secondaryButtonAppearence" segmentTrackCTA (buttonClick)="cancel()" [alwaysDisplayFullWidth]="true">Cancel</hc-button>
    </div>
  </div>
</div>
