import { UiActions, EUiActions } from "src/app/store/ui/ui.actions";
import { initialUiState, UiState } from "src/app/store/ui/ui.state";

export function uiReducer(
  state = initialUiState,
  action: UiActions,
): UiState {
  switch (action.type) {
    case EUiActions.SetScreenWidth:
      return {
        ...state,
        screenWidth: {
          data: action.payload,
          error: null,
          loading: false
        }
      }
    default: {
      return state;
    }
  }
}