import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss']
})
export class LoginRedirectComponent {
  constructor(
    private authService: AuthenticationService
  ) {

  }

  ngOnInit() {
    this.authService.loginCallback();
  }
}
