<div id="filter-bottom-sheet" fxLayout="column">
  <div class="header-area" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="header global-title">Filter</div>
    <button id="filterBottomSheetCancel" mat-button color="primary" (click)="cancel()">
      <mat-icon class="global-icon-gray">close</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="overflow filter-form-area">
  <!-- <app-job-search-filter-area [opened]="true" (mainClick)="onMainClick()" (closeClick)="cancel()"></app-job-search-filter-area> -->

</div>
</div>
