import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import {
  ESegmentActions,
  FacilityViewed,
  SegmentIdentify,
  OnboardingStepCompleted,
  SegmentActions,
  PhoneNumberClicked,
  ExternalLinkClicked,
  InternalLinkClicked,
  OnboardingStepViewed,
  DocumentUploaded,
  EducationsViewed,
  WorkHistoriesViewed,
  SavedJobsViewed,
  SkillChecklistViewed,
  SkillChecklistCompleted,
  ApplicationsViewed,
  ApplicationStarted,
  NavigationItemClicked,
  CallToActionClicked,
  SegmentSoftRegister,
  OnboardingCompleted,
  TasksViewed,
  SegmentReady,
} from './segment.actions';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import {
  IJob,
  ILookup,
  NurseModel,
  Submittal,
  WorkHistory,
} from 'src/app/common';
import { EUserContextActions } from '../userContext/userContext.actions';
import {
  selectStateLookup,
  selectWeeklyHoursLookup,
  selectTraumaLevelLookup,
  selectProfessionLookup,
  selectSpecialtyLookup,
  selectLookupsLoading,
  selectShiftLookup,
  selectAssignmentLengthsLookup,
  selectBedSizeLookup,
  selectErVisitsPerYearLookup,
  selectStartDatesLookup,
  selectBirthsPerYearLookup,
  selectTechnologyLookup,
  selectProficiencyLookup,
  selectPositionTitleLookup,
  selectProfessionTypesLookup,
  selectYesNoIdLookup,
} from '../lookups/lookups.selectors';
import {
  selectNurseData,
  selectNurseDataLoading,
} from '../userContext/userContext.selectors';
import { SegmentService } from 'src/app/services/segment.service';
import { blsCertificateId, emptyGuid } from 'src/app/common/constants';
import {
  EJobsActions,
  GetSpecificJobSuccess,
  ArchiveSubmittalSuccess,
  SetSavedJobSuccess,
  SetJobPreferencesSuccess,
  GetAvailableJobsSuccess,
} from '../jobs/jobs.actions';
import {
  selectJobFilterV2,
  selectLastArchivedSubmittal,
} from '../jobs/jobs.selectors';
import { NursePortalApi } from 'src/app/services';
import {
  DeleteLicenseSuccess,
  ELicensesActions,
} from '../licenses/licenses.actions';
import {
  AddCertificationSuccess,
  ECertificationsActions,
} from '../certifications/certifications.actions';
import {
  AddTechnologySuccess,
  DeleteTechnologySuccess,
  ETechnologyActions,
} from '../technologies/technology.actions';
import {
  AddReferenceSuccess,
  EReferencesActions,
} from '../references/references.actions';
import {
  selectWorkHistoryList,
  selectWorkHistoryListLoading,
} from '../workHistory/workHistoryContext.selectors';
import {
  selectPreviousTasks,
  selectTasks,
  selectTasksLoading,
} from '../tasks/tasks.selectors';
import { NurseTask } from 'src/app/common/models/db-objects';
import { ETasksActions } from '../tasks/tasks.actions';
import {
  AddWorkHistorySuccess,
  DeleteWorkHistorySuccess,
  EWorkHistoryContextActions,
} from '../workHistory/workHistoryContext.actions';
import {
  AddEducationSuccess,
  DeleteEducationSuccess,
  EEducationActions,
} from '../education/education.actions';
import { InitializeAndGetFlags } from '../flags/flags.actions';

@Injectable({
  providedIn: 'root',
})
export class SegmentEffects {
  constructor(
    private actions$: Actions,
    private _store: Store,
    private _segmentService: SegmentService,
    private _nursePortalApi: NursePortalApi
  ) {}

  ready$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ESegmentActions.SegmentReady),
        tap((action: SegmentReady) => {
            this._store.dispatch(new InitializeAndGetFlags())
        }
        )
      ),
    { dispatch: false }
  );

  identify$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ESegmentActions.SegmentIdentify),
        filter(
          (action: SegmentIdentify) => action.id && action.id !== emptyGuid
        ),
        tap((action: SegmentIdentify) =>
          this._segmentService.identify(action.id, action.traits)
        )
      ),
    { dispatch: false }
  );

  storeBasedIdentify$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(EUserContextActions.GetNurseSuccess),
        withLatestFrom(
          this._store.pipe(select(selectNurseData)),
          this._store.pipe(select(selectProfessionLookup)),
          this._store.pipe(select(selectSpecialtyLookup)),
          this._store.pipe(select(selectStateLookup)),
          this._store.pipe(select(selectTasks)),
          this._store.pipe(select(selectNurseDataLoading)),
          this._store.pipe(select(selectLookupsLoading)),
          this._store.pipe(select(selectTasksLoading)),
        ),
        filter(
          ([
            ,
            nurse,
            professionLookup,
            specialtyLookup,
            stateLookup,
            tasks,
            nurseLoading,
            lookupsLoading,
            tasksLoading
          ]) =>
            nurse &&
            nurse.merlinId &&
            nurse.merlinId !== emptyGuid &&
            professionLookup &&
            specialtyLookup &&
            stateLookup &&
            tasks &&
            !nurseLoading &&
            !lookupsLoading &&
            !tasksLoading
        ),
        tap(([, nurse, professionLookup, specialtyLookup, stateLookup, tasks, , ,]) => {
          this._segmentService.storeBasedIdentify(
            nurse,
            professionLookup,
            specialtyLookup,
            stateLookup,
            tasks
          );
        })
      ),
    { dispatch: false }
  );

  segmentSoftRegister$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ESegmentActions.SegmentSoftRegister),
        filter((action: SegmentSoftRegister) => (action.email ? true : false)),
        tap((action: SegmentSoftRegister) =>
          this._segmentService.softRegister(action.email)
        )
      ),
    { dispatch: false }
  );

  jobsSearched$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(EJobsActions.GetAvailableJobsSuccess),
        withLatestFrom(
          this._store.pipe(select(selectJobFilterV2)),
          this._store.pipe(select(selectWeeklyHoursLookup)),
          this._store.pipe(select(selectTraumaLevelLookup)),
          this._store.pipe(select(selectProfessionLookup)),
          this._store.pipe(select(selectShiftLookup)),
          this._store.pipe(select(selectAssignmentLengthsLookup)),
          this._store.pipe(select(selectStartDatesLookup)),
          this._store.pipe(select(selectBedSizeLookup)),
          this._store.pipe(select(selectErVisitsPerYearLookup)),
          this._store.pipe(select(selectBirthsPerYearLookup)),
          this._store.pipe(select(selectLookupsLoading))
        ),
        filter(
          ([
            ,
            jobFilterV2,
            weeklyHoursLookup,
            traumaLevelLookup,
            professionLookup,
            shiftLookup,
            assignmentLengthsLookup,
            startDatesLookup,
            bedSizeLookup,
            erVisitsPerYearLookup,
            birthsPerYearLookup,
            lookupsLoading,
          ]) =>
            jobFilterV2 && 
            weeklyHoursLookup &&
            traumaLevelLookup &&
            professionLookup &&
            shiftLookup &&
            assignmentLengthsLookup &&
            startDatesLookup &&
            bedSizeLookup &&
            erVisitsPerYearLookup &&
            birthsPerYearLookup &&
            !lookupsLoading
        ),
        tap(
          ([
            action,
            jobFilterV2,
            weeklyHoursLookup,
            traumaLevelLookup,
            professionLookup,
            shiftLookup,
            assignmentLengthsLookup,
            startDatesLookup,
            bedSizeLookup,
            erVisitsPerYearLookup,
            birthsPerYearLookup,
          ]) =>
            this._segmentService.jobsSearched(
              jobFilterV2,
              weeklyHoursLookup,
              traumaLevelLookup,
              professionLookup,
              shiftLookup,
              assignmentLengthsLookup,
              startDatesLookup,
              bedSizeLookup,
              erVisitsPerYearLookup,
              birthsPerYearLookup,
              action as unknown as GetAvailableJobsSuccess
            )
        )
      ),
    { dispatch: false }
  );

  jobViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(EJobsActions.GetSpecificJobSuccess),
        withLatestFrom(
          this._store.pipe(select(selectStateLookup)),
          this._store.pipe(select(selectShiftLookup)),
          this._store.pipe(select(selectLookupsLoading))
        ),
        filter(
          ([action, stateLookup, shiftLookup, lookupLoading]: [
            GetSpecificJobSuccess,
            Map<string, ILookup<string>>,
            Map<number, ILookup<number>>,
            boolean
          ]) => action?.payload && stateLookup && shiftLookup && !lookupLoading
        ),
        tap(
          ([action, stateLookup, shiftLookup]: [
            GetSpecificJobSuccess,
            Map<string, ILookup<string>>,
            Map<number, ILookup<number>>,
            boolean
          ]) => {
            this._segmentService.jobViewed(
              action.payload,
              stateLookup,
              shiftLookup
            );
          }
        )
      ),
    { dispatch: false }
  );

  jobSaved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(EJobsActions.SetSavedJobSuccess),
        tap((action: SetSavedJobSuccess) => {
          if (action.payload?.saveValue) {
            this._segmentService.jobSaved(action.payload.job.id);
          }
        })
      ),
    { dispatch: false }
  );

  savedJobsViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.SavedJobsViewed),
        tap((action: SavedJobsViewed) => {
          this._segmentService.savedJobsViewed();
        })
      ),
    { dispatch: false }
  );

  applicationStarted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ESegmentActions.ApplicationStarted),
        tap((action: ApplicationStarted) => {
          if (action.jobId) {
            this._segmentService.applicationStarted(action.jobId);
          }
        })
      ),
    { dispatch: false }
  );

  applicationsViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ESegmentActions.ApplicationsViewed),
        tap((action: ApplicationsViewed) =>
          this._segmentService.applicationsViewed(action.numberOfApplications)
        )
      ),
    { dispatch: false }
  );

  applicationArchived$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(EJobsActions.ArchiveSubmittalSuccess),
        withLatestFrom(
          this._store.pipe(select(selectNurseData)),
          this._store.pipe(select(selectLastArchivedSubmittal)),
          this._store.pipe(select(selectStateLookup)),
          this._store.pipe(select(selectShiftLookup)),
          this._store.pipe(select(selectLookupsLoading)),
          this._store.pipe(select(selectNurseDataLoading))
        ),
        filter(
          ([
            action,
            nurseData,
            application,
            stateLookup,
            shiftLookup,
            lookupsLoading,
            nurseDataLoading,
          ]: [
            ArchiveSubmittalSuccess,
            NurseModel,
            Submittal,
            Map<string, ILookup<string>>,
            Map<number, ILookup<number>>,
            boolean,
            boolean
          ]) =>
            action.payload?.id &&
            nurseData &&
            application &&
            stateLookup &&
            shiftLookup &&
            !lookupsLoading &&
            !nurseDataLoading
        ),
        tap(
          ([, nurseData, application, stateLookup, shiftLookup]: [
            ArchiveSubmittalSuccess,
            NurseModel,
            Submittal,
            Map<string, ILookup<string>>,
            Map<number, ILookup<number>>,
            boolean,
            boolean
          ]) => {
            this._nursePortalApi
              .getJob(application.jobOrderId)
              .subscribe((job: IJob) => {
                if (job) {
                  this._segmentService.applicationArchived(
                    application,
                    job,
                    stateLookup,
                    shiftLookup
                  );
                }
              });
          }
        )
      ),
    { dispatch: false }
  );

  navigationItemClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.NavigationItemClicked),
        tap((action: NavigationItemClicked) => {
          this._segmentService.navigationItemClicked(
            action.payload.navigationText,
            action.payload.navigationPlacement,
            action.payload.navigationUrl
          );
        })
      ),
    { dispatch: false }
  );

  signedUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.SignedUp),
        tap(() => {
          let eventFired = sessionStorage.getItem("hasSignupBeenTracked");
          if(eventFired !== 'true') {
            this._segmentService.signedUp();
            sessionStorage.setItem("hasSignupBeenTracked","true");
          }
        })
      ),
    { dispatch: false }
  );

  signedIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.SignedIn),
        tap(() => {
          this._segmentService.signedIn();
        })
      ),
    { dispatch: false }
  );

  signedOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.SignedOut),
        tap(() => {
          this._segmentService.signedOut();
        })
      ),
    { dispatch: false }
  );

  profileTasksViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.ProfileTasksViewed),
        tap(() => {
          this._segmentService.profileTasksViewed();
        })
      ),
    { dispatch: false }
  );

  callToActionClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.CallToActionClicked),
        tap((action: CallToActionClicked) => {
          this._segmentService.callToActionClicked(
            action.payload.pageURL,
            action.payload.pageTitle,
            action.payload.destinationURL,
            action.payload.ctaText
          );
        })
      ),
    { dispatch: false }
  );

  onboardingStepViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.OnboardingStepViewed),
        tap((action: OnboardingStepViewed) => {
          this._segmentService.onboardingStepViewed(
            action.payload?.stepName,
            action.payload?.stepNumber
          );
        })
      ),
    { dispatch: false }
  );

  onboardingStepCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.OnboardingStepCompleted),
        tap((action: OnboardingStepCompleted) => {
          this._segmentService.onboardingStepCompleted(
            action.payload?.stepName,
            action.payload?.stepNumber,
            action.payload?.skipped
          );
        })
      ),
    { dispatch: false }
  );

  onboardingCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.OnboardingCompleted),
        tap((action: OnboardingCompleted) => {
          this._segmentService.onboardingCompleted(
            action.payload?.stepName,
            action.payload?.stepNumber
          );
        })
      ),
    { dispatch: false }
  );

  jobPreferencesCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(EJobsActions.SetJobPreferencesSuccess),
        tap((action: SetJobPreferencesSuccess) => {
          const isCompleted =
            (action.response?.locationDetails?.length ||
              action.response?.submitToCompactState) &&
            action.response?.shiftPreferences?.length &&
            action.response?.weeklyPay;
          if (isCompleted) {
            this._segmentService.jobPreferencesCompleted();
          }
        })
      ),
    { dispatch: false }
  );

  licensesViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.LicensesViewed),
        tap(() => {
          this._segmentService.licensesViewed();
        })
      ),
    { dispatch: false }
  );

  licenseAdded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(
          ELicensesActions.CreateLicenseSuccess,
          ELicensesActions.NursysSync
        ),
        tap(() => {
          this._segmentService.licenseAdded();
        })
      ),
    { dispatch: false }
  );

  licenseDeleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ELicensesActions.DeleteLicenseSuccess),
        tap((action: DeleteLicenseSuccess) => {
          this._segmentService.licenseDeleted(action.id);
        })
      ),
    { dispatch: false }
  );

  licenseVerified$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ELicensesActions.NursysAddSuccess),
        tap(() => {
          this._segmentService.licenseVerified();
        })
      ),
    { dispatch: false }
  );

  documentUploaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.DocumentUploaded),
        tap((action: DocumentUploaded) => {
          if (action.payload) {
            this._segmentService.documentUploaded(
              action.payload.documentId,
              action.payload.documentName,
              action.payload.documentType,
              action.payload.documentURL
            );
          }
        })
      ),
    { dispatch: false }
  );

  blsUploaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ECertificationsActions.AddCertificationSuccess),
        tap((action: AddCertificationSuccess) => {
          if (
            action.payload?.returnValue?.certificationId === blsCertificateId
          ) {
            this._segmentService.blsUploaded();
          }
        })
      ),
    { dispatch: false }
  );

  technologiesViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.TechnologiesViewed),
        tap(() => {
          this._segmentService.technologiesViewed();
        })
      ),
    { dispatch: false }
  );

  technologyAdded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ETechnologyActions.AddTechnologySuccess),
        withLatestFrom(
          this._store.pipe(select(selectTechnologyLookup)),
          this._store.pipe(select(selectProficiencyLookup)),
          this._store.pipe(select(selectLookupsLoading))
        ),
        filter(([, , , loading]) => !loading),
        tap(
          ([action, technologyLookup, proficencyLookup, loading]: [
            AddTechnologySuccess,
            Map<string, ILookup<string>>,
            Map<number, ILookup<any>>,
            boolean
          ]) => {
            if (action.request) {
              this._segmentService.technologyAdded(
                action.request,
                technologyLookup,
                proficencyLookup
              );
            }
          }
        )
      ),
    { dispatch: false }
  );

  technologyDeleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ETechnologyActions.DeleteTechnologySuccess),
        tap((action: DeleteTechnologySuccess) => {
          if (action.request?.id && action.request.id !== emptyGuid) {
            this._segmentService.technologyDeleted(action.request?.id);
          }
        })
      ),
    { dispatch: false }
  );

  referenceAdded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(EReferencesActions.AddReferenceSuccess),
        withLatestFrom(
          this._store.pipe(select(selectPositionTitleLookup)),
          this._store.pipe(select(selectWorkHistoryList)),
          this._store.pipe(select(selectWorkHistoryListLoading)),
          this._store.pipe(select(selectLookupsLoading))
        ),
        filter(
          ([, , workHistoryList, workHistoryListLoading, lookupLoading]: [
            AddReferenceSuccess,
            Map<number, any>,
            WorkHistory[],
            boolean,
            boolean
          ]) =>
            !workHistoryListLoading &&
            !lookupLoading &&
            workHistoryList?.length > 0
        ),
        tap(
          ([action, positionTitleLookup, workHistoryList, ,]: [
            AddReferenceSuccess,
            Map<number, any>,
            WorkHistory[],
            boolean,
            boolean
          ]) => {
            this._segmentService.referenceAdded(
              action.request,
              positionTitleLookup,
              workHistoryList
            );
          }
        )
      ),
    { dispatch: false }
  );

  professionalOverviewAdded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(EUserContextActions.UpdateSummarySuccess),
        tap(() => {
          this._segmentService.professionalOverviewAdded();
        })
      ),
    { dispatch: false }
  );

  backgroundQuestionnaireCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(EUserContextActions.UpdateQuestionnaireSuccess),
        tap(() => {
          this._segmentService.backgroundQuestionnaireCompleted();
        })
      ),
    { dispatch: false }
  );
  workHistoryAdded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(EWorkHistoryContextActions.AddWorkHistorySuccess),
        withLatestFrom(
          this._store.pipe(select(selectSpecialtyLookup)),
          this._store.pipe(select(selectProfessionLookup)),
          this._store.pipe(select(selectProfessionTypesLookup)),
          this._store.pipe(select(selectYesNoIdLookup)),
          this._store.pipe(select(selectStateLookup)),
          this._store.pipe(select(selectLookupsLoading))
        ),
        filter(
          ([
            action,
            specialty,
            profession,
            professionTypes,
            yesNo,
            states,
            isLookupsLoading,
          ]: [
            AddWorkHistorySuccess,
            Map<string, ILookup<string>>,
            Map<string, ILookup<string>>,
            Map<string, ILookup<string>>,
            Map<number, string>,
            Map<string, ILookup<string>>,
            boolean
          ]) => !isLookupsLoading && action.request !== null
        ),
        tap(
          ([
            action,
            specialty,
            profession,
            professionTypes,
            yesNo,
            states,
            isLookupsLoading,
          ]: [
            AddWorkHistorySuccess,
            Map<string, ILookup<string>>,
            Map<string, ILookup<string>>,
            Map<string, ILookup<string>>,
            Map<number, string>,
            Map<string, ILookup<string>>,
            boolean
          ]) => {
            this._segmentService.workHistoryAdded(
              action.request,
              specialty,
              profession,
              professionTypes,
              yesNo,
              states
            );
          }
        )
      ),
    { dispatch: false }
  );

  tasksViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ESegmentActions.TasksViewed),
        withLatestFrom(
          this._store.pipe(select(selectTasks)),
          this._store.pipe(select(selectTasksLoading))
        ),
        filter(
          ([action, tasks, tasksLoading]: [
            TasksViewed,
            NurseTask[],
            boolean
          ]) => !tasksLoading && tasks?.length > 0
        ),
        tap(([action, tasks]: [TasksViewed, NurseTask[], boolean]) => {
          this._segmentService.tasksViewed(action.tasksType, tasks);
        })
      ),
    { dispatch: false }
  );

  taskCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ETasksActions.GetTasksSuccess),
        withLatestFrom(
          this._store.pipe(select(selectTasks)),
          this._store.pipe(select(selectPreviousTasks)),
          this._store.pipe(select(selectTasksLoading))
        ),
        filter(
          ([action, tasks, previousTasks, tasksLoading]: [
            TasksViewed,
            NurseTask[],
            NurseTask[],
            boolean
          ]) => !tasksLoading && tasks?.length > 0 && previousTasks?.length > 0
        ),
        tap(
          ([action, tasks, previousTasks]: [
            TasksViewed,
            NurseTask[],
            NurseTask[],
            boolean
          ]) => {
            this._segmentService.taskCompleted(tasks, previousTasks);
          }
        )
      ),
    { dispatch: false }
  );

  workHistoriesViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.WorkHistoriesViewed),
        tap((action: WorkHistoriesViewed) => {
          this._segmentService.workHistoriesViewed();
        })
      ),
    { dispatch: false }
  );

  workHistoryDeleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(EWorkHistoryContextActions.DeleteWorkHistorySuccess),
        tap((action: DeleteWorkHistorySuccess) => {
          if (action?.itemId) {
            this._segmentService.workHistoryDeleted(action.itemId);
          }
        })
      ),
    { dispatch: false }
  );

  educationAdded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(EEducationActions.AddEducationSuccess),
        tap((action: AddEducationSuccess) => {
          this._segmentService.educationAdded(action.payload.education);
        })
      ),
    { dispatch: false }
  );

  educationsViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.EducationsViewed),
        tap((action: EducationsViewed) => {
          this._segmentService.educationsViewed();
        })
      ),
    { dispatch: false }
  );

  educationDeleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(EEducationActions.DeleteEducationSuccess),
        tap((action: DeleteEducationSuccess) => {
          this._segmentService.educationDeleted(action.id);
        })
      ),
    { dispatch: false }
  );

  facilityViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.FacilityViewed),
        withLatestFrom(
          this._store.pipe(select(selectStateLookup)),
          this._store.pipe(select(selectLookupsLoading))
        ),
        filter(
          ([action, statesLookup, isLoading]: [
            FacilityViewed,
            Map<string, ILookup<string>>,
            boolean
          ]) => action?.payload && statesLookup && !isLoading
        ),
        tap(
          ([action, statesLookup, isLoading]: [
            FacilityViewed,
            Map<string, ILookup<string>>,
            boolean
          ]) => {
            this._segmentService.facilityViewed(action.payload, statesLookup);
          }
        )
      ),
    { dispatch: false }
  );

  phoneNumberClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.PhoneNumberClicked),
        tap((action: PhoneNumberClicked) => {
          this._segmentService.phoneNumberClicked(
            action.payload.location,
            action.payload.phoneType,
            action.payload.phoneNumber
          );
        })
      ),
    { dispatch: false }
  );

  externalLinkClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.ExternalLinkClicked),
        tap((action: ExternalLinkClicked) => {
          this._segmentService.externalLinkClicked(
            action.pageURL,
            action.ctaText,
            action.clickURL
          );
        })
      ),
    { dispatch: false }
  );

  internalLinkClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.InternalLinkClicked),
        tap((action: InternalLinkClicked) => {
          this._segmentService.internalLinkClicked(
            action.pageURL,
            action.ctaText,
            action.clickURL
          );
        })
      ),
    { dispatch: false }
  );

  skillChecklistViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.SkillChecklistViewed),
        tap((action: SkillChecklistViewed) => {
          this._segmentService.skillChecklistViewed(action.payload);
        })
      ),
    { dispatch: false }
  );

  skillChecklistCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SegmentActions>(ESegmentActions.SkillChecklistCompleted),
        tap((action: SkillChecklistCompleted) => {
          this._segmentService.skillChecklistCompleted(action.payload);
        })
      ),
    { dispatch: false }
  );
}
