import { IBadgeDetails } from "hc-design-system-lib/lib/components/banner/banner.interfaces";

export interface JobBadgeData {
  badge?: IBadgeDetails;
  sortPriority: number;
  imagePath?: string;
}

export enum JobBadge {
  ANCCMagnet = 'ANCC Magnet Status',
  Crisis = 'Crisis',
  FirstTimeTraveler = 'Accepts First-Time Travelers',
  JointCommission = 'Joint Commission Accredited',
  PPUSAAdvance = 'PassportUSA Advance',
  Teaching = 'Teaching',
  Trauma = 'Trauma Center',
  Local = 'Local',
  Travel = 'Travel'
}
