import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  exhaustMap,
  map,
  skipWhile,
  withLatestFrom,
} from 'rxjs/operators';
import { defer, forkJoin, of } from 'rxjs';
import { Certification, FileUploadTargets } from 'src/app/common';
import { DocumentHelperService, NursePortalApi } from 'src/app/services';
import {
  ECertificationsActions,
  CertificationsActions,
  GetCertificationsSuccess,
  GetCertificationsError,
  GetCertificationById,
  GetCertificationPopulate,
  GetCertificationPopulateError,
  GetCertificationPopulateSuccess,
  AddCertification,
  AddCertificationSuccess,
  AddCertificationError,
  GetCertifications,
  UpdateCertification,
  UpdateCertificationSuccess,
  DeleteCertification,
  DeleteCertificationSuccess,
  DeleteCertificationError, AddCertificationFile, AddCertificationFileError, UpdateCertificationFileError
} from './certifications.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '../app/app.state';
import {
  selectCertificationActionRequestId,
  selectCertifications,
  selectCertificationsExpiration,
} from './certifications.selectors';
import {
  checkIfDateIsAfter,
  getDateInFutureWithMinutes,
} from 'src/app/common/functions/date-manipulations';

@Injectable({
  providedIn: 'root',
})
export class CertificationsEffects {
  constructor(
    private actions$: Actions,
    private _api: NursePortalApi,
    private _documentHelperService: DocumentHelperService,
    private _store: Store<IAppState>
  ) { }

  getCertifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CertificationsActions>(ECertificationsActions.GetCertifications),
      map((action: GetCertifications) => action.forceUpdate),
      withLatestFrom(
        this._store.select(selectCertifications),
        this._store.select(selectCertificationsExpiration)
      ),
      exhaustMap(([forceUpdate, certifications, expirationDate]) => {
        const isAfter = checkIfDateIsAfter(new Date(), expirationDate);
        if (forceUpdate || isAfter) {
          return this._api.getCertifications().pipe(
            map((certs: Certification[]) => {
              return new GetCertificationsSuccess({
                certifications: certs,
                expirationDate: getDateInFutureWithMinutes(10),
              });
            }),
            catchError((error: Error) => of(new GetCertificationsError(error)))
          );
        } else {
          return of(
            new GetCertificationsSuccess({
              certifications,
              expirationDate,
            })
          );
        }
      })
    )
  );

  getCertificationPopulate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CertificationsActions>(
        ECertificationsActions.GetCertificationPopulate
      ),
      map((action: GetCertificationPopulate) => action.input),
      exhaustMap((certificationId) => {
        if (certificationId) {
          return this._api.getCertificationPopulate(certificationId).pipe(
            map(
              (prepopulatedFields) =>
                new GetCertificationPopulateSuccess(prepopulatedFields)
            ),
            catchError((error: Error) =>
              of(new GetCertificationPopulateError(error))
            )
          );
        } else {
          return of(new GetCertificationPopulateSuccess({}));
        }
      })
    )
  );

  addCertification$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CertificationsActions>(ECertificationsActions.AddCertification),
      map((action: AddCertification) => action.payload),
      exhaustMap((payload) =>
        this._api.addCertification(payload.model).pipe(
          map((addCertificationResponse) => {
            return new AddCertificationFile({
              model: addCertificationResponse,
              files: payload.files,
            });
          }),
          catchError((error: Error) => of(new AddCertificationError(error)))
        )
      )
    )
  );

  addCertificationFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CertificationsActions>(
        ECertificationsActions.AddCertificationFile
      ),
      map((action: AddCertificationFile) => action.payload),
      exhaustMap(({ model, files }) =>
        this._documentHelperService
          ._getDocumentObservable(
            {
              itemId: model.returnValue.id,
              target: FileUploadTargets.Certification,
            },
            files
          )
          .pipe(
            map((result) => new AddCertificationSuccess(model)),
            catchError((err) => of(new AddCertificationFileError({model: model}, err)))
          )
      )
    )
  );

  addCertificationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CertificationsActions>(
        ECertificationsActions.AddCertificationSuccess,
        ECertificationsActions.AddCertificationFileError,
        ECertificationsActions.UpdateCertificationSuccess,
        ECertificationsActions.UpdateCertificationFileError,
        ECertificationsActions.DeleteCertificationSuccess
      ),
      map(() => new GetCertifications(true))
    )
  );

  refreshCertification$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CertificationsActions>(
        ECertificationsActions.UpdateCertificationSuccess
      ),
      map((action: UpdateCertificationSuccess) => action.payload),
      withLatestFrom(this._store.select(selectCertificationActionRequestId)),
      skipWhile(([payload, requestId]) => !requestId),
      exhaustMap(([payload, requestId]) =>
        of(new GetCertificationById(requestId))
      )
    )
  );

  updateCertification$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CertificationsActions>(ECertificationsActions.UpdateCertification),
      map((action: UpdateCertification) => action.updateCertificationModel),
      exhaustMap(
        ({ model, isFileUpdated, isDeletingDocument, frontFile, backFile }) =>
          forkJoin({
            certification: this._api.updateCertification(model),
            document: defer(() => {
              if (isFileUpdated) {
                return this._documentHelperService._getDocumentObservable(
                  {
                    itemId: model.id,
                    target: FileUploadTargets.Certification,
                    isDeleting: isDeletingDocument,
                    qualificationId: model?.qualificationId,
                  },
                  [...frontFile, ...backFile]
                );
              } else {
                return of(true);
              }
            }),
          })
      ),
      map(
        (certificationUpdateSuccess) =>
          new UpdateCertificationSuccess(certificationUpdateSuccess)
      ),
      catchError((error) => of(new UpdateCertificationFileError(error)))
    )
  );

  deleteCertification$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CertificationsActions>(ECertificationsActions.DeleteCertification),
      map((action: DeleteCertification) => action.input),
      exhaustMap((input) =>
        forkJoin({
          certification: this._api.deleteCertification(input.id),
          qualification: defer(() => {
            if (input.qualificationId != null) {
              return this._api.deleteQualification(input.qualificationId);
            } else {
              return of(true);
            }
          }),
        }).pipe(
          map((payload) => new DeleteCertificationSuccess(payload)),
          catchError((err) => of(new DeleteCertificationError(err)))
        )
      )
    )
  );
}
