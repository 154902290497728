import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ILookups, Submittal } from 'src/app/common';
import { NavHelper } from 'src/app/services';
import { selectLookups } from 'src/app/store/lookups/lookups.selectors';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { Subscription, Observable } from 'rxjs';
import { ICardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { SubmittalsService } from 'src/app/services/submittals.service';

@Component({
  selector: 'app-new-submittal-card',
  templateUrl: './new-submittal-card.component.html',
  styleUrls: ['./new-submittal-card.component.scss'],
})
export class NewSubmittalCardComponent implements OnInit, OnDestroy {
  @Input() submittal: Submittal;
  @Input() completedTasks: boolean;
  @ViewChild('filDialogTemplate')
  filDialogTemplate: TemplateRef<any>;

  cardConfig: ICardConfig;

  lookups: ILookups;
  lookupsSubscription: Subscription;
  lookups$: Observable<ILookups> = this._store.pipe(select(selectLookups));

  constructor(
    private _navHelper: NavHelper,
    private _store: Store<IAppState>,
    private _submittalsService: SubmittalsService
  ) {}

  ngOnInit(): void {
    this.initializeLookups();
  }

  ngOnDestroy(): void {
    this.lookupsSubscription?.unsubscribe();
  }

  initializeLookups(): void {
    this.lookupsSubscription = this.lookups$.subscribe((lookups) => {
      this.buildCardConfig();
    });
  }

  buildCardConfig(): void {
    this.cardConfig = this._submittalsService.mapSubmittalToCard(
      this.submittal
    );
  }

  cardClicked(event) {
    const contractType = this.submittal.contractType;
    this._navHelper.goToJobsSpecificCustomParams(this.submittal.job.id, { contractType });
  }

  submittalCardLinkClicked(event) {
    this._submittalsService.submittalCardLinkClicked(
      event,
      this.completedTasks
    );
  }

  submittalCardHelp(event) {
    this._submittalsService.submittalCardHelp(event);
  }
}
