import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, retry, tap } from 'rxjs/operators';
import { Facility } from 'src/app/common/models/facility';
import { CandidateJobsApiService } from 'src/app/services/candidate-jobs-api.service';
import {
  EFacilitiesActions,
  FacilitiesActions,
  GetFacilities,
  GetFacilitiesError,
  GetFacilitiesSuccess,
  GetFacilityApplicationsError,
  GetFacilityApplicationsSuccess,
  PostFacilityApplication,
  PostFacilityApplicationError,
  PostFacilityApplicationSuccess,
  SearchFacilitiesByProximity,
  SearchFacilitiesByProximityError,
  SearchFacilitiesByProximitySuccess,
} from './facilities.actions';
import { FacilityProximitySearch } from 'src/app/common/contracts/facility-proximity-search';
import { UsersApiService } from 'src/app/services/users-api.service';
import { CandidateFacility } from 'src/app/common/models/candidate-facility';
import { FacilityApplication } from 'src/app/common/models/facility-application';
import { NotificationService } from 'hc-design-system-lib';

@Injectable({
  providedIn: 'root',
})
export class FacilityEffects {
  constructor(
    private actions$: Actions,
    private _candidateJobsApi: CandidateJobsApiService,
    private _usersApi: UsersApiService,
    private _notificationService: NotificationService
  ) {
  }

  getFacilitiesBySearchString$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<FacilitiesActions>(EFacilitiesActions.GetFacilities),
      map((action: GetFacilities) => action.input),
      concatMap((searchString: string) =>
        this._candidateJobsApi.getFacilitiesBySearchString(searchString).pipe(
          map((facilities: Facility[]) => new GetFacilitiesSuccess(facilities)),
          catchError((error: Error) => of(new GetFacilitiesError(error)))
        )
      )
    );
  });

  searchFacilitiesByProximity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<FacilitiesActions>(EFacilitiesActions.SearchFacilitiesByProximity),
      map((action: SearchFacilitiesByProximity) => action.parameters),
      concatMap((parameters: FacilityProximitySearch) => {
          if (parameters.location === undefined && parameters.facility === undefined) {
            return of(new SearchFacilitiesByProximitySuccess([]));
          } else {
            return this._candidateJobsApi.getFacilitiesByProximity(parameters).pipe(
              map(
                (facilities: CandidateFacility[]) =>
                  new SearchFacilitiesByProximitySuccess(facilities)
              ),
              catchError((error: Error) =>
                of(new SearchFacilitiesByProximityError(error))
              )
            );
          }
        }
      )
    );
  });

  postFacilityApplication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<FacilitiesActions>(EFacilitiesActions.PostFacilityApplication),
      map((action: PostFacilityApplication) => action.facilityId),
      concatMap((facilityId: string) =>
        this._usersApi.postFacilityApplication(facilityId).pipe(
          retry(3),
          map(
            (facilityApplication: FacilityApplication) =>
              new PostFacilityApplicationSuccess(facilityApplication)
          ),
          catchError((error: Error) =>
            of(new PostFacilityApplicationError(error))
          )
        )
      )
    );
  });

  postFacilityApplicationSuccessPopup$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<FacilitiesActions>(
          EFacilitiesActions.PostFacilityApplicationSuccess
        ),
        tap(() =>
          this._notificationService.showNotification(
            'Your profile has been submitted.',
            'success'
          )
        )
      );
    },
    { dispatch: false }
  );

  postFacilityApplicationErrorPopup$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<FacilitiesActions>(
          EFacilitiesActions.PostFacilityApplicationError
        ),
        tap(() =>
          this._notificationService.showNotification(
            'We were unable to submit your profile. Please try again or contact recruiter.',
            'error'
          )
        )
      );
    },
    { dispatch: false }
  );

  getFacilityApplications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<FacilitiesActions>(
        EFacilitiesActions.GetFacilityApplications,
        EFacilitiesActions.SearchFacilitiesByProximity
      ),
      concatMap(() =>
        this._usersApi.getFacilityApplications().pipe(
          map(
            (facilityApplications: FacilityApplication[]) =>
              new GetFacilityApplicationsSuccess(facilityApplications)
          ),
          catchError((error: Error) =>
            of(new GetFacilityApplicationsError(error))
          )
        )
      )
    );
  });
}
