import { IRadioButtonOption } from 'hc-design-system-lib/lib/components/form/form.interfaces';

export const supportEmail = 'ondemandsupport@healthcarousel.com';

export const medicalAssistantProfession =
  '8be3171a-e4f2-e611-8103-e0071b66cfe1';

export const blsCertificateId = '4c0f7bb9-2b0f-e711-80f5-e0071b66cfa1';

export const PHONE_MASK = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const SSN_MASK = [
  /[*X0-9]/,
  /[*X0-9]/,
  /[*X0-9]/,
  ' ',
  /[*X0-9]/,
  /[*X0-9]/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const SSN_MASK_SHORT = [/\d/, /\d/, /\d/, /\d/];

export const PATIENT_RATIO_MASK = [/[0-9]/, /[0-9]/, ':', /[1-9]/];

export const PHONE_VALIDATOR_PATTERN = '\\(?\\d{3}\\)? ?\\d{3}-?\\d{4}';

export const PATIENT_RATIO_VALIDATOR_PATTERN = '\\d{1,2}\\:?\\d+';

export const emptyGuid = '00000000-0000-0000-0000-000000000000';
export const radiusOptions: number[] = [null, 5, 25, 50, 75, 100];
export const minimumPayOptions: number[] = [null, 1500, 2000, 2500, 3000];

export const enum PROFESSION_HIERARCHY_NODE_TYPES {
  ProfessionSector = 'Profession Sector',
  ProfessionCategory = 'Profession Category',
  Profession = 'Profession',
  Specialty = 'Specialty',
}

export const enum PROFESSION_HIERARCHY_SECTORS {
  Nursing = 'Nursing',
  Allied = 'Allied',
}

export const enum SkillStatus {
  InProgress = 'In Progress',
  NotStarted = 'Not Started',
  Complete = 'Complete',
}

export const preferredClientBanner = {
  icon: 'star_half',
  text: 'Preferred Partner',
  fontColor: 'white',
  backgroundColor: '#F68C2C',
};

export const enum VerificationType {
  Email = 1,
  Sms = 2,
}

export const enum VerificationStatus {
  Failure,
  SuccessForUserWithRecruiter,
  SuccessForUserWithoutRecruiter
}

export const enum CommunicationFrequency
{
    Daily = 1,
    Weekly = 2,
    Monthly = 3
}

export const enum NotificationType
{
    Email = 1,
    SMS = 2,
}

export enum VisaStep {
  InterviewSignAgreement = 948050000,
  VisaFilingPrep = 948050001,
  DocumentApproval = 948050014,
  ETAReview = 948050012,
  I140DraftingandReview = 948050013,
  VisaPetitionSent = 948050003,
  VisaPetitionFiled = 948050004,
  VisaPetitionApproved = 948050005,
  VisaFeeBillsIssued = 948050006,
  VisaFeeBillsPaid = 948050007,
  NVCDocumentsSubmitted = 948050008,
  EmbassyAppointment = 948050009,
  USVisaIssued = 948050010,
  DocumentarilyQualified = 948050011
}

export const genderOptions: IRadioButtonOption[] = [
  { text: 'Male', value: 1, disabled: false },
  { text: 'Female', value: 2, disabled: false },
  { text: 'Non Binary', value: 948050000, disabled: false },
  { text: 'Other', value: 948050001, disabled: false },
];
