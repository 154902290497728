import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EMPTY, Observable } from 'rxjs';
import { API_ROUTES } from '../common';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GetSpecificJobNotFound } from '../store/jobs/jobs.actions';

@Injectable()
export class BadJobInterceptor implements HttpInterceptor {
  constructor(private _store: Store) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const isJobReq =
      req.url.includes(environment.apiBaseUrl) &&
      req.urlWithParams.includes(API_ROUTES.job + 'specific-job');

    return isJobReq
      ? next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404 || error.status === 400) {
              this._store.dispatch(new GetSpecificJobNotFound(true));
              return EMPTY;
            }
            return next.handle(req);
          }),
        )
      : next.handle(req);
  }
}
