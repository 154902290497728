import { Action } from '@ngrx/store';
import { Technology } from 'src/app/common';
import { TaskCompletionReturnObject } from 'src/app/common/models/task-competion-return-object';

export enum ETechnologyActions {
  GetTechnology = '[TECHNOLOGY] get technology',
  GetTechnologySuccess = '[TECHNOLOGY] get technology success',
  GetTechnologyError = '[TECHNOLOGY] get technology error',
  GetTechnologyByID = '[TECHNOLOGY] get technology by id',
  GetTechnologyByIDSuccess = '[TECHNOLOGY] get technology success by id',
  GetTechnologyByIDError = '[TECHNOLOGY] get technology error by id',
  AddTechnology = '[TECHNOLOGY] add technology',
  AddTechnologySuccess = '[TECHNOLOGY] add technology success',
  AddTechnologyError = '[TECHNOLOGY] add technology error',
  DeleteTechnology = '[TECHNOLOGY] delete technology',
  DeleteTechnologySuccess = '[TECHNOLOGY] delete technology success',
  DeleteTechnologyError = '[TECHNOLOGY] delete technology error',
  UpdateTechnology = '[TECHNOLOGY] update technology',
  UpdateTechnologySuccess = '[TECHNOLOGY] update technology success',
  UpdateTechnologyError = '[TECHNOLOGY] update technology error',
  ResetDeleteTechnology = '[TECHNOLOGY] resetting delete technology state',
  ResetUpdateTechnology = '[TECHNOLOGY] resetting update technology state',
  ResetAddTechnology = '[TECHNOLOGY] resetting add technology state'
}

export class GetTechnology implements Action {
  public readonly type = ETechnologyActions.GetTechnology;
  constructor( ) { }
}

export class GetTechnologySuccess implements Action {
  public readonly type = ETechnologyActions.GetTechnologySuccess;

  constructor(public technology: Technology[]) { }
}

export class GetTechnologyError implements Action {
  public readonly type = ETechnologyActions.GetTechnologyError;

  constructor(public error: Error) { }
}


export class GetTechnologyByID implements Action {
  public readonly type = ETechnologyActions.GetTechnologyByID;
  constructor(public technologyId: string) { }
}

export class GetTechnologyByIDSuccess implements Action {
  public readonly type = ETechnologyActions.GetTechnologyByIDSuccess;

  constructor(public payload: Technology) { }
}

export class GetTechnologyByIDError implements Action {
  public readonly type = ETechnologyActions.GetTechnologyByIDError;

  constructor(public error: Error) { }
}


export class AddTechnology implements Action {
  public readonly type = ETechnologyActions.AddTechnology;

  constructor(public payload: Technology[]) {}
}

export class AddTechnologySuccess implements Action {
  public readonly type = ETechnologyActions.AddTechnologySuccess;

  constructor(public response: TaskCompletionReturnObject, public request: any = null) {}
}

export class AddTechnologyError implements Action {
  public readonly type = ETechnologyActions.AddTechnologyError;

  constructor(public error: Error) {}
}

export class DeleteTechnology implements Action {
  public readonly type = ETechnologyActions.DeleteTechnology;

  constructor(public payload: Technology) {}
}

export class DeleteTechnologySuccess implements Action {
  public readonly type = ETechnologyActions.DeleteTechnologySuccess;

  constructor(public response: TaskCompletionReturnObject, public request: Technology = null) {}
}

export class DeleteTechnologyError implements Action {
  public readonly type = ETechnologyActions.DeleteTechnologyError;

  constructor(public error: Error) {}
}

export class UpdateTechnology implements Action {
  public readonly type = ETechnologyActions.UpdateTechnology;

  constructor(public payload: Technology) {}
}

export class UpdateTechnologySuccess implements Action {
  public readonly type = ETechnologyActions.UpdateTechnologySuccess;

  constructor(public response: TaskCompletionReturnObject) {}
}

export class UpdateTechnologyError implements Action {
  public readonly type = ETechnologyActions.UpdateTechnologyError;

  constructor(public error: Error) {}
}

export class ResetDeleteTechnology implements Action {
  public readonly type = ETechnologyActions.ResetDeleteTechnology;

  constructor() {}
}

export class ResetUpdateTechnology implements Action {
  public readonly type = ETechnologyActions.ResetUpdateTechnology;

  constructor() {}
}

export class ResetAddTechnology implements Action {
  public readonly type = ETechnologyActions.ResetAddTechnology;

  constructor() {}
}

export type TechnologyActions =
  GetTechnology |
  GetTechnologySuccess |
  GetTechnologyError |
  AddTechnology |
  AddTechnologySuccess |
  AddTechnologyError |
  DeleteTechnology |
  DeleteTechnologySuccess |
  DeleteTechnologyError |
  UpdateTechnology |
  UpdateTechnologySuccess |
  UpdateTechnologyError |
  ResetDeleteTechnology |
  ResetUpdateTechnology |
  ResetAddTechnology|
  GetTechnologyByID |
  GetTechnologyByIDSuccess |
  GetTechnologyByIDError;
