export enum PortalStatuses {
  OfferAccepted = 948050019,
  OfferDeclined = 948050020,
  Offered = 948050018,
  NoLongerAvailable = 948050016,
  ActionRequired = 948050015,
  PendingReview = 948050001,
  HospitalReview = 948050017,
  OnAssignment = 948050003,
  AssignmentComplete = 948050004,
}

export enum AssignmentSources {
  FIL = 948050001,
  Recommendation = 948050000
}

export enum AssignmentReasonSubStages {
  ApplicationExpired = '4e353338-4983-eb11-a812-000d3a59606a',
  CandDeclinedAcceptedOfferWithUs = '85287770-01fd-e611-8104-e0071b66aea1',
  CandDeclinedOfferWithOtherCompany = '4537907c-01fd-e611-8104-e0071b66aea1',
  CandDeclinedNoLongerInterested = '240c8f88-01fd-e611-8104-e0071b66aea1',
  CandDeclinedInfoChanged = '83ffa0ac-01fd-e611-8104-e0071b66aea1',
  CandDeclinedRevisedOffer = '01d3beb8-01fd-e611-8104-e0071b66aea1',
  CandDeclinedLocation = 'dfa4fbc4-01fd-e611-8104-e0071b66aea1',
  CandDeclinedInaccurateJobDesc = '7ece24d7-01fd-e611-8104-e0071b66aea1',
  CandDeclinedUnresponsive = '2f1f55e9-01fd-e611-8104-e0071b66aea1',
  CandDeclinedPayPackageDoesNotMeetRequirements = '843ebd52-3876-e911-a97b-000d3a3acde0',
  CandDeclinedTookPermPosition = '324f9a01-02fd-e611-8104-e0071b66aea1',
  CandDeclinedOther = '6dfd910d-02fd-e611-8104-e0071b66aea1'
}

export enum AssignmentStageConstants {
  Application = '1031105b-23a1-eb11-b1ac-00224802c42f'
}

export const AssignmentReasonSubStagesArray = [
  <string>AssignmentReasonSubStages.ApplicationExpired,
  <string>AssignmentReasonSubStages.CandDeclinedAcceptedOfferWithUs,
  <string>AssignmentReasonSubStages.CandDeclinedInaccurateJobDesc,
  <string>AssignmentReasonSubStages.CandDeclinedInfoChanged,
  <string>AssignmentReasonSubStages.CandDeclinedLocation,
  <string>AssignmentReasonSubStages.CandDeclinedNoLongerInterested,
  <string>AssignmentReasonSubStages.CandDeclinedOfferWithOtherCompany,
  <string>AssignmentReasonSubStages.CandDeclinedRevisedOffer,
  <string>AssignmentReasonSubStages.CandDeclinedUnresponsive,
  <string>AssignmentReasonSubStages.CandDeclinedPayPackageDoesNotMeetRequirements,
  <string>AssignmentReasonSubStages.CandDeclinedTookPermPosition,
  <string>AssignmentReasonSubStages.CandDeclinedOther,
];

export const WithdrawnReasonsSubstageArray = [
  <string>AssignmentReasonSubStages.CandDeclinedNoLongerInterested,
  <string>AssignmentReasonSubStages.CandDeclinedLocation,
  <string>AssignmentReasonSubStages.CandDeclinedPayPackageDoesNotMeetRequirements,
  <string>AssignmentReasonSubStages.CandDeclinedOfferWithOtherCompany,
  <string>AssignmentReasonSubStages.CandDeclinedTookPermPosition,
  <string>AssignmentReasonSubStages.CandDeclinedOther,
];

