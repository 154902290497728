export enum RegistrationConstants {
  alreadyExists = 0,
  matchFound = 1,
  MultipleMatchesFound = 2,
  Nonefound = 3,
  NoRecruiter = 4,
  NotWelcomed = 5,
  RegistrationInProgressNewUser = 6,
  RegistrationInProgressMatchedUser = 7,
  RegistrationInProgressInvitedUser = 8,
  RegisteredRequiresVerification = 9,
  RegisteredDoesNotRequireVerification = 10,
}
