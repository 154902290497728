import { NavigationStart } from '@angular/router';

declare module '@angular/router' {
    interface NavigationStart {
        getQueryParameterValue(this: NavigationStart, key: string): string;
    }
}

function getQueryParameterValue(this: NavigationStart, key: string): string {
    if (this.url.includes(key)) {
        const value = getParameterbyName(key, this.url);
        return value;
    }

    return null;
}

export function getParameterbyName(name: string, url: string): string {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
NavigationStart.prototype.getQueryParameterValue = getQueryParameterValue;
