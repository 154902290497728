import { ContractType } from './contract-type';
import { Hospital } from '../models/hospital';
import { InterviewComplianceRequirementModel } from '../models/interview-compliance-requirement-model';

export class IJob {
  id: string;
  hospital?: string;
  hospitalId?: string;
  name: string;
  state: string;
  stateName: string;
  city: string;
  profession: string;
  specialty: string;
  subSpecialty: string;
  facility: string;
  startDate?: Date;
  endDate?: Date;
  assignmentLength?: number;
  shift?: number;
  createdOn?: Date;
  hoursPerDay: number;
  hoursPerWeek: number;
  billRate: number;
  details?: string;
  teachingFacilityId: number;
  saved: boolean;
  distance?: number;
  searchedLocation: string;
  // googleData: GoogleData;
  applied: boolean;
  hasStarted: boolean;
  hasCompletedApplication: boolean;
  hasWithdrawn: boolean;
  withdrawnDate: Date;
  hospitalInfo: Hospital;
  crisis: number;
  stateCode: number;
  statusCode: number;
  quantityOpen?: number;
  modifiedOn?: Date;
  rating?: number;
  certificationRequirements?: any[];
  complianceRequirements?: any;
  covidRequirements?: any;
  estGrossPay?: number;
  estTravelGrossPay?: number;
  estLocalGrossPay?: number;
  estHourlyPay?: number;
  estHousing?: number;
  estMIE?: number;
  estOvertimePay?: number;
  localEstHourlyPay?: number;
  localEstOvertimePay?: number;
  localEstWeeklyGross?: number;
  closed?: boolean;
  closedDate?: Date;
  stateRequirement?: any;
  showPay?: number;
  ppUsaAdvanceCode?: number;
  firstTimeTraveler?: number;
  interviewTypeComplianceRequirement: InterviewComplianceRequirementModel;
  quantityRequired?: number;
  quantityFilled?: number;
  recommendedJob: boolean;
  covidCompliant: boolean;
  localTraveler?: boolean;
  contractType?: ContractType = null;
  notFound = false;
}
