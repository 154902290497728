import { Component, OnDestroy, OnInit } from '@angular/core';
import { Submittal } from 'src/app/common';
import { AssignmentReasonSubStagesArray } from 'src/app/common/models/submittal-constants';
import { FormHeaderService } from 'src/app/services';
import { AssignmentStageConstants } from 'src/app/common/models/submittal-constants';
import { select, Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { IAppState } from 'src/app/store/app/app.state';
import {
  selectJobSubmittals,
  selectJobSubmittalsLoading,
} from 'src/app/store/jobs/jobs.selectors';
import { selectToDoApplyTasks } from 'src/app/store/tasks/tasks.selectors';
import { NurseTask } from 'src/app/common/models/db-objects';
import { GetTasks } from 'src/app/store/tasks/tasks.actions';
import { ApplicationsViewed } from 'src/app/store/segment/segment.actions';
import { BodySize } from 'hc-design-system-lib';

@Component({
  selector: 'app-new-submittals',
  templateUrl: './new-submittals.component.html',
  styleUrls: ['./new-submittals.component.scss'],
})
export class NewSubmittalsComponent implements OnInit, OnDestroy {
  bodySize: BodySize = BodySize.Body;
  submittals: Submittal[] = [];
  submittalsByType = {
    submitted: [],
    inProgress: [],
    withdrawn: [],
    closed: [],
  };
  completedTasks: boolean;

  submittalsSubscription: Subscription;
  submittals$: Observable<Submittal[]> = this._store.pipe(
    select(selectJobSubmittals)
  );

  toDoApplyTasks$: Observable<NurseTask[]> = this._store.pipe(select(selectToDoApplyTasks));

  submittalsLoading$: Observable<boolean> = this._store.pipe(
    select(selectJobSubmittalsLoading)
  );

  constructor(
    public _formHeaderService: FormHeaderService,
    private _store: Store<IAppState>
  ) { }

  ngOnInit() {
    this._store.dispatch(new GetTasks(false, true));
    this.addSubmittalsSubscription();
    this._formHeaderService.resetFormHeaderAttributes({
      title: 'My Applications',
      showBackButton: false,
    });
  }

  ngOnDestroy(): void {
    this.submittalsSubscription?.unsubscribe();
  }

  getSubmittalsCount(type): String {
    if (!this.submittalsByType[type]) {
      return '';
    } else {
      return ` (${this.submittalsByType[type].length})`;
    }
  }

  addSubmittalsSubscription(): void {
    this.submittalsSubscription = this.submittals$.subscribe((submittals) => {
      if (submittals) {
        this.submittals = submittals;
        this._store.dispatch(new ApplicationsViewed(this.submittals?.length));
        this.separateSubmittals(this.submittals);
      }
    });
  }

  separateSubmittals(submittals: Submittal[]): void {
    this.submittalsByType = {
      submitted: [],
      inProgress: [],
      withdrawn: [],
      closed: [],
    };
    submittals.forEach((submittal) => {
      if (submittal.submittalWithdrawnDate) {
        this.submittalsByType.withdrawn.push(submittal);
      } else if (submittal.matchingInactiveJobOrder) {
        this.submittalsByType.closed.push(submittal);
      } else if (
        !AssignmentReasonSubStagesArray.includes(submittal.assnSubstage)
      ) {
        if (submittal.assnStage === AssignmentStageConstants.Application) {
          this.submittalsByType.inProgress.push(submittal);
        } else {
          this.submittalsByType.submitted.push(submittal);
        }
      }
    });
  }
}
