<div fxFlex fxLayout="column" fxLayoutGap="16px" id="main-content">
  <div fxLayout="column" class="top-section">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
      <div class="top-header">
        Tell us why you're declining so we can find better jobs for you in the future.
      </div>
    </div>
  </div>
  <form [formGroup]="form" fxLayout="column" (ngSubmit)="onSubmit()">
    <mat-form-field>
      <mat-select formControlName="reasonForDeclination" placeholder="Choose a reason">
        <mat-option [value]=null></mat-option>
        <mat-option *ngFor="let declinationReason of declinationReasons" [value]="declinationReason">
          {{declinationReason}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <div fxLayoutAlign="end end">
    <div>
      <button id="declinationFeedbackPopupCancel" mat-button color="primary" (click)="cancel()">Cancel</button>
    </div>
    <button id="declinationFeedbackPopupConfirm" mat-button color="primary" (click)="onSubmit()" [disabled]="!form.valid">
      Submit
    </button>
  </div>
</div>
