import { Component, Input, OnInit } from '@angular/core';
import {
  IJob,
  ILookup,
  NurseModel,
  SimilarJobByIdSearchModel,
} from 'src/app/common';
import { IBackable } from 'src/app/common/contracts/ibackable';
import { NavHelper } from 'src/app/services';
import { CardComponentConfig } from '../../cards/card-template/card-template.component';
import { Store } from '@ngrx/store';
import {
  selectSavedJobs,
  selectSimilarJobsByFacilityId,
  selectSimilarJobsByFacilityIdLoading,
  selectSimilarJobsByJobId,
  selectSimilarJobsByJobIdLoading,
} from 'src/app/store/jobs/jobs.selectors';
import { IAppState } from 'src/app/store/app/app.state';
import {
  GetSimilarJobsByFacilityId,
  GetSimilarJobsByJobId,
  SetSavedJob,
} from 'src/app/store/jobs/jobs.actions';
import { combineLatest, Observable } from 'rxjs';
import {
  IBadgeDetails,
  ICardConfig,
} from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { JobDataModel } from 'src/app/common/models/job-data-model';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import { filter, map, skipWhile, take, tap } from 'rxjs/operators';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import {
  selectLookupsLoading,
  selectProfessionLookup,
  selectSpecialtyLookup,
  selectStateLookup,
  selectYesNoLookup,
  selectYesNoOnlyLookup,
} from 'src/app/store/lookups/lookups.selectors';
import { ContractType } from 'src/app/common/contracts/contract-type';
import { LOCAL_BADGE, TRAVEL_BADGE } from 'src/app/common/models/badge';
import { UrlHelper } from 'src/app/common/UrlHelper';
import { mapJobToCardConfig } from '../../job-card-utils/job-card-utils';
import { ActivatedRoute } from '@angular/router';
import { AppUrls } from 'src/app/app-urls';

export enum SimilarJobLookupType {
  ByJob,
  ByFacility,
}

@Component({
  selector: 'app-similar-jobs-list',
  templateUrl: './similar-jobs-list.component.html',
  styleUrls: ['./similar-jobs-list.component.scss'],
})
export class SimilarJobsListComponent implements OnInit, IBackable {
  @Input() similarJobLookupType: SimilarJobLookupType =
    SimilarJobLookupType.ByJob;
  @Input() id: string;

  jobConfig: CardComponentConfig;
  primaryBadge: IBadgeDetails = null;
  contractType: ContractType;

  savedJobs$ = this._store.select(selectSavedJobs);
  yesNoOnlyLookup$ = this._store.select(selectYesNoOnlyLookup);
  yesNoLookup$ = this._store.select(selectYesNoLookup);
  specialtyLookup$ = this._store.select(selectSpecialtyLookup);
  professionalLookup$ = this._store.select(selectProfessionLookup);
  stateLookup$ = this._store.select(selectStateLookup);
  lookupsLoading$ = this._store.select(selectLookupsLoading);

  nurse$: Observable<NurseModel> = this._store.select(selectNurseData);

  similarJobCardConfigs$: Observable<ICardConfig[]>;

  similarJobsByJobId$: Observable<IJob[]> = this._store
    .select(selectSimilarJobsByJobId)
    .pipe(filter((responseModel) => !!responseModel));

  similarJobsByFacilityId$: Observable<IJob[]> = this._store.select(
    selectSimilarJobsByFacilityId,
  );

  similarJobs$: Observable<IJob[]>;
  similarJobsLoading$: Observable<boolean>;

  similarJobsByJobIdLoading$: Observable<boolean> = this._store.select(
    selectSimilarJobsByJobIdLoading,
  );
  similarJobsByFacilityIdLoading$: Observable<boolean> = this._store.select(
    selectSimilarJobsByFacilityIdLoading,
  );

  constructor(
    private nav: NavHelper,
    public _store: Store<IAppState>,
    public _route: ActivatedRoute,
  ) {}

  goBack(): void {
    this.nav.goToJobSearch();
  }

  ngOnInit(): void {
    this.setJobIdOnSimilarJobsPage();

    this.jobConfig = {
      showStatus: false,
      showInfoDetails: true,
      showTransferApplication: false,
      showBadges: true,
      showLinks: false,
      useEmitter: true,
    };

    this.similarJobs$ = this.getSimilarJobsObservable();
    this.similarJobsLoading$ = this.getSimilarJobsLoadingObservable();

    this.similarJobCardConfigs$ = combineLatest([
      this.nurse$,
      this.similarJobs$,
      this.savedJobs$,
      this.yesNoOnlyLookup$,
      this.yesNoLookup$,
      this.specialtyLookup$,
      this.professionalLookup$,
      this.stateLookup$,
      this.lookupsLoading$,
    ]).pipe(
      skipWhile(
        ([
          nurse,
          jobs,
          savedJobs,
          yesNoOnlyLookup,
          yesNoLookup,
          specialtyLookup,
          professionLookup,
          stateLookup,
        ]: [
          NurseModel,
          IJob[],
          JobDataModel,
          Map<string, ILookup<string>>,
          Map<string, ILookup<string>>,
          Map<string, ILookup<string>>,
          Map<string, ILookup<string>>,
          Map<string, ILookup<string>>,
          boolean,
        ]) =>
          nurse &&
          !jobs?.length &&
          !savedJobs &&
          !!yesNoOnlyLookup &&
          !yesNoLookup &&
          !specialtyLookup &&
          !professionLookup &&
          !!stateLookup,
      ),
      map(
        ([
          ,
          jobs,
          savedJobs,
          ,
          yesNoLookup,
          specialtyLookup,
          professionLookup,
          stateLookup,
        ]) => {
          return this.mapJobsToCardConfigs(
            jobs,
            savedJobs,
            yesNoLookup,
            specialtyLookup,
            professionLookup,
            stateLookup,
          );
        },
      ),
    );

    this.setupAndDispatchSimilarJobsData();
  }

  private setupAndDispatchSimilarJobsData() {
    if (this.similarJobLookupType === SimilarJobLookupType.ByJob) {
      const jobSearchModelRequest = new SimilarJobByIdSearchModel(this.id);
      this._store.dispatch(new GetSimilarJobsByJobId(jobSearchModelRequest));
    } else if (this.similarJobLookupType === SimilarJobLookupType.ByFacility) {
      this._store.dispatch(new GetSimilarJobsByFacilityId(this.id));
    }
  }

  setJobIdOnSimilarJobsPage() {
    if (this._route.snapshot.url[0]?.path === AppUrls.SIMILAR_JOBS) {
      this.id = this._route.snapshot.params['id'];
    }
  }

  setContractType() {
    if (UrlHelper.GetContractType() === ContractType.Local) {
      this.contractType = ContractType.Local;
      this.primaryBadge = LOCAL_BADGE;
    } else {
      this.contractType = ContractType.Travel;
      this.primaryBadge = TRAVEL_BADGE;
    }
  }

  getSimilarJobsObservable(): Observable<IJob[]> {
    switch (this.similarJobLookupType) {
      case SimilarJobLookupType.ByJob:
        return this.similarJobsByJobId$;
      case SimilarJobLookupType.ByFacility:
        return this.similarJobsByFacilityId$;
    }
  }

  getSimilarJobsLoadingObservable(): Observable<boolean> {
    switch (this.similarJobLookupType) {
      case SimilarJobLookupType.ByJob:
        return this.similarJobsByJobIdLoading$;
      case SimilarJobLookupType.ByFacility:
        return this.similarJobsByFacilityIdLoading$;
    }
  }

  toggleSavedJob(event: HcEvent): void {
    const jobEventInfo = event?.data?.jobCardData?.jobData;
    const clickedJobId = jobEventInfo['jobId'] || null;
    const cardIndex = event?.data?.jobCardData?.cardIndex;

    const similarJobs$ = this.getSimilarJobsObservable();

    combineLatest([this.similarJobCardConfigs$, similarJobs$])
      .pipe(
        filter(([jobDetails, similarJobs]) => !!jobDetails && !!similarJobs),
        take(1),
        tap(([jobDetails, similarJobs]) => {
          const saveValue = !jobDetails[cardIndex].isCardActionActive;
          const job = similarJobs.find((j: IJob) => j.id === clickedJobId);
          this._store.dispatch(
            new SetSavedJob({
              job,
              saveValue,
              contractType: this.contractType,
            }),
          );
        }),
      )
      .subscribe();
  }

  mapJobsToCardConfigs(
    jobs: IJob[],
    savedJobs: JobDataModel,
    yesNoLookup: Map<string, ILookup<string>>,
    specialtyLookup: Map<string, ILookup<string>>,
    professionLookup: Map<string, ILookup<string>>,
    stateLookup: Map<string, ILookup<string>>,
  ): ICardConfig[] {
    this.setContractType();

    const jobCardConfigs: ICardConfig[] = [];

    if (jobs?.length > 0) {
      jobs.forEach((job: IJob) => {
        let saved = job.saved;
        saved = savedJobs?.jobs?.some(
          (savedJob) =>
            savedJob.id === job.id &&
            savedJob.contractType === this.contractType,
        );

        jobCardConfigs.push(
          mapJobToCardConfig(
            { ...job, contractType: this.contractType, saved },
            { yesNoLookup, specialtyLookup, professionLookup, stateLookup },
            [],
            this.primaryBadge,
          ),
        );
      });
    }

    return jobCardConfigs;
  }

  jobCardClicked(event: HcEvent, recommended = true): void {
    const jobData = event?.data?.jobCardData?.jobData;
    const jobId = jobData['jobId'] || null;
    if (jobId) {
      this.nav.goToJobsSpecificCustomParams(jobId, {
        recommendedJob: recommended,
        ...(this.contractType && { contractType: this.contractType }),
      });
    }
  }
}
