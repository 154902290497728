import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { ILookup, License, NurseModel } from 'src/app/common';
import { IAppState } from 'src/app/store/app/app.state';
import {
  CallToActionClicked,
  LicensesViewed,
} from 'src/app/store/segment/segment.actions';
import {
  GetLicenses,
  NursysAdd,
} from 'src/app/store/licenses/licenses.actions';
import {
  selectLicenses,
  selectLicensesLoading,
  selectNursysAddError,
  selectNursysAddLoading,
} from 'src/app/store/licenses/licenses.selectors';
import {
  filter,
  map,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IRecordCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import {
  BodyColor,
  BodySize,
  CardElevation,
  DialogService,
  IDialogParameters,
  LinkSize,
  LinkTarget,
} from 'hc-design-system-lib';
import { formatDate } from '@angular/common';
import { ButtonSize } from 'hc-design-system-lib/lib/components/button/button.enums';
import {
  selectFlags,
  selectFlagsLoading,
  selectIsSpecificFlagOn,
} from 'src/app/store/flags/flags.selectors';
import { featureFlagNames } from 'src/app/services/feature-flags.service';
import { Observable, Subject } from 'rxjs';
import { selectStateLookup } from 'src/app/store/lookups/lookups.selectors';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { NursysModalComponent } from '../nursys-modal/nursys-modal.component';
import { AddEditLicenseModalComponent } from '../add-edit-license-modal/add-edit-license-modal.component';
import { GetVariant } from 'src/app/store/flags/flags.actions';

@Component({
  selector: 'app-license-list',
  templateUrl: './license-list.component.html',
  styleUrls: ['./license-list.component.scss'],
})
export class LicenseListComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  stateLookup: Map<string, ILookup<string>>;
  nursysVerified: boolean;
  hasSynced: boolean;
  nursysUpdateTime: string;
  isSaving = false;
  today = new Date();
  deleteToday = new Date();
  isNurseRnOrLpn: boolean;
  useNursys: boolean;
  licenseDetails: IRecordCardConfig;
  linkSize = LinkSize.Body;
  linkTarget = LinkTarget.Blank;
  bodySizeSmall = BodySize.Small;
  buttonSize = ButtonSize.Narrow;
  qualificationComplete = '948050002';

  selectedLicense: License;

  @ViewChild('nursysDialogTemplate')
  nursysDialogTemplate: TemplateRef<NursysModalComponent>;

  @ViewChild('addEditLicenseDialogTemplate')
  addEditLicenseDialogTemplate: TemplateRef<AddEditLicenseModalComponent>;

  useNursys$: Observable<boolean> = this._store.select(selectFlagsLoading).pipe(
    withLatestFrom(this._store.select(selectFlags)),
    skipWhile(([loading, data]) => loading || data == null),
    switchMap(() =>
      this._store.select(selectIsSpecificFlagOn(featureFlagNames.useNursys)),
    ),
    take(1),
  );

  licenses$: Observable<License[]> = this._store
    .select(selectLicenses)
    .pipe(filter((licenses) => !!licenses));

  stateLookup$: Observable<Map<string, ILookup<string>>> =
    this._store.select(selectStateLookup);

  nurseData$: Observable<NurseModel> = this._store
    .select(selectNurseData)
    .pipe(filter((nurseData) => !!nurseData));

  nursysDataLoading$: Observable<boolean> = this._store.select(
    selectNursysAddLoading,
  );
  licensesLoading$: Observable<boolean> = this._store.select(
    selectLicensesLoading,
  );

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store<IAppState>,
    private _dialogService: DialogService,
  ) {}

  ngOnInit() {
    this._store.dispatch(new LicensesViewed());
    this.addStateLookupsSubscription();
    this.addNurseDataSubscription();
    this.addUseNursysSubscription();
    this._store.dispatch(new GetVariant(featureFlagNames.useNursys));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addUseNursysSubscription(): void {
    this.useNursys$
      .pipe(takeUntil(this.destroy$))
      .subscribe((useNursys: boolean) => {
        this.useNursys = useNursys;
      });
  }

  addStateLookupsSubscription(): void {
    this.stateLookup$
      .pipe(takeUntil(this.destroy$))
      .subscribe((stateLookup: Map<string, ILookup<string>>) => {
        this.stateLookup = stateLookup;
      });
  }

  addNurseDataSubscription(): void {
    this.nurseData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((nurseData: NurseModel) => {
        this.addNurse(nurseData);
      });
  }

  addNurse(nurseData: NurseModel): void {
    if (nurseData) {
      const nurse = new NurseModel();
      Object.assign(nurse, nurseData);

      this.hasSynced = nurse.nursys?.hasSynced;
      this.isNurseRnOrLpn = nurse.isRnOrLpn();

      if (nurse.nursys.hasSynced && nurse.nursys.lastSyncDate) {
        this.nursysUpdateTime = moment(nurse.nursys.lastSyncDate).format(
          'MMM D, YYYY',
        );
      }
    }
  }

  getRealDate(date: Date) {
    const correctDate = new Date(date);
    correctDate.setDate(correctDate.getDate() + 1);
    return correctDate;
  }

  private daysBetweenNowAndThen(license: License): number {
    const today = this.today;
    today.setHours(0, 0, 0, 0);
    const expiryDate = this.getRealDate(new Date(license.expirationDate));
    expiryDate.setHours(0, 0, 0, 0);
    const diff = this.today.getTime() - expiryDate.getTime();
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays;
  }

  isThisAnExpire(license: License): boolean {
    const diffDays = this.daysBetweenNowAndThen(license);
    if (diffDays >= 0) {
      return true;
    } else {
      return false;
    }
  }

  fillCardDetails(license: License): IRecordCardConfig {
    let status: string;
    let statusColor: BodyColor;
    let licenseExpiration = moment.isMoment(license.expirationDate)
      ? license.expirationDate.format('YYYY/MM/DD')
      : license.expirationDate;

    if (this.isThisAnExpire(license)) {
      status = 'Expired';
      statusColor = BodyColor.orange;
    } else {
      status = 'Active';
      statusColor = BodyColor.green;
    }

    this.licenseDetails = {
      title: license.licenseNumber,
      firstLine: this.stateLookup.get(license.stateId)?.name,
      secondLine:
        'Exp: ' +
        (licenseExpiration !== undefined
          ? formatDate(licenseExpiration, 'shortDate', 'en-US')
          : ''),
      status: status,
      statusColor: statusColor,
      icon: 'edit',
    };
    return this.licenseDetails;
  }

  nursysCheck(): void {
    const pageUrl = environment.appBaseUrl + this._router.url;
    const ctaText = 'USE NURSYS TO ADD LICENSES';
    if (this.useNursys && this.hasSynced) {
      this._store.dispatch(
        new CallToActionClicked({
          pageURL: pageUrl,
          pageTitle: this._route.routeConfig.title as string,
          destinationURL: pageUrl,
          ctaText: ctaText,
        }),
      );
      this._store.dispatch(new NursysAdd());

      this.nursysDataLoading$
        .pipe(
          filter((loading) => !loading),
          take(1),
          switchMap(() =>
            this._store.select(selectNursysAddError).pipe(
              take(1),
              map((error) => {
                if (!error) {
                  this._store.dispatch(new GetLicenses());
                }
              }),
            ),
          ),
        )
        .subscribe();
    } else {
      this._store.dispatch(
        new CallToActionClicked({
          pageURL: pageUrl,
          pageTitle: this._route.routeConfig.title as string,
          destinationURL: environment.appBaseUrl + '/nursys-setup',
          ctaText: ctaText,
        }),
      );

      this.showNursysDialog();
    }
  }

  switchToNursysDialog(license: License): void {
    this.selectedLicense = license;
    this.showNursysDialog();
  }

  showNursysDialog(): void {
    const dialogData: IDialogParameters = {
      title: 'Nursys License Retrieval',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.nursysDialogTemplate,
      separatedHeader: true,
      noStyling: true,
      isResponsive: true,
      useCustomCloseLogic: true,
    };
    this._dialogService.showDialog(dialogData, true);
  }

  showAddLicenseDialog(selectedLicense?: License): void {
    this.selectedLicense = selectedLicense;
    const dialogData: IDialogParameters = {
      title: selectedLicense == undefined ? 'Add a License' : 'Edit License',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.addEditLicenseDialogTemplate,
      separatedHeader: true,
      noStyling: true,
      isResponsive: true,
      useCustomCloseLogic: true,
    };
    this._dialogService.showDialog(dialogData, true);
  }
}
