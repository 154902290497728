import { createSelector } from "@ngrx/store";
import { IAppState } from "src/app/store/app/app.state";
import { LocationState } from "./location.state";

const selectLocationState = (state: IAppState) => state.location;

export const selectLocation = createSelector(
  selectLocationState,
  (state: LocationState) => state.locationData.data
);


export const selectLocationLoading = createSelector(
  selectLocationState,
  (state: LocationState) => state.locationData.loading
);
