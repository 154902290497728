<ng-container
  *ngIf="{
    poLoading: professionalOverviewLoading$ | async,
    phLoading: professionalHierarchyLoading$ | async,
    formCreated: form !== null
  } as observables"
>
  <div class="speciality-popup">
    <img class="speciality-popup-logo" [src]="odLogo" alt="logo" />
    <hc-heading class="speciality-popup-title" size="h1"
      >Welcome to
      <span class="speciality-popup-title-span">On Demand</span>!</hc-heading
    >
    <hc-body class="speciality-popup-body"
      >To get started, please select your Primary Specialty. You can change this
      later if needed.</hc-body
    >
    <form
      class="speciality-popup-form"
      action=""
      [formGroup]="form"
      [disabled]="observables.poLoading || observables.phLoading"
      (keydown.enter)="$event.preventDefault(); save()"
    >
      <div class="speciality-popup-form-inputs">
        <hc-dropdown
          *ngIf="form?.controls?.profession"
          id="profession-dropdown"
          label="Profession"
          placeholder="Add Profession"
          errorMessage="Profession Required"
          [control]="form?.controls?.profession"
          [required]="true"
          [data]="professions"
        ></hc-dropdown>
        <hc-dropdown
          *ngIf="form?.controls?.specialty && !isSaving"
          id="specialty-dropdown"
          label="Primary Specialty"
          placeholder="Add Primary Specialty"
          errorMessage="Primary Specialty Required"
          [control]="form?.controls?.specialty"
          [required]="true"
          [data]="specialties"
        ></hc-dropdown>
        <hc-dropdown
        *ngIf="form?.controls?.disabledSpecialty && isSaving"
        id="disabled-specialty-dropdown"
        label="Primary Specialty"
        placeholder="Add Primary Specialty"
        errorMessage="Primary Specialty Required"
        [control]="form?.controls?.disabledSpecialty"
        [required]="true"
        [data]="specialties"
      ></hc-dropdown>
      </div>
      <div class="speciality-popup-form-buttons">
        <hc-button *ngIf="showSkipButton && !isSaving" (buttonClick)="skip()" [appearance]="secondary"
          >I don’t have a specialty</hc-button
        >
        <hc-button *ngIf="showSkipButton && isSaving" [appearance]="secondary" [disabled]="true"
          >I don’t have a specialty</hc-button
        >
        <hc-button *ngIf="!isSaving" (buttonClick)="save()" class="speciality-popup-submit-button"
          >{{showSkipButton ? 'Save' : 'Submit'}} Specialty</hc-button
        >
        <hc-button *ngIf="isSaving" class="speciality-popup-submit-button" [disabled]="true"
          >{{showSkipButton ? 'Save' : 'Submit'}} Specialty</hc-button
        >
      </div>
    </form>
  </div>
</ng-container>
