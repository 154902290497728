import { ValidatorFn, AbstractControl } from '@angular/forms';
import { ZipCodeLookupComponent } from 'src/app/components/shared/zip-code-lookup/zip-code-lookup.component';
import { isEqual } from 'lodash';

// value must belong to a certain list
export function autocompleteValidator(list: any[]): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const contains = list.some(validElement => isEqual(validElement, control.value));
    if (control.value == null || control.value === '') {
      return null;
    } else {
      if (!contains) {
        return {'forbiddenName': {value: control.value}};
      } else {
        return null;
      }
    }
  };
}

// Used for autocomplete functions that don't have a set list. Allows for passing in a function for validation
export function remoteAutocompleteValidator(validateFunc: any = null, component: ZipCodeLookupComponent = null): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
      if (validateFunc !== undefined) {
        return validateFunc(control.value, component);
      } else {
        return null;
      }
  };
}
