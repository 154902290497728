<div fxFlex class="view-verify" fxLayout="column">
  <div fxLayout="column" fxLayoutGap="16px">
    <span class="global-font-20 verify-header"> Confirm Your Email </span>
    <div>
      <span> We sent a verification email to </span>
      <span *ngIf="userEmail" class="email"> {{ userEmail }}. </span>
      <span> Please check your inbox and click the confirmation link. </span>
    </div>
    <div>
      <span> Didn't receive an email? </span>
      <span *ngIf="userEmail" class="a click-link" (click)="resendEmail()"> Resend Email </span>
    </div>
    <div>
      <span> Need Help? </span>
      <span class="a click-link" (click)="navigateToVerifySupport()"> Contact Us </span>
    </div>
  </div>
</div>
