<ng-container
  *ngIf="{
    questionnaireLoading: questionnaireLoading$ | async,
    questionnaireSaving: updateQuestionnaireSaving$ | async,
    zipCodeDropdowns: zipCodeDropdowns$ | async,
    yesNoOnlyRadios: yesNoOnlyRadios$ | async,
    stateDropdowns: stateDropdowns$ | async
  } as obs"
>
  <app-loading-screen-spinner [show]="obs.questionnaireLoading || !form"></app-loading-screen-spinner>

  <div *ngIf="!obs.questionnaireLoading || !form" class="tasks-container">
    <div class="tasks-desc-section">
      <hc-body [size]="bodySize" [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">
        We need to ask some basic questions about your eligibility and work history.
      </hc-body>
    </div>
    <app-base-form
      *ngIf="form"
      #baseForm
      [form]="form"
      [isLoadingObs]="questionnaireLoading$"
      [isSavingObs]="updateQuestionnaireSaving$"
      [formHeaderAttributes]="formHeaderAttributes"
      (saveableEmitter)="onSave($event)"
    >
      <ng-container ngProjectAs="[formControls]">
        <div class="tasks-form-controls-wrapper" fxLayout="column" fxLayoutGap="32px">
          <div fxLayout="row" fxLayoutGap="29px">
            <hc-body>1.</hc-body>
            <div fxLayout="column" fxLayoutGap="14px">
              <hc-body [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">Are you authorized to work in the US for any employer?</hc-body>
              <hc-radio-button [options]="obs.yesNoOnlyRadios" [control]="form?.controls?.workAuthorization" (selectedValueChange)="form?.markAsDirty()" id="qe-workAuthQuestionOptions"></hc-radio-button>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="29px">
            <hc-body>2.</hc-body>
            <div fxLayout="column" fxLayoutGap="14px">
              <hc-body [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }"
                >Have you ever had any government-issued license or certification investigated, suspended, or revoked?</hc-body
              >
              <hc-radio-button [options]="obs.yesNoOnlyRadios" [control]="form?.controls?.licenseRevoked" (selectedValueChange)="form?.markAsDirty()" id="qe-licenseRevokedQuestionOptions"></hc-radio-button>
              <ng-container *ngIf="convertYesNoLookupIdToBool(form.controls.licenseRevoked.value)">
                <hc-textarea
                  id="licenseRevokedDetailsInput"
                  [control]="form?.controls?.licenseRevokedDetails"
                  label="If yes, please explain: "
                  [required]="true"
                  errorMessage="Response is required"
                ></hc-textarea>
              </ng-container>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="29px">
            <hc-body>3.</hc-body>
            <div fxLayout="column" fxLayoutGap="14px">
              <hc-body [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }"
                >Have you ever been named as a defendant in a malpractice, negligence, or other professional liability action?</hc-body
              >
              <hc-radio-button [options]="obs.yesNoOnlyRadios" [control]="form?.controls?.negligenceLiability" (selectedValueChange)="form?.markAsDirty()" id="qe-negligenceLiabilityQuestionOptions"></hc-radio-button>
              <ng-container *ngIf="convertYesNoLookupIdToBool(form.controls.negligenceLiability.value)">
                <hc-textarea
                  [id]="negligenceLiabilityDetailsInput"
                  [control]="form?.controls?.negligenceLiabilityDetails"
                  label="If yes, please explain: "
                  [required]="true"
                  errorMessage="Response is required"
                ></hc-textarea>
              </ng-container>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="29px">
            <hc-body>4.</hc-body>
            <div fxLayout="column" fxLayoutGap="14px">
              <div>
                <hc-body [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }"
                  >Do you have any restrictions that would prevent you from performing essential functions in the position you are applying for?</hc-body
                >
                <hc-body [size]="microBodySize" [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">
                  (i.e., inability to participate in Medicare or Medicaid programs; no active driver's license, unwilling to relocate, etc.)
                </hc-body>
              </div>
              <hc-radio-button [options]="obs.yesNoOnlyRadios" [control]="form?.controls?.restrictions" (selectedValueChange)="form?.markAsDirty()" id="qe-restrictionsQuestionOptions"></hc-radio-button>
              <ng-container *ngIf="convertYesNoLookupIdToBool(form?.controls.restrictions.value)">
                <hc-textarea
                  [id]="restrictionsDetailsInput"
                  [control]="form?.controls?.restrictionsDetails"
                  label="If yes, please explain: "
                  [required]="true"
                  errorMessage="Response is required"
                ></hc-textarea>
              </ng-container>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="29px">
            <hc-body>5.</hc-body>
            <div fxLayout="column" fxLayoutGap="14px">
              <hc-body [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">
                Do you have at least one (1) year of working experience in your profession and at least one (1) year of working experience in your current discipline/specialty?
              </hc-body>
              <hc-radio-button [options]="obs.yesNoOnlyRadios" [control]="form?.controls?.workExperience" (selectedValueChange)="form?.markAsDirty()" id="qe-workExperienceQuestionOptions"></hc-radio-button>
              <ng-container *ngIf="form?.controls?.workExperience?.value && !convertYesNoLookupIdToBool(form.controls.workExperience.value)">
                <hc-textarea
                  [id]="workExperienceDetailsInput"
                  [control]="form?.controls?.workExperienceDetails"
                  label="If no, please explain:"
                  [required]="true"
                  errorMessage="Response is required"
                ></hc-textarea>
              </ng-container>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="29px">
            <hc-body>6.</hc-body>
            <div fxLayout="column" fxLayoutGap="14px">
              <hc-body [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">
                Are there any other voluntary disclosure that you wish to make at this time or believe that the Company needs to be aware of?
              </hc-body>
              <hc-radio-button [options]="obs.yesNoOnlyRadios" [control]="form?.controls?.voluntaryDisclosures" (selectedValueChange)="form?.markAsDirty()" id="qe-voluntaryDisclosuresQuestionOptions"></hc-radio-button>
              <ng-container *ngIf="convertYesNoLookupIdToBool(form.controls.voluntaryDisclosures.value)">
                <hc-textarea
                  [id]="voluntaryDisclosuresDetailsInput"
                  [control]="form?.controls?.voluntaryDisclosuresDetails"
                  label="If yes, please explain: "
                  [required]="true"
                  errorMessage="Response is required"
                ></hc-textarea>
              </ng-container>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="29px">
            <hc-body>7.</hc-body>
            <div fxLayout="column" fxLayoutGap="14px">
              <hc-body [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">Do you maintain a permanent tax residence?</hc-body>
              <hc-body [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">
                Your permanent tax home status is an important factor in providing you with an IRS compliant pay package. Please
                <hc-link
                  segmentTrackLinkClick
                  segmentEventType="External"
                  [target]="linkTarget"
                  url="https://7558670.fs1.hubspotusercontent-na1.net/hubfs/7558670/HCTN/Open%20Enrollment%20Documents/TAX%20HOME%20DETERMINATION%20WORKSHEET.pdf"
                >
                  click here
                </hc-link>
                for help determining your tax home status.
              </hc-body>
              <hc-body [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">
                If you maintain a permanent tax residence, you will be asked to provide proof of address prior to the start of an assignment. For a list of acceptable proof
                documents,
                <hc-link
                  segmentTrackLinkClick
                  segmentEventType="External"
                  [target]="linkTarget"
                  url="https://7558670.fs1.hubspotusercontent-na1.net/hubfs/7558670/HCTN/Open%20Enrollment%20Documents/Tax%20Home%20Documents%20for%20HCPs.pdf"
                >
                  click here.
                </hc-link>
              </hc-body>
              <hc-radio-button [options]="obs.yesNoOnlyRadios" [control]="form?.controls?.taxResidence" (selectedValueChange)="form?.markAsDirty()" id="qe-taxResidenceQuestionOptions"></hc-radio-button>
              <ng-container *ngIf="convertYesNoLookupIdToBool(form.controls.taxResidence.value)">
                <hc-input
                  [id]="streetAddress1Input"
                  label="Street 1"
                  placeholder="Enter your address"
                  errorMessage="Street 1 is required"
                  [control]="form?.controls?.streetAddress1"
                  [icon]="locationIcon"
                  [required]="true"
                ></hc-input>
                <hc-input
                  [id]="streetAddress2Input"
                  label="Street 2"
                  placeholder="Enter your address"
                  [control]="form?.controls?.streetAddress2"
                  [icon]="locationIcon"
                ></hc-input>
                <hc-dropdown
                  [id]="zipCodeInput"
                  label="Zip Code"
                  placeholder="Enter your zip code"
                  [errorMessage]="baseForm.getErrorMessage(form?.controls?.zipCode, 'Zip Code')"
                  icon="location_pin"
                  [control]="form?.controls?.zipCode"
                  [icon]="locationIcon"
                  [required]="true"
                  [data]="obs.zipCodeDropdowns"
                  [isDataPrimitive]="false"
                  [useDataFilter]="false"
                  [dropdownValueTemplate]="zipCodeDropdownTemplate"
                  (searchInputChanged)="zipCodeSelected($event)"
                ></hc-dropdown>
                <hc-input
                  [id]="cityInput"
                  label="City"
                  placeholder="Enter your city"
                  errorMessage="City is required"
                  [control]="form?.controls?.city"
                  [icon]="locationIcon"
                  [required]="true"
                ></hc-input>
                <hc-dropdown
                  [id]="stateInput"
                  label="State"
                  placeholder="Select your state"
                  [errorMessage]="baseForm.getErrorMessage(form?.controls?.state, 'State')"
                  [control]="form?.controls?.state"
                  [icon]="locationIcon"
                  [required]="true"
                  [data]="obs.stateDropdowns"
                  [isDataPrimitive]="false"
                ></hc-dropdown>
              </ng-container>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="16px">
            <div fxLayout="column" fxLayoutGap="24px">
              <div fxLayout="column" fxLayoutGap="8px">
                <hc-heading [size]="headingSizeH6" [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">Authorization Statement</hc-heading>
                <hc-card [elevation]="noneCardElevation" [hideBorder]="false">
                  <div class="authorization-text-card">
                    <hc-body [size]="microBodySize" [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">
                      I, the undersigned Healthcare Professional (the “Professional”) and employee of Health Carousel Travel Network, LLC (“HCTN”), attest that the information
                      disclosed herein is, to the best of my knowledge, truthful, factual, and accurate. I understand and acknowledge that the disclosure of false information is
                      expressly prohibited and the disclosure of false information may incur disciplinary action, up to and including termination of employment. I further
                      understand, in the event that I disclose false information to HCTN, I agree to compensate HCTN for any and all costs or expenses incurred related to or as
                      a result of such falsified disclosure.
                    </hc-body>
                    <hc-body [size]="microBodySize" [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">
                      I hereby authorize HCTN to release any and all professional credentials, work verifications, skills checklists, OSHA/HIPAA training results, professional
                      competency exams, criminal background checks, drug screen results and/or health information that I have disclosed to or otherwise authorized to be acquired
                      by HCTN to the extent necessary to:
                    </hc-body>
                    <hc-body [size]="microBodySize" [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">
                      i. Allow me to be considered for an assignment at a Client Healthcare Facility (the “Facility”); and/or
                    </hc-body>
                    <hc-body [size]="microBodySize" [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">
                      ii. Ensure that all required credentials and regulatory documentation are current at all times prior to and during an assignment at a Facility.
                    </hc-body>
                  </div>
                </hc-card>
              </div>
              <hc-body [ngClass]="{ 'tasks-form-grey-text': obs.questionnaireSaving }">
                By tapping “Submit Questionnaire,” I acknowledge that I have read the above Authorization Statement and hereby agree to its terms. I agree that the full name
                typed below will be the electronic representation of my signature.
              </hc-body>
            </div>

            <hc-input
              id="signatureInput"
              label="Professional's Signature"
              placeholder="Enter your full name to sign"
              errorMessage="Signature is required"
              [control]="form?.controls?.signature"
            ></hc-input>
          </div>
        </div>
      </ng-container>
      <ng-container ngProjectAs="[formButtons]">
        <div class="tasks-form-buttons-wrapper">
          <app-flow-handler
            [recordObs]="updateQuestionnaire$"
            [form]="form"
            [isSavingObs]="updateQuestionnaireSaving$"
            [segmentDestinationUrl]="'/professional-overview'"
            [showRegularSaveButton]="true"
            [saveButtonLabel]="'Submit Questionnaire'"
            (saveClickEmitter)="baseForm.saveCheck()"
          ></app-flow-handler>
        </div>
      </ng-container>
    </app-base-form>
  </div>

  <ng-template #zipCodeDropdownTemplate let-option="option"> {{ option.key }} - {{ option.value.city }}, {{ option.value.state }} </ng-template>
</ng-container>
