<div class="standard-padding" fxLayout="column" fxFlex fxLayoutGap="16px">
    <div fxLayoutAlign="end end">
        <button id="applyPopupClose" mat-icon-button class="global-x-btn">
            <mat-icon (click)="cancel()">close</mat-icon>
        </button>
    </div>
    <div class="standard-padding global-font-20 center">
        Let's put together your <b>Profile</b>.
    </div>
    <div class="center">
        Before sending to the employer, we'll double-check everything to help you get that interview!
    </div>
    <div class="full-width standard-padding" fxLayout="row" fxLayoutAlign="center center">
        <button id="applyPopupConfirm" mat-flat-button color="primary" (click)="onClick()">
            GOT IT!
        </button>
    </div>

</div>