import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Store } from '@ngrx/store';
import {
  BadgeColor,
  CardElevation,
  DialogService,
  HierarchicalMultiSelectCheckboxComponent,
  IDialogParameters,
  LoadingBarLength,
} from 'hc-design-system-lib';
import {
  ButtonAppearance,
  ButtonIconType,
  ButtonSize,
} from 'hc-design-system-lib/lib/components/button/button.enums';
import { InputIcon } from 'hc-design-system-lib/lib/components/form/form.enums';
import { IHierarchicalDropdownNode } from 'hc-design-system-lib/lib/components/form/form.interfaces';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  skipWhile,
  takeUntil,
} from 'rxjs/operators';
import {
  IJobFilterAPI,
  ILookup,
  ILookups,
  IProfessionalHierarchy,
  NurseModel,
  SimilarJobSearchModel,
} from 'src/app/common';
import {
  minimumPayOptions,
  PROFESSION_HIERARCHY_NODE_TYPES,
  radiusOptions,
} from 'src/app/common/constants';
import { ContractType } from 'src/app/common/contracts/contract-type';
import {
  getHierarchyNodeByType,
  getHierarchyTreeById,
} from 'src/app/common/functions/dropdown-helpers';
import { JobSearchForm } from 'src/app/common/models/job-search-form';
import { RequiredWhenEmptyFieldsValidator } from 'src/app/common/validators/emptyFieldsRequiredValidators';
import { BaseFormComponent } from 'src/app/components/shared/base-form/base-form.component';
import { FormHeaderAttributes } from 'src/app/services';
import { IAppState } from 'src/app/store/app/app.state';
import {
  GetFacilities,
  GetFacilitiesSuccess,
} from 'src/app/store/facilities/facilities.actions';
import { selectFacilities } from 'src/app/store/facilities/facilities.selectors';
import {
  GetAvailableJobs,
  GetSimilarJobs,
  SetContractType,
  SetJobFilterV2,
} from 'src/app/store/jobs/jobs.actions';
import { selectJobFilterV2 } from 'src/app/store/jobs/jobs.selectors';
import {
  GetLocation,
  GetLocationSuccess,
} from 'src/app/store/location/location.actions';
import { selectLocation } from 'src/app/store/location/location.selectors';
import {
  selectLookups,
  selectLookupsLoading,
  selectProfessionalHierarchy,
} from 'src/app/store/lookups/lookups.selectors';
import { selectIsMobileNonTablet } from 'src/app/store/ui/ui.selectors';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { HeadingSize } from 'hc-design-system-lib/lib/typography/components/heading/heading.component';
import { BodySize } from 'hc-design-system-lib/lib/typography/components/body/body.component';

@Component({
  selector: 'app-job-search-form',
  templateUrl: './job-search-form.component.html',
  styleUrls: ['./job-search-form.component.scss'],
})
export class JobSearchFormComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  private locationComponent: HierarchicalMultiSelectCheckboxComponent;
  @ViewChild('locationInput')
  set locationComponentSet(content: HierarchicalMultiSelectCheckboxComponent) {
    if (content && !this.locationComponent) {
      this.locationComponent = content;
    }
  }

  private facilityComponent: HierarchicalMultiSelectCheckboxComponent;

  @ViewChild('facilityInput')
  set facilityComponentSet(content: HierarchicalMultiSelectCheckboxComponent) {
    if (content && !this.facilityComponent) {
      this.facilityComponent = content;
    }
  }

  @ViewChild('baseForm') baseForm: BaseFormComponent;
  @ViewChild('facilityDetailsModalTemplate')
  facilityDetailsModalTemplate: TemplateRef<any>;
  @ViewChild('mobileSearch') mobileSearchTemplate: TemplateRef<any>;
  @ViewChild('mobileAdvancedFilters')
  mobileAdvancedFiltersTemplate: TemplateRef<any>;

  showShiftPayBadge = false;
  showFacilityBadge = false;
  showLocationBadge = false;
  showAssignmentBadge = false;

  locationIcon = InputIcon.LocationPin;
  headerBadgeColor = BadgeColor.Blue;
  textButtonAppearance = ButtonAppearance.Text;
  secondaryButtonAppearance = ButtonAppearance.Secondary;
  buttonNarrow = ButtonSize.Narrow;
  purple = BadgeColor.Purple;
  h4 = HeadingSize.H4;
  h5 = HeadingSize.H5;
  h6 = HeadingSize.H6;
  narrow = ButtonSize.Narrow;
  micro = BodySize.Micro;
  small = BodySize.Small;
  large = LoadingBarLength.PercentLarge;
  round = ButtonIconType.Round;
  radiusOptions = radiusOptions;
  minimumPayOptions = minimumPayOptions;
  shifts: ILookup<number>[];
  hoursPerWeek: ILookup<number>[];
  startDates: ILookup<number>[];
  assignmentLengths: ILookup<number>[];

  form: FormGroup<JobSearchForm>;
  formHeaderAttributes: FormHeaderAttributes;
  formLoading$: Observable<boolean> = of(false);
  contractTypes: IHierarchicalDropdownNode[] = Object.values(ContractType).map(
    (v, index) => ({ id: '' + index, name: v, type: 'Contract Type' }),
  );

  isMobile$ = this._store.select(selectIsMobileNonTablet);
  nurse$: Observable<NurseModel> = this._store.select(selectNurseData);

  lookups$: Observable<ILookups> = this._store.select(selectLookups);
  lookupsLoading$: Observable<boolean> =
    this._store.select(selectLookupsLoading);
  professionalHierarchy$: Observable<IProfessionalHierarchy[]> =
    this._store.select(selectProfessionalHierarchy);

  specialtyHierarchy: BehaviorSubject<IHierarchicalDropdownNode[]> =
    new BehaviorSubject([]);
  specialtyHierarchy$: Observable<IHierarchicalDropdownNode[]> =
    this.specialtyHierarchy.asObservable();

  locationsData: IHierarchicalDropdownNode[] = [];
  locationSearch: Subject<string> = new Subject();
  locationsData$: Observable<void> = this._store.select(selectLocation).pipe(
    map((locationData) => {
      if (locationData?.length) {
        this.locationsData = locationData.map(
          (location, index) =>
            ({
              id: '' + index,
              name: location,
              type: 'Location',
            }) as IHierarchicalDropdownNode,
        );
      } else {
        this.locationsData = [
          { type: 'Location' } as IHierarchicalDropdownNode,
        ];
      }
    }),
  );

  facilitiesData: IHierarchicalDropdownNode[] = [];
  facilitySearch: Subject<string> = new Subject();
  facilitiesData$: Observable<void> = this._store.select(selectFacilities).pipe(
    map((facilityData) => {
      if (facilityData?.length) {
        this.facilitiesData = facilityData.map(
          (facility) =>
            ({
              id: facility.facilityId,
              name: facility.facilityName,
              type: 'Facility',
            }) as IHierarchicalDropdownNode,
        );
      } else {
        this.facilitiesData = [
          { type: 'Facility' } as IHierarchicalDropdownNode,
        ];
      }
    }),
  );

  jobFilter$: Observable<IJobFilterAPI> = this._store.select(selectJobFilterV2);

  constructor(
    private _store: Store<IAppState>,
    private _fb: FormBuilder,
    private _dialogService: DialogService,
    public matSheet: MatBottomSheet,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.nurse$,
      this.lookups$,
      this.lookupsLoading$,
      this.professionalHierarchy$,
      this.jobFilter$,
    ])
      .pipe(
        filter(([nurse]) => nurse?.email !== null),
        skipWhile(
          ([nurse, lookups, lookupsLoading, professions]) =>
            !nurse ||
            lookupsLoading ||
            !professions ||
            !lookups?.shiftLookup ||
            !lookups?.weeklyHoursLookup ||
            !lookups?.startDatesLookup ||
            !lookups?.assignmentLengthsLookup,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(([nurseData, lookups, , professionalHierarchy, jobFilter]) => {
        this.shifts = Array.from(lookups.shiftLookup.values()).map((shift) => ({
          ...shift,
          name: shift.name.replace(/s?$/, ' shifts'),
        }));
        this.hoursPerWeek = Array.from(lookups.weeklyHoursLookup.values()).map(
          (hour) => ({
            ...hour,
            name:
              hour.name.replace(/^</, '0-').replace(/^>(\w+)/, '$1+') +
              ' hours',
          }),
        );
        this.startDates = Array.from(lookups.startDatesLookup.values());
        this.assignmentLengths = Array.from(
          lookups.assignmentLengthsLookup.values(),
        );

        this.form = this._fb.group<JobSearchForm>({
          // Search criteria
          profession: this._fb.control<IHierarchicalDropdownNode>(null, {
            validators: RequiredWhenEmptyFieldsValidator([
              'locations',
              'facilities',
            ]),
          }),
          specialties: this._fb.control<IHierarchicalDropdownNode[]>(null),
          locations: this._fb.control<IHierarchicalDropdownNode[]>(null, {
            validators: RequiredWhenEmptyFieldsValidator([
              'profession',
              'facilities',
            ]),
          }),
          facilities: this._fb.control<IHierarchicalDropdownNode[]>(null, {
            validators: RequiredWhenEmptyFieldsValidator([
              'locations',
              'profession',
            ]),
          }),
          contractType: this._fb.control<IHierarchicalDropdownNode[]>({
            value: [
              this.contractTypes.find((v) => v.name === ContractType.Travel),
            ],
            disabled: false,
          }),

          // Shift and pay details
          minimumPay: this._fb.control<number>(null),
          shift: this._fb.array<FormControl<boolean>>([]),
          hoursPerWeek: this._fb.array<FormControl<boolean>>([]),

          // Location details
          radius: this._fb.control<number>(null),

          // Facility details
          firstTimeTraveler: this._fb.control<boolean>(null),
          anccMagnetHospitalCode: this._fb.control<boolean>(null),
          exclusiveDiverseProgram: this._fb.control<boolean>(null),
          multiStateLicensure: this._fb.control<boolean>(null),

          // Assignment details
          startDate: this._fb.array<FormControl<boolean>>([]),
          assignmentLength: this._fb.array<FormControl<boolean>>([]),

          // Pagination
          jobsPerPage: this._fb.control<number>(20),
          page: this._fb.control<number>(1),
          orderBy: this._fb.control<number>(3),

          // unused
          bedSize: this._fb.array<FormControl<number>>([]),
          births: this._fb.array<FormControl<number>>([]),
          erVisits: this._fb.array<FormControl<number>>([]),
          traumaLevel: this._fb.array<FormControl<number>>([]),
          facilityTypes: this._fb.array<FormControl<string>>([]),
          technologies: this._fb.array<FormControl<string>>([]),
          teachingFacility: this._fb.control<boolean>(null),
          tempLicenseStates: this._fb.control<boolean>(null),
          crisis: this._fb.control<boolean>(null),
        });

        this.formHeaderAttributes = {
          form: this.form,
          showSaveButton: false,
          disableStayOnPagePopupOnNav: true,
          backAction: null,
        };

        if (jobFilter) {
          this.setupPrepopulatedFilter(
            jobFilter,
            professionalHierarchy,
            nurseData,
          );
        } else {
          if (!this.form.touched) {
            this.setupProfessionAndSpecialty(
              professionalHierarchy as IHierarchicalDropdownNode[],
              nurseData,
              jobFilter,
            );
          }

          this.setUpFormValueChangeListeners();
        }

        this._store.dispatch(
          new SetContractType(
            this.form.controls.contractType.value?.[0]?.name as ContractType,
          ),
        );
      });
  }

  ngOnDestroy(): void {
    this._store.dispatch(new GetLocationSuccess([]));
    this._store.dispatch(new GetFacilitiesSuccess([]));
    this.destroy$.next();
    this.destroy$.complete();
  }

  setupPrepopulatedFilter(jobFilter, professionalHierarchy, nurseData) {
    if (jobFilter?.page && jobFilter.page !== this.form.controls.page.value) {
      this.form.controls.page.setValue(jobFilter.page);
    }

    if (
      jobFilter?.orderBy &&
      jobFilter.orderBy !== this.form.controls.orderBy.value
    ) {
      this.form.controls.orderBy.setValue(jobFilter.orderBy);
    }
    // Search criteria
    this.setupProfessionAndSpecialty(
      professionalHierarchy as IHierarchicalDropdownNode[],
      nurseData,
      jobFilter,
    );
    if (jobFilter.locations?.length) {
      this.form.controls.locations.setValue(
        jobFilter.locations?.map(
          (location, index) =>
            ({
              id: '' + index,
              name: location,
              type: 'Location',
            }) as IHierarchicalDropdownNode,
        ),
      );
    }
    if (jobFilter.facilities?.length) {
      this.form.controls.facilities.setValue(
        jobFilter.facilities?.map(
          (facility) =>
            ({
              id: facility.facilityId,
              name: facility.facilityName,
              type: 'Facility',
            }) as IHierarchicalDropdownNode,
        ),
      );
    }
    if (jobFilter.contractType) {
      this.form.controls.contractType.setValue([
        this.contractTypes.find((ct) => ct.name === jobFilter.contractType),
      ]);
    }

    // Shift details
    if (jobFilter.minimumPay) {
      this.form.controls.minimumPay.setValue(
        minimumPayOptions.indexOf(jobFilter.minimumPay) ?? null,
      );
    }
    const shifts = this.convertValueArrayToCheckboxArray(
      jobFilter.shift,
      this.shifts,
    );
    shifts.forEach((shift) => this.form.controls.shift.push(shift));
    const hoursPerWeek = this.convertValueArrayToCheckboxArray(
      jobFilter.hoursPerWeek,
      this.hoursPerWeek,
    );
    hoursPerWeek.forEach((hour) => this.form.controls.hoursPerWeek.push(hour));

    // Location details
    if (jobFilter.radius) {
      this.form.controls.radius.setValue(
        radiusOptions.indexOf(jobFilter.radius) ?? null,
      );
    }

    // Facility details
    if (jobFilter.firstTimeTraveler) {
      this.form.controls.firstTimeTraveler.setValue(true);
    }
    if (jobFilter.anccMagnetHospitalCode) {
      this.form.controls.anccMagnetHospitalCode.setValue(true);
    }
    if (jobFilter.exclusiveDiverseProgram) {
      this.form.controls.exclusiveDiverseProgram.setValue(true);
    }
    if (jobFilter.multiStateLicensure) {
      this.form.controls.multiStateLicensure.setValue(true);
    }

    // Assignment details
    const startDates = this.convertValueArrayToCheckboxArray(
      jobFilter.startDate,
      this.startDates,
    );
    startDates.forEach((startDate) =>
      this.form.controls.startDate.push(startDate),
    );
    const assignmentLengths = this.convertValueArrayToCheckboxArray(
      jobFilter.assignmentLength,
      this.assignmentLengths,
    );
    assignmentLengths.forEach((assgnLength) =>
      this.form.controls.assignmentLength.push(assgnLength),
    );
  }

  submitSearch(isValid: boolean): void {
    if (isValid) {
      this.matSheet.dismiss();
      this.form.controls.page.setValue(1);
      const formValue = this.form.value;

      const professionSector = getHierarchyNodeByType(
        formValue.profession,
        PROFESSION_HIERARCHY_NODE_TYPES.ProfessionSector,
      );
      const profession = getHierarchyNodeByType(
        formValue.profession,
        PROFESSION_HIERARCHY_NODE_TYPES.Profession,
      );
      const model = formValue as unknown as IJobFilterAPI;
      model.professionSector = professionSector?.name;
      // Search criteria
      model.profession = profession?.id;
      model.specialties = formValue.specialties ?? [];
      model.locations = formValue.locations?.map((v) => v.name) ?? [];
      model.facilities =
        formValue.facilities?.map((v) => ({
          facilityId: v.id,
          facilityName: v.name,
        })) ?? [];
      model.contractType = formValue.contractType[0].name;

      model.minimumPay = minimumPayOptions[model.minimumPay] ?? null;
      model.shift = this.convertCheckboxArrayToValueArray(
        formValue.shift,
        this.shifts,
      );
      model.hoursPerWeek = this.convertCheckboxArrayToValueArray(
        formValue.hoursPerWeek,
        this.hoursPerWeek,
      );

      model.radius = radiusOptions[model.radius] ?? null;

      model.startDate = this.convertCheckboxArrayToValueArray(
        formValue.startDate,
        this.startDates,
      );
      model.assignmentLength = this.convertCheckboxArrayToValueArray(
        formValue.assignmentLength,
        this.assignmentLengths,
      );

      model.reload = true;

      this._store.dispatch(new SetJobFilterV2(model));

      this._store.dispatch(
        new GetSimilarJobs(model as unknown as SimilarJobSearchModel),
      );
      this._store.dispatch(new GetAvailableJobs(model));
    }
  }

  professionChanged(value: any) {
    const specialties = getHierarchyNodeByType(
      value?.data.selectedOptions,
      PROFESSION_HIERARCHY_NODE_TYPES.Profession,
    )?.children;
    this.specialtyHierarchy.next(specialties);
    this.form.controls.specialties.setValue(null);
  }

  setupProfessionAndSpecialty(
    professionalHierarchy: IHierarchicalDropdownNode[],
    nurseData: NurseModel,
    jobFilter?: IJobFilterAPI,
  ) {
    const profession = getHierarchyTreeById(
      professionalHierarchy,
      jobFilter ? jobFilter.profession : nurseData.professionId,
    );
    if (profession?.length) {
      this.form.controls.profession.setValue(profession[0]);

      const specialties = getHierarchyNodeByType(
        profession[0],
        PROFESSION_HIERARCHY_NODE_TYPES.Profession,
      )?.children;
      this.specialtyHierarchy.next(specialties);

      if (jobFilter) {
        this.form.controls.specialties.setValue(
          jobFilter.specialties?.length ? jobFilter.specialties : null,
        );
      } else {
        const specialty = specialties?.find(
          (s) => s.id === nurseData.specialtyId,
        );
        if (specialty) {
          this.form.controls.specialties.setValue([specialty]);
        }
      }
    }
  }

  setUpFormValueChangeListeners() {
    this.form.controls.contractType.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value?.length) {
          this._store.dispatch(
            new SetContractType(value[0]?.name as ContractType),
          );
        } else {
          this._store.dispatch(new SetContractType(ContractType.Travel));
        }
      });

    this.locationSearch
      .pipe(debounceTime(750), takeUntil(this.destroy$))
      .subscribe((newValue: string) => {
        this._store.dispatch(new GetLocation(newValue));
      });

    this.facilitySearch
      .pipe(
        filter((searchString: string) => searchString.length > 2),
        debounceTime(750),
        takeUntil(this.destroy$),
      )
      .subscribe((searchString: string) => {
        this._store.dispatch(new GetFacilities(searchString));
      });

    this.form.controls.profession.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.form.controls.locations.updateValueAndValidity({
          emitEvent: false,
        });
        this.form.controls.facilities.updateValueAndValidity({
          emitEvent: false,
        });
      });
    this.form.controls.locations.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.form.controls.profession.updateValueAndValidity({
          emitEvent: false,
        });
        this.form.controls.facilities.updateValueAndValidity({
          emitEvent: false,
        });
      });
    this.form.controls.facilities.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.form.controls.profession.updateValueAndValidity({
          emitEvent: false,
        });
        this.form.controls.locations.updateValueAndValidity({
          emitEvent: false,
        });
      });
  }

  filterHasValues(formControls: string[]): number | null {
    let filtersWithValue: number = null;
    formControls.forEach((controlName) => {
      if (this.form.controls[controlName]?.value) {
        if (Array.isArray(this.form.controls[controlName]?.value)) {
          if (filtersWithValue === null) {
            filtersWithValue = 0;
          }
          filtersWithValue += this.form.controls[controlName].value?.filter(
            (v) => v,
          ).length;
        } else {
          if (filtersWithValue === null) {
            filtersWithValue = 0;
          }
          filtersWithValue++;
        }
      }
    });
    return filtersWithValue;
  }

  clearValues(formControls: string[]): void {
    formControls.forEach((controlName) => {
      if (Array.isArray(this.form.controls[controlName]?.value)) {
        this.form.controls[controlName].controls.forEach((o) =>
          o.setValue(null),
        );
      } else {
        this.form.controls[controlName].setValue(null);
      }
    });
  }

  displaySliderOptions(options: number[]) {
    return (sliderValue: number): string => {
      const value = options[sliderValue];
      return value ? `${value}` : '';
    };
  }

  locationLocked(): boolean {
    const radius = this.form.controls.radius;
    if (
      this.form.controls.tempLicenseStates.value ||
      this.form.controls.multiStateLicensure.value
    ) {
      radius.setValue(null);
      radius.disable();
      return true;
    } else {
      if (radius.disabled) {
        radius.enable();
      }
      return false;
    }
  }

  getLookups(lookups: ILookups, lookupName: string) {
    return Array.from(lookups?.[lookupName]?.values());
  }

  convertCheckboxArrayToValueArray(
    checkboxArray: boolean[],
    lookupArray: ILookup<number>[],
  ): number[] {
    const valueArray = [];
    checkboxArray.forEach((checkbox, index) => {
      if (checkbox) {
        valueArray.push(lookupArray[index].id);
      }
    });

    return valueArray;
  }

  convertValueArrayToCheckboxArray(
    valueArray: number[],
    lookupArray: ILookup<number>[],
  ): FormControl<boolean>[] {
    const checkboxArray = [];
    lookupArray.forEach((lookup) => {
      checkboxArray.push(
        new FormControl<boolean>(valueArray?.includes(lookup.id) ?? false),
      );
    });

    return checkboxArray;
  }

  showFacilityDetailsInfo() {
    const dialogData: IDialogParameters = {
      title: 'Facility Criteria',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.facilityDetailsModalTemplate,
      isResponsive: true,
      separatedHeader: true,
      noStyling: true,
      displayFullScreenOnMobile: false,
    };
    if (this._dialogService.dialog.openDialogs.length) {
      this._dialogService.updateOpenDialog(dialogData);
    } else {
      this._dialogService.showDialog(dialogData);
    }
  }

  showMobileSearch(): void {
    this.matSheet.open(this.mobileSearchTemplate, {
      panelClass: ['mobile-search-sheet'],
    });
  }

  showMobileFilters(): void {
    this.showAssignmentBadge = true;
    this.showFacilityBadge = true;
    this.showLocationBadge = true;
    this.showShiftPayBadge = true;
    this.matSheet.open(this.mobileAdvancedFiltersTemplate, {
      panelClass: ['mobile-search-sheet'],
    });
  }

  toggleBadge(event: HcEvent, badgeToggle: string) {
    this[badgeToggle] = !event.event['isAccordionOpen'];
  }
}
