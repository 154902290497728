export class SimilarJobSeenIds {
    private ids: string[] = [];

    get(): string[] {
        return JSON.parse(localStorage.getItem('seenIds'));
    }

    add(id: string): void {
        if (localStorage.getItem('seenIds') === null) {
            this.set();
        }
        this.ids = JSON.parse(localStorage.getItem('seenIds'));
        if (!this.ids.includes(id)) {
            this.ids.push(id);
        }
        this.set();
    }

    remove(id: string): void {
        const ids = JSON.parse(localStorage.getItem('seenIds'));
        this.ids = ids.filter(o => o !== id);
        this.set();
    }

    removeAll(): void {
        this.ids = [];
        this.set();
    }

    set(): void {
        localStorage.setItem('seenIds', JSON.stringify(this.ids));
    }

    includesId(id: string): boolean {
        return this.ids.includes(id);
    }
}