import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppInjector } from './app/shared/app-injector';
import { EnvironmentId } from 'src/app/common';
import { init, BrowserTracing, instrumentAngularRouting } from '@sentry/angular-ivy';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Capacitor } from '@capacitor/core';

import 'hammerjs';

if (environment.environmentId == EnvironmentId.Test || environment.environmentId == EnvironmentId.Production) {
  init({
    dsn: "https://600e36997f8241a1a964427e3644e104@o1260822.ingest.sentry.io/6437836",
    environment: environment.sentryEnvironment,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [Capacitor.isNativePlatform() ? environment.ionicAuthConfig.appRedirectUri : environment.ionicAuthConfig.webRedirectUri],
        routingInstrumentation: instrumentAngularRouting,
        beforeNavigate: context => {
          return {
            ...context,
            // We could use angular routing to find the matching route template here.
            // So we can replace with the actual parameter name?
            name: location.pathname
              .replace(/\/[a-f0-9]{32}/g, "/<hash>")
              .replace(/\/[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}/g, "/<guid>")
              .replace(/\/\d+/g, "/<int>"),
          };
        },
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.sentrySampleRate,
    release: environment.releaseVersion,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    if ('serviceWorker' in navigator && environment.production) {
      window.addEventListener('load', function () {
        navigator.serviceWorker
          .register('./ngsw-worker.js')
          .then(function (registration) {
            console.log(
              'Registration successful, scope is:',
              registration.scope
            );
          })
          .catch((err) => console.error('Failed to register worker, error is: ', err));
      });
    }

    AppInjector.setInjector(moduleRef.injector);
  })
  .catch(console.error);

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
