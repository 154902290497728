import { AfterViewInit, Directive, ElementRef, HostListener, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { ExternalLinkClicked, InternalLinkClicked } from "src/app/store/segment/segment.actions";
import { environment } from "src/environments/environment";

/* 
  Applying this directive to an element will cause it to listen for a click event on the element and make a request to segment.
    - The segmentEventType input is required otherwise no track request will be made to segment.
    - segmentEventType = 'Internal' will submit a 'Internal Link Clicked' event to segment.
    - segmentEventType = 'External' will submit a 'External Link Clicked' event to segment.

  SegmentTrackLinkClickDirective verified to work with the following elements:
    - hc-link
    - a
*/
@Directive({
  selector: '[segmentTrackLinkClick]'
})
export class SegmentTrackLinkClickDirective implements AfterViewInit {
  @Input() segmentEventType: 'Internal' | 'External';
  currentRoute: string;
  clickText: string;
  clickUrl: string;

  @HostListener('click', ['$event']) onClick($event){
    this.trackLinkClicked(this.segmentEventType);
  }

  constructor(private eleRef: ElementRef, private router: Router, private _store: Store) {}

  ngAfterViewInit(): void {
    this.currentRoute = environment.appBaseUrl + this.router.url;
    this.clickUrl = this.eleRef.nativeElement?.attributes?.href?.nodeValue || this.eleRef.nativeElement?.attributes?.url?.nodeValue;
    this.clickText = this.eleRef.nativeElement.innerText;
  }

  trackLinkClicked(segmentEventType: 'Internal' | 'External'): void {
    if (segmentEventType) {
      switch (segmentEventType) {
        case 'External': {
          this._store.dispatch(new ExternalLinkClicked(this.currentRoute, this.clickText, this.clickUrl));
          break;
        }
        case 'Internal': {
          this._store.dispatch(new InternalLinkClicked(this.currentRoute,this.clickText, this.clickUrl));
          break;
        }
        default:
          break;
      }
    }
  }
}