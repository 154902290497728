import { ContractType } from "../contracts/contract-type";
import { IJobFilterAPI } from "./job-filter";

export enum EmailTypes {
  Submittal,
  PayPackage,
  AutoOffer,
  JobDetails,
  JobSearch,
  JobFavorites
}

export class EmailFormModel {
  assignments: AssignmentEmailModel[];
  emailBody: string;
  emailType: EmailTypes = EmailTypes.Submittal;
  contractTypes: ContractType[];
  jobSearchParameters: IJobFilterAPI;
}

export class AssignmentEmailModel {
  id: string;
  name: string;
}
