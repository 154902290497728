import { Component, OnDestroy, OnInit } from '@angular/core';
import { IFileUploadOptions, FileUploadTargets } from 'src/app/common';
import { Observable, Subscription } from 'rxjs';
import { BulkUploadPopupComponent } from './bulk-upload-popup/bulk-upload-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { DocumentHelperService } from 'src/app/services/document-helper-service';
import { extensionList } from 'src/app/common/models/extension-list';
import { BadExtensionComponent } from 'src/app/components/shared/bad-extension-popup/bad-extension-popup.component';
import { Store } from '@ngrx/store';
import { AngularticsEventTrack } from 'src/app/store/angulartics2/angulartics2.actions';
import { GetDocuments } from 'src/app/store/userContext/userContext.actions';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss'],
  providers: [DocumentHelperService]
})
export class BulkUploadComponent implements OnInit, OnDestroy {
  files: Array<File>;
  isSaving = false;
  extList = extensionList;
  documentSubscription: Subscription;
  constructor(
    public dialog: MatDialog,
    private _store: Store,
    private _documentHelperService: DocumentHelperService
  ) {}

  isNative: boolean = Capacitor.isNativePlatform();

  ngOnDestroy(): void {
    this.documentSubscription?.unsubscribe();
  }

  ngOnInit() {
  }

  onSave(): void {
    this.isSaving = true;
    this.documentSubscription = this._getDocumentObservable().subscribe(() => {},
    err => this.isSaving = false,
    () => this.onSuccess());
  }

  onSuccess() {
    this.isSaving = false;
    this._store.dispatch(new AngularticsEventTrack('Upload', 'Complete Profile', 'Drop and Go'));
    this._store.dispatch(new GetDocuments());
    this.dialog.open(BulkUploadPopupComponent, {
      data: { fileCount: this.files.length }
    });
  }

  handleFileInput(fileList: FileList, input: HTMLInputElement) {
    const ext = fileList[0].name.substr(fileList[0].name.lastIndexOf('.') + 1).toLowerCase();
    if (!this.extList.includes(ext)) {
      input.value = null;
      this.dialog.open(BadExtensionComponent);
    } else {
      this.files = Array.from(fileList);
      this.onSave();
    }
  }

  async takePicture() {
    // Only allows a single photo instead of a list
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      webUseInput: true
    });

    // Fetch the photo, read as a blob, then convert to file format
    const response = await fetch(image.webPath);
    const blob = await response.blob();
    const filename = image.webPath.split('/').pop();
    const imageFile = new File([blob], filename, { type: 'image/jpg' });
    const extension = image.format.toLocaleLowerCase();

    if (!this.extList.includes(extension)) {
      this.dialog.open(BadExtensionComponent);
    } else {
      this.files = [imageFile];
      this.onSave();
    }
  }

  _getDocumentObservable(): Observable<any> {
    const options: IFileUploadOptions = {
      target: FileUploadTargets.Bulk
    };
   return this._documentHelperService._getDocumentObservable(options, this.files);
  }
}
