import {
  AuthConnect,
  AzureProvider,
  ProviderOptions,
  AuthResult,
  Manifest,
  AuthConnectConfig,
  Params,
} from '@ionic-enterprise/auth';
import { Injectable } from '@angular/core';
import { QueryParams } from 'src/app/common';
import { Platform, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { VaultService } from './vault.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import {
  InitializeUser,
  NukeState,
} from '../store/userContext/userContext.actions';
import { API_ROUTES } from '../common';
import { Minimatch } from 'minimatch';
import { isNullOrUndefined } from '@microsoft/applicationinsights-core-js';
import { AppUrls } from 'src/app/app-urls';
import { Location } from '@angular/common';
import { debounceTime } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { SegmentSoftRegister, SignedIn, SignedUp } from '../store/segment/segment.actions';

export class CustomAzureProvider extends AzureProvider {
  queryParams = new Map<string, string>();

  async authorizeRequest(
    manifest: Manifest,
    options: ProviderOptions,
    config: Pick<
      AuthConnectConfig,
      'ios' | 'android' | 'web' | 'platform' | 'logLevel'
    >,
  ) {
    const { url, params } = await super.authorizeRequest(
      manifest,
      options,
      config,
    );

    // Set the query params, and then delete them from class variable so they don't get set on subsequent calls authomatically.
    Object.keys(this.queryParams).forEach((key) => {
      params[key] = this.queryParams[key];
    });
    this.queryParams = new Map<string, string>();

    return {
      url,
      params,
    };
  }
}

export class B2CScopeAudience {
  scope: string;
  audience: string;

  constructor(scope: string, audience: string) {
    this.scope = scope;
    this.audience = audience;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private initializing: Promise<void> | undefined;
  public isNative;
  private readonly provider = new CustomAzureProvider();
  private readonly signInOptions: ProviderOptions;

  private protectedResourceMap: Map<string, B2CScopeAudience> = new Map<
    string,
    B2CScopeAudience
  >();

  private unprotectedResourceList: string[] = [];

  public userUnathenticated$: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  private authenticationFlowInProgress = false;

  private ionicConfig = environment.ionicAuthConfig;

  constructor(
    platform: Platform,
    private vault: VaultService,
    private navCtrl: NavController,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private _store: Store,
    private location: Location,
  ) {
    this.protectedResourceMap.set(`${environment.apiBaseUrl}`, this.getScope());
    this.protectedResourceMap.set(
      `${environment.expenseManagementUrl}`,
      new B2CScopeAudience(
        this.ionicConfig.archerApi.scopes,
        this.ionicConfig.archerApi.audience,
      ),
    );
    this.protectedResourceMap.set(
      `${environment.timeBaseUrl}`,
      new B2CScopeAudience(
        this.ionicConfig.timeApi.scopes,
        this.ionicConfig.timeApi.audience,
      ),
    );
    this.protectedResourceMap.set(
      `${environment.nurseSentimentApiBaseUrl}`,
      new B2CScopeAudience(
        this.ionicConfig.timeApi.scopes,
        this.ionicConfig.timeApi.audience,
      ),
    );
    this.protectedResourceMap.set(
      `${environment.usersApiBaseUrl}`,
      this.getScope(),
    );
    this.protectedResourceMap.set(
      `${environment.internationalApiBaseUrl}`,
      new B2CScopeAudience(
        this.ionicConfig.hcinApi.scopes,
        this.ionicConfig.hcinApi.audience,
      ),
    );
    this.protectedResourceMap.set(
      `${environment.candidateJobsApiBaseUrl}/v1/jobs/search`,
      this.getScope(),
    );

    this.unprotectedResourceList.push(
      `${environment.apiBaseUrl}${API_ROUTES.lookups}`,
    );
    this.unprotectedResourceList.push(
      `${environment.apiBaseUrl}${API_ROUTES.App}/state`,
    );
    this.unprotectedResourceList.push(
      `${environment.apiBaseUrl}${API_ROUTES.questionnaire}`,
    );
    this.unprotectedResourceList.push(
      `${environment.usersApiBaseUrl}v1/create-ad-user`,
    );

    this.isNative = platform.is('hybrid');
    this.signInOptions = {
      clientId: environment.ionicAuthConfig.clientId,
      discoveryUrl: environment.ionicAuthConfig.discoveryUrl,
      redirectUri: this.isNative
        ? environment.ionicAuthConfig.appRedirectUri
        : window.location.origin + '/redirect',
      logoutUrl: this.isNative
        ? environment.ionicAuthConfig.appLogoutUrl
        : environment.ionicAuthConfig.webLogoutUrl,
      scope: environment.ionicAuthConfig.defaultScopes,
      audience: environment.ionicAuthConfig.audience,
    };
    this.initialize();

    // Debounce stops the http interceptor from spamming logout if there are backed up http requests.
    this.userUnathenticated$
      .pipe(debounceTime(5000))
      .subscribe((userUnathenticated) => {
        if (userUnathenticated && !this.authenticationFlowInProgress) {
          this.logout();
        }
      });
  }

  private getPostLoginRedirectUri() {
    return this.location.path().includes(AppUrls.LOGOUT) ||
      this.location.path().includes(AppUrls.REDIRECT)
      ? '/' + AppUrls.DASHBOARD
      : this.location.path();
  }

  private getScope(): B2CScopeAudience {
    const url = window.location.pathname;
    if (url === '/internal-login' || url === '/contact-select') {
      return new B2CScopeAudience(
        this.ionicConfig.internalScopes,
        this.ionicConfig.audience,
      );
    }
    return new B2CScopeAudience(
      this.ionicConfig.defaultScopes,
      this.ionicConfig.audience,
    );
  }

  private setup(): Promise<void> {
    return AuthConnect.setup({
      platform: this.isNative ? 'capacitor' : 'web',
      logLevel: 'DEBUG',
      ios: {
        webView: 'private',
      },
      web: {
        uiMode: 'current',
        authFlow: 'PKCE',
      },
    });
  }

  private initialize(): Promise<void> {
    if (!this.initializing) {
      this.initializing = new Promise((resolve) => {
        this.setup().then(() => resolve());
      });
    }
    return this.initializing;
  }

  isUnprotectedEndPoint(endpoint: string): boolean {
    const isUnprotected = this.unprotectedResourceList.some((o) => {
      return endpoint.indexOf(o) > -1;
    });
    return isUnprotected;
  }

  public getScopesForEndpoint(endpoint: string): B2CScopeAudience {
    const protectedResourcesArray = Array.from(
      this.protectedResourceMap.keys(),
    );
    const keyMatchesEndpointArray = protectedResourcesArray.filter((key) => {
      const minimatch = new Minimatch(key);
      const match =
        !this.isUnprotectedEndPoint(endpoint) &&
        (minimatch.match(endpoint) || endpoint.indexOf(key) > -1);
      return match;
    });

    // process all protected resources and send the first matched resource
    if (keyMatchesEndpointArray.length > 0) {
      const keyForEndpoint = keyMatchesEndpointArray[0];
      if (keyForEndpoint) {
        return this.protectedResourceMap.get(keyForEndpoint);
      }
    }

    return null;
  }

  public async login(redirectUrl?: string, email?: string): Promise<void> {
    localStorage.setItem('url', window.location.href);
    this.authenticationFlowInProgress = true;
    await this.initialize();

    if (!(await this.isAuthenticated())) {
      let redirectUri = redirectUrl ?? this.getPostLoginRedirectUri();
      await this.vault.setRedirect(redirectUri);
      try {
        if (email) {
          this.provider.queryParams[QueryParams.USER_NAME] = encodeURI(email);
        }
        const authResult = await AuthConnect.login(
          this.provider,
          this.signInOptions,
        );
        await this.saveAuthResult(authResult);
      } catch (err) {
        // retry login
        await this.login(redirectUrl, email);
      }

      this.navCtrl.navigateRoot('/redirect');
    }
  }


  public async logout(): Promise<void> {
    this.authenticationFlowInProgress = true;
    await this.initialize();

    if (await this.isAuthenticated()) {
      const authResult = await this.getAuthResult();
      await this.saveAuthResult(null);
      if (authResult) {
        await AuthConnect.logout(this.provider, authResult);
        this._store.dispatch(new NukeState());
        this.vault.clear();
      }
      await this.login();
    }
  }

  public async refreshAuth(
    authResult: AuthResult,
    b2cScopeAudience: B2CScopeAudience = null,
  ): Promise<AuthResult | null> {
    let newAuthResult: AuthResult | null = null;

    let isRefreshTokenAvailable =
      authResult !== null
        ? await AuthConnect.isRefreshTokenAvailable(authResult)
        : false;
    if (isRefreshTokenAvailable) {
      try {
        // The scope may not be set if the access token has expired, or if it is a call for the default app scope.
        // This was implemented to catch when the scope changes. Ie from On Demand to On Assignment.
        if (
          b2cScopeAudience?.scope !== null &&
          b2cScopeAudience?.scope !== undefined
        ) {
          // Create new ProviderOptions with updated scope/audience
          const opts = {
            ...this.signInOptions,
            scope: b2cScopeAudience?.scope,
            audience: b2cScopeAudience?.audience,
          };

          //Generate an empty AuthResult based ProviderOptions
          const tempAuthResult = await AuthConnect.buildAuthResult(
            this.provider,
            opts,
            { refreshToken: authResult.refreshToken },
          );
          newAuthResult = await AuthConnect.refreshSession(
            this.provider,
            tempAuthResult,
            b2cScopeAudience?.scope,
          );
        } else {
          newAuthResult = await AuthConnect.refreshSession(
            this.provider,
            authResult,
          );
        }
      } catch (err) {
        newAuthResult = await AuthConnect.login(
          this.provider,
          this.signInOptions,
        );
      }
      this.saveAuthResult(newAuthResult);
    } else if (authResult?.accessToken) {
      return authResult;
    }

    return newAuthResult;
  }

  public async getAuthResult(): Promise<AuthResult | null> {
    let authResult = await this.vault.getSession();
    if (authResult && (await AuthConnect.isAccessTokenExpired(authResult))) {
      authResult = await this.refreshAuth(authResult);
    }

    return authResult;
  }

  private async saveAuthResult(authResult: AuthResult | null): Promise<void> {
    if (authResult) {
      await this.vault.setSession(authResult);
    } else {
      await this.vault.clear();
    }
  }

  public async buildAuthResultFromToken(accessToken: string): Promise<void> {
    let tempAuthResult = await AuthConnect.buildAuthResult(
      this.provider,
      this.signInOptions,
      { accessToken },
    );
    tempAuthResult.scope = this.signInOptions.scope;
    await this.saveAuthResult(tempAuthResult);
  }

  private disallowConcurrency(fn) {
    let inprogressPromise: Promise<boolean> = Promise.resolve(true);

    return (...args) => {
      inprogressPromise = inprogressPromise.then(() => fn(...args));

      return inprogressPromise;
    };
  }
  private asyncIsAuthenticated = async (): Promise<boolean> => {
    await this.initialize();
    return !!(await this.getAuthResult());
  };
  public isAuthenticated = this.disallowConcurrency(this.asyncIsAuthenticated);

  public async getAccessToken(
    b2cScopeAudience: B2CScopeAudience,
  ): Promise<string | undefined> {
    await this.initialize();
    const authResult = await this.getAuthResult();
    let b2cScopeArray = JSON.stringify(
      b2cScopeAudience?.scope?.split(' ').sort(this.baseSort),
    );
    let authResultScopeArray = JSON.stringify(
      authResult?.scope?.split(' ').sort(this.baseSort),
    );

    if (
      !isNullOrUndefined(b2cScopeAudience?.scope) &&
      b2cScopeArray !== authResultScopeArray
    ) {
      let newAuth = await this.refreshAuth(authResult, b2cScopeAudience);
      return newAuth?.accessToken;
    }

    return authResult?.accessToken;
  }

  private baseSort(a, b) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }

  public async passwordReset(
    postResetUrl: string = null,
    email: string = null,
    isPasswordChange: boolean = false,
  ): Promise<void> {
    await this.initialize();

    if (email) {
      this.provider.queryParams[QueryParams.USER_NAME] = encodeURI(email);
    }
    const authResult = await AuthConnect.login(this.provider, {
      ...this.signInOptions,
      discoveryUrl: isPasswordChange
        ? environment.ionicAuthConfig.passwordChangeDiscoveryUrl
        : environment.ionicAuthConfig.passwordResetDiscoveryUrl,
    });
    await this.saveAuthResult(authResult);
    await this.login(postResetUrl);
  }

  public async signUp(
    postSignupUrl: string = null,
    email: string = null,
  ): Promise<void> {
    await this.initialize();
    if (email) {
      this.provider.queryParams[QueryParams.USER_NAME] = encodeURI(email);
    }

    const authResult = await AuthConnect.login(this.provider, {
      ...this.signInOptions,
      discoveryUrl: environment.ionicAuthConfig.signupDiscoveryUrl,
    });
    await this.saveAuthResult(authResult);
    await this.login(postSignupUrl);
  }

  private getURL(urlInfo) {
    if (urlInfo.params) {
      const url = new URL(urlInfo.url);
      if (url.search.slice(1)) {
        const splitParam = url.search.slice(1).trim().split('&');
        splitParam.forEach((param) => {
          const kv = param.split('=');
          urlInfo.params[kv[0]] = kv[1];
        });
        url.search = '';
      }
      const queryParams = Object.keys(urlInfo.params)
        .map((key) => {
          return (
            encodeURIComponent(key) +
            '=' +
            encodeURIComponent(urlInfo.params[key])
          );
        })
        .join('&');
      url.search = queryParams;
      return url.toString();
    }
    return urlInfo.url;
  }

  public async loginCallback() {
    await this.initialize();

    if (this.isNative) {
      await this.handleWebLoginCallback()
    } else {
      await this.handleWebLoginCallback(this.activatedRoute.snapshot.queryParams);
    }
  }
  private async handleWebLoginCallback(params?: Params) {
    await this.initialize();

    let code = null;
    let state = null;
    let email: string;
    let newUser = false;
    let authResult = null;

    if(params) {
      code = params['code'];
      state = params['state'];
    }

    // code && state come as query parameters only on web, due to setting uiMode: 'current',
    // In this case, we need to manually handle the login callbacks. 
    if (code && state) {
      const queryEntries = {
        code: code,
        state: state,
      };

      authResult = await this.handleAuthConnectLoginCallback(queryEntries,
        this.signInOptions);
    }
    else {
      authResult = await this.getAuthResult();
    }


      // Handles the passive registration flow, where users are sent an email link to create their password.
      // Also handled the password change flow.
      // In those case the token we get back from b2c is a passwordreset/change grant type and can't authenticate with the backend for http calls.
      // We fix that by calling login() again which does a silent login using that token to get the proper one.
      if (authResult?.idToken) {
        let idToken = this.getDecodedToken(authResult.idToken);
        email = this.getDecodedToken(authResult.accessToken)["signInNames.emailAddress"];
        newUser = idToken['newUser'] ? JSON.parse(idToken['newUser']) : newUser;
        if (
          idToken['acr'] === 'b2c_1a_passwordreset' ||
          idToken['acr'] === 'b2c_1a_passwordchange'
        ) {
          const newAuthResult = await AuthConnect.login(
            this.provider,
            this.signInOptions,
          );
          await this.saveAuthResult(newAuthResult);
        } else {
          await this.saveAuthResult(authResult);
        }
      } else {
        await this.saveAuthResult(authResult);
      }

    this.segementSetup(newUser, email);
    await this.handleDeeplinkRedirect();
  }

  getDecodedToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (e) {
      return null;
    }
  }

  async handleAuthConnectLoginCallback(queryEntries: {[key: string]: string;}, providerOptions: ProviderOptions): Promise<AuthResult> {
    return await AuthConnect.handleLoginCallback(
      queryEntries,
      providerOptions
    );
  }

  async handleDeeplinkRedirect() {
    this.authenticationFlowInProgress = false;

    // Will init a new user (if they're new) which results in a redirect to registration
    // It does so via route guards
    this._store.dispatch(new InitializeUser());

    // Get saved redirect url
    const redirect = await this.vault.getRedirect();

    if (redirect === null || redirect.length === 0) {
      await this.route.navigateByUrl('/dashboard');
    } else {
      await this.route.navigateByUrl(redirect);
    }
  }

  public async logoutCallback() {
    this.login();
  }

  public segementSetup(newUser: boolean, email: string) {
    if (newUser) {
      this._store.dispatch(new SignedUp());
      this._store.dispatch(new SegmentSoftRegister(email));
    } else {
      this._store.dispatch(new SignedIn());
    }
  }
}
