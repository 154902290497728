import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class AppState {
  public isLoaded = false;
  private shouldShowSpinner = true;
  public noConnection = false;
  constructor(
  ) { }

  loaded(): boolean {
    return this.isLoaded && !this.shouldShowSpinner;
  }

  setSpinnerState(shouldShowSpinner: boolean) {
    setTimeout(() => {
      this.shouldShowSpinner = shouldShowSpinner;
    });
  }
}
