import { Action } from '@ngrx/store';
import { NurseTask, VaccineTask } from 'src/app/common/models/db-objects';
import { IFileUploadOptions } from 'src/app/common';

export enum ETasksActions {
  GetTasks = '[TASKS] get tasks',
  GetTasksSuccess = '[TASKS] get tasks success',
  GetTasksError = '[TASKS] get tasks error',
  UploadFluVaccine = '[TASKS] upload flu vaccine',
  UploadFluVaccineSuccess = '[TASKS] upload flu vaccine success',
  UploadFluVaccineError = '[TASKS] upload flu vaccine error',
  GetFluVaccine = '[TASKS] get flu vaccine',
  GetFluVaccineSuccess = '[TASKS] get flu vaccine success',
  GetFluVaccineError = '[TASKS] get flu vaccine error',
  UploadCovidVaccine = '[TASKS] upload covid vaccine',
  UploadCovidVaccineSuccess = '[TASKS] upload covid vaccine success',
  UploadCovidVaccineError = '[TASKS] upload covid vaccine error',
  GetCovidVaccine = '[TASKS] get covid vaccine',
  GetCovidVaccineSuccess = '[TASKS] get covid vaccine success',
  GetCovidVaccineError = '[TASKS] get covid vaccine error',
}

export class GetTasks implements Action {
  public readonly type = ETasksActions.GetTasks;

  constructor(public includePending = false, public forceUpdateCache = false) {}
}

export class GetTasksSuccess implements Action {
  public readonly type = ETasksActions.GetTasksSuccess;

  constructor(public payload: { tasks: NurseTask[]; expiration: Date }) {}
}

export class GetTasksError implements Action {
  public readonly type = ETasksActions.GetTasksError;

  constructor(public error: Error) {}
}

export class GetFluVaccine implements Action {
  public readonly type = ETasksActions.GetFluVaccine;

  constructor() {}
}

export class GetFluVaccineSuccess implements Action {
  public readonly type = ETasksActions.GetFluVaccineSuccess;

  constructor(public task: VaccineTask) {}
}

export class GetFluVaccineError implements Action {
  public readonly type = ETasksActions.GetFluVaccineError;

  constructor(public error: Error) {}
}

export class UploadFluVaccine implements Action {
  public readonly type = ETasksActions.UploadFluVaccine;
  constructor(public options: IFileUploadOptions, public files: Array<File>) {}
}

export class UploadFluVaccineSuccess implements Action {
  public readonly type = ETasksActions.UploadFluVaccineSuccess;

  constructor(public payload: VaccineTask) {}
}

export class UploadFluVaccineError implements Action {
  public readonly type = ETasksActions.UploadFluVaccineError;

  constructor(public error: Error) {}
}


export class GetCovidVaccine implements Action {
  public readonly type = ETasksActions.GetCovidVaccine;

  constructor() {}
}

export class GetCovidVaccineSuccess implements Action {
  public readonly type = ETasksActions.GetCovidVaccineSuccess;

  constructor(public task: VaccineTask) {}
}

export class GetCovidVaccineError implements Action {
  public readonly type = ETasksActions.GetCovidVaccineError;

  constructor(public error: Error) {}
}

export class UploadCovidVaccine implements Action {
  public readonly type = ETasksActions.UploadCovidVaccine;
  constructor(public options: IFileUploadOptions, public files: Array<File>) {}
}

export class UploadCovidVaccineSuccess implements Action {
  public readonly type = ETasksActions.UploadCovidVaccineSuccess;

  constructor(public payload: VaccineTask) {}
}

export class UploadCovidVaccineError implements Action {
  public readonly type = ETasksActions.UploadCovidVaccineError;

  constructor(public error: Error) {}
}


export type TasksActions =
  | GetTasks
  | GetTasksSuccess
  | GetTasksError
  | UploadFluVaccineError
  | UploadFluVaccineSuccess
  | UploadFluVaccine
  | GetFluVaccine
  | GetFluVaccineError
  | GetFluVaccineSuccess
  | UploadCovidVaccine
  | UploadCovidVaccineSuccess
  | UploadCovidVaccineError
  | GetCovidVaccine
  | GetCovidVaccineSuccess
  | GetCovidVaccineError;
