<ng-container *ngIf="steps?.length > 0 && currentStepId">
    <hc-tile-card
        class="progress-tracker-container"
        [templateReference]="trackerTemplate"
    ></hc-tile-card>

    <ng-template #trackerTemplate>
        <div class="title">
            <h3 id="title" class="hc-h3 indent">{{ title }}<mat-icon *ngIf="infoDialogTemplate" (click)="showInfoDialog()">help</mat-icon></h3>
        </div>
        <div class="subtitle">
            <hc-body id="subtitle" [size]="smallBodySize">
                {{ subtitle }}
            </hc-body>
        </div>
        <div class="stepper-container">
            <mat-horizontal-stepper [linear]="true" [selectedIndex]="stepIndex" [disableRipple]="true" labelPosition="bottom">
                <ng-container *ngFor="let step of steps; let i = index">
                    <mat-step [aria-labelledby]="i <= stepIndex ? 'completed' : 'incomplete'" [state]="step.icon"
                     [editable]=false>
                    </mat-step>
                </ng-container>
            </mat-horizontal-stepper>
        </div>
        <h3 class="step-counter">{{stepIndex + 1}}/{{steps.length}}: {{steps[stepIndex].name}}</h3>
    </ng-template>
</ng-container>