import { ContractType } from '../contracts/contract-type';
import { Facility } from './facility';
import { IProfessionalHierarchy } from './lookups';

export class JobFilterApiBase {
  specialties: any[];
  profession: any;
  shift: number[];
  id: string;
  hoursPerWeek: number[];
  professionalHierarchy: IProfessionalHierarchy[];
}

export class IJobFilterAPI extends JobFilterApiBase {
  radius: number;
  assignmentLength: number[];
  startDate: number[];
  traumaLevel: number[];
  facilityTypes: any[];
  crisis: boolean = null;
  multiStateLicensure: boolean;
  localTraveler: boolean = null;
  firstTimeTraveler: boolean;
  teachingFacility: boolean;
  exclusiveDiverseProgram: boolean;
  advanceJobs: boolean;
  tempLicenseStates: boolean;
  anccMagnetHospitalCode: boolean;
  bedSize: number[];
  erVisits: number[];
  births: number[];
  technologies: any[];
  minimumPay: number;
  page: number;
  jobsPerPage = 20;
  locations: string[];
  facilities: Facility[];
  orderBy?: number;
  contractType?: string;
  professionSector?: string;
  reload = true;
}

export class LocationRadiusGroupApi {
  location: string;
  radius: number;
}


export class SimilarJobSearchModel extends JobFilterApiBase {
  location: string;
}

export class SimilarJobByIdSearchModel {

  constructor(public id: string) {
  }
}

export enum FilterType {
  Location = 1,
  Profession = 2,
  Specialty = 3
}

export class IFilterGeneral {
  value: any;
  displayValue?: string;
  principal: boolean;
  filterType?: FilterType;

  constructor(value: any = null, displayValue: string = null, principal: boolean = false, type: FilterType = null) {
    this.value = value;
    this.displayValue = displayValue;
    this.principal = principal;
    this.filterType = type;
  }
}

export class ChipFilter {
  key: string;
  value: IFilterGeneral;

  constructor(key: string, value: IFilterGeneral) {
    this.key = key;
    this.value = value;
  }
}

export class LocationRadiusGroupFilter {
  location: IFilterGeneral = new IFilterGeneral();
  radius: IFilterGeneral = new IFilterGeneral();
}

export class IJobFilter {
  profession: IFilterGeneral = new IFilterGeneral();
  specialties: IFilterGeneral[] | ChipFilter[] = [];
  shift: IFilterGeneral[] = [];
  radius: number;
  contractType: ContractType;
  assignmentLength: IFilterGeneral[] = [];
  facilityTypes: IFilterGeneral[] | ChipFilter[] = [];
  hoursPerWeek: IFilterGeneral[] = [];
  startDate: IFilterGeneral[] = [];
  traumaLevel: IFilterGeneral[] = [];
  crisis: IFilterGeneral = new IFilterGeneral();
  multiStateLicensure: IFilterGeneral = new IFilterGeneral();
  localTraveler: IFilterGeneral = new IFilterGeneral();
  firstTimeTraveler: IFilterGeneral = new IFilterGeneral();
  teachingFacility: IFilterGeneral = new IFilterGeneral();
  exclusiveDiverseProgram: IFilterGeneral = new IFilterGeneral();
  advanceJobs: IFilterGeneral = new IFilterGeneral();
  tempLicenseStates: IFilterGeneral = new IFilterGeneral();
  anccMagnetHospitalCode: IFilterGeneral = new IFilterGeneral();
  bedSize: IFilterGeneral[] = [];
  erVisits: IFilterGeneral[] = [];
  births: IFilterGeneral[] = [];
  technologies: IFilterGeneral[] | ChipFilter[] = [];
  id: IFilterGeneral = new IFilterGeneral();
  minimumPay: IFilterGeneral = new IFilterGeneral();
  facilities: Facility[];
  locations: string[] = [];
  expandedProperties: ChipFilter[];
  orderBy?: number;
  professionalHierarchy: IProfessionalHierarchy[];
  professionSector: string;

  private populateProperties(model: JobFilterApiBase): void {
    model.profession = this.profession != null ? this.profession.value : null;
    model.specialties = this.specialties ? this.specialties?.map(o => o?.value) : [];
    model.shift = this.shift?.map(o => o.value);
    model.id = this.id != null ? this.id.value : null;
    model.hoursPerWeek = this.hoursPerWeek ? this.hoursPerWeek?.map(o => o.value) : null;
    model.professionalHierarchy = this.professionalHierarchy;
  }

  ToApi(page: number = 1): IJobFilterAPI {
    const model = new IJobFilterAPI();

    this.populateProperties(model);
    model.professionSector = this.professionSector;
    model.radius = this.radius;
    model.assignmentLength = this.assignmentLength?.map(o => o.value);
    model.facilityTypes = this.facilityTypes?.map(o => o.value);
    model.startDate = this.startDate?.map(o => o.value);
    model.traumaLevel = this.traumaLevel?.map(o => o.value);
    model.crisis = this.crisis != null ? this.convertCheckboxValues(this.crisis.value, 948050000) : null;
    model.multiStateLicensure = this.multiStateLicensure != null ? this.multiStateLicensure.value : null;
    model.localTraveler = this.localTraveler != null ? this.convertCheckboxValues(this.localTraveler.value, 948050000) : null;
    model.firstTimeTraveler = this.firstTimeTraveler != null ? this.convertCheckboxValues(this.firstTimeTraveler.value, 948050002) : null;
    model.teachingFacility = this.teachingFacility != null ? this.teachingFacility.value : null;
    model.exclusiveDiverseProgram = this.exclusiveDiverseProgram?.value;
    model.advanceJobs = this.advanceJobs != null ? this.advanceJobs.value : null;
    model.tempLicenseStates = this.tempLicenseStates != null ? this.tempLicenseStates.value : null;
    model.bedSize = this.bedSize?.map(o => o.value);
    model.erVisits = this.erVisits?.map(o => o.value);
    model.births = this.births?.map(o => o.value);
    model.technologies = this.technologies?.map(o => o.value);
    model.minimumPay = this.minimumPay?.value ?? null;
    model.anccMagnetHospitalCode = this.anccMagnetHospitalCode?.value != null ? this.anccMagnetHospitalCode.value : null;
    model.facilities = this.facilities;
    model.locations = this.locations;
    model.contractType = this.contractType;

    model.orderBy = this.orderBy;
    model.page = page;

    return model;
  }

  checkForState(value: string): boolean {
    return value === ' USA';
  }

  ToSimilarSearchModel() {
    const model = new SimilarJobSearchModel();

    this.populateProperties(model);
    model.location = this.locations[0];

    return model;
  }

  Remove(item: ChipFilter): void {
    const prop = this[item.key];
    const isArray = Array.isArray(prop);
    if (!isArray) {
      this[item.key] = null;
    } else {
      const y = prop as LocationRadiusGroupFilter[];
      let i = 0;
      let selected = 0;
      if (y[0].location) {
        y.forEach(x => {
          if (x.location.value === item.value.value) {
            selected = i;
          }
          i++;
        });
        y.splice(selected, 1);
      } else {
        const index = prop.indexOf(item.value);
        (this[item.key] as IFilterGeneral[]).splice(index, 1);
      }
    }
  }

  ExpandProperties(): void {
    const expandedProperties = [];
    Object.keys(this).filter(o => o !== 'expandedProperties').forEach(o => {
      const value = this[o];
      if (value) {
        if (Array.isArray(value)) {
          value.forEach(x => {
            const y = x as LocationRadiusGroupFilter;
            if (y.location) {
              const chipValue = new IFilterGeneral(
                `${y.location?.value}`,
                `${y.location?.displayValue} ${y.radius === null ? '' : y.radius?.displayValue}`
              );
              expandedProperties.push(new ChipFilter(o, chipValue));
            } else {
              expandedProperties.push(new ChipFilter(o, x as IFilterGeneral));
            }
          });
        } else {
          const filter = (value as IFilterGeneral);
          if (filter.value != null) {
            expandedProperties.push(new ChipFilter(o, filter));
          }
        }
      }
    });
    this.expandedProperties = expandedProperties;
  }

  convertCheckboxValues(checkbox: any, value: number) {
    if (checkbox !== true) {
      return checkbox === value;
    } else {
      return checkbox;
    }
  }
}
