import { Injectable } from '@angular/core';
import { IJob } from 'src/app/common';
import { ContractType } from 'src/app/common/contracts/contract-type';

@Injectable({
  providedIn: 'root'
})
export class PayDisplayCalculator {

  constructor() {
  }

  public DetermineDisplayType(job: IJob, yesNoIdLookup: Map<number, string>, contractType?: ContractType): PayDisplay {
    const hasPay = this.hasPayValues(job, contractType);
    const isPayPermittedToShow = !(yesNoIdLookup?.get(job.showPay) !== 'Yes');

    if (!hasPay || !isPayPermittedToShow) {
      return PayDisplay.FinancialsNotProvided;
    }

    return PayDisplay.PayIsVisible;
  }

  private hasPayValues(job: IJob, contractType: ContractType): boolean {
    if (!job) {
      return false;
    }

    switch (contractType) {
      // If the contract type is provided, check if the est. weekly gross pay is available
      case ContractType.Travel:
        return !!job.estGrossPay;
      case ContractType.Local:
        return !!job.localEstWeeklyGross;
      // If the contract type is not provided, check if ANY pay value is provided
      default:
        return !!(job.estHourlyPay
          || job.estOvertimePay
          || job.estHousing
          || job.estMIE
          || job.estGrossPay
          || job.localEstWeeklyGross
          || job.localEstHourlyPay
          || job.localEstOvertimePay);
    }
  }
}

export enum PayDisplay {
  PayIsVisible,
  FinancialsNotProvided
}
