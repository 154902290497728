import { createSelector } from '@ngrx/store';
import moment from 'moment';
import { OverlappingWorkHistoryObject, WorkHistory } from 'src/app/common';
import {
  WorkHistoryProfessionTypeIds,
  WorkHistoryTypeIds,
} from 'src/app/common/models/work-history-constants';
import { IAppState } from '../app/app.state';
import { EWorkHistoryContextActions } from './workHistoryContext.actions';
import { IWorkHistoryContextState } from './workHistoryContext.state';

const selectWorkHistoryContext = (state: IAppState) => state.workHistoryContext;

export const selectWorkHistoryList = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.workHistoryList?.data
);
export const selectWorkHistoryListLoading = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.workHistoryList?.loading
);
export const selectWorkHistoryListError = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.workHistoryList?.error
);
export const selectWorkHistoryListExpiration = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.workHistoryList?.expirationDate
);
export const selectSortedWorkHistoryList = createSelector(
  selectWorkHistoryList,
  (workHistoryList: WorkHistory[]) => {
    if (workHistoryList) {
      return [...workHistoryList].sort((a: WorkHistory, b: WorkHistory) => {
        const aEndDate =
          a.endDate != null
            ? new Date(a.endDate).getTime()
            : new Date().getTime();
        const bEndDate =
          b.endDate != null
            ? new Date(b.endDate).getTime()
            : new Date().getTime();
        return bEndDate - aEndDate;
      });
    }
  }
);
export const selectSortedWorkHistoryListWithGaps = createSelector(
  selectSortedWorkHistoryList,
  (workHistoryList: WorkHistory[]) => {
    if (workHistoryList?.length) {
      const workHistoryListSortedByStartDate = [...workHistoryList].sort(
        (a, b) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );
      const timeline: any = {
        startDate: new Date(workHistoryListSortedByStartDate[0].startDate),
        endDate: new Date(workHistoryListSortedByStartDate[0].endDate),
        gaps: [],
      };
      for (let i = 1; i < workHistoryListSortedByStartDate.length; i++) {
        const startDate = new Date(
          workHistoryListSortedByStartDate[i].startDate
        );
        const endDate = workHistoryListSortedByStartDate[i].endDate
          ? new Date(workHistoryListSortedByStartDate[i].endDate)
          : new Date();

        if (startDate > timeline.endDate) {
          timeline.gaps.push({
            isNeeded: true,
            type: WorkHistoryTypeIds.PTO,
            startDate: timeline.endDate,
            endDate: startDate,
          });
        }
        if (endDate > timeline.endDate) {
          timeline.endDate = endDate;
        }
      }
      const results = [
        ...workHistoryListSortedByStartDate,
        ...timeline.gaps,
      ].sort((a, b) =>
        b.endDate
          ? new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
          : 1
      );
      return results;
    }
  }
);

export const selectWorkHistoryById = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.workHistory?.data
);
export const selectWorkHistoryByIdLoading = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.workHistory?.loading
);
export const selectWorkHistoryByIdError = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.workHistory?.error
);
export const selectWorkHistoryByIdExpiration = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.workHistory?.expirationDate
);

export const factorySelectors = {
  selectWorkHistoryOverlap: (workHistoryBeingAdded: WorkHistory) =>
    createSelector(
      selectWorkHistoryList,
      selectWorkHistoryById,
      (workHistoryList, storedWorkHistory) => {
        if (!workHistoryList) return null;

        const oldWorkHistory = storedWorkHistory ?? workHistoryBeingAdded;

        // REMOVE OLD WORKHISTORY FOR EDIT AND PER DIEMS
        const sortedWorkHistoryList = workHistoryList.filter(
          (o) =>
            o.professionType !== WorkHistoryProfessionTypeIds.PerDiem &&
            o.id !== workHistoryBeingAdded.id &&
            o.isNeeded !== true
        );
        for (const workHistory of sortedWorkHistoryList) {
          // SETTING NECESSARY DATES FOR COMPARISION
          const startDateModel = moment(workHistoryBeingAdded.startDate);
          const startDateOther = moment(workHistory.startDate);
          const endDateModel =
            workHistoryBeingAdded.endDate != null
              ? moment(workHistoryBeingAdded.endDate)
              : moment();
          const endDateOther =
            workHistory.endDate != null
              ? moment(workHistory.endDate)
              : moment();
          // DETERMINE IF OVERLAP EXISTS
          let overlapExists = false;
          if (
            (startDateModel.isBefore(startDateOther, 'month') &&
              startDateOther.isBefore(endDateModel, 'month')) ||
            (startDateModel.isSameOrAfter(startDateOther, 'month') &&
              startDateModel.isBefore(endDateOther, 'month'))
          ) {
            overlapExists = true;
          }

          // IF SO RETURN THE WORK HISTORY OBJECT THAT IS BEING OVERLAPPED
          if (overlapExists) {
            if (workHistory.type === WorkHistoryTypeIds.PTO) {
              let doDelete = false;
              const oldStartDate = moment(oldWorkHistory.startDate);
              if (oldStartDate.isBefore(startDateOther, 'month')) {
                const diffGreaterThan1 =
                  endDateOther.diff(endDateModel, 'months') > 1;
                if (!diffGreaterThan1) {
                  doDelete = true;
                }
              } else {
                const diffGreaterThan1 =
                  startDateModel.diff(startDateOther, 'months') > 1;
                if (!diffGreaterThan1) {
                  doDelete = true;
                }
              }
              if (doDelete) {
                const overlappingHistory: OverlappingWorkHistoryObject = {
                  workHistory,
                  delete: true,
                };
                return overlappingHistory;
              }
            } else {
              const overlappingHistory = new OverlappingWorkHistoryObject();
              overlappingHistory.workHistory = workHistory;
              return overlappingHistory;
            }
          }
        }
        return null;
      }
    ),
};
export const selectWorkHistoryAction = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.workHistoryAction
);
export const selectWorkHistoryActionLoading = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.workHistoryAction?.loading
);
export const selectWorkHistoryActionError = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.workHistoryAction?.error
);
export const selectWorkHistoryActionData = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.workHistoryAction?.data
);
export const selectWorkHistoryActionRequestId = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.workHistoryAction?.requestId
);
export const selectWorkHistoryActionDeletedResult = createSelector(
  selectWorkHistoryAction,
  (workHistoryAction) => {
    if (
      !workHistoryAction.loading &&
      workHistoryAction.data &&
      [
        EWorkHistoryContextActions.DeleteWorkHistorySuccess,
        EWorkHistoryContextActions.DeleteWorkHistoryError,
      ].includes(workHistoryAction.data.type)
    )
      return workHistoryAction;
  }
);
export const selectWorkHistoryActionAddedResult = createSelector(
  selectWorkHistoryAction,
  (workHistoryAction) => {
    if (
      !workHistoryAction.loading &&
      workHistoryAction.data &&
      [
        EWorkHistoryContextActions.AddWorkHistorySuccess,
        EWorkHistoryContextActions.AddWorkHistoryError,
      ].includes(workHistoryAction.data?.type)
    )
      return workHistoryAction;
  }
);
export const selectWorkHistoryActionUpdatedResult = createSelector(
  selectWorkHistoryAction,
  (workHistoryAction) => {
    if (
      !workHistoryAction.loading &&
      workHistoryAction.data &&
      [
        EWorkHistoryContextActions.UpdateWorkHistorySuccess,
        EWorkHistoryContextActions.UpdateWorkHistoryError,
      ].includes(workHistoryAction.data?.type)
    )
      return workHistoryAction;
  }
);

export const selectFacilityList = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.facilityResultList?.data
);
export const selectFacilityLoading = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.facilityResultList?.loading
);
export const selectFacilityError = createSelector(
  selectWorkHistoryContext,
  (state: IWorkHistoryContextState) => state.facilityResultList?.error
);
