import { createSelector } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { IRecruiterState } from './recruiter.state';
import { DomSanitizer } from '@angular/platform-browser';
import { CurrentAssigneeInformation, Recruiter } from 'src/app/common/models/recruiter';
import { AvatarSupportCardDetails } from 'src/app/components/support-team-tile/support-team-tile.component';
import { selectAccountStateDataLoading, selectCanSeeInternational } from '../userContext/userContext.selectors';

const recruiterContextState = (state: IAppState) => state.recruiter;

export const selectRecruiterData = createSelector(
  recruiterContextState,
  (state: IRecruiterState) => state.recruiterData?.data
);

export const selectRecruiterDataLoading = createSelector(
  recruiterContextState,
  (state: IRecruiterState) => state.recruiterData?.loading
);

export const selectRecruiterDataError = createSelector(
  recruiterContextState,
  (state: IRecruiterState) => state.recruiterData?.error
);

export const selectRecruiterPopupData = createSelector(
  recruiterContextState,
  (state: IRecruiterState) => state.recruiterPopupData.data
);

export const selectRecruiterPopupDataLoading = createSelector(
  recruiterContextState,
  (state: IRecruiterState) => state.recruiterPopupData.loading
);

export const selectRecruiterPopupDataError = createSelector(
  recruiterContextState,
  (state: IRecruiterState) => state.recruiterPopupData.error
);

export const selectDefaultReferralRecruiterData = createSelector(
  recruiterContextState,
  (state: IRecruiterState) => state.defaultReferralRecruiterData?.data
);

export const selectDefaultReferralRecruiterDataLoading = createSelector(
  recruiterContextState,
  (state: IRecruiterState) => state.defaultReferralRecruiterData?.loading
);

export const selectPayRecruiterData = createSelector(
  recruiterContextState,
  (state: IRecruiterState) => state.payRecruiter.data
);

export const selectPayRecruiterLoading = createSelector(
  recruiterContextState,
  (state: IRecruiterState) => state.payRecruiter.loading
);

export const selectCurrentAssigneeINData = createSelector(
  recruiterContextState,
  (state: IRecruiterState) => state.currentAssigneeIN.data
);

export const selectCurrentAssigneeINLoading = createSelector(
  recruiterContextState,
  (state: IRecruiterState) => state.currentAssigneeIN.loading
);

export const selectSupportContact = createSelector(
  selectCanSeeInternational,
  selectRecruiterData,
  selectCurrentAssigneeINData,
  (isIN: boolean, recruiter: Recruiter, currentAssignee: CurrentAssigneeInformation) => {
    if (isIN) {
      if(currentAssignee) {
        return {
          id: currentAssignee?.id,
          name: currentAssignee?.firstName + ' ' + currentAssignee?.lastName,
          email: currentAssignee?.emailAddress,
          phone: currentAssignee?.phoneNumber,
          title: currentAssignee?.position,
          photoUrl: currentAssignee?.profilePicture
        } as Recruiter
       }
    } else {
      return recruiter
    }
  }
);

export const selectSupportContactLoading = createSelector(
  selectAccountStateDataLoading,
  selectCanSeeInternational,
  selectRecruiterDataLoading,
  selectCurrentAssigneeINLoading,
  (isINisLoading: boolean, isIN: boolean, recruiterLoading: boolean, currentAssigneeLoading: boolean) =>
    isINisLoading || (isIN && currentAssigneeLoading) || recruiterLoading
);

export const selectRecruiterAvatarConfiguration = (sanitizer: DomSanitizer) =>
  createSelector(
    selectRecruiterData,
    selectCurrentAssigneeINData,
    selectCanSeeInternational,
    (recruiter: Recruiter, currentAssignee: CurrentAssigneeInformation, canSeeInternational: boolean) => {
      if(canSeeInternational) {
        if(currentAssignee?.firstName) {
          return [{
            jobTitle: currentAssignee?.position,
            name: currentAssignee?.firstName + ' ' + currentAssignee?.lastName,
            email: currentAssignee?.emailAddress,
            phone: currentAssignee?.phoneNumber,
            imageSource: currentAssignee?.profilePicture ? sanitizer.bypassSecurityTrustHtml('data:image/png;base64' + currentAssignee.profilePicture) : null,
            text: currentAssignee?.firstName[0] + currentAssignee?.lastName[0]
          }] as AvatarSupportCardDetails[]
        }
      } else if (recruiter?.name) {
        return [{
          jobTitle: 'Recruiter',
          name: recruiter?.name,
          email: recruiter?.email,
          phone: recruiter?.phone,
          imageSource: recruiter?.photoUrl ? sanitizer.bypassSecurityTrustHtml('data:image/png;base64' + recruiter.photoUrl) : null,
          text: recruiter.name.split(' ').map(name => name[0]).join('')
        }] as AvatarSupportCardDetails[]
      }
  })
