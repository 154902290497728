import { Action } from '@ngrx/store';
import { DashboardObject } from 'src/app/components';
import { IResourceCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';

export enum EDashboardActions {
  GetDashboardInfo = '[Dashboard] get dashboardInfo',
  GetDashboardInfoSuccess = '[Dashboard] get dashboardInfo success',
  GetDashboardInfoError = '[Dashboard] get dashboardInfo error',
  UpdateUserSeenDashboard = '[Dashboard] update userToHaveSeenDashboard',
  UpdateUserSeenDashboardSuccess = '[Dashboard] update userToHaveSeenDashboard success',
  UpdateUserSeenDashboardError = '[Dashboard] update userToHaveSeenDashboard error',
  GetResources = '[Dashboard] get resources',
  GetResourcesSuccess = '[Dashboard] get resources success',
  GetResourcesError = '[Dashboard] get resources error',
}

export class GetDashboardInfo implements Action {
  public readonly type = EDashboardActions.GetDashboardInfo;

  constructor(public forceUpdateCache = false) {
  }
}

export class GetDashboardInfoSuccess implements Action {
  public readonly type = EDashboardActions.GetDashboardInfoSuccess;

  constructor(public payload: { dashboardObject: DashboardObject, expiration: Date }) {
  }
}

export class GetDashboardInfoError implements Action {
  public readonly type = EDashboardActions.GetDashboardInfoError;

  constructor(public error: Error) {
  }
}

export class UpdateUserSeenDashboard implements Action {
  public readonly type = EDashboardActions.UpdateUserSeenDashboard;
}

export class UpdateUserSeenDashboardSuccess implements Action {
  public readonly type = EDashboardActions.UpdateUserSeenDashboardSuccess;

  constructor(public payload: boolean) {
  }
}

export class UpdateUserSeenDashboardError implements Action {
  public readonly type = EDashboardActions.UpdateUserSeenDashboardError;

  constructor(public error: Error) {
  }
}

export class GetResources implements Action {
  public readonly type = EDashboardActions.GetResources;
}

export class GetResourcesSuccess implements Action {
  public readonly type = EDashboardActions.GetResourcesSuccess;

  constructor(public payload: { resources: IResourceCardConfig[] }) {
  }
}

export class GetResourcesError implements Action {
  public readonly type = EDashboardActions.GetResourcesError;

  constructor(public error: Error) {
  }
}


export type DashboardActions =
  GetDashboardInfo |
  GetDashboardInfoSuccess |
  GetDashboardInfoError |
  UpdateUserSeenDashboard |
  UpdateUserSeenDashboardSuccess |
  UpdateUserSeenDashboardError |
  GetResources |
  GetResourcesSuccess |
  GetResourcesError;
