import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BodyColor } from 'hc-design-system-lib';
import { ITileCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, skipWhile, takeUntil, tap } from 'rxjs/operators';
import { AppUrls } from 'src/app/app-urls';
import { NurseModel } from 'src/app/common';
import { NavHelper } from 'src/app/services';
import { CallToActionClicked } from 'src/app/store/segment/segment.actions';
import { selectIsMobile } from 'src/app/store/ui/ui.selectors';
import { selectCanSeeTravel, selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { environment } from 'src/environments/environment';
import { contactPreferencesQuickLink, expenseManagementQuickLink, jobPreferencesQuickLink, shareDocumentsQuickLink, timeMobileQuickLink } from './quick-links.constants';
import { featureFlagNames } from 'src/app/services/feature-flags.service';
import { selectIsSpecificFlagOn } from 'src/app/store/flags/flags.selectors';

@Component({
  selector: 'app-quick-links-tile',
  templateUrl: './quick-links-tile.component.html',
  styleUrls: ['./quick-links-tile.component.scss'],
})
export class QuickLinksTileComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  bodyPurple = BodyColor.purple;

  tileConfig: ITileCardConfig = {
    title: 'Quick Links',
  };

  isMobile$: Observable<boolean> = this._store.select(selectIsMobile);
  nurse$: Observable<NurseModel> = this._store.select(selectNurseData).pipe(filter((nurseData: NurseModel) => nurseData !== null));
  canSeeTravel$: Observable<boolean> = this._store.select(selectCanSeeTravel);

  contactPreferencesOn: boolean = false;
  contactPreferencesOn$: Observable<boolean> = this._store.select(selectIsSpecificFlagOn(featureFlagNames.communicationPreferences)).pipe(
    skipWhile((contactPreferencesOn) => contactPreferencesOn === null),
    map((contactPreferencesOn) => (this.contactPreferencesOn = contactPreferencesOn)),
    takeUntil(this.destroy$),
  );

  quickLinks = [];

  constructor(
    private _navHelper: NavHelper,
    private _store: Store,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    combineLatest([this.nurse$, this.isMobile$, this.contactPreferencesOn$, this.canSeeTravel$])
      .pipe(
        tap(([nurse, isMobile, contactPreferencesOn, canSeeTravel]) => this.getQuickLinks(nurse, isMobile, canSeeTravel)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getQuickLinks(nurse: NurseModel, isMobile: boolean, canSeeTravel: boolean) {
    this.quickLinks = [];
    if (nurse.hasTimeMobile) {
      this.quickLinks.push({
        ...timeMobileQuickLink,
        action: () => this.navigateToTimeKeeping(),
      });
    }
    if (nurse.hasExpenseManagement) {
      this.quickLinks.push({
        ...expenseManagementQuickLink,
        action: () => this.navigateToExpenseManagement(),
      });
    }
    this.quickLinks.push({
      ...shareDocumentsQuickLink,
      action: () => this.goToBulkUpload(),
    });

    if (canSeeTravel) {
      if (isMobile) {
        this.quickLinks.push({
          ...jobPreferencesQuickLink,
          action: () => this.navigateToJobPreferences(),
        });
      }
      if (this.contactPreferencesOn) {
        this.quickLinks.push({
          ...contactPreferencesQuickLink,
          action: () => this.navigateToContactPreferences(),
        });
      }
    }
  }

  goToBulkUpload(): void {
    this._store.dispatch(new CallToActionClicked(this.getCtaProperties(shareDocumentsQuickLink.text, AppUrls.DROP_AND_GO)));
    this._navHelper.goToBulkUpload();
  }

  navigateToJobPreferences(): void {
    this._store.dispatch(new CallToActionClicked(this.getCtaProperties(jobPreferencesQuickLink.text, AppUrls.JOB_PREFERENCES)));
    this.contactPreferencesOn ? this._navHelper.goToNewJobPreferences() : this._navHelper.goToJobPreferences();
  }

  navigateToContactPreferences(): void {
    this._store.dispatch(new CallToActionClicked(this.getCtaProperties(contactPreferencesQuickLink.text, AppUrls.CONTACT_PREFERENCES)));
    this._navHelper.goToContactPreferences();
  }

  navigateToTimeKeeping(): void {
    this._store.dispatch(new CallToActionClicked(this.getCtaProperties(timeMobileQuickLink.text, AppUrls.TIME_TRACKING)));
    this._navHelper.goToMainTime();
  }

  navigateToExpenseManagement(): void {
    this._store.dispatch(new CallToActionClicked(this.getCtaProperties(expenseManagementQuickLink.text, AppUrls.EXPENSE_MANAGEMENT)));
    this._navHelper.goToExpenseSummary();
  }

  getCtaProperties(ctaText, destinationUrl) {
    return {
      pageURL: environment.appBaseUrl + this._router.url,
      pageTitle: this._route.routeConfig?.title as string,
      destinationURL: environment.appBaseUrl + `/${destinationUrl}`,
      ctaText: ctaText,
    };
  }
}
