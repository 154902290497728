<div class="no-shrink">
    <div  fxFlex fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px">
        <div>
            {{message}}
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px" class="full-width">
            <button id="expiringYes" mat-button  color="accent" (click)="existing()">
                YES
            </button>
            <button id="expiringNo" mat-raised-button (click)="cancel()">
                NO
            </button>
        </div>
    </div>
</div>