import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { Component, Input, OnDestroy, TemplateRef } from "@angular/core";
import { Subject } from "rxjs";
import { BodySize, DialogService, CardElevation, IDialogParameters} from 'hc-design-system-lib';

export interface StepItem {
  id: any,
  name: string;
  icon: string;
}

@Component({
  selector: 'app-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['./progress-tracker.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})

export class ProgressTrackerComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @Input() steps: StepItem[];

  @Input() currentStepId: number;

  @Input() title: string;

  @Input() subtitle: string;

  @Input() infoDialogTemplate: TemplateRef<any>;

  @Input() infoDialogTitle: string;
  

  smallBodySize: BodySize = BodySize.Small;

  stepIndex: number;

  constructor(
    private _dialogService: DialogService
  ) { }

  ngOnInit() {
    this.stepIndex = this.steps.findIndex(step => step.id === this.currentStepId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showInfoDialog(): void {

    const dialogParams: IDialogParameters = {
      title: this.infoDialogTitle,
      text: "",
      icon: null,
      showCloseIcon: true,
      elevation: CardElevation.Large,
      template: this.infoDialogTemplate,
      isResponsive: true
    };

    this._dialogService.showDialog(dialogParams, false);
  }
}