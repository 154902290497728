<ng-container
  *ngIf="{
    jobSubmittalsLoading: jobSubmittalsLoading$ | async,
    submittalCards: submittalCards$ | async
  } as obs"
>
  <hc-tile-card
    *ngIf="obs.jobSubmittalsLoading || obs.submittalCards?.length"
    [cardConfig]="currentSubmissionTileDetails"
    [templateReference]="submissionJobCarousel"
    (promptClicked)="showFirstInLineModal()"
  >
    <div bodyContent>
      <hc-body [size]="bodySize">
        Estimated weekly rates are subject to change upon based on your Tax Home address that is on file.  Please reach out to your recruiter for any questions
      </hc-body>
    </div>
  </hc-tile-card>

  <ng-template #submissionJobCarousel>
    <hc-card-carousel
      [isLoading]="obs.jobSubmittalsLoading"
      [skeletonCardsCount]="3"
      [cardData]="obs.submittalCards"
      (cardClicked)="jobCardClicked($event)"
      (cardActionClicked)="submittalCardHelp($event)"
      (cardLinkClicked)="submittalCardLinkClicked($event)"
    ></hc-card-carousel>
  </ng-template>
  <ng-template #filDialogTemplate>
    <app-fil-pop-up></app-fil-pop-up>
  </ng-template>
</ng-container>
