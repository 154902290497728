<ng-container
  *ngIf="{
    isMobile: isMobile$ | async,
    showSupportTeamTile: showSupportTeamTile$ | async,
    canSeeInternational: canSeeInternational$ | async,
    canSeeTravel: canSeeTravel$ | async
  } as observables"
>
  <ng-template #recommendedDialogTemplate>
    <app-recommended-jobs-popup></app-recommended-jobs-popup>
  </ng-template>

  <ng-template #filDialogTemplate>
    <app-fil-pop-up></app-fil-pop-up>
  </ng-template>

  <div
    class="dashboard-container"
    fxLayout.gt-sm="row"
    fxLayout="column"
    fxLayoutGap.gt-sm="24"
    fxLayoutGap="16"
  >
    <div
      class="left-column"
      fxFlex.gt-sm="66"
      fxLayout="column"
      fxLayoutGap.gt-sm="24"
      fxLayoutGap="16"
    >
      <hc-tile-card
        *ngIf="observables.canSeeTravel && (isRegistrationPath || hasProfileTasks || hasPayTasks)"
        fxFlexOrder="1"
        class="complete-your-profile-tile"
        [cardConfig]="completeYourProfileTileDetails.cardConfig"
        [templateReference]="completeYourProfileTemplate"
      ></hc-tile-card>

      <!-- TODO Current Assignment tile is not ready yet  -->
      <!-- <hc-card fxFlexOrder.gt-sm="2" fxFlexOrder="2">
          <h1>Current Assignment</h1>
          <h4>2L on Desktop</h4>
          <h4>2 on Mobile</h4>
        </hc-card> -->

      <app-current-submission-carousel
        class="hide-tile-if-empty"
        [hiddenStatuses]="hiddenSubmittalStatuses"
        [hiddenLinks]="newHiddenSubmittalLinks"
        [completedTasks]="!(toDoApplyTasks$ | async)?.length"
        fxFlexOrder="3"
      ></app-current-submission-carousel>
      <hc-tile-card
        *ngIf="observables.canSeeTravel"
        fxFlexOrder.gt-sm="4"
        fxFlexOrder="4"
        [cardConfig]="jobSearchDetails"
        [templateReference]="jobSearchTemplate"
      ></hc-tile-card>
      <app-recommended-jobs-tile
        *ngIf="observables.canSeeTravel"
        class="hide-tile-if-empty"
        [fxFlexOrder]="(dashboardVariantsOn$ | async) ? 0 : 5"
      ></app-recommended-jobs-tile>
      <app-immigration-tracker
        *ngIf="observables.canSeeInternational"
        fxFlexOrder="6"
      ></app-immigration-tracker>
      <app-visa-details-tile
        *ngIf="observables.canSeeInternational"
        fxFlexOrder="7">
      </app-visa-details-tile>
      <app-support-team-tile
        *ngIf="observables.showSupportTeamTile && observables.isMobile"
        fxFlexOrder="8"
      ></app-support-team-tile>
      <app-quick-links-tile
        *ngIf="observables.isMobile"
        fxFlexOrder="9"
      ></app-quick-links-tile>
      <hc-tile-card
        *ngIf="observables.isMobile && observables.canSeeTravel"
        fxFlexOrder="10"
        (linkClicked)="showFirstInLineModal()"
        [cardConfig]="firstInLineTileDetails"
      >
        <hc-body [size]="smallBodySize" ngProjectAs="[bodyContent]">
          The fastest and easiest way to land your top choice destinations once
          your profile and job preferences are complete.
        </hc-body>
      </hc-tile-card>
      <hc-tile-card
        fxFlexOrder="11"
        [cardConfig]="resourceCenterDetails"
        [templateReference]="resourceCenterTemplate"
      ></hc-tile-card>
    </div>
    <div
      class="right-column"
      fxFlex.gt-sm="33"
      fxLayout="column"
      fxLayoutGap="24"
      *ngIf="!observables.isMobile"
    >
      <app-support-team-tile
        *ngIf="observables.showSupportTeamTile"
        fxFlexOrder="1"
      ></app-support-team-tile>
      <app-job-preferences-tile
        *ngIf="observables.canSeeTravel"
        fxFlexOrder.gt-sm="2"
        fxFlexOrder="2"
      ></app-job-preferences-tile>
      <app-quick-links-tile
        fxFlexOrder.gt-sm="3"
        fxFlexOrder="3"
      ></app-quick-links-tile>
      <hc-tile-card
        *ngIf="observables.canSeeTravel"
        fxFlexOrder.gt-sm="4"
        fxFlexOrder="4"
        (linkClicked)="showFirstInLineModal()"
        [cardConfig]="firstInLineTileDetails"
      >
        <hc-body [size]="smallBodySize" ngProjectAs="[bodyContent]">
          The fastest and easiest way to land your top choice destinations once
          your profile and job preferences are complete.
        </hc-body>
      </hc-tile-card>
    </div>
  </div>

  <ng-template #completeYourProfileTemplate>
    <div class="complete-your-profile-template">
      <div class="complete-your-profile-template__container">
        <div class="complete-your-profile-template__container__content">
          <hc-heading [size]="headingSizeH6">Profile Progress</hc-heading>
          <hc-body>
            Your profile is used to build the application packet that's shared
            with a facility when you apply to a role.
          </hc-body>
          <div
            *ngIf="completeYourProfileTileDetails.progressDetails"
            class="complete-your-profile-template__container__content_progress"
          >
            <hc-progress-bar
              mode="determinate"
              [value]="completeYourProfileTileDetails.progressDetails.value"
            ></hc-progress-bar>
            <hc-body>
              {{
                completeYourProfileTileDetails.progressDetails.completedItems
              }}/{{ completeYourProfileTileDetails.progressDetails.totalItems }}
            </hc-body>
          </div>
        </div>
        <div class="complete-your-profile-template__container__button">
          <hc-button (click)="navigateToTasksList()">
            Complete My Tasks
          </hc-button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #jobSearchTemplate>
    <div class="job-search-content-container">
      <hc-body>
        Discover meaningful jobs around the country that fit your needs.
      </hc-body>
      <hc-button
        [appearance]="buttonAppearance.secondary"
        (buttonClick)="navigateToJobSearch()"
      >
        Go to Job Search
      </hc-button>
    </div>
  </ng-template>

  <ng-template #resourceCenterTemplate>
    <div class="resource-center-content-container">
      <hc-body [size]="smallBodySize">
        Explore helpful resources on the Health Carousel website
      </hc-body>
      <div class="cards-container">
        <hc-card-carousel-v2
          [resourceCardData]="resources$ | async"
          [isLoading]="(resourcesLoading$ | async) === true"
          (resourceCardButtonClicked)="linkClicked($event)"
          style="width: 100%"
          [grid]="grid"
          [gridBreakpoints]="gridBreakpoints"
        ></hc-card-carousel-v2>
      </div>
    </div>
  </ng-template>
</ng-container>
