<div class="filDialogTemplate-content">
  <hc-body>
    Our First in Line matching system is designed to get your profile in front of a nurse manager faster at no cost to you.
  </hc-body>
  <hc-body>
    Automated tools combined with our quality assurance team will match your profile against the perfect assignments and begin submitting your application - allowing you a hassle-free way to find your ideal job.
  </hc-body>
  <div>
    <hc-heading [size]="headingSizeH6">
      Exact matches, every time
    </hc-heading>
    <hc-body>
      We are finding you the perfect job based on the preferences you indicated here:
      <div>
        <hc-link
          [target]="linkTarget"
          [routerLink]="['/job-preferences']"
          [url]="null"

          (click)="_dialogService.dialog.closeAll()"
          >Job Preferences</hc-link>
      </div>
    </hc-body>
  </div>
  <div>
    <hc-heading [size]="headingSizeH6">Stay informed</hc-heading>
    <hc-body>
      Want to be notified in real-time as you are submitted
      to jobs? Set up text message Submission Alerts!
    </hc-body>
  </div>
</div>