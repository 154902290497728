<div fxFlex fxLayout="column" class="container">
  <div fxLayout="row" class="question">
    Are you sure you want to remove and archive this file?
  </div>
  <div fxLayout="row" class="body">
    We'll completely remove this file from your document library.
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between start" class="options">
    <button id="deleteDocumentPopupCancel" mat-button color="primary" (click)="cancel()">CANCEL</button>
    <button id="deleteDocumentPopupConfirm" mat-button color="primary" (click)="onRemove()">YES, REMOVE</button>
  </div>
</div>
