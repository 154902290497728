import {
  initialLookupsState,
  ILookupsState,
} from 'src/app/store/lookups/lookups.state';
import {
  ELookupsActions,
  LookupsActions,
} from 'src/app/store/lookups/lookups.actions';

export function lookupsReducer(
  state = initialLookupsState,
  action: LookupsActions
): ILookupsState {
  switch (action.type) {
    case ELookupsActions.GetLookups:
      return {
        ...state,
        appLookups: {
          ...state.appLookups,
          error: null,
          loading: true,
        },
      };

    case ELookupsActions.GetLookupsSuccess:
      return {
        ...state,
        appLookups: {
          ...state.appLookups,
          data: {
            ...state.appLookups.data,
            ...action.payload,
          },
          error: null,
          loading: false,
        },
      };

    case ELookupsActions.GetLookupsError:
      return {
        ...state,
        appLookups: {
          ...state.appLookups,
          error: action.error,
          loading: false,
        },
      };

    case ELookupsActions.GetINLookups:
      return {
        ...state,
        appLookupsIN: {
          ...state.appLookupsIN,
          error: null,
          loading: true,
        },
      };

    case ELookupsActions.GetINLookupsSuccess:
      return {
        ...state,
        appLookupsIN: {
          ...state.appLookupsIN,
          data: {
            ...state.appLookupsIN.data,
            ...action.payload,
          },
          error: null,
          loading: false,
        },
      };

    case ELookupsActions.GetINLookupsError:
      return {
        ...state,
        appLookupsIN: {
          ...state.appLookupsIN,
          error: action.error,
          loading: false,
        },
      };

    case ELookupsActions.GetSkillAreaInfoLookups:
      return {
        ...state,
        appLookups: {
          ...state.appLookups,
          error: null,
          loading: false,
        },
      };

    case ELookupsActions.GetSkillAreaInfoLookupsSuccess:
      return {
        ...state,
        appLookups: {
          ...state.appLookups,
          data: {
            ...state.appLookups.data,
            ...action.payload,
          },
          error: null,
          loading: false,
        },
      };

    case ELookupsActions.GetSkillAreaInfoLookupsError:
      return {
        ...state,
        appLookups: {
          ...state.appLookups,
          error: action.error,
          loading: false,
        },
      };

    case ELookupsActions.GetQuestionLookups:
      return {
        ...state,
        appLookups: {
          ...state.appLookups,
          error: null,
          loading: false,
        },
      };

    case ELookupsActions.GetQuestionLookupsSuccess:
      return {
        ...state,
        appLookups: {
          ...state.appLookups,
          data: {
            ...state.appLookups.data,
            questionsLookup: action.payload.questionsLookup,
          },
          error: null,
          loading: false,
        },
      };

    case ELookupsActions.GetQuestionLookupsError:
      return {
        ...state,
        appLookups: {
          ...state.appLookups,
          error: action.error,
          loading: false,
        },
      };

    case ELookupsActions.GetProfessionalHierarchy:
      return {
        ...state,
        professionalHierarchy: {
          ...state.professionalHierarchy,
          error: null,
          loading: false,
        },
      };

    case ELookupsActions.GetProfessionalHierarchySuccess:
      return {
        ...state,
        professionalHierarchy: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };

    case ELookupsActions.GetProfessionalHierarchyError:
      return {
        ...state,
        professionalHierarchy: {
          ...state.professionalHierarchy,
          error: action.error,
          loading: false,
        },
      };

    default:
      return state;
  }
}
