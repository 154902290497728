import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService as DesignSystemNotificationService } from 'hc-design-system-lib';
import { AppInsights } from '../shared/app-insights.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorTrackingNotificationService extends DesignSystemNotificationService {
  constructor(
    private _snackBar: MatSnackBar,
    private _appInsights: AppInsights
  ) {
    super(_snackBar);
  }

  showFormErrorNotification(formName: string, formGroup: UntypedFormGroup): void {
    this.showNotification(
      'Please review the following errors and try again.',
      'error'
    );

    const errors = {};
    Object.keys(formGroup.controls).forEach(controlName => {
      if (formGroup.controls[controlName].errors) {
        errors[controlName] = formGroup.controls[controlName].errors;
      }
    });
    console.log({ formValue: formGroup.value, formErrors: errors, formName });
    this._appInsights.trackEvent('form.error', {
      formValue: formGroup.value,
      formErrors: errors,
    });
  }
}
