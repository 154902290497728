import { createSelector } from '@ngrx/store';
import { IHierarchicalDropdownNode } from 'hc-design-system-lib/lib/components/form/form.interfaces';
import { ICertificationLookupModel, ILookup, ILookups, IProfessionalHierarchy, NurseProfileModel, } from 'src/app/common';
import { medicalAssistantProfession, PROFESSION_HIERARCHY_SECTORS, } from 'src/app/common/constants';
import { Proficiency } from 'src/app/common/contracts/proficiency';
import { Shifts } from 'src/app/common/contracts/shifts-id';
import { certificationLookupDropdownSort, convertIntoDropdownData, getHierarchyTreeById, } from 'src/app/common/functions/dropdown-helpers';
import { convertIntoRadioGroupData } from 'src/app/common/functions/radio-helpers';
import { IAppState } from '../app/app.state';
import { ILookupsState } from './lookups.state';
import { IDataState } from '../app/app.models';
import { selectCanSeeInternational, selectProfessionalOverview } from '../userContext/userContext.selectors';

const selectLookupsState = (state: IAppState) => state.lookups;

export const selectLookup = createSelector(
  selectLookupsState,
  selectCanSeeInternational,
  (state: ILookupsState, isIN: boolean) => {
    let lookups = state.appLookups;
    if(isIN) {
      lookups = {
        data: {...state.appLookups.data, ...state.appLookupsIN.data },
        error: state.appLookups.error ?  state.appLookups.error : state.appLookupsIN.error,
        loading: state.appLookups.loading || state.appLookupsIN.loading
      };
    }
    return lookups;
  }
);

export const selectLookups = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data
);

export const selectLookupsLoading = createSelector(
  selectLookupsState,
  selectCanSeeInternational,
  (state: ILookupsState, isIN: boolean) =>
      isIN ?
        state.appLookups.loading || state.appLookupsIN.loading :
        state.appLookups.loading
);

export const selectINLookupsImmigrationStages = createSelector(
  selectLookupsState,
  (state: ILookupsState) => state.appLookupsIN?.data?.immigrationStagesLookup
);

export const selectBusinessUnitLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.businessUnitLookup
);

export const selectStateLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.stateLookup
);

export const selectStateDropdowns = createSelector(
  selectStateLookup,
  (stateLookup: Map<string, ILookup<string>>) =>
    Array.from(stateLookup?.values() ?? [], (v) => convertIntoDropdownData(v, 'name'))
);

export const selectShiftLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.shiftLookup
);

export const selectTraumaLevelLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.traumaLevelLookup
);

export const selectCompactCardDisplayStatusesLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.compactCardDisplayStatuses
);

export const selectSpecialtyLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.specialtyLookup
);

export const selectProfessionLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.professionLookup
);

export const selectYesNoOnlyLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.yesNoOnlyLookup
);

export const selectYesNoOnlyRadios = createSelector(
  selectYesNoOnlyLookup,
  (lookup: Map<string, ILookup<string>>) =>
    Array.from(lookup?.values() ?? [], (v) => convertIntoRadioGroupData(v))
);

export const selectYesNoLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.yesNoLookup
);

export const selectYesNoIdLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.yesNoIdLookup
);

export const selectTravelExperienceLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.travelExperienceLookup
);

export const selectWorkExperienceLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.workExperienceLookup
);

export const selectIssuingLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.issuingLookup
);

export const selectCertificationLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.certificationLookup
);

export const selectLicenseCertificationStatusLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.licenseCertificationStatusLookup
);

export const selectLicenseLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.licenseLookup
);

export const selectDegreeLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.degreeLookup
);

export const selectCountryLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.countryLookup
);

export const selectPositionTitleLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.positionTitleLookup
);

export const selectTechnologyLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.technologyLookup
);

export const selectProficiencyLookup = createSelector(
  selectLookupsState,
  (state: ILookupsState) => {
    const profficiencyData = state.appLookups.data?.proficiencyLookup;
    if (profficiencyData) {
      profficiencyData.set(Proficiency.Beginner, {
        id: Proficiency.Beginner,
        name: 'Beginner',
        Description: 'Can operate with heavy assistance',
        sortOrder: 1,
      });
      profficiencyData.set(Proficiency.Intermediate, {
        id: Proficiency.Intermediate,
        name: 'Intermediate',
        Description: 'Can operate with minimal assistance',
        sortOrder: 2,
      });
      profficiencyData.set(Proficiency.Advanced, {
        id: Proficiency.Advanced,
        name: 'Advanced',
        Description: 'Can operate independently ',
        sortOrder: 3,
      });
      profficiencyData.set(Proficiency.Expert, {
        id: Proficiency.Expert,
        name: 'Expert',
        Description: 'Can use pro features ',
        sortOrder: 4,
      });
      profficiencyData.set(Proficiency.Instructor, {
        id: Proficiency.Instructor,
        name: 'Instructor',
        Description: 'Can teach this software',
        sortOrder: 5,
      });
    }
    return profficiencyData;
  }
);

export const selectProfessionTypesLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.professionTypesLookup
);

export const selectWeeklyHoursLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.weeklyHoursLookup
);

export const selectAssignmentLengthsLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.assignmentLengthsLookup
);

export const selectFrequencyLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.frequencyLookup
);

export const selectProfessionDataWithFilteredOffMedicalAssistant =
  createSelector(
    selectProfessionLookup,
    (professionLookup: Map<string, ILookup<string>>) =>
      Array.from(professionLookup.values()).filter(
        (o) => o.id !== medicalAssistantProfession
      )
  );

export const selectFacilityTypesLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.facilityTypesLookup
);

export const selectBedSizeLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.bedSizeLookup
);

export const selectErVisitsPerYearLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.erVisitsPerYearLookup
);

export const selectBirthsPerYearLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.birthsPerYearLookup
);

export const selectStartDatesLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.startDatesLookup
);

export const selectRegistrationWorkExperience = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.registrationWorkExperience
);

export const selectAssignmentSubstageLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.assignmentSubstageLookup
);

export const selectSkillLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.skillLookup
);

export const selectQuestionsLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.questionsLookup
);

export const selectGroupLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.groupLookup
);

export const selectPerformanceScaleLookup = createSelector(
  selectLookup,
  (state: IDataState<ILookups>) => state.data?.performanceScale
);

export const selectJobPreferencesShifts = createSelector(
  selectShiftLookup,
  (shiftLookup: Map<number, ILookup<number>>) =>
    Array.from(shiftLookup?.values()).filter(
      (o) => o.id !== Shifts.Rotating && o.id !== Shifts.Mids
    )
);

export const selectSortedCertificationsLookupDropdownData = createSelector(
  selectCertificationLookup,
  (certLookup: Map<string, ICertificationLookupModel>) => {
    const unsortedCertifications = Array.from(certLookup.values(), (c) =>
      convertIntoDropdownData(c, 'certification')
    );
    return certificationLookupDropdownSort(unsortedCertifications);
  }
);

export const selectProfessionalHierarchy = createSelector(
  selectLookupsState,
  (state: ILookupsState) => state.professionalHierarchy?.data
);

export const selectProfessionalHierarchyLoading = createSelector(
  selectLookupsState,
  (state: ILookupsState) => state.professionalHierarchy?.loading
);

export const selectProfessionHierarchyBySelector = (selector: string) =>
  createSelector(
    selectProfessionalHierarchy,
    (hierarchy: IProfessionalHierarchy[]) =>
      hierarchy.find(
        (sector) => sector.name.toLowerCase() === selector.toLowerCase()
      )?.children
  );

export const selectNursingProfessionalHierarchy = createSelector(
  selectProfessionalHierarchy,
  (hierarchy: IProfessionalHierarchy[]) =>
    hierarchy?.find(
      (sector) =>
        sector.name.toLowerCase() ===
        PROFESSION_HIERARCHY_SECTORS.Nursing.toLowerCase()
    )?.children
);

//TODO: reconsider this selector?
export const selectProfessionBasedOnProfessionalOverview = createSelector(
  selectProfessionalOverview,
  selectProfessionalHierarchy,
  (
    professionOverview: NurseProfileModel,
    professionalHierarchy: IProfessionalHierarchy[],
  ) => {
    let professionToReturn = null;
    if (professionOverview?.profession && professionalHierarchy?.length > 0) {
      professionToReturn = getHierarchyTreeById(
        professionalHierarchy as IHierarchicalDropdownNode[],
        professionOverview.profession,
      );
    }
    return professionToReturn?.length ? professionToReturn[0] : null;
  },
);

export const selectProfessionSpecialtyRequirement = (professionId: string) =>
  createSelector(
    selectNursingProfessionalHierarchy,
    (professionalHierarchy: IProfessionalHierarchy[]) => {
      const profession = getHierarchyTreeById(
        professionalHierarchy as IHierarchicalDropdownNode[],
        professionId
      );
      const requiresSpecialty =
        profession && profession[0]?.children?.length > 0;
      return requiresSpecialty;
    }
  );

export const selectShowSpecialtyPopup = (
  professionId: string,
  specialtyId: string,
  sector?: string
) =>
  createSelector(
    selectProfessionalHierarchy,
    (professionalHierarchy: IProfessionalHierarchy[]) => {
      if (!specialtyId) {
        if (sector) {
          professionalHierarchy = [
            ...professionalHierarchy.filter(
              (sectorHierarchyTree) =>
                sectorHierarchyTree.name.toLowerCase() === sector.toLowerCase()
            ),
          ];
        }
        const hierarchyTrees = professionalHierarchy.reduce(
          (sectors, sectorNode) => {
            const sectorCategories = sectorNode.children.reduce(
              (categories, category) => {
                const profession = category.children.find(
                  (p) => p.id === professionId
                );
                if (profession) {
                  categories.push({ ...category, children: [profession] });
                }
                return categories;
              },
              []
            );
            if (sectorCategories?.length) {
              sectors.push({ ...sectorNode, children: sectorCategories });
            }
            return sectors;
          },
          []
        );
        const hierarchyTree =
          hierarchyTrees?.length > 1 ? hierarchyTrees[1] : hierarchyTrees[0];
        const hasSpecialities =
          hierarchyTree &&
          hierarchyTree?.children[0]?.children[0]?.children.length > 0;

        const isEnrolling = sessionStorage.getItem('isEnrolling');
        return (
          hasSpecialities &&
          (hierarchyTree.name.toLowerCase() ===
            PROFESSION_HIERARCHY_SECTORS.Nursing.toLowerCase() ||
            isEnrolling === 'true')
        );
      }

      return false;
    }
  );
