import { Component, OnInit } from '@angular/core';
import { UrlHelper } from 'src/app/common/UrlHelper';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private authService: AuthenticationService
  ) { }

    ngOnInit(): void {
      const currentUrl = environment.appBaseUrl;
      const username = UrlHelper.GetUserName();
      this.authService.passwordReset(currentUrl, username);
    }
}
