import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  retry,
  withLatestFrom,
} from 'rxjs/operators';
import { ICertificationLookupModel, IImmigrationStageLookupModel, ILookup, ILookups } from 'src/app/common';
import { Question } from 'src/app/common/models/question';
import { HCINPortalAPIService, NursePortalApi } from 'src/app/services';
import {
  ISkillAreaInfoLookups,
  IQuestionsLookups,
  IProfessionalHierarchy,
} from '../../common/models/lookups';
import { IAppState } from '../app/app.state';
import { selectLookups } from './lookups.selectors';
import {
  ELookupsActions,
  GetINLookupsError,
  GetINLookupsSuccess,
  GetLookupsError,
  GetLookupsSuccess,
  GetProfessionalHierarchyError,
  GetProfessionalHierarchySuccess,
  GetQuestionLookupsError,
  GetQuestionLookupsSuccess,
  GetSkillAreaInfoLookupsError,
  GetSkillAreaInfoLookupsSuccess,
  LookupsActions,
} from './lookups.actions';
import { UsersApiService } from 'src/app/services/users-api.service';

export const mapTNLookupsData = (data: any): ILookups => {
  return {
    professionLookup: new Map(
      data.professions.map((i): [string, ILookup<string>] => [i.id, i])
    ),
    specialtyLookup: new Map(
      data.specialities
        .sort((a: { name: string }, b: { name: string }) =>
          a.name?.localeCompare(b.name)
        )
        .map((i: ILookup<string>): [string, ILookup<string>] => [i.id, i])
    ),
    facilityTypesLookup: new Map(
      data.facilityTypes.map((i: any): [string, ILookup<string>] => [i.id, i])
    ),
    technologyLookup: new Map(
      data.technologies.map((i: any): [string, ILookup<string>] => [i.id, i])
    ),
    shiftLookup: new Map(
      data.shifts.map((i: any): [number, ILookup<number>] => [i.id, i])
    ),
    degreeLookup: new Map(
      data.degrees.map((i: any): [number, ILookup<number>] => [i.id, i])
    ),
    proficiencyLookup: new Map(
      data.proficiencies.map((i: any): [number, ILookup<number>] => [i.id, i])
    ),
    workExperienceLookup: new Map(
      data.workExperiences.map((i: any): [number, ILookup<number>] => [i.id, i])
    ),
    travelExperienceLookup: new Map(
      data.workExperiences.map((i: any): [number, ILookup<number>] => [i.id, i])
    ),
    stateLookup: new Map(
      data.states.map((i: any): [string, ILookup<string>] => [i.id, i])
    ),
    countryLookup: new Map(
      data.countries.map((i: any): [string, ILookup<string>] => [i.id, i])
    ),
    licenseCertificationStatusLookup: new Map(
      data.licenseCertificationStatuses.map(
        (i: any): [number, ILookup<number>] => [i.id, i]
      )
    ),
    certificationLookup: new Map(
      data.certifications.map((i: any): [number, ICertificationLookupModel] => [i.id, i])
    ),
    professionTypesLookup: new Map(
      data.professionTypes.map((i: any): [string, ILookup<string>] => [
        i.name,
        i,
      ])
    ),
    yesNoLookup: new Map(
      data.yesNo.map((i: any): [string, ILookup<string>] => [i.name, i])
    ),
    yesNoIdLookup: new Map(
      data.yesNo.map((i: any): [string, number] => [i.id, i.name])
    ),
    communicationPreferenceLookup: new Map(
      data.communicationPreferences.map((i: any): [string, ILookup<string>] => [
        i.name,
        i,
      ])
    ),
    yesNoOnlyLookup: new Map(
      data.yesNoOnly.map((i: any): [string, ILookup<string>] => [i.name, i])
    ),
    performanceScale: new Map(
      data.performanceScale.map((i: any): [string, ILookup<string>] => [
        i.name,
        i,
      ])
    ),
    positionTitleLookup: new Map(
      data.positionTitles.map((i: any): [number, any] => [i.id, i])
    ),
    weeklyHoursLookup: new Map(
      data.weeklyHours.map((i: any): [number, ILookup<number>] => [i.id, i])
    ),
    businessUnitLookup: new Map(
      data.businessUnitIds.map((i: any): [string, ILookup<string>] => [i.id, i])
    ),
    displayCategoryLookup: new Map(
      data.displayCategory.map((i: any): [number, string] => [i.id, i])
    ),
    traumaLevelLookup: new Map(
      data.traumaLevels.map((i: any): [number, ILookup<string>] => [i.id, i])
    ),
    frequencyLookup: new Map(
      data.frequencies.map((i: any): [number, ILookup<string>] => [i.id, i])
    ),
    issuingLookup: new Map(
      data.issuingBodies.map((i: any): [string, ILookup<string>] => [i.id, i])
    ),
    submittalStatusLookup: new Map(
      data.submittalStatuses.map((i: any): [number, ILookup<number>] => [
        i.id,
        i,
      ])
    ),
    compactCardDisplayStatuses: new Map(
      data.compactCardDisplayStatuses.map((i: any): [string, number] => [
        i.id,
        i.name,
      ])
    ),
    bedSizeLookup: new Map(
      data.bedSizes.map((i: any): [number, string] => [i.id, i])
    ),
    erVisitsPerYearLookup: new Map(
      data.erVisitsPerYear.map((i: any): [number, string] => [i.id, i])
    ),
    birthsPerYearLookup: new Map(
      data.birthsPerYear.map((i: any): [number, string] => [i.id, i])
    ),
    startDatesLookup: new Map(
      data.startDates.map((i: any): [number, string] => [i.id, i])
    ),
    assignmentLengthsLookup: new Map(
      data.assignmentLengths.map((i: any): [number, string] => [i.id, i])
    ),
    assignmentSubstageLookup: new Map(
      data.assignmentSubstages.map((i: any): [string, ILookup<string>] => [
        i.id,
        i,
      ])
    ),
    registrationWorkExperience: new Map(
      data.registrationWorkExperience.map(
        (i: any): [string, ILookup<string>] => [i.id, i]
      )
    ),
  } as ILookups;
};

export const mapINLookupsData = (data: any): ILookups => {
  return {
    immigrationStagesLookup: new Map(
      data.immigrationStages.map((i: any): [number, IImmigrationStageLookupModel] => [i.id, i])
    ),
    certificationLookup:
        new Map(
          data.certifications.map((i: any): [number, ICertificationLookupModel] => [i.certificationId,
            {
              id: i.certificationId,
              certificationId: i.certificationId,
              certification: i.certificationName,
              description: i.description,
              isProfessionalCert: i.isProfessionalCert
            }
          ])
        ),
      professionLookup:
      new Map(
        data.professions.map((i: any): [number, ILookup<string>] => [i.id,
          {
            id: i.id,
            name: i.name,
            shortName: i.code,
            requiresSpecialty: i.requiresSpecialty,
            sortOrder: 2147483647
          }
        ])
      ),
      specialtyLookup:
      new Map(
        data.professions.map((i: any): [number, ILookup<string>] => [i.id,
          {
            id: i.id,
            parentId: i.parentId,
            type: i.type,
            name: i.name,
            shortName: i.shortName
          }
        ])
      ),
      yesNoLookup: new Map(
        data.yesNo.map((i: any): [number, ILookup<number>] => [i.id, i])
      ),
  } as ILookups;
};

@Injectable({
  providedIn: 'root',
})
export class LookupsEffects {
  constructor(
    private actions$: Actions,
    private _api: NursePortalApi,
    private _store: Store<IAppState>,
    private _usersApi: UsersApiService,
    private _hcinApi: HCINPortalAPIService
  ) { }

  getLookups$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LookupsActions>(ELookupsActions.GetLookups),
      withLatestFrom(this._store.select(selectLookups)),
      exhaustMap(([, lookups]) => {
        if (!!lookups) {
          return of(new GetLookupsSuccess(lookups));
        }
        return this._api.getLookups().pipe(
          retry(1),
          map(
            (data: any) => {
              const mappedLookups = mapTNLookupsData(data);
              return new GetLookupsSuccess(mappedLookups);
            }
          ),
          catchError((error: Error) => of(new GetLookupsError(error)))
        );
      })
    )
  );
  getINLookups$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LookupsActions>(ELookupsActions.GetINLookups),
      exhaustMap(() => {
        return this._hcinApi.getLookups().pipe(
          retry(1),
          map(
            (data: any) => {
              const mappedLookups = mapINLookupsData(data);
              return new GetINLookupsSuccess(mappedLookups);
            }
          ),
          catchError((error: Error) => of(new GetINLookupsError(error)))
        );
      })
    )
  );
  getSkillAreaInfoLookups$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LookupsActions>(ELookupsActions.GetSkillAreaInfoLookups),
      exhaustMap(() =>
        this._api.getSkillAreaInfo().pipe(
          retry(1),
          map(
            (data: any) =>
              new GetSkillAreaInfoLookupsSuccess({
                skillLookup: new Map(
                  data.skills.map((i): [number, ILookup<string>] => [i.id, i])
                ),
                groupLookup: new Map(
                  data.groups.map((i): [number, any] => [i.id, i])
                ),
              } as ISkillAreaInfoLookups)
          ),
          catchError((error: Error) =>
            of(new GetSkillAreaInfoLookupsError(error))
          )
        )
      )
    )
  );
  getQuestionLookups$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LookupsActions>(ELookupsActions.GetQuestionLookups),
      exhaustMap(() =>
        this._api.getAllQuestions().pipe(
          retry(1),
          map(
            (data: Question[]) =>
              new GetQuestionLookupsSuccess({
                questionsLookup: new Map(
                  data.map((i): [number, Question] => [i.id, i])
                ),
              } as IQuestionsLookups)
          ),
          catchError((error: Error) => of(new GetQuestionLookupsError(error)))
        )
      )
    )
  );

  getProfessionalHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LookupsActions>(ELookupsActions.GetProfessionalHierarchy),
      exhaustMap(() => {
        return this._usersApi.getProfessionalHierarchy().pipe(
          retry(1),
          map(
            (data: IProfessionalHierarchy[]) =>
              new GetProfessionalHierarchySuccess(data)
          ),
          catchError((error: Error) =>
            of(new GetProfessionalHierarchyError(error))
          )
        );
      })
    )
  );
}
