import { Component, ViewEncapsulation } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';


@Component({
  selector: 'app-filter-bottom-sheet',
  templateUrl: './filter-bottom-sheet.component.html',
  styleUrls: ['./filter-bottom-sheet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterBottomSheetComponent {

  constructor(private bottomSheetRef: MatBottomSheetRef<FilterBottomSheetComponent>) { }

  onMainClick() {
    this.bottomSheetRef.dismiss('refresh');
  }

  cancel(): void {
    this.bottomSheetRef.dismiss();
  }
}
