import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';
import { CurrentAssigneeInformation, Recruiter } from 'src/app/common/models/recruiter';
import { RecruiterTaskCreationModel } from 'src/app/common/models/recruiter-task-creation-model';
import { HCINPortalAPIService, NavHelper, NursePortalApi } from 'src/app/services';
import { UsersApiService } from 'src/app/services/users-api.service';
import {
  GetRecruiterSuccess,
  GetRecruiterError,
  ERecruiterActions,
  RecruiterActions,
  GetDefaultReferralRecruiterError,
  GetDefaultReferralRecruiterSuccess,
  GetPayRecruiterSuccess,
  GetPayRecruiterError,
  CreatePayPackageTask,
  CreatePayPackageTaskSuccess,
  CreatePayPackageTaskError,
  GetCurrentAssigneeINSuccess,
  GetCurrentAssigneeINError,
} from '../recruiter/recruiter.actions';
import { EUserContextActions, GetAccountStateSuccess, UserContextActions } from '../userContext/userContext.actions';

@Injectable({
  providedIn: 'root'
})
export class RecruiterEffects {
  constructor(
    private actions$: Actions,
    private _api: NursePortalApi,
    private _apiIN: HCINPortalAPIService,
    private _usersApiService: UsersApiService,
    private _navHelper: NavHelper
  ) { }

  getRecruiter$ = createEffect(() => this.actions$.pipe(
    ofType<RecruiterActions | UserContextActions>(ERecruiterActions.GetRecruiter, EUserContextActions.SubmitRegistrationSuccess),
    exhaustMap(() =>
      this._api.getMyRecruiter().pipe(
        map((recruiter: Recruiter) => new GetRecruiterSuccess(recruiter)),
        catchError((error: Error) => of(new GetRecruiterError(error)))
      )
    )
  ));


  getDefaultReferralRecruiter$ = createEffect(() => this.actions$.pipe(
    ofType<RecruiterActions | UserContextActions>(ERecruiterActions.GetDefaultReferralRecruiter, EUserContextActions.InitializeUser),
    exhaustMap(() =>
      this._usersApiService.getDefaultReferralRecruiter().pipe(
        map((recruiter: Recruiter) => new GetDefaultReferralRecruiterSuccess(recruiter)),
        catchError((error: Error) => of(new GetDefaultReferralRecruiterError(error)))
      )
    )
  ));

  getPayRecruiter$ = createEffect(() => this.actions$.pipe(
    ofType<RecruiterActions | UserContextActions>(ERecruiterActions.GetPayRecruiter),
    exhaustMap(() =>
      this._api.getPayRecruiter().pipe(
        map((recruiter: Recruiter) => new GetPayRecruiterSuccess(recruiter)),
        catchError((error: Error) => of(new GetPayRecruiterError(error)))
      )
    )
  ));

  createPayPackageTask$ = createEffect(() => this.actions$.pipe(
    ofType<RecruiterActions>(ERecruiterActions.CreatePayPackageTask),
    map((action: CreatePayPackageTask) => action.payload),
    exhaustMap((payload: RecruiterTaskCreationModel) =>
      this._api.createPayPackageTask(payload).pipe(
        map((response) => new CreatePayPackageTaskSuccess(response)),
        catchError((error: Error) => of(new CreatePayPackageTaskError(error)))
      ))
  )
  );

  getCurrentAssigneeIN$ = createEffect(() => this.actions$.pipe(
    ofType<UserContextActions>(
      EUserContextActions.GetAccountStateSuccess,
    ),
    filter((response: GetAccountStateSuccess) => response.payload.canSeeInternational),
    exhaustMap(() =>
      this._apiIN.getCurrentAssigneeInformation().pipe(
        map((currentAssignee: CurrentAssigneeInformation) => new GetCurrentAssigneeINSuccess(currentAssignee)),
        catchError((error: Error) => of(new GetCurrentAssigneeINError(error)))
      )
    )
  ));

}
