<ng-container *ngIf="{
    isMobile: isMobile$ | async,
    nurseData: nurseIN$ | async
  } as obs">
  <hc-tile-card [cardConfig]="tileConfig" [templateReference]="visaDetailsTemplate"></hc-tile-card>

  <ng-template #visaDetailsTemplate>
    <div class="visa-details">
      <div class="visa-details-column" *ngIf="obs?.nurseData?.immigrationRecords?.length || obs.isMobile">
        <div class="immigration-record-details" *ngFor="let record of obs?.nurseData?.immigrationRecords">
          <div class="visa-detail-field uscisCaseNumber">
            <span class="visa-detail-field-title" >
              USCIS Number
            </span>
            <div *ngIf="record.uscisCaseNumber != null;then hasUscisCaseNumber else doesNotHaveData"></div>
            <ng-template #hasUscisCaseNumber>
              <span class="visa-detail-field-val uscisCaseNumberVal" fxFlex>
                {{record.uscisCaseNumber}}
              </span>
            </ng-template>
            <ng-template #doesNotHaveData>
              <span class="visa-detail-field-val tbd" fxFlex>
                TBD
              </span>
            </ng-template>
          </div>
          <div class="visa-detail-field nvcCaseNumber">
            <span class="visa-detail-field-title" >
              NVC Number
            </span>
            <div *ngIf="record.nvcCaseNumber != null;then hasNvcCaseNumber else doesNotHaveData">
            </div>
            <ng-template #hasNvcCaseNumber>
              <span class="visa-detail-field-val" fxFlex>
                {{record.nvcCaseNumber}}
              </span>
            </ng-template>
          </div>
          <div class="visa-detail-field">
            <span class="visa-detail-field-title" >
              NVC Invoice
            </span>
            <div *ngIf="record.nvcInvoiceId != null;then hasNvcInvoiceId else doesNotHaveData"></div>
            <ng-template #hasNvcInvoiceId>
              <span class="visa-detail-field-val" fxFlex>
                {{record.nvcInvoiceId}}
              </span>
            </ng-template>
          </div>
          <div class="visa-detail-field">
            <span class="visa-detail-field-title" >
              Visa Filed Date
            </span>
            <div *ngIf="record.visaFiledDate != null;then hasVisaFiledDate else doesNotHaveData"></div>
            <ng-template #hasVisaFiledDate>
              <span class="visa-detail-field-val" fxFlex>
                {{record.visaFiledDate | date:'MM/dd/yy'}}
              </span>
            </ng-template>
          </div>
          <div class="visa-detail-field">
            <span class="visa-detail-field-title" >
              Priority Date
              <mat-icon class=" icon gray-icon" hcTooltipHeading="Priority Date"
                hcTooltip="This is the date that USCIS sets as your place in line to continue the immigration process. Priority date becomes “current” once it reaches the front of the line and a green card is available. The date depends on current laws, personal circumstances, and country of origin.">
                help
              </mat-icon>
            </span>
            <div *ngIf="record.priorityDate != null;then hasPriorityDate else doesNotHaveData"></div>
            <ng-template #hasPriorityDate>
              <span class="visa-detail-field-val" fxFlex>
                {{record.priorityDate | date:'MM/dd/yy'}}
              </span>
              <ng-container *ngIf="showPriorityDateToolTip">
                <app-tooltip-modal [toolTip]="priorityDateToolTip"></app-tooltip-modal>
              </ng-container>
            </ng-template>
          </div>
          <div *ngIf="record.interviewDate != null" class="visa-detail-field">
            <span class="visa-detail-field-title" >
              Interview Date
            </span>
            <span class="visa-detail-field-val" fxFlex>
              {{record.interviewDate | date:'MM/dd/yy'}}
            </span>
          </div>
          <br>
        </div>
        <div *ngIf="obs.isMobile; then visaDetailLinks"></div>
      </div>
      <div *ngIf="!obs.isMobile">
        <div *ngIf="!obs.isMobile; then visaDetailLinks"></div>
      </div>
    </div>
  </ng-template>

  <ng-template #visaDetailLinks>
    <div class=" links-area">
      <div class="links-heading">Helpful Links: </div>
      <a (click)="openExternalLink(visaDetailURLs.EMBASSY_INTERVIEW)" class="hc-a">
        <mat-icon>link</mat-icon>
        National Visa Center & Embassy Resources
      </a>
      <br>
      <a (click)="openExternalLink(visaDetailURLs.USCIS_CASE_STATUS)" class="hc-a">
        <mat-icon>link</mat-icon>
        USCIS Case Status
      </a>
      <br>
      <a (click)="openExternalLink(visaDetailURLs.USCIS_PROCESSING_TIMES)" class="hc-a">
        <mat-icon>link</mat-icon>
        USCIS Processing Times&nbsp;
      </a>
      <mat-icon class=" icon gray-icon" hcTooltipHeading="USCIS Processing Times"
        hcTooltip="You can check the current USCIS processing times at this website by choosing the form number (I-140) and Service Center (Nebraska). Be sure to click “Read All Lines” to see Schedule A Nurses.">
        help
      </mat-icon>
      <br>
    </div>
  </ng-template>
</ng-container>