<div fxFlex class="view-verify" fxLayout="column">
  <div fxLayout="column" fxLayoutGap="16px">
    <span class="global-font-20 verify-header"> Let's Verify It's You. </span>
    <div *ngIf="nurse" fxLayout="column" fxLayoutGap="10px">
      <span fxFlex class="sub-header">
        Hi {{ nurse.firstName }}, your security is our top priority. We need to confirm your information. How would you like us to contact you?
      </span>
      <mat-radio-group fxFlex fxLayout="column" fxLayoutGap="10px" [(ngModel)]="selection">
        <mat-radio-button fxFlex class="verify-choice warning-margin-exception" value="sms">
          Text code to <b>+{{ userPhoneNumber }}</b>
        </mat-radio-button>
        <div class="sms-warning">*Standard message and data rates may apply.</div>
        <mat-radio-button fxFlex class="verify-choice" value="email">
          Email me at <b>{{ userEmail }}</b>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <button fxFlex class="choice-button" (click)="navigateToVerify()" mat-raised-button>Send Verification</button>
  </div>
</div>
