import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ILookup,
  IProfessionalHierarchy,
  IRegistrationModel,
  IRegistrationResponseModel,
  NurseModel,
  TeamIds,
} from 'src/app/common';
import { NavHelper } from 'src/app/services';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { RegistrationConstants } from 'src/app/common/models/registration-constants';
import { HeadingSize } from 'hc-design-system-lib/lib/typography/components/heading/heading.component';
import { BodySize } from 'hc-design-system-lib/lib/typography/components/body/body.component';
import { InputType } from 'hc-design-system-lib/lib/components/form/form.enums';
import {
  ButtonAppearance,
  ButtonSize,
} from 'hc-design-system-lib/lib/components/button/button.enums';
import { ErrorTrackingNotificationService } from 'src/app/services/error-tracking-notification.service';
import { noSpecialCharacters } from 'src/app/common/validators/nameValidator';
import { autocompleteValidator } from 'src/app/common/validators/autocompleteValidator';
import { Store } from '@ngrx/store';
import {
  GetRegistration,
  SetIsReferred,
  SubmitRegistration,
} from 'src/app/store/userContext/userContext.actions';
import {
  selectNurseData,
  selectNurseDataLoading,
  selectRegistrationGetData,
  selectRegistrationGetLoading,
  selectRegistrationSubmitData,
  selectRegistrationSubmitError,
  selectRegistrationSubmitLoading,
} from 'src/app/store/userContext/userContext.selectors';
import { IAppState } from 'src/app/store/app/app.state';
import {
  OnboardingStepCompleted,
  OnboardingStepViewed,
  SegmentReady,
} from 'src/app/store/segment/segment.actions';
import {
  filter,
  map,
  skipWhile,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import {
  selectRegistrationWorkExperience,
  selectWorkExperienceLookup,
  selectLookupsLoading,
  selectProfessionalHierarchy,
  selectProfessionSpecialtyRequirement,
} from 'src/app/store/lookups/lookups.selectors';
import {
  convertIntoDropdownData,
  getHierarchyNodeByType,
  getHierarchyTreeById,
} from 'src/app/common/functions/dropdown-helpers';
import { UrlHelper } from 'src/app/common/UrlHelper';
import {
  IDropdownData,
  IHierarchicalDropdownNode,
} from 'hc-design-system-lib/lib/components/form/form.interfaces';
import {
  DropdownComponent,
  HierarchicalMultiSelectCheckboxComponent,
} from 'hc-design-system-lib';
import {
  PROFESSION_HIERARCHY_NODE_TYPES,
  PROFESSION_HIERARCHY_SECTORS,
} from 'src/app/common/constants';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, OnDestroy {
  readonly destroy$: Subject<void> = new Subject<void>();

  @ViewChild('professionHierarchy')
  professionHierarchyComponent: HierarchicalMultiSelectCheckboxComponent;

  private specialtyDropdown: DropdownComponent;
  @ViewChild('specialtyDropdown')
  set specialtyDropdownElementSet(content: DropdownComponent) {
    if (content && !this.specialtyDropdown) {
      this.specialtyDropdown = content;
    }
  }

  availabilityData = ['1-5 Weeks', '6+ Weeks', 'Just browsing'];
  bodySize = BodySize.Body;
  buttonSize = ButtonSize.Default;
  buttonType = ButtonAppearance.Primary;
  form: UntypedFormGroup;
  headingSize = HeadingSize.H4;
  inputType = InputType.Text;
  isSaving = false;
  model: IRegistrationModel;
  professionSectorOptions: any[];
  radioOptions: any[];
  referralOptions: any[];
  specialties: IDropdownData[];
  worksExperiences: Array<ILookup<number>>;
  professionLookup: Map<string, ILookup<string>>;
  registrationWorkExperience: Map<number, ILookup<string>>;
  workExperienceLookup: Map<number, ILookup<number>>;
  allProfessionHierarchy: IHierarchicalDropdownNode[] = [];
  existingProfessionalHierarchy: IHierarchicalDropdownNode[];
  professionHierarchyDepth = 1;
  specialtyRequired = false;

  registrationGetData$: Observable<IRegistrationModel> = this._store.select(
    selectRegistrationGetData,
  );
  registrationGetLoading$: Observable<boolean> = this._store.select(
    selectRegistrationGetLoading,
  );
  registrationResult$: Observable<IRegistrationResponseModel> =
    this._store.select(selectRegistrationSubmitData);
  registrationError$: Observable<Error> = this._store.select(
    selectRegistrationSubmitError,
  );
  registrationSubmitLoading$: Observable<boolean> = this._store.select(
    selectRegistrationSubmitLoading,
  );
  nurseData$: Observable<NurseModel> = this._store.select(selectNurseData);
  nurseLoading$: Observable<boolean> = this._store.select(
    selectNurseDataLoading,
  );

  professionHierarchyLookup$: Observable<IProfessionalHierarchy[]> =
    this._store.select(selectProfessionalHierarchy);

  registrationWorkExperience$: Observable<Map<number, ILookup<string>>> =
    this._store.select(selectRegistrationWorkExperience);

  workExperienceLookup$: Observable<Map<number, ILookup<number>>> =
    this._store.select(selectWorkExperienceLookup);

  lookupsLoading$: Observable<boolean> =
    this._store.select(selectLookupsLoading);

  constructor(
    private _navHelper: NavHelper,
    private _notificationService: ErrorTrackingNotificationService,
    private _store: Store<IAppState>,
    private _cdr: ChangeDetectorRef,
  ) {
    Segment.ready(() => this._store.dispatch(new SegmentReady()));
    this._store.dispatch(new GetRegistration());

    this.referralOptions = Array.from([
      { text: 'Yes', value: true },
      { text: 'No', value: false },
    ]);
    this.professionSectorOptions = Array.from([
      { text: 'Allied', value: 'Allied' },
      { text: 'Nursing', value: 'Nursing' },
    ]);
  }

  ngOnInit(): void {
    this.addRegistrationWorkExperienceSubscription();
    this.addWorkExperienceLookupSubscription();
    this.addRegistrationGetSubscription();

    this._store.dispatch(
      new OnboardingStepViewed({ stepName: 'Registration', stepNumber: 1 }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addRegistrationGetSubscription(): void {
    combineLatest([
      this.registrationGetData$,
      this.registrationGetLoading$,
      this.lookupsLoading$,
      this.professionHierarchyLookup$,
    ])
      .pipe(
        skipWhile(
          ([, registrationLoading, lookupsLoading, professionHierarchy]: [
            IRegistrationModel,
            boolean,
            boolean,
            IProfessionalHierarchy[],
          ]) =>
            registrationLoading ||
            lookupsLoading ||
            professionHierarchy === null,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(
        ([result, , , professionHierarchy]: [
          IRegistrationModel,
          boolean,
          boolean,
          IProfessionalHierarchy[],
        ]) => {
          this.allProfessionHierarchy =
            professionHierarchy as IHierarchicalDropdownNode[];

          this.model = result;
          this._createForm();

          this.addProfessionSectorValueChangeSubscription();
          this.addProfessionHierarchyValueChangeSubscription();
        },
      );
  }

  addWorkExperienceLookupSubscription(): void {
    this.workExperienceLookup$
      .pipe(
        filter((workExperienceLookup) => !!workExperienceLookup),
        takeUntil(this.destroy$),
      )
      .subscribe((workExperienceLookup) => {
        this.workExperienceLookup = workExperienceLookup;
        this.worksExperiences = Array.from(this.workExperienceLookup.values());
      });
  }

  addRegistrationWorkExperienceSubscription(): void {
    this.registrationWorkExperience$
      .pipe(
        filter((registrationWorkExperience) => !!registrationWorkExperience),
        takeUntil(this.destroy$),
      )
      .subscribe((registrationWorkExperience) => {
        this.registrationWorkExperience = registrationWorkExperience;
        this.radioOptions = Array.from(
          this.registrationWorkExperience.values(),
          (option) => {
            return { text: option.name, value: option.id };
          },
        );
      });
  }

  addProfessionSectorValueChangeSubscription(): void {
    if (this.form.controls.professionSector.value) {
      this.professionHierarchyDepth = 2;
      this.existingProfessionalHierarchy = this.allProfessionHierarchy.find(
        (h) =>
          h.name.toLowerCase() ===
          this.form.controls.professionSector.value.toLowerCase(),
      )?.children;
    }

    this.form.controls.professionSector.valueChanges
      .pipe(
        filter((o) => !!o),
        takeUntil(this.destroy$),
      )
      .subscribe((o: string) => {
        this.professionHierarchyDepth = 2;
        this.existingProfessionalHierarchy = this.allProfessionHierarchy.find(
          (h) => h.name.toLowerCase() === o.toLowerCase(),
        )?.children;
        this.professionHierarchyComponent.onClearMenu(null);
        this._cdr.detectChanges();
      });
  }

  addProfessionHierarchyValueChangeSubscription(): void {
    this.form.controls.professionHierarchy.valueChanges
      .pipe(
        switchMap((val: IHierarchicalDropdownNode) => {
          const profession = getHierarchyNodeByType(
            val,
            PROFESSION_HIERARCHY_NODE_TYPES.Profession,
          );

          return combineLatest([
            of(profession),
            this._store
              .select(selectProfessionSpecialtyRequirement(profession?.id))
              .pipe(take(1)),
          ]);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(([profession, specialtyRequired]) => {
        this.specialtyRequired = specialtyRequired;
        this.specialties = profession?.children?.map((s) =>
          convertIntoDropdownData(s, 'name'),
        );

        if (this.specialtyRequired && this.specialties) {
          this.form.controls.specialty.setValidators([
            Validators.required,
            autocompleteValidator(this.specialties),
          ]);
        } else {
          this.form.controls.specialty.setValidators([]);
        }
      });
  }

  private _createForm() {
    let mobilePhone = this.model?.mobilePhone || null;
    if (mobilePhone) {
      mobilePhone = mobilePhone.replace(new RegExp('\\D', 'g'), '');
    }

    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.model?.firstName, [
        Validators.required,
        noSpecialCharacters,
      ]),
      lastName: new UntypedFormControl(this.model?.lastName, [
        Validators.required,
        noSpecialCharacters,
      ]),
      mobilePhone: new UntypedFormControl(mobilePhone, [
        Validators.required,
        Validators.pattern('\\(?[2-9]{1}\\d{2}\\)? ?\\d{3}-?\\d{4}'),
      ]),
      availability: new UntypedFormControl(this.model?.startDate, [
        Validators.required,
        autocompleteValidator(this.availabilityData),
      ]),
      travelExperience: new UntypedFormControl(this.model?.travelExperience, [
        Validators.required,
      ]),
      referralStatus: new UntypedFormControl(null, [Validators.required]),
      referralText: new UntypedFormControl(null),
    });

    this.addAlliedFlowFormControls();
    this.setReferralTextRequired(this.model?.referralStatus);

    this.form.controls.referralStatus.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((o) => {
        this.setReferralTextRequired(o);
      });
  }

  save() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.isSaving = true;
      this._prepareSave().then((newModel) => {
        this.model = newModel;

        this._store.dispatch(new SubmitRegistration(this.model));

        combineLatest([
          this.registrationResult$,
          this.registrationSubmitLoading$,
          this.nurseData$,
          this.nurseLoading$,
        ])
          .pipe(
            filter(
              ([registrationResult, registrationLoading, nurse, nurseLoading]: [
                IRegistrationResponseModel,
                boolean,
                NurseModel,
                boolean,
              ]) =>
                registrationResult?.registrationStatus &&
                !registrationLoading &&
                !nurseLoading &&
                nurse !== null,
            ),
            map(
              ([registrationResult, , nurse]: [
                IRegistrationResponseModel,
                boolean,
                NurseModel,
                boolean,
              ]) => [registrationResult, nurse],
            ),
            takeUntil(this.destroy$),
          )
          .subscribe(
            ([registrationResult, nurse]: [
              IRegistrationResponseModel,
              NurseModel,
            ]) => {
              if (nurse?.isNew) {
                this._store.dispatch(
                  new OnboardingStepCompleted({
                    stepName: 'Registration',
                    stepNumber: 1,
                    skipped: false,
                  }),
                );
                this._store.dispatch(
                  new SetIsReferred(this.model.referralStatus),
                );

                this.postRegistrationNavigation(registrationResult, nurse);
              }
            },
            (err) => {
              this.isSaving = false;
            },
          );
        this.registrationError$
          .pipe(
            filter((err) => !!err),
            takeUntil(this.destroy$),
          )
          .subscribe(() => (this.isSaving = false));
      });
    } else {
      this._notificationService.showFormErrorNotification(
        'registrationForm',
        this.form,
      );
    }
  }

  postRegistrationNavigation(
    registrationResult: IRegistrationResponseModel,
    nurse: NurseModel,
  ) {
    switch (registrationResult.registrationStatus) {
      case RegistrationConstants.RegistrationInProgressNewUser:
        this._navHelper.goToJobPreferences(true);
        break;
      case RegistrationConstants.RegistrationInProgressMatchedUser:
        if (nurse.verificationType === 2) {
          this._navHelper.goToVerifyUserSMS();
        } else {
          this._navHelper.goToVerifyUserEmail();
        }
        break;
      case RegistrationConstants.RegistrationInProgressInvitedUser:
        this._navHelper.goToJobPreferences(true);
        break;
      case RegistrationConstants.RegisteredDoesNotRequireVerification:
        this._navHelper.goToJobPreferences(true);
        break;
      case RegistrationConstants.RegisteredRequiresVerification:
        if (nurse.verificationType === 2) {
          this._navHelper.goToVerifyUserSelection();
        } else {
          this._navHelper.goToVerifyUserEmail();
        }
        break;
    }
  }

  getErrorMessage(control: AbstractControl, fieldName: string): string {
    if (!this.form || !control) {
      return '';
    }
    if (control.hasError('pattern')) {
      return 'Not a valid number';
    }
    if (control.hasError('nospecialornum')) {
      return `${fieldName} cannot contain numbers or special characters`;
    }
    if (control.hasError('required')) {
      return `${fieldName} required`;
    }
    if (control.hasError('forbiddenName')) {
      return `Invalid ${fieldName}`;
    }
  }

  private async _prepareSave(): Promise<IRegistrationModel> {
    const formGroupValue = this.form.value;
    const data = UrlHelper.getGACampaignDataFromSessionStorage();
    let campaign: string;
    let medium: string;
    let source: string;
    if (data && data.size > 0) {
      campaign = data.get('utm_campaign');
      medium = data.get('utm_medium');
      source = data.get('utm_source');
    }
    const model: IRegistrationModel = {
      email: this.model.email,
      firstName: formGroupValue.firstName,
      lastName: formGroupValue.lastName,
      profession: formGroupValue?.profession?.value?.id || null,
      specialtyDto: formGroupValue?.specialty?.value || null,
      startDate: formGroupValue.availability || null,
      travelExperience: formGroupValue.travelExperience || null,
      mobilePhone: formGroupValue.mobilePhone
        .replace(new RegExp('\\D', 'g'), '')
        .slice(-10),
      teamId: TeamIds.HCTN,
      recruiter: null,
      referralStatus: formGroupValue.referralStatus || false,
      referralText: formGroupValue.referralText,
      campaign: campaign,
      medium: medium,
      source: source,
    };

    const professionHierarchy =
      formGroupValue.professionHierarchy as IHierarchicalDropdownNode;
    const profession = getHierarchyNodeByType(
      professionHierarchy,
      PROFESSION_HIERARCHY_NODE_TYPES.Profession,
    );
    if (profession) {
      model.profession = profession.id;
    }
    if (formGroupValue?.specialty?.value) {
      model.specialtyDto = {
        id: formGroupValue.specialty.value.id,
        name: formGroupValue.specialty.value.name,
      };
    }
    model.professionSector = formGroupValue.professionSector;

    return model;
  }

  setReferralTextRequired(input: boolean) {
    if (input) {
      this.form.controls.referralText.setValidators([Validators.required]);
      this.form.controls.referralText.updateValueAndValidity();
    } else {
      this.form.controls.referralText.setValue(null);
      this.form.controls.referralText.clearValidators();
      this.form.controls.referralText.updateValueAndValidity();
    }
  }

  addAlliedFlowFormControls() {
    let existingDataHierarchy: IHierarchicalDropdownNode[] = null;
    let existingProfessionSector: string = null;
    let existingSpecialty: IDropdownData = null;

    if (this.model?.profession) {
      [existingProfessionSector, existingDataHierarchy, existingSpecialty] =
        this.getHierarchy();
    }

    this.form.addControl(
      'professionSector',
      new UntypedFormControl(
        existingProfessionSector ?? this.model?.professionSector,
        [Validators.required],
      ),
    );
    this.form.addControl(
      'professionHierarchy',
      new FormControl<IHierarchicalDropdownNode>(
        existingDataHierarchy ? existingDataHierarchy[0] : null,
        [Validators.required],
      ),
    );
    this.form.addControl(
      'specialty',
      new UntypedFormControl(existingSpecialty, []),
    );
    if (existingProfessionSector === PROFESSION_HIERARCHY_SECTORS.Nursing) {
      this.form.controls.specialty.addValidators([Validators.required]);
    }
    if (this.specialties) {
      this.form.controls.specialty.addValidators([
        autocompleteValidator(this.specialties),
      ]);
    }
  }

  getHierarchy(): [string, IHierarchicalDropdownNode[], IDropdownData] {
    let hierarchy: IHierarchicalDropdownNode[] = null;
    let professionSector: string = null;
    let specialty = null;

    const nursingHierarchy = this.allProfessionHierarchy.find(
      (s) =>
        s.name.toLowerCase() ===
        PROFESSION_HIERARCHY_SECTORS.Nursing.toLowerCase(),
    )?.children;
    const alliedHierarchy = this.allProfessionHierarchy.find(
      (s) =>
        s.name.toLowerCase() ===
        PROFESSION_HIERARCHY_SECTORS.Allied.toLowerCase(),
    )?.children;

    const nursingSelectedHierarchy = getHierarchyTreeById(
      nursingHierarchy,
      this.model?.profession,
    );
    const nonNursingSelectedHierarchy = getHierarchyTreeById(
      alliedHierarchy,
      this.model?.profession,
    );

    if (nursingSelectedHierarchy) {
      professionSector = PROFESSION_HIERARCHY_SECTORS.Nursing;
      hierarchy = nursingSelectedHierarchy;
    } else {
      professionSector = PROFESSION_HIERARCHY_SECTORS.Allied;
      hierarchy = nonNursingSelectedHierarchy;
    }

    if (hierarchy) {
      specialty = this.getSpecialty(hierarchy);
    }

    return [professionSector, hierarchy, specialty];
  }

  getSpecialty(hierarchy: IHierarchicalDropdownNode[]): IDropdownData {
    let specialty = null;
    const specialties = getHierarchyNodeByType(
      hierarchy[0],
      PROFESSION_HIERARCHY_NODE_TYPES.Profession,
    )?.children;

    if (specialties) {
      this.specialties = specialties.map((s) =>
        convertIntoDropdownData(s, 'name'),
      );

      if (this.model?.specialty) {
        specialty = this.specialties.find(
          (s) => s.value.id === this.model.specialty,
        );
      }
    }

    return specialty;
  }

  professionChanged() {
    this.form.controls.specialty.setValue(null);

    if (this.specialtyDropdown) {
      this.specialtyDropdown.required = this.specialtyRequired;
      this.specialtyDropdown.setRequiredState();
    }

    this.form.controls.specialty.updateValueAndValidity();
  }
}
