import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
  constructor(
    private authService: AuthenticationService
  ) {

  }

  ngOnInit() {
    this.authService.logoutCallback();
  }
}
