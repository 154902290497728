export class WorkHistory {
    id?: string;             // GUID
    employerName?: string;
    employerCity?: string;
    employerState?: string; // GUID
    employerStreet1?: string;
    employerStreet2?: string;
    employerZip?: string;
    startDate?: Date;
    endDate?: Date;
    currentPosition?: string|boolean;
    description1?: string;
    specialtyId?: string; // GUID
    specialty?: any; // GUID
    profession?: string;
    professionType?: number;
    patientRatio?: string;
    float?: string|boolean;
    lapseReason?: string;
    facilityBed?: string;
    traumaLevel?: string;
    chargeExperience?: string|boolean;
    preceptorExperience?: string|boolean;
    shiftsPerMonth?: number;
    type?: number;
    isNeeded?: boolean;
    taskCreated?: boolean;
}



