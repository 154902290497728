<div *ngIf="(verificationCheckLoading$ | async) === false && (verificationCheckResultError$ | async) !== null" fxFlex class="view-verify" fxLayout="column">
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="column" fxLayoutGap="16px">
      <span class="global-font-20 verify-header"> Email Verification </span>
      <span> This link has expired. Please sign up/in again. </span>
      <button mat-raised-button color="accent" (click)="resendEmail()">Send New Verification Email</button>
      <div></div>
      <div fxLayout="row" fxLayoutGap="8px">
        <span> Need Help? </span>
        <span class="a click-link" (click)="navigateToVerifySupport()"> Contact Us </span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="verificationCheckLoading$ | async" class="loading-overlay">
  <mat-spinner color="accent" class="loading-spinner"></mat-spinner>
</div>
