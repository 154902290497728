import { WorkHistory } from './work-history';

export class GetFacilitiesResult {
  data: Array<Facility>;
  message: string;
  isValid: boolean;
}

export class Facility {
  accountId: string;
  name: string;
  state: string;
  zip: string;
}
