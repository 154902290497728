import { Action } from "@ngrx/store";
import { NewReference, ReferencesResponse, TaskCompletionReturnObject, UpdatedReference } from "src/app/common";
import { GenericCompletionReturnObject } from "src/app/common/models/generic-completion-return-model";

export enum EReferencesActions {
  GetReferences = '[REFERENCES] get references',
  GetReferencesSuccess = '[REFERENCES] get references success',
  GetReferencesError = '[REFERENCES] get references error',

  GetReferenceEmails = '[REFERENCES] get reference emails',
  GetReferenceEmailsSuccess = '[REFERENCES] get reference emails success',
  GetReferenceEmailsError = '[REFERENCES] get reference emails error',

  AddReference = '[REFERENCES] add reference',
  AddReferenceSuccess = '[REFERENCES] add reference success',
  AddReferenceError = '[REFERENCES] add reference error',

  UpdateReference = '[REFERENCES] update reference',
  UpdateReferenceSuccess = '[REFERENCES] update reference success',
  UpdateReferenceError = '[REFERENCES] update reference error',

  DeleteReference = '[REFERENCES] delete reference',
  DeleteReferenceSuccess = '[REFERENCES] delete reference success',
  DeleteReferenceError = '[REFERENCES] delete reference error'
}

export class GetReferences implements Action {
  public readonly type = EReferencesActions.GetReferences;

  constructor() { }
}

export class GetReferencesSuccess implements Action {
  public readonly type = EReferencesActions.GetReferencesSuccess;

  constructor(public payload: ReferencesResponse) { }
}

export class GetReferencesError implements Action {
  public readonly type = EReferencesActions.GetReferencesError;

  constructor(public error: Error) { }
}

export class GetReferenceEmails implements Action {
  public readonly type = EReferencesActions.GetReferenceEmails;

  constructor() { }
}

export class GetReferenceEmailsSuccess implements Action {
  public readonly type = EReferencesActions.GetReferenceEmailsSuccess;

  constructor(public payload: string[]) { }
}

export class GetReferenceEmailsError implements Action {
  public readonly type = EReferencesActions.GetReferenceEmailsError;

  constructor(public error: Error) { }
}

export class AddReference implements Action {
  public readonly type = EReferencesActions.AddReference;

  constructor(public reference: NewReference) { }
}

export class AddReferenceSuccess implements Action {
  public readonly type = EReferencesActions.AddReferenceSuccess;

  constructor(public payload: TaskCompletionReturnObject, public request: NewReference = null) { }
}

export class AddReferenceError implements Action {
  public readonly type = EReferencesActions.AddReferenceError;

  constructor(public error: Error) { }
}

export class UpdateReference implements Action {
  public readonly type = EReferencesActions.UpdateReference;

  constructor(public reference: UpdatedReference) { }
}

export class UpdateReferenceSuccess implements Action {
  public readonly type = EReferencesActions.UpdateReferenceSuccess;

  constructor(public payload: TaskCompletionReturnObject) { }
}

export class UpdateReferenceError implements Action {
  public readonly type = EReferencesActions.UpdateReferenceError;

  constructor(public error: Error) { }
}

export class DeleteReference implements Action {
  public readonly type = EReferencesActions.DeleteReference;

  constructor(public id: string) { }
}

export class DeleteReferenceSuccess implements Action {
  public readonly type = EReferencesActions.DeleteReferenceSuccess;

  constructor(public payload: TaskCompletionReturnObject) { }
}

export class DeleteReferenceError implements Action {
  public readonly type = EReferencesActions.DeleteReferenceError;

  constructor(public error: Error) { }
}

export type ReferencesActions =
  | GetReferences
  | GetReferencesSuccess
  | GetReferencesError
  | GetReferenceEmails
  | GetReferenceEmailsSuccess
  | GetReferenceEmailsError
  | AddReference
  | AddReferenceSuccess
  | AddReferenceError
  | UpdateReference
  | UpdateReferenceSuccess
  | UpdateReferenceError
  | DeleteReference
  | DeleteReferenceSuccess
  | DeleteReferenceError
  ;