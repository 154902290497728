<div
  fxLayout="row"
  class="main-content"
  *ngIf="{
    jobsLoading: savedJobsLoading$ | async
  } as obs"
>
  <div class="job-list-area" fxLayout="column" fxFlex>
    <div class="job-list">
      <div
        fxLayoutAlign="center center"
        class="no-jobs"
        *ngIf="availableJobs.length === 0 && unavailableJobs.length === 0"
      >
        <app-empty-jobs
          style="margin: 28px 0"
          fxFlex
          [header]="'No Jobs found'"
          [text]="'You haven\'t saved any jobs.'"
          [setTabIndex]="setTabIndex"
        >
          <img height="160" src="/assets/img/saved-jobs.svg" alt="no saved jobs" />
        </app-empty-jobs>
      </div>

      <ng-container>
        <div
          class="job-list-inner"
          *ngIf="
            availableSavedJobCardConfigs.length > 0 ||
            unavailableSavedJobCardConfigs.length > 0
          "
        >
          <div class="saved-jobs-header real-header">
            <div>You saved {{ availableSavedJobCardConfigs.length }} jobs</div>
            <div *ngIf="completedProfile">
              We've reviewed your profile and you're ready to apply. Go ahead,
              it's easy!
            </div>
            <div *ngIf="!completedProfile">
              You will be ready to apply for most jobs once your
              <a href="javascript:void(0);" (click)="navigateToTasksList()"
                >profile</a
              >
              is complete.
            </div>
          </div>
          <div
            class="saved-available-job-list"
            fxLayout="row wrap"
            [class.padding]="unavailableSavedJobCardConfigs?.length === 0"
            *ngIf="availableSavedJobCardConfigs.length > 0"
          >
            <ng-container *ngFor="let job of availableSavedJobCardConfigs">
              <hc-job-card
                [cardConfig]="job"
                (cardClicked)="navigateToJob(job)"
                (cardActionClicked)="updateSavedJob(job)"
                [class.responsive-flex]="true"
              ></hc-job-card>
            </ng-container>
          </div>
          <ng-container
            *ngIf="merlinId && unavailableSavedJobCardConfigs.length > 0"
          >
            <div class="saved-jobs-header real-header">
              <div>These jobs are no longer available</div>
              <div>
                <hc-checkbox
                  text="Notify me if a job becomes available again"
                  [control]="checkboxControl"
                  (checkedChange)="activateJobNotifications($event.event)"
                ></hc-checkbox>
              </div>
            </div>
            <div class="saved-unavailable-job-list padding" fxLayout="row wrap">
              <ng-container *ngFor="let job of unavailableSavedJobCardConfigs">
                <hc-job-card
                  [cardConfig]="job"
                  (cardClicked)="navigateToJob(job)"
                  (cardActionClicked)="updateSavedJob(job)"
                ></hc-job-card>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div id="saved-jobs-removal-toaster">
  <img src="assets/img/green-check.svg" alt="Green Checkmark" />Removed from Favorites
</div>
