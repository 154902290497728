import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { CardElevation, DialogService } from 'hc-design-system-lib';
import { Capacitor } from '@capacitor/core';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild('deleteAccountFlow')
  deleteAccountFlowTemplate: TemplateRef<any>;
  isNative = Capacitor.isNativePlatform();

  navItems: Array<{ name, id, func }> = [];

  constructor(
    private _authService: AuthenticationService,
    private navCtrl: NavController,
    private _dialogService: DialogService
  ) { }

  determineNavItems(): void {
    this.navItems = [{
      name: 'Change Password',
      id: 'change-password',
      func: () => {
        this._authService.passwordReset(null, null, true)
        this.navCtrl.navigateRoot('/');
      }
    }];

    if (this.isNative) {
      this.navItems.push({
        name: 'Delete My Account',
        id: 'delete-account',
        func: () => {
          this._dialogService.showDialog(
            {
              title: 'Delete Account?',
              template: this.deleteAccountFlowTemplate,
              text: '',
              showCloseIcon: true,
              elevation: CardElevation.Default,
              icon: null,
              separatedHeader: false,
              noStyling: true,
              useCustomCloseLogic: false
            },
            true
          )
        }
      });
    }

  }

  ngOnInit(): void {
    this.determineNavItems();
  }

}