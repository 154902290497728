<div fxFlex fxLayout="column" id="main-content" fxLayoutGap="16px">
  <div fxLayout="column" class="top-section">
    <div fxLayoutAlign="end end">
      <button id="contactRecruiterPopupClose" mat-icon-button class="global-x-btn">
        <mat-icon (click)="cancel()">close</mat-icon>
      </button>
    </div>
    <div *ngIf="!emailSent">
      <div *ngIf="showContent" class="recruiter-section" fxLayout="column" fxLayoutAlign="center center"
        fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutGap="15px">
          <div *ngIf="recruiter.photoUrl else initialsImage">
            <img class="profile-image" fxLayoutAlign="center" src="{{this.recruiter.photoUrl}}">
          </div>
          <ng-template #initialsImage>
            <div class="profile-image" fxLayoutAlign="center center">
              {{initials}}
            </div>
          </ng-template>
          <div fxLayout="column" fxLayoutAlign="center start">
            <span class="global-title">{{recruiter.name}}</span>
            <span class="global-subheader">{{recruiter.title}}</span>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="10px" class="button-section">
          <a href="tel:{{recruiter.phone}}" (click)="trackPhoneNumberClick(recruiter.phone)" mat-flat-button color="primary" class="btn">
            <mat-icon>phone</mat-icon>
            <span class="button-text">Call {{firstName}}</span>
          </a>
          <button id="contactRecruiterEmailSelect" mat-flat-button color="primary" (click)="emailSelection()" class="btn">
            <mat-icon>email</mat-icon>
            <span class="button-text">Email {{firstName}}</span>
          </button>
        </div>
      </div>
      <div *ngIf="!showContent && contactRecruiterType === ContactRecruiterType.Submittal" class="recruiter-section" fxLayout="column"
        fxLayoutAlign="center center" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutGap="15px">
          <div *ngIf="recruiter.photoUrl else initialsImage">
            <img class="profile-image" fxLayoutAlign="center" src="{{this.recruiter.photoUrl}}">
          </div>
          <ng-template #initialsImage>
            <div class="profile-image" fxLayoutAlign="center center">
              {{initials}}
            </div>
          </ng-template>
          <div fxLayout="column" fxLayoutAlign="center start">
            <span class="global-title">{{recruiter.name}}</span>
            <span class="global-subheader">{{recruiter.title}}</span>
          </div>
        </div>
        <form *ngIf="form?.controls" [formGroup]="form" class="form">
          <div fxLayout="column">
            <mat-form-field>
              <mat-select multiple formControlName="assignments" placeholder="Choose Submittal"
                panelClass="multi-select-panel">
                <mat-option *ngFor="let item of assignments" [value]="{id: item.id, name: item.assnName}">
                  {{getJobName(item)}}/{{item.workSite}}</mat-option>
                <mat-option [value]="{id: '0', name: 'Other'}">Other</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <textarea matInput rows="9" cols="20" (focus)="clearEmailBody()" formControlName="emailBody"></textarea>
            </mat-form-field>
            <button id="contactRecruiterSendEmail" mat-flat-button color="primary" (click)="sendSubmittalEmail()" class="btn">
              <mat-icon>email</mat-icon>
              <span class="button-text">Send</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="emailSent">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
        <mat-icon class="check material-icons md-48">check_circle</mat-icon>
        <div class="top-header">
          {{recruiter.name}} has been sent a message and will reach out soon.
        </div>
      </div>
    </div>
  </div>
</div>
