import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { DialogService } from 'hc-design-system-lib';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { IDropdownData } from 'hc-design-system-lib/lib/components/form/form.interfaces';
import { Observable, Subject, combineLatest } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';
import {
  IProfessionalHierarchy,
  NurseProfileModel,
  ProfessionalOverviewModel,
} from 'src/app/common';
import { PROFESSION_HIERARCHY_SECTORS } from 'src/app/common/constants';
import {
  convertIntoDropdownData,
  sortDropdownValuesByName,
} from 'src/app/common/functions/dropdown-helpers';
import { autocompleteValidator } from 'src/app/common/validators/autocompleteValidator';
import { IAppState } from 'src/app/store/app/app.state';
import { selectProfessionBasedOnProfessionalOverview, selectProfessionalHierarchyLoading } from 'src/app/store/lookups/lookups.selectors';
import { EUserContextActions, UpdateSummaryData } from 'src/app/store/userContext/userContext.actions';
import {
  selectProfessionalOverview,
  selectProfessionalOverviewFormDataLoading,
  selectUserProfessionSector,
} from 'src/app/store/userContext/userContext.selectors';

@Component({
  selector: 'app-specialty-popup',
  templateUrl: './specialty-popup.component.html',
  styleUrls: ['./specialty-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialtyPopupComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();
  odLogo = '/assets/img/on-demand/on-demand.svg';
  form: UntypedFormGroup;
  secondary = ButtonAppearance.Secondary;
  showSkipButton = false;
  isSaving = false
  

  professionalOverview$: Observable<NurseProfileModel> = this._store.pipe(
    select(selectProfessionalOverview)
  );
  professionBasedOnProfessionalOverview$: Observable<IProfessionalHierarchy> =
    this._store.pipe(select(selectProfessionBasedOnProfessionalOverview));
  professionalOverviewLoading$: Observable<boolean> = this._store.pipe(
    select(selectProfessionalOverviewFormDataLoading)
  );
  professionalHierarchyLoading$: Observable<boolean> = this._store.pipe(
    select(selectProfessionalHierarchyLoading)
  );
  professionSector$: Observable<string> = this._store.pipe(
    select(selectUserProfessionSector)
  );

  professions: IDropdownData[] = [];
  specialties: IDropdownData[] = [];
  professionalOverview: ProfessionalOverviewModel = null;

  constructor(
    private _store: Store<IAppState>,
    private _fb: UntypedFormBuilder,
    private _dialogService: DialogService,
    private actions$: Actions,
  ) {}

  ngOnInit(): void {
    this.initializeObservables();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializeObservables(): void {
    combineLatest([
      this.professionalOverviewLoading$,
      this.professionalHierarchyLoading$,
      this.professionalOverview$,
      this.professionBasedOnProfessionalOverview$,
      this.professionSector$,
    ])
      .pipe(
        skipWhile(
          ([poLoading, phLoading, professionalOverview, profession]) =>
            poLoading || phLoading || !professionalOverview || !profession
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(([, , professionalOverview, profession, professionSector]) => {
        this.professionalOverview = professionalOverview;
        this.showSkipButton = professionSector
          ? professionSector.toLowerCase() ===
            PROFESSION_HIERARCHY_SECTORS.Allied.toLowerCase()
          : profession.name.toLowerCase() ===
            PROFESSION_HIERARCHY_SECTORS.Allied.toLowerCase();
        this.convertLookupsToDropdowns(profession);
        this._createForm();
      });
  }

  convertLookupsToDropdowns(profession: IProfessionalHierarchy) {
    if (profession) {
      this.professions = [
        convertIntoDropdownData(profession.children[0].children[0], 'name'),
      ];
      this.specialties = Array.from(profession.children[0].children[0].children?.values(), (p) =>
        convertIntoDropdownData(p, 'name')
      ).sort(sortDropdownValuesByName);
    }
  }

  save() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.isSaving = true;
      const model = this._prepareSave();
      this._store.dispatch(new UpdateSummaryData(model));
      sessionStorage.removeItem('isEnrolling');

      //Persist pop-up till the summary is updated
      this.actions$.pipe(
        ofType(EUserContextActions.UpdateSummarySuccess),
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this._dialogService.closeAll();
        this.isSaving = false;
      });

      //Persist pop-up, allow error message, and enable button(s) if summary save failed
      this.actions$.pipe(
        ofType(EUserContextActions.UpdateSummaryError),
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.isSaving = false;
      });
    }
  }

  skip() {
    sessionStorage.removeItem('isEnrolling');
    this._dialogService.closeAll();
  }

  private _prepareSave() {
    const model = { ...this.professionalOverview };
    model.specialtyDto = {
      ...this.form.controls.specialty.value?.value,
      type: 0,
    };
    return model;
  }

  private _createForm() {
    this.form = this._fb.group({
      profession: new UntypedFormControl(
        {
          value: this.professions[0],
          disabled: true,
        },
        [Validators.required]
      ),
      specialty: new UntypedFormControl(null, [
        autocompleteValidator(this.specialties),
        Validators.required,
      ]),

      disabledSpecialty: new UntypedFormControl(
        {
          value: null,
          disabled: true,
        }
      )
    });
  }
}
