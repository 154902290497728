import { CandidateSkill } from 'src/app/common/models/candidate-skill';
import { CandidateSkillSummary } from 'src/app/common/models/candidate-skill-summary-model';
import { Question } from 'src/app/common/models/question';
import { Skill } from 'src/app/common/models/skill';
import { SkillDictionary } from 'src/app/common/models/skill-dictionary';
import { SkillSummary } from 'src/app/common/models/skill-summary';
import { initializeData, IDataState } from 'src/app/store/app/app.models';

export interface ISkillsChecklistState {
  skill: IDataState<Skill>;
  skills: IDataState<SkillSummary[]>;
  candidateSkill: IDataState<CandidateSkill>;
  candidateSkills: IDataState<Map<number, CandidateSkillSummary>>;
  skillDictionary: IDataState<SkillDictionary>;
  skillDictionaryFilter: IDataState<string>;
  nurseApiSkillQuestions: IDataState<Question[]>;
  updateSkillQuestions: IDataState<CandidateSkill>;
}

export const initialSkillsChecklistState: ISkillsChecklistState = {
  skill: initializeData(),
  skills: initializeData(),
  candidateSkill: initializeData(),
  candidateSkills: initializeData(),
  skillDictionary: initializeData(),
  skillDictionaryFilter: initializeData(),
  nurseApiSkillQuestions: initializeData(),
  updateSkillQuestions: initializeData(),
};
