import { EventEmitter, Inject, OnDestroy, Optional, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UpdateAssignmentModel } from 'src/app/common';
import { NursePortalApi } from 'src/app/services';
import { AssignmentReasonSubStages, AssignmentStageConstants } from 'src/app/common/models/submittal-constants';
import { select, Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { selectAssignmentSubstageLookup } from 'src/app/store/lookups/lookups.selectors';
import { IAppState } from 'src/app/store/app/app.state';

@Component({
  selector: 'app-remove-submittal-popup',
  templateUrl: './remove-submittal-popup.component.html',
  styleUrls: ['./remove-submittal-popup.component.scss'],
})

export class RemoveSubmittalPopupComponent implements OnInit, OnDestroy {
  assignmentSubstageLookup: Map<string, string>;

  assignmentSubstageLookupSubscription: Subscription;
  assignmentSubstageLookup$: Observable<Map<string, string>> = this._store.pipe(
    select(selectAssignmentSubstageLookup)
  );

  constructor(
    public dialogRef: MatDialogRef<RemoveSubmittalPopupComponent>,
    private _api: NursePortalApi,
    private _store: Store<IAppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  @Output() outputOnConfirm = new EventEmitter();
  @Output() outputRemoveAll = new EventEmitter();

  assignmentId = this.data.assignmentId;
  stageId = this.data.stageId;
  assignmentSubstageList: any[];
  newApplicationId = AssignmentStageConstants.Application;
  CandidateDeclinedOfferWithOtherCompany = AssignmentReasonSubStages.CandDeclinedOfferWithOtherCompany;
  CandidateDeclinedPermPosition = AssignmentReasonSubStages.CandDeclinedTookPermPosition;
  CandidateToEnterOtherReason = AssignmentReasonSubStages.CandDeclinedOther;

  // Default Value
  reasonControl = new UntypedFormControl(AssignmentReasonSubStages.CandDeclinedNoLongerInterested);
  bulkRemoveControl = new UntypedFormControl(false);
  otherText = new UntypedFormControl('');

  ngOnInit(): void {
    this.addAssignmentSubstageLookupSubscription();
  }

  ngOnDestroy(): void {
      this.assignmentSubstageLookupSubscription?.unsubscribe();
  }

  addAssignmentSubstageLookupSubscription() {
    this.assignmentSubstageLookupSubscription = this.assignmentSubstageLookup$.subscribe((assignmentSubstageLookup) => {
      this.assignmentSubstageLookup = assignmentSubstageLookup;
      this.assignmentSubstageList = Array.from((this.assignmentSubstageLookup.values()));
    });
  }

  submit(): void {
    const model = new UpdateAssignmentModel();
    model.assignmentId = this.assignmentId;
    model.reasonId = this.reasonControl.value;
    model.otherText = this.otherText.value;
    model.stageId = this.stageId;

    if (this.bulkRemoveControl.value) {
      this._api.updateAllSubmittals(model).subscribe(
        () => {},
        () => {},
        () => {
          this.outputRemoveAll.emit(this.assignmentId);
        });
    } else {
      this._api.updateSubmittal(model).subscribe(
        () => {},
        () => {},
        () => {
          this.outputOnConfirm.emit(this.assignmentId);
        });
    }

    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }
  
}
