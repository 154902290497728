import { AbstractControl, ValidatorFn } from '@angular/forms';

export function RequiredWhenEmptyFieldsValidator(fieldsToCheck: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let fieldsEmpty = false;
    const currentFieldEmpty = control?.value === null || control?.value === undefined;
    fieldsEmpty = fieldsToCheck?.every((fieldName) => {
        const fieldToCheck = control.parent?.get(fieldName);
        return fieldToCheck?.value === null || fieldToCheck?.value === undefined;
    }) ?? false;
    return fieldsEmpty && currentFieldEmpty ? { ['requiredFieldEmpty']: true } : null;
  };
}
