import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VerifyContextService {

  constructor() { }

  options: any[];

}
