import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CandidateQualification, Education, FileUploadTargets, IFileUploadOptions, ILookup } from 'src/app/common';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { selectDegreeLookup } from 'src/app/store/lookups/lookups.selectors';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { EducationsViewed } from 'src/app/store/segment/segment.actions';
import { GetEducation, GetEducationList } from 'src/app/store/education/education.actions';
import { selectEducationListLoading, selectEducationListResult } from 'src/app/store/education/education.selectors';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { CardElevation, DialogService, IDialogParameters } from 'hc-design-system-lib';
import { EducationModalComponent } from '../education-modal/education-modal.component';
import { IRecordCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { formatDate } from '@angular/common';
import { IFile } from 'hc-design-system-lib/lib/components/form/file-upload/file-upload.interfaces';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { selectCanSeeInternational, selectCanSeeTravel, selectDocumentsLoading, selectResumeDocuments } from 'src/app/store/userContext/userContext.selectors';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import { AngularticsEventTrack } from 'src/app/store/angulartics2/angulartics2.actions';
import { UploadResume } from 'src/app/store/workHistory/workHistoryContext.actions';
import { defaultTruncationSettings, generateFileTruncationSettings } from 'src/app/common/functions/filename-manipulators';
import { selectIsMobile } from 'src/app/store/ui/ui.selectors';
import { SetScreenWidth } from 'src/app/store/ui/ui.actions';


@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
})
export class EducationComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();
  isDesktop: boolean = true;
  file: IFile;
  files: Array<File> = [];
  resumeModified: boolean;
  form: UntypedFormGroup;
  qualificationID: string;
  prepopulatedFile: { fileName: string; fileUrl: string; };
  stateLookup: Map<string, ILookup<string>>;
  fileTruncationFunction = new BehaviorSubject(generateFileTruncationSettings());

@ViewChild('educationDialogTemplate')
educationDialogTemplate: TemplateRef<EducationModalComponent>;

  degreeLookup: Map<number, ILookup<number>>;

  degreeLookupSubscription: Subscription;
  degreeLookup$: Observable<Map<number, ILookup<number>>> = this._store.select(selectDegreeLookup);
  documents$: Observable<CandidateQualification[]> = this._store.select(selectResumeDocuments);
  documentsLoading$: Observable<boolean> = this._store.select(selectDocumentsLoading);
  isMobile$ = this._store.select(selectIsMobile);

  canSeeInternational$: Observable<boolean> = this._store.select(selectCanSeeInternational);
  canSeeTravel$: Observable<boolean> = this._store.select(selectCanSeeTravel);

  educationList$: Observable<Education[]> = this._store.select(selectEducationListResult).pipe(
    filter((educationListResponse: Education[]) => educationListResponse !== null),
    map((educationListResponse: Education[]) => {
      educationListResponse = educationListResponse.map((education: Education) => {
        return {
          ...education,
          degreeStringName: this.getDegreeName(education),
          schoolAttendedStringName: this.getSchoolAttended(education),
        };
      });

      return educationListResponse;
    })
  );

  educationListResultLoading$: Observable<boolean> = this._store.select(selectEducationListLoading);

  constructor(
    private _fb: UntypedFormBuilder,
    private _store: Store<IAppState>,
    private _dialogService: DialogService
  ) { }

  ngOnInit() {
    this.addDegreeLookupsSubscription();
    this.subscribeToIsMobileSelector();
    this.resumeModified = false;
    this._store.dispatch(new EducationsViewed());
    this._store.dispatch(new GetEducationList());
    this.initializeObservables();
  }

  subscribeToIsMobileSelector(): void {
    this.isMobile$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile) => {
        this.fileTruncationFunction.next(generateFileTruncationSettings({
          maxLength: isMobile? 
            defaultTruncationSettings.mobileMaxLength:
            defaultTruncationSettings.maxLength
          })
        );
        this.isDesktop = !isMobile;
      });
  }

  ngOnDestroy(): void {
    this.degreeLookupSubscription?.unsubscribe();
    this.destroy$.next();
  }

  addDegreeLookupsSubscription(): void {
    this.degreeLookupSubscription = this.degreeLookup$.subscribe((degreeLookup: Map<number, ILookup<number>>) => {
      this.degreeLookup = degreeLookup;
    });
  }

  refresh(): void {
    this._store.dispatch(new GetEducationList());
  }

  getDegreeName(education: Education): string {
    if (education.degree) {
      return this.degreeLookup.get(education.degree).name;
    } else {
      return 'NO DEGREE';
    }
  }

  getSchoolAttended(education: Education): string {
    if (education.schoolAttended) {
      return education.schoolAttended;
    } else {
      return 'NO SCHOOL GIVEN';
    }
  }

  initializeObservables(): void {
    combineLatest([this.documents$, this.documentsLoading$])
      .pipe(
        filter(([, documentLoading]) => !documentLoading),
        tap(([documents]) => {
          if (documents?.length > 0) {
            this.prepopulatedFile = {
              fileName: this.getFileName(documents[documents.length - 1].fileDownloadUrl),
              fileUrl: documents[documents.length - 1].fileDownloadUrl
            };
            this.qualificationID = documents[documents.length - 1].id;
          }
          else {
            this.prepopulatedFile = null;
            this.qualificationID = null;
          }
          this._createForm();
        }),
        take(1)
      )
      .subscribe();
  }

  _createForm() {
    this.form = this._fb.group({
      fileUpload: new UntypedFormControl()
    });
  }

  // N.B. Shared with work-history component
  getFileName(document: string): string {
    const fileName = document.split(' - ').pop();
    return fileName;
  }

  @HostListener('window:resize', ['$event'])
  OnResize(event): void {
    this._store.dispatch(new SetScreenWidth(window.innerWidth));
  }

  getRecordCardConfig(education: Education, isTravel: boolean): IRecordCardConfig {
    return {
      title: education.schoolAttended || 'NO SCHOOL GIVEN',
      firstLine: education.degreeStringName,
      secondLine: formatDate(education.graduationDate, 'MM/yyyy', 'en-US'),
      icon: isTravel ? 'edit' : null,
    }
  }

  handleFileInput(event: HcEvent) {
    this.form.controls.fileUpload.markAsTouched();
    if (event?.eventValue?.length) {
      this._store.dispatch(new AngularticsEventTrack('Upload', 'Complete Profile', 'Uploaded Resume'));
      this.files = Array.from(event.eventValue);
      this.resumeModified = true;
      if (this.files) {
        const options: IFileUploadOptions = {
          target: FileUploadTargets.WorkHistory,
          qualificationId: this.qualificationID,
          isDeleting: this.qualificationID !== null && this.resumeModified === true
        };
        this._store.dispatch(new UploadResume(options, this.files));
      }
    }
    else {
      this.files = [];
      this.resumeModified = false;
    }
  }

  getErrorMessage(control: AbstractControl, fieldName: string, isTextField: boolean = false): string {
    if (!control) {
      return '';
    }
    fieldName = fieldName.toLowerCase();
    if (control.hasError('matDatepickerParse')) {
      return `Please enter ${fieldName}`;
    }
    if (control.hasError('required')) {
      return isTextField ? `Please enter ${fieldName}` : `Please select ${fieldName}`;
    }
    if (control.hasError('forbiddenName')) {
      return `Invalid ${fieldName}`;
    }
  }

  showEducationDialog(education?: Education): void {
    this._store.dispatch(new GetEducation(education?.id));

    const dialogData: IDialogParameters = {
      title: `${education ? 'Edit' : 'Add'} Education`,
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.educationDialogTemplate,
      separatedHeader: true,
      noStyling: true,
      useCustomCloseLogic: true,
      isResponsive: true
    };
    this._dialogService.showDialog(dialogData, true);
  }
  // needed for the empty list component to work
  boundShowEducationDialog = this.showEducationDialog.bind(this);

}
