import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryParams } from '../common';

@Injectable()
export class MerlinIdInterceptor implements HttpInterceptor {
    constructor() { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        const merlinId = sessionStorage.getItem(QueryParams.MERLIN_ID);
        if (merlinId) {
            request = request.clone({
                setHeaders: {
                    merlinId: merlinId
                }
            });
        }

        return next.handle(request);
    }
}
