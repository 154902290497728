import { createSelector } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { FacilitiesState } from './facilities.state';
import { IFacilityCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { preferredClientBanner } from 'src/app/common/constants';

const selectFacilitiesState = (state: IAppState) => state.facilities;

export const selectFacilities = createSelector(
  selectFacilitiesState,
  (state: FacilitiesState) => state.facilitiesData.data
);

export const selectFacilitiesLoading = createSelector(
  selectFacilitiesState,
  (state: FacilitiesState) => state.facilitiesData.loading
);

export const selectCandidateFacilities = createSelector(
  selectFacilitiesState,
  (state: FacilitiesState) => state.candidateFacilitiesData.data
);

export const selectCandidateFacilitiesCount = createSelector(
  selectFacilitiesState,
  (state: FacilitiesState) => state.candidateFacilitiesData.data?.length ?? 0
);

export const selectCandidateFacilitiesLoading = createSelector(
  selectFacilitiesState,
  (state: FacilitiesState) => state.candidateFacilitiesData.loading
);

export const selectFacilityApplications = createSelector(
  selectFacilitiesState,
  (state: FacilitiesState) => state.facilityApplications.data
);

export const selectFacilityApplicationsLoading = createSelector(
  selectFacilitiesState,
  (state: FacilitiesState) => state.facilityApplications.loading
);

export const selectFacilityCardConfigs = createSelector(
  selectFacilitiesState,
  (state: FacilitiesState) =>
    state.candidateFacilitiesData.data?.map((facility) => {
      const facilityCardConfig: IFacilityCardConfig = {
        banner: facility.preferredClient ? preferredClientBanner : undefined,
        facilityDetails: {
          id: facility.id,
          name: facility.name,
          type: facility.facilityType ?? undefined,
          location: undefined,
          bedCount: facility.numberOfBeds?.toString() ?? undefined,
          submittedDate: state.facilityApplications.data?.find(
            (fa) =>
              fa?.facilityId?.toLowerCase() === facility?.id?.toLowerCase()
          )?.createdDate,
        },
        supplementalDetails: [],
      };
      if (facility.birthsPerYear > 0) {
        facilityCardConfig.supplementalDetails.push({
          icon: 'check_circle',
          text: `Births per year: ${facility.birthsPerYear}`,
        });
      }
      if (facility.erVisitsPerYear > 0) {
        facilityCardConfig.supplementalDetails.push({
          icon: 'check_circle',
          text: `ER visits per year: ${facility.erVisitsPerYear}`,
        });
      }
      if (facility.location) {
        facilityCardConfig.facilityDetails.location = facility.location;
      } else if (facility.city && facility.state) {
        facilityCardConfig.facilityDetails.location = `${facility.city}, ${facility.state}`;
      }
      return facilityCardConfig;
    })
);

export const selectFacilityParameters = createSelector(selectFacilitiesState, (state: FacilitiesState) => state.candidateFacilitiesParameters);
