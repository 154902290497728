<div fxLayout="column" fxFlex fxLayoutAlign="center center">
    <button *ngIf="isLoggedIn === false" mat-raised-button color="primary" (click)="login()">
        Login
    </button>
    <div fxLayout="column" *ngIf="isLoggedIn === true" fxLayoutGap="16px">
        <span>You are logged in.</span>
        <button mat-raised-button color="primary" (click)="selectContact()">
            Select Contact
        </button>
        <button mat-raised-button color="primary" (click)="logout()">
            Log out
        </button>
    </div>

</div>