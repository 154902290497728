import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BodySize } from 'hc-design-system-lib';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { InputIcon } from 'hc-design-system-lib/lib/components/form/form.enums';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import { select, Store } from '@ngrx/store';
import { FormHeaderAttributes } from 'src/app/services';
import { FileUploadTargets, IFileUploadOptions } from 'src/app/common';
import { GetFluVaccine, UploadFluVaccine } from 'src/app/store/tasks/tasks.actions';
import { selectFluVaccine, selectFluVaccineData, selectFluVaccineLoading, selectUpdateFluVaccine, selectUpdateFluVaccineLoading } from 'src/app/store/tasks/tasks.selectors';
import { VaccineTask } from 'src/app/common/models/db-objects';
import { IDataState } from 'src/app/store/app/app.models';
import { defaultTruncationSettings, generateFileTruncationSettings } from 'src/app/common/functions/filename-manipulators';
import { selectIsMobile } from 'src/app/store/ui/ui.selectors';
import { SetScreenWidth } from 'src/app/store/ui/ui.actions';

@Component({
  selector: 'app-flu-vaccine',
  templateUrl: './flu-vaccine.component.html',
  styleUrls: ['./flu-vaccine.component.scss'],
})
export class FluVaccineComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  primaryButtonAppearance = ButtonAppearance.Primary;
  secondaryButtonAppearance = ButtonAppearance.Secondary;
  locationIcon = InputIcon.LocationPin;
  smallBodySize = BodySize.Small;

  form: UntypedFormGroup;
  formHeaderAttributes: FormHeaderAttributes;
  fileTruncationFunction = new BehaviorSubject(generateFileTruncationSettings());

  files: Array<File> = [];
  prepopulatedFile: { fileName: string; fileUrl: string };
  isDesktop = true;
  qualificationID: string;

  constructor(private _fb: UntypedFormBuilder, public _store: Store) {}

  @HostListener('window:resize', ['$event'])
  OnResize(event): void {
    this._store.dispatch(new SetScreenWidth(window.innerWidth));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateFluVaccine$: Observable<IDataState<VaccineTask>> = this._store.pipe(select(selectUpdateFluVaccine));
  updateFluVaccineSaving$: Observable<boolean> = this._store.pipe(select(selectUpdateFluVaccineLoading));
  fluVaccine$: Observable<IDataState<VaccineTask>> = this._store.pipe(select(selectFluVaccine));
  fluVaccineData$: Observable<VaccineTask> = this._store.pipe(select(selectFluVaccineData));
  fluVaccineLoading$: Observable<boolean> = this._store.pipe(select(selectFluVaccineLoading));
  isMobile$ = this._store.pipe(
    select(selectIsMobile)
  );

  ngOnInit() {
    this._store.dispatch(new GetFluVaccine());
    this.addFluVaccineSubscription();
    this.subscribeToIsMobileSelector();
  }

  subscribeToIsMobileSelector(): void {
    this.isMobile$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile) => {
        this.fileTruncationFunction.next(generateFileTruncationSettings({
          maxLength: isMobile? 
            defaultTruncationSettings.mobileMaxLength:
            defaultTruncationSettings.maxLength
          })
        );
        this.isDesktop = !isMobile;
      });
  }


  _createForm() {
    this.form = this._fb.group({
      fileUpload: new UntypedFormControl('', [Validators.required]),
    });

    this.formHeaderAttributes = {
      form: this.form,
      title: 'Flu Vaccine Status',
      showSaveButton: false,
    };
  }


  addFluVaccineSubscription(): void {
    combineLatest([this.fluVaccineData$, this.fluVaccineLoading$])
      .pipe(
        skipWhile(([, fluVaccineLoading]) => fluVaccineLoading),
        takeUntil(this.destroy$)
      )
      .subscribe(([fluVaccine]: [VaccineTask, boolean]) => {
        if (fluVaccine != null) {
          this.prepopulatedFile = fluVaccine.documentUrl ? { fileName: this.getFileName(fluVaccine.documentUrl), fileUrl: fluVaccine.documentUrl } : null;
          this.qualificationID = fluVaccine.qualificationID;
        } else {
          this.qualificationID = null;
          this.prepopulatedFile = null;
        }
        this._createForm();
      });
  }

  onSave(isValid: boolean) {
    if (isValid) {
      const options: IFileUploadOptions = {
        target: FileUploadTargets.FluVaccination,
        qualificationId: this.qualificationID,
        isDeleting: this.qualificationID !== null,
      };
      this._store.dispatch(new UploadFluVaccine(options, this.files));
    }
  }

  handleFileInput(event: HcEvent) {
    this.prepopulatedFile = null;
    this.form.markAllAsTouched();
    this.form.markAsDirty();
    if (event?.eventValue?.length) {
      this.files = Array.from(event.eventValue);
    } else {
      this.files = [];
    }
  }

  getFileName(document: string): string {
    const fileName = document.split('/').pop();
    return fileName;
  }

  hasPrepopulatedFile(): boolean {
    return this.prepopulatedFile != null;
  }
}
