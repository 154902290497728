import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '../services';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthenticationService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return await this.checkAuth(state);
  }

  private async checkAuth(state: RouterStateSnapshot) {
    const authed = await this.authService.isAuthenticated();
    return authed || this.routeToLogin(state);
  }

  private routeToLogin(state: RouterStateSnapshot): boolean {
    this.authService.login(state.url);
    return false;
  }
}
