import { Action } from '@ngrx/store';
import { CurrentAssigneeInformation, Recruiter } from 'src/app/common/models/recruiter';
import { RecruiterTaskCreationModel } from 'src/app/common/models/recruiter-task-creation-model';

export enum ERecruiterActions {
  GetRecruiter = '[RECRUITER] report recruiter ',
  GetRecruiterSuccess = '[RECRUITER] report recruiter success',
  GetRecruiterError = '[RECRUITER] report recruiter error',
  UpdatePortalRecruiterField = '[RECRUITER] update portalRecruiterField',
  UpdatePortalRecruiterFieldSuccess = '[RECRUITER] update portalRecruiterField success',
  UpdatePortalRecruiterFieldError = '[RECRUITER] update portalRecruiterField error',
  GetDefaultReferralRecruiter = '[RECRUITER] get default referral recruiter ',
  GetDefaultReferralRecruiterSuccess = '[RECRUITER] get default referral recruiter success',
  GetDefaultReferralRecruiterError = '[RECRUITER] get default referral recruiter error',
  GetPayRecruiter = '[RECRUITER] get payRecruiter',
  GetPayRecruiterSuccess = '[RECRUITER] get payRecruiter success',
  GetPayRecruiterError = '[RECRUITER] get payRecruiter error',
  CreatePayPackageTask = '[RECRUITER] create payPackageTask',
  CreatePayPackageTaskSuccess = '[RECRUITER] create payPackageTask success',
  CreatePayPackageTaskError = '[RECRUITER] create payPackageTask error',
  GetCurrentAssigneeIN = '[RECRUITER] get current assignee for IN user',
  GetCurrentAssigneeINSuccess = '[RECRUITER] get current assignee for IN user success',
  GetCurrentAssigneeINError = '[RECRUITER] get current assignee for IN user error',
}

export class GetRecruiter implements Action {
  public readonly type = ERecruiterActions.GetRecruiter;

  constructor() { }
}

export class GetRecruiterSuccess implements Action {
  public readonly type = ERecruiterActions.GetRecruiterSuccess;

  constructor(public recruiterData: Recruiter) { }
}

export class GetRecruiterError implements Action {
  public readonly type = ERecruiterActions.GetRecruiterError;

  constructor(public error: Error) { }
}

export class GetDefaultReferralRecruiter implements Action {
  public readonly type = ERecruiterActions.GetDefaultReferralRecruiter;

  constructor() { }
}

export class GetDefaultReferralRecruiterSuccess implements Action {
  public readonly type = ERecruiterActions.GetDefaultReferralRecruiterSuccess;

  constructor(public recruiterData: Recruiter) { }
}

export class GetDefaultReferralRecruiterError implements Action {
  public readonly type = ERecruiterActions.GetDefaultReferralRecruiterError;

  constructor(public error: Error) { }
}

export class GetPayRecruiter implements Action {
  public readonly type = ERecruiterActions.GetPayRecruiter;

  constructor() { }
}

export class GetPayRecruiterSuccess implements Action {
  public readonly type = ERecruiterActions.GetPayRecruiterSuccess;

  constructor(public response: Recruiter) { }
}

export class GetPayRecruiterError implements Action {
  public readonly type = ERecruiterActions.GetPayRecruiterError;

  constructor(public error: Error) { }
}

export class CreatePayPackageTask implements Action {
  public readonly type = ERecruiterActions.CreatePayPackageTask;

  constructor(public payload: RecruiterTaskCreationModel) { }
}

export class CreatePayPackageTaskSuccess implements Action {
  public readonly type = ERecruiterActions.CreatePayPackageTaskSuccess;

  constructor(public response) { }
}

export class CreatePayPackageTaskError implements Action {
  public readonly type = ERecruiterActions.CreatePayPackageTaskError;

  constructor(public error: Error) { }
}

export class GetCurrentAssigneeIN implements Action {
  public readonly type = ERecruiterActions.GetCurrentAssigneeIN;
}

export class GetCurrentAssigneeINSuccess implements Action {
  public readonly type = ERecruiterActions.GetCurrentAssigneeINSuccess;

  constructor(public currentAssigneeIN: CurrentAssigneeInformation) { }
}

export class GetCurrentAssigneeINError implements Action {
  public readonly type = ERecruiterActions.GetCurrentAssigneeINError;

  constructor(public error: Error) { }
}



export type RecruiterActions =
  | GetRecruiter
  | GetRecruiterSuccess
  | GetRecruiterError
  | GetDefaultReferralRecruiter
  | GetDefaultReferralRecruiterSuccess
  | GetDefaultReferralRecruiterError
  | GetPayRecruiter
  | GetPayRecruiterSuccess
  | GetPayRecruiterError
  | CreatePayPackageTask
  | GetCurrentAssigneeIN
  | GetCurrentAssigneeINSuccess
  | GetCurrentAssigneeINError;
