import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { AppUrls } from '../app-urls';
import { AuthenticationService } from '../services';


@Injectable({
  providedIn: 'root'
})
export class EnrollGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _authService: AuthenticationService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return from(this._authService.isAuthenticated().then(isAuthenticated => {
        if (!isAuthenticated) {
          sessionStorage.setItem('isEnrolling', 'true');
          return true;
        } else {
          return this._router.parseUrl(AppUrls.JOB_SEARCH);
        }
      }))
  }
}
