import { TechnologyActions, ETechnologyActions } from './technology.actions';
import { initialTechnologyState, TechnologyState } from './technology.state';

export function technologyReducer(
  state = initialTechnologyState,
  action: TechnologyActions
): TechnologyState {
  switch (action.type) {
    case ETechnologyActions.GetTechnology:
      return {
        ...state,
        technologyData: {
          data: null,
          error: null,
          loading: true,
        },
      };
    case ETechnologyActions.GetTechnologySuccess:
      return {
        ...state,
        technologyData: {
          data: action.technology,
          error: null,
          loading: false,
        },
      };
    case ETechnologyActions.GetTechnologyError:
      return {
        ...state,
        technologyData: {
          data: null,
          error: action.error,
          loading: false,
        },
      };

    case ETechnologyActions.GetTechnologyByID:
      return {
        ...state,
        technologyDataByID: {
          ...state.technologyDataByID,
          error: null,
          loading: true,
        },
      };
    case ETechnologyActions.GetTechnologyByIDSuccess:
      return {
        ...state,
        technologyDataByID: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case ETechnologyActions.GetTechnologyByIDError:
      return {
        ...state,
        technologyDataByID: {
          data: null,
          error: action.error,
          loading: false,
        },
      };

    case ETechnologyActions.AddTechnology:
      return {
        ...state,
        addTechnologyData: {
          data: null,
          error: null,
          loading: true,
        },
      };
    case ETechnologyActions.AddTechnologySuccess:
      return {
        ...state,
        addTechnologyData: {
          data: action.response,
          error: null,
          loading: false,
        },
      };
    case ETechnologyActions.AddTechnologyError:
      return {
        ...state,
        addTechnologyData: {
          data: null,
          error: action.error,
          loading: false,
        },
      };

    case ETechnologyActions.DeleteTechnology:
      return {
        ...state,
        deleteTechnologyData: {
          data: null,
          error: null,
          loading: true,
        },
      };
    case ETechnologyActions.DeleteTechnologySuccess:
      return {
        ...state,
        deleteTechnologyData: {
          data: action.response,
          error: null,
          loading: false,
        },
      };
    case ETechnologyActions.DeleteTechnologyError:
      return {
        ...state,
        deleteTechnologyData: {
          data: null,
          error: action.error,
          loading: false,
        },
      };

    case ETechnologyActions.UpdateTechnology:
      return {
        ...state,
        updateTechnologyData: {
          data: null,
          error: null,
          loading: true,
        },
      };
    case ETechnologyActions.UpdateTechnologySuccess:
      return {
        ...state,
        updateTechnologyData: {
          data: action.response,
          error: null,
          loading: false,
        },
      };
    case ETechnologyActions.UpdateTechnologyError:
      return {
        ...state,
        updateTechnologyData: {
          data: null,
          error: action.error,
          loading: false,
        },
      };

    case ETechnologyActions.ResetDeleteTechnology:
      return {
        ...state,
        deleteTechnologyData: {
          data: null,
          error: null,
          loading: false,
        },
      };

    case ETechnologyActions.ResetUpdateTechnology:
      return {
        ...state,
        updateTechnologyData: {
          data: null,
          error: null,
          loading: false,
        },
      };

    case ETechnologyActions.ResetAddTechnology:
      return {
        ...state,
        addTechnologyData: {
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
}
