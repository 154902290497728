import { ESharedDataActions, SharedDataActions } from "./sharedData.actions";
import { initialSharedDataState, SharedDataState } from "./sharedData.state";

export function sharedDataReducer(
  state = initialSharedDataState,
  action: SharedDataActions
): SharedDataState {
  switch (action.type) {

    case ESharedDataActions.GetZipCodeInfo:
      return {
        ...state,
        zipCodes: {
          ...state.zipCodes,
          loading: true,
          error: null
        }
      }

    case ESharedDataActions.GetZipCodeInfoSuccess:
      return {
        ...state,
        zipCodes: {
          data: action.response,
          loading: false,
          error: null
        }
      }

      case ESharedDataActions.GetZipCodeInfoError:
        return {
          ...state,
          zipCodes: {
            ...state.zipCodes,
            loading: false,
            error: action.error
          }
        }
  }
}