import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormHeaderService, NavHelper } from 'src/app/services';
import { ApplicationReviewModel, IJob, ILookup } from 'src/app/common';
import { combineLatest, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { TaskNavigatorService } from 'src/app/services/task-navigator.service';
import {
  selectProfessionLookup,
  selectSpecialtyLookup,
} from 'src/app/store/lookups/lookups.selectors';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { TaskStatusConstants } from 'src/app/common/models/task-status';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import {
  DialogService,
  HeadingSize,
  NotificationService,
} from 'hc-design-system-lib';
import {
  ApplyToSpecificJob,
  ApplyToSpecificJobError,
  ArchiveSubmittal,
  GetApplication,
  GetJobSubmittals,
  GetSpecificJob,
  UpdateApplication,
  UpdateApplicationError,
  UpdateProfileReviewData,
} from 'src/app/store/jobs/jobs.actions';
import {
  selectApplicationReview,
  selectApplyProcessSaving,
  selectApplyToJobResult,
  selectSpecificJob,
  selectUpdateApplicationResult,
  selectUpdateProfileReviewResult,
} from 'src/app/store/jobs/jobs.selectors';
import { selectTasks } from 'src/app/store/tasks/tasks.selectors';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { PHONE_MASK } from 'src/app/common/constants';
import { GetTasks } from 'src/app/store/tasks/tasks.actions';
import { NurseTask } from 'src/app/common/models/db-objects';

@Component({
  selector: 'app-application-review',
  templateUrl: './application-review.component.html',
  styleUrls: ['./application-review.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApplicationReviewComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() isDialog = false;

  // Constants
  headingSizeH6 = HeadingSize.H6;
  phoneMask = PHONE_MASK;
  requestTimeOffNote =
    'Note: requesting more than a week off may negatively impact your chances of being selected for the assignment';
  primaryButtonAppearance = ButtonAppearance.Primary;
  secondaryButtonAppearance = ButtonAppearance.Secondary;

  // Variables
  profile: ApplicationReviewModel;
  form: UntypedFormGroup;
  selectedJob: IJob;
  isJobRecommended: boolean;
  jobOrderName: string;
  jobOrderId: string;
  jobOrderSpecialtyName: string;
  jobOrderProfessionName: string;
  applicationModel: ApplicationReviewModel;
  estimatedStartDate: string;
  phoneNumber: string;
  timeOffRequest: boolean;
  userAvailableDate: Date;
  startDateUpdated = false;
  updatePending = false;
  titleStart = '';
  hasCompletedTasks: boolean;
  specialtyLookup: Map<string, ILookup<string>>;
  professionLookup: Map<string, ILookup<string>>;
  successfulSubmission = false;

  // Observables
  specialtyLookup$: Observable<Map<string, ILookup<string>>> =
    this._store.select(selectSpecialtyLookup);

  professionLookup$: Observable<Map<string, ILookup<string>>> =
    this._store.select(selectProfessionLookup);

  job$: Observable<IJob> = this._store.select(selectSpecificJob);

  application$: Observable<ApplicationReviewModel> = this._store.select(
    selectApplicationReview,
  );

  tasks$: Observable<any> = this._store.select(selectTasks);

  updateApplicationResult$: Observable<any> = this._store.select(
    selectUpdateApplicationResult,
  );

  applyToJobResult$: Observable<any> = this._store.select(
    selectApplyToJobResult,
  );

  applyProcessSaving$: Observable<boolean> = this._store.select(
    selectApplyProcessSaving,
  );

  updateProfileReviewResult$: Observable<any> = this._store.select(
    selectUpdateProfileReviewResult,
  );

  destroy$: Subject<void> = new Subject();

  constructor(
    public _dialog: MatDialog,
    public _dialogService: DialogService,
    public _location: Location,
    public _fb: UntypedFormBuilder,
    public _route: ActivatedRoute,
    public _datePipe: DatePipe,
    public _notificationService: NotificationService,
    public _navHelper: NavHelper,
    public _formHeaderService: FormHeaderService,
    private _taskNav: TaskNavigatorService,
    private _store: Store<IAppState>,
  ) {}

  ngOnInit() {
    this.initializeObservables();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._dialogService.closeAll();
  }

  addApplySubscription(): void {
    this.applyToJobResult$
      .pipe(
        filter((result) => result != null),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => {
        if (!result.isSuccessStatusCode) {
          this._store.dispatch(new ApplyToSpecificJobError(result.error));
        } else {
          this.onSuccessUpdateOrApply();
        }
      });
  }

  addUpdateApplicationSubscription(): void {
    this.updateApplicationResult$
      .pipe(
        filter((result) => result != null),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => {
        if (result === 0) {
          this._store.dispatch(new UpdateApplicationError(result.error));
        } else {
          this.onSuccessUpdateOrApply();
        }
      });
  }

  addUpdateProfileReviewSubscription(): void {
    this.updateProfileReviewResult$
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.updatePending === false
            ? this._store.dispatch(
                new ApplyToSpecificJob(
                  this.selectedJob,
                  this.applicationModel?.requestedTimeOff,
                  this.hasCompletedTasks,
                  this.isJobRecommended,
                ),
              )
            : this._store.dispatch(
                new UpdateApplication(
                  this.selectedJob,
                  this.applicationModel?.requestedTimeOff,
                ),
              );
        }
      });
  }

  initializeObservables(): void {
    combineLatest([
      this.job$,
      this.tasks$,
      this.application$,
      this.specialtyLookup$,
      this.professionLookup$,
    ])
      .pipe(
        filter(
          ([job, tasks, application, specialtyLookup, professionLookup]) =>
            this.hasCorrectJob(job) &&
            this.hasTasks(tasks) &&
            this.hasApplication(application) &&
            !!specialtyLookup &&
            !!professionLookup,
        ),
        take(1),
        tap(([job, tasks, application, specialtyLookup, professionLookup]) => {
          this.initializeForm(
            job,
            tasks,
            application,
            specialtyLookup,
            professionLookup,
          );
          this.addApplySubscription();
          this.addUpdateApplicationSubscription();
          this.addUpdateProfileReviewSubscription();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  hasCorrectJob(job: IJob): boolean {
    if (job?.id.toLowerCase() === this._route.snapshot.params['id']) {
      return true;
    } else {
      this._store.dispatch(
        new GetSpecificJob(this._route.snapshot.params['id']),
      );
      return false;
    }
  }

  hasTasks(tasks): boolean {
    if (tasks === null) {
      this._store.dispatch(new GetTasks());
      return false;
    } else {
      return true;
    }
  }

  hasApplication(application: ApplicationReviewModel): boolean {
    if (application === null) {
      this._store.dispatch(new GetApplication());
      return false;
    } else {
      return true;
    }
  }

  initializeForm(
    job: IJob,
    tasks: NurseTask[],
    application: ApplicationReviewModel,
    specialtyLookup: Map<string, ILookup<string>>,
    professionLookup: Map<string, ILookup<string>>,
  ): void {
    this.selectedJob = job;
    this.isJobRecommended =
      this._route.snapshot.queryParamMap.get('RecommendedJob') === 'true';
    this.profile = { ...application };
    this.stripPhone();

    this.hasCompletedTasks = this.checkTaskCompletion(tasks);
    if (this.hasCompletedTasks && job.hasStarted) {
      this.updatePending = true;
    }

    this.jobOrderProfessionName = professionLookup.get(job.profession)
      ?.shortName;
    this.jobOrderSpecialtyName = specialtyLookup.get(job.specialty)?.shortName;

    this.titleStart = !this.updatePending ? 'Apply for' : 'Update';
    this.timeOffRequest = !!this.profile.requestedTimeOff;
    this.jobOrderName = job.name;
    this.jobOrderId = job.id;

    // Set up UTC date and get value
    const estimateJobStartDate = moment(job.startDate).toDate();
    const jobStartValue = estimateJobStartDate.valueOf();
    const nowValue = Date.now().valueOf();

    if (application.dateAvailable !== undefined) {
      // Set as UTC and get value
      const dateAvailable = moment(application.dateAvailable).toDate();
      const dateAvailableValue = dateAvailable.valueOf();

      // If the user got an available date that's greater than the job order date and
      // greater than today's date, show that. Otherwise use the job date or now
      const shouldUseUserAvailableDate =
        dateAvailableValue > nowValue && dateAvailableValue > jobStartValue;

      if (shouldUseUserAvailableDate) {
        this.userAvailableDate = dateAvailable;
      } else {
        this.userAvailableDate = this.calculateAvailableDate(
          jobStartValue,
          nowValue,
          estimateJobStartDate,
        );
      }
    } else {
      this.userAvailableDate = this.calculateAvailableDate(
        jobStartValue,
        nowValue,
        estimateJobStartDate,
      );
    }

    if (jobStartValue > nowValue) {
      this.estimatedStartDate = this._datePipe.transform(
        job.startDate,
        'shortDate',
      );
    } else {
      this.estimatedStartDate = 'ASAP';
    }

    this._createForm();

    this.form.controls.timeOff.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: boolean) => {
        this.timeOffRequest = event;
      });

    this.form.controls.startDate.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.startDateUpdated = true;
      });

    if (!this.isDialog) {
      this._formHeaderService.resetFormHeaderAttributes({
        form: this.form,
        title: `${this.titleStart} ${this.jobOrderSpecialtyName} Travel ${this.jobOrderProfessionName}`,
      });
    }
  }

  private calculateAvailableDate(
    jobStartValue: number,
    nowValue: number,
    estimateJobStartDate: Date,
  ): Date {
    if (jobStartValue > nowValue) {
      return moment(estimateJobStartDate).toDate();
    } else {
      return new Date();
    }
  }

  stripPhone(): void {
    this.profile.mobilePhone = this.profile.mobilePhone?.replace(/\W/g, '');
  }

  _createForm() {
    this.form = this._fb.group({
      phone: new UntypedFormControl(
        this.profile.mobilePhone ? this.profile.mobilePhone : '',
        [
          Validators.required,
          Validators.pattern('\\(?\\d{3}\\)? ?\\d{3}-?\\d{4}'),
        ],
      ),
      startDate: new UntypedFormControl(this.userAvailableDate, [
        Validators.required,
      ]),
      timeOff: new UntypedFormControl(this.timeOffRequest),
      requestedTimeOff: new UntypedFormControl(this.profile.requestedTimeOff),
    });
  }

  prepareSubmit() {
    const model = new ApplicationReviewModel();
    model.id = this.profile.id;
    model.mobilePhone = this.form.controls.phone.value;
    model.requestedTimeOff = this.form.controls.requestedTimeOff.value;

    let dateValue: any;
    if (this.startDateUpdated) {
      dateValue = moment(this.form.controls.startDate.value)
        .local(true)
        .toDate();
    } else {
      if (this.profile.dateAvailable) {
        dateValue = moment(this.profile.dateAvailable).local(true).toDate();
      } else {
        dateValue = moment(this.form.controls.startDate.value)
          .local(true)
          .toDate();
      }
    }
    model.dateAvailable = dateValue;

    if (!this.timeOffRequest) {
      model.requestedTimeOff = 'No';
    }
    return model;
  }

  onSubmit() {
    this._formHeaderService.setDisableActions(true);
    this.applicationModel = this.prepareSubmit();
    this._store.dispatch(new UpdateProfileReviewData(this.applicationModel));
  }

  checkTaskCompletion(tasks: NurseTask[]): boolean {
    return (
      tasks.filter(
        (t) => t.requiredForApply === true && !this.taskIsCompleteOrPending(t),
      ).length === 0
    );
  }

  taskIsCompleteOrPending(task: NurseTask): boolean {
    return (
      task.status === TaskStatusConstants.complete ||
      task.status === TaskStatusConstants.pending
    );
  }

  onSuccessUpdateOrApply(): void {
    this._taskNav.removeApplyJobTag();

    // When Applying Successfully if "transferring" an application we have to inactivate
    if (sessionStorage.getItem('transferredApplicationId')) {
      const transferredApplicationIds = sessionStorage
        .getItem('transferredApplicationId')
        .split(':');
      const transferredApplicationId = transferredApplicationIds[0];
      const matchingJobId = transferredApplicationIds[1];

      if (matchingJobId.toLowerCase() === this.jobOrderId.toLowerCase()) {
        this._store.dispatch(
          new ArchiveSubmittal({ id: transferredApplicationId }),
        );
        sessionStorage.setItem('transferredApplicationId', null);
      }
    }
    this._store.dispatch(new GetJobSubmittals(true));

    const dialogRef = this.showSuccessMessage();

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((x) => {
        if (x === 'closed' || x === undefined) {
          this.selectedJob = null;
        }
      });
  }

  cancel() {
    this._dialogService.closeAll();
  }

  getErrorMessage(control: AbstractControl, fieldName: string): string {
    if (!control) {
      return '';
    }
    if (control.hasError('phone')) {
      return 'Valid Phone Number Required';
    }
    if (control.hasError('startDate')) {
      return 'Date must be in MM/DD/YYYY';
    }
    if (control.hasError('required')) {
      return `Please enter a ${fieldName}`;
    }
    if (control.hasError('pattern')) {
      return `Not a valid ${fieldName}`;
    }
  }

  showSuccessMessage() {
    this.successfulSubmission = true;
    return this._dialogService.updateOpenDialog({
      title: '',
      separatedHeader: false,
    });
  }
}
