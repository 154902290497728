import { TaskCompletionReturnObject } from "src/app/common";
import { License } from "src/app/common/models/license";
import { IDataState, initializeData } from "../app/app.models";

export interface LicensesState {
  license: IDataState<License>;
  licenses: IDataState<License[]>;
  nursysAdd: IDataState<boolean>;
  nursysUpdate: IDataState<number>;
  updateResponse: IDataState<TaskCompletionReturnObject>;
  fileUrl: IDataState<string>;
};

export const initialLicensesState: LicensesState = {
  license: initializeData(),
  licenses: initializeData(),
  nursysAdd: initializeData(),
  nursysUpdate: initializeData(),
  updateResponse: initializeData(),
  fileUrl: initializeData(),
};
