import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Certification,
  CandidateQualification,
  ILookup,
  ICertificationLookupModel,
} from 'src/app/common';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { GetCertificationPopulate, GetCertifications } from 'src/app/store/certifications/certifications.actions';
import { BodyColor, CardElevation, DialogService, HeadingSize, IDialogParameters } from 'hc-design-system-lib';
import { IRecordCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { selectCertificationLookup, selectIssuingLookup, selectLicenseCertificationStatusLookup } from 'src/app/store/lookups/lookups.selectors';
import { selectCertifications, selectCertificationsLoading } from 'src/app/store/certifications/certifications.selectors';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { NurseTask } from 'src/app/common/models/db-objects';
import { selectTasksLoading, selectToDoCertTasks } from 'src/app/store/tasks/tasks.selectors';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';

@Component({
  selector: 'app-certification-list',
  templateUrl: './certification-list.component.html',
  styleUrls: ['./certification-list.component.scss'],
})
export class CertificationListComponent implements OnInit, AfterViewInit {
  @ViewChild('addCertForm')
  addCertFormTemplate: TemplateRef<any>;

  prefilledCertId: string = null;
  headingSize6 = HeadingSize.H6;
  buttonAppearanceSecondary = ButtonAppearance.Secondary;
  certifications: Certification[];
  qualifications: CandidateQualification[];
  certificationLookup: Map<string, ICertificationLookupModel>;
  licenseCertificationStatusLookup: Map<number, ILookup<number>>;
  editingCert: Certification = null;

  certificationLookup$ = this._store.pipe(select(selectCertificationLookup));
  statusLookup$ = this._store.pipe(select(selectLicenseCertificationStatusLookup));
  certifications$ = this._store.pipe(select(selectCertifications));
  issuingLookup$ = this._store.pipe(select(selectIssuingLookup));

  certificationCards$: Observable<IRecordCardConfig[]> = combineLatest([this.certificationLookup$, this.statusLookup$, this.certifications$, this.issuingLookup$]).pipe(
    filter(([certLookup, statusLookup, certs, issuingLookup]) => !!certLookup && !!statusLookup && !!certs && !!issuingLookup),
    map(([certLookup, statusLookup, certs, issuingLookup]) => {
      this.certifications = certs;
      return this.mapCertsToCardDetails(certLookup, statusLookup, certs, issuingLookup);
    })
  )
  certificationsLoading$: Observable<boolean> = this._store.pipe(select(selectCertificationsLoading));

  toDoCertTasks$: Observable<NurseTask[]> = this._store.pipe(
    select(selectToDoCertTasks),
    filter(tasks => !!tasks),
  );
  tasksLoading$: Observable<boolean> = this._store.pipe(
    select(selectTasksLoading)
  );

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<IAppState>,
    private _dialogService: DialogService
  ) { }

  ngOnInit() {
    this._store.dispatch(new GetCertifications());
  }

  ngAfterViewInit() {
    this.prefillCertAndOpenDialog();
  }

  mapCertsToCardDetails(
    certLookup: Map<string, ICertificationLookupModel>,
    statusLookup: Map<number, ILookup<number>>,
    certs: Certification[],
    issuingLookup: Map<string, ILookup<string>>
  ): IRecordCardConfig[] {
    return certs.map(cert => {
      const status = statusLookup.get(cert.statusId).name;
      const expDate = new Date(cert.expirationDate);
      // Currently not mapping status because it is not accurate to what we expect from Merlin.
      return {
        title: certLookup.get(cert.certificationId)?.certification,
        firstLine: issuingLookup.get(cert.issuingBodyId)?.name,
        secondLine: `Exp: ${expDate.toLocaleDateString()}`,
        statusColor: this.getStatusColor(status),
        icon: 'edit',
        id: cert.id
      }
    });
  }

  getStatusColor(status): BodyColor {
    switch (status) {
      case 'Pending Review':
        return BodyColor.purple;
      case 'Active':
        return BodyColor.green;
      case 'Inactive/Expired':
        return BodyColor.orange;
      default:
        break;
    }
  }

  prefillCertAndOpenDialog(): void {
    const id = this._route.snapshot.queryParamMap.get('id');
    if (id) {
      this.certificationCards$.pipe(
        filter(cards => !!cards),
        take(1),
        tap(certCards => this.openFormFromTask(id))
      ).subscribe();
    }
  }

  openFormFromTask(id): void {
    this.resetEditingCertAndPrefillCert();
    const prexistingCert = this.certifications.find(cert => cert.id === id);

    if (prexistingCert) {
      this.editingCert = prexistingCert;
    } else {
      this._store.dispatch(new GetCertificationPopulate(id));
      this.prefilledCertId = id;
    }
    this.openCertModalDialog();
  }

  openEditForm(id): void {
    this.resetEditingCertAndPrefillCert();
    this.editingCert = this.certifications.find(cert => cert.id === id);
    this.openCertModalDialog();
  }

  openAddForm(): void {
    this.resetEditingCertAndPrefillCert();
    this.openCertModalDialog();
  }

  resetEditingCertAndPrefillCert(): void {
    this.editingCert = null;
    this.prefilledCertId = null;
  }

  openCertModalDialog(): void {
    const title = this.editingCert ? 'Edit Certification' : 'Add Certification';
    const template = this.addCertFormTemplate;
    const dialogData: IDialogParameters = {
      title,
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: null,
      template,
      separatedHeader: true,
      noStyling: true,
      isResponsive: true,
      useCustomCloseLogic: true,
    };

    this._dialogService.showDialog(dialogData, true, true, false);
  }
}
