import { AbstractControl, ValidationErrors } from "@angular/forms";

// https://stackoverflow.com/questions/275160/regex-for-names

export const noSpecialCharacters = (control: AbstractControl): ValidationErrors | null => {
    const pattern = new RegExp("^([ \u00c0-\u01ffa-zA-Z'\-])+$");
    const value = control?.value ?? '';
    if (value.length > 0 && !pattern.test(value)) {
        return { nospecialornum: true };
    }

    return null;
}