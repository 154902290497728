import { Component, OnInit, Inject, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NursePortalApi } from 'src/app/services';
import { Recruiter } from 'src/app/common/models/recruiter';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Submittal, EmailFormModel, EmailTypes, NurseModel, ILookup } from 'src/app/common';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { IAppState } from 'src/app/store/app/app.state';
import {
  selectSpecialtyLookup,
  selectProfessionLookup,
} from 'src/app/store/lookups/lookups.selectors';
import { PhoneNumberClicked } from 'src/app/store/segment/segment.actions';
import {IJobFilterAPI} from 'src/app/common/models/job-filter';
import { ContractType } from 'src/app/common/contracts/contract-type';

export enum ContactRecruiterType {
  NoRecruiterEmail = -1,
  PayPackage = 1,
  Submittal = 2,
  AutoOffer = 3,
  UnavailablePay = 4,
  JobDetails = 5,
  JobSearch = 6,
  //JobAlerts = 7,
  JobFavorites = 8
}

@Component({
  selector: 'app-contact-recruiter-pop-up',
  templateUrl: './contact-recruiter-pop-up.component.html',
  styleUrls: ['./contact-recruiter-pop-up.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ContactRecruiterPopUpComponent implements OnInit, OnDestroy {
  showContent = true;
  default = true;
  emailSent = false;
  initials: string;
  firstName: string;
  form: UntypedFormGroup;
  recruiter: Recruiter;
  assignments: any;
  jobSearchParameters: IJobFilterAPI;
  contactRecruiterType: ContactRecruiterType; 
  contractTypes: ContractType[];

  professionLookup: Map<string, ILookup<string>>;
  specialtyLookup: Map<string, ILookup<string>>;

  professionLookupSubscription: Subscription;
  professionLookup$: Observable<Map<string, ILookup<string>>> = this._store.pipe(
    select(selectProfessionLookup)
  );

  specialtyLookupSubscription: Subscription;
  specialtyLookup$: Observable<Map<string, ILookup<string>>> = this._store.pipe(
    select(selectSpecialtyLookup)
  );


  nurse$: Observable<NurseModel> = this._store.pipe(select(selectNurseData));
  nurseSubscription: Subscription;

  constructor(
    public _api: NursePortalApi,
    public dialogRef: MatDialogRef<ContactRecruiterPopUpComponent>,
    private _fb: UntypedFormBuilder,
    private _store: Store<IAppState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.addProfessionLookupSubscription();
    this.addSpecialtyLookupSubscription();
    this.addNurseSubscription();
    this.contactRecruiterType = this.data.contactRecruiterType;
    this.recruiter = this.data.a;
    this.assignments = this.data.b;
    if(this.data.contractType) {
      this.contractTypes = [this.data.contractType];
    }

    this.jobSearchParameters = this.data.jobSearchParameters;
    this.setInitials();
    if (this.recruiter.email == null || this.recruiter.email == '' || this.recruiter.email.toLowerCase() == "susan.gossett@healthcarousel.com") {
      this.contactRecruiterType = ContactRecruiterType.NoRecruiterEmail;
    }
    this.emailSelection();
  }

  ngOnDestroy(): void {
    this.professionLookupSubscription?.unsubscribe();
    this.specialtyLookupSubscription?.unsubscribe();
    this.nurseSubscription?.unsubscribe();
  }

  addProfessionLookupSubscription(): void {
    this.professionLookupSubscription = this.professionLookup$.subscribe((professionLookup) => {
      this.professionLookup = professionLookup;
    });
  }

  addSpecialtyLookupSubscription(): void {
    this.specialtyLookupSubscription = this.specialtyLookup$.subscribe((specialtyLookup) => {
      this.specialtyLookup = specialtyLookup;
    });
  }

  addNurseSubscription(): void {
    this.nurseSubscription = this.nurse$.subscribe((nurseData: NurseModel) => {
      if (nurseData) {
        this.createForm(nurseData);
      }
    });
  }

  createForm(nurse: NurseModel) {
    this.form = this._fb.group({
      assignments: new UntypedFormControl(''),
      emailBody: new UntypedFormControl(
        `Can you give me an update on this assignment?\n\nThanks,\n${nurse.firstName} ${nurse.lastName}`
      ),
    });
  }

  clearEmailBody() {
    if (this.default) {
      this.form.controls.emailBody.setValue('');
      this.default = false;
    }
  }

  setInitials() {
    const name = this.recruiter.name.split(' ');
    this.firstName = name[0];
    this.initials = name[0].substr(0, 1) + name[1].substr(0, 1);
  }

  emailSelection() {
    switch(this.contactRecruiterType) {
      case ContactRecruiterType.PayPackage: this.sendJobPayEmail();
      break;
      case ContactRecruiterType.Submittal: this.showContent = false;
      break;
      case ContactRecruiterType.AutoOffer: this.sendAutoOfferEmail();
      break;
      case ContactRecruiterType.UnavailablePay: this.sendJobPayEmail();
      break;
      case ContactRecruiterType.JobDetails: this.sendJobDetailsEmail();
      break;
      case ContactRecruiterType.JobSearch: this.sendJobSearchEmail();
      break;
      case ContactRecruiterType.JobFavorites: this.sendJobFavoritesEmail();
      break;
    }
  }

  sendSubmittalEmail() {
    if (this.default) {
      this.form.controls.emailBody.setValue('');
    }
    const model = this.form.value as EmailFormModel;
    const email = this._api.contactRecruiter(model);
    this.showContent = false;
    this.emailSent = true;
    email.subscribe();
  }

  sendJobPayEmail() {
    const model = {
      assignments: [{ id: this.assignments[0].id, name: this.assignments[0].name }],
      emailBody: '',
      emailType: EmailTypes.PayPackage,
      contractTypes: this.contractTypes
    } as EmailFormModel;
    const email = this._api.contactRecruiter(model);
    this.emailSent = true;
    email.subscribe();
  }

  sendAutoOfferEmail() {
    const model = {
      assignments: [{ id: this.assignments[0].id, name: this.assignments[0].name }],
      emailBody: '',
      emailType: EmailTypes.AutoOffer,
    } as EmailFormModel;
    const email = this._api.contactRecruiter(model);
    this.emailSent = true;
    email.subscribe();
  }

  sendJobDetailsEmail() {
    const model = {
      assignments: [{ id: this.assignments[0].id, name: this.assignments[0].name }],
      emailBody: '',
      emailType: EmailTypes.JobDetails,
      contractTypes: this.contractTypes
    } as EmailFormModel;
    const email = this._api.contactRecruiter(model);
    this.emailSent = true;
    email.subscribe();
  }

  sendJobSearchEmail(){
    const model = {
      jobSearchParameters: this.jobSearchParameters,
      contractTypes: this.contractTypes,
      emailBody: '',
      emailType: EmailTypes.JobSearch,
    } as EmailFormModel;
    const email = this._api.contactRecruiter(model);
    this.emailSent = true; 
    email.subscribe();
  }

  sendJobFavoritesEmail(){
    const model = {
      emailBody: '',
      emailType: EmailTypes.JobFavorites,
    } as EmailFormModel;
    const email = this._api.contactRecruiter(model);
    this.emailSent = true;
    email.subscribe();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  getJobName(submittal: Submittal) {
    const specialty = this.specialtyLookup.get(submittal.specialty);
    const prof = this.professionLookup.get(submittal.profession);
    const profName = prof != null ? prof.shortName : null;
    const specialityName = specialty?.name != null ? specialty.name : null;
    return specialityName != null ? specialityName + ' Travel ' + profName : 'Travel ' + profName;
  }

  trackPhoneNumberClick(recruiterPhoneNumber: string): void {
    this._store.dispatch(new PhoneNumberClicked({location: 'Contact Recruiter Pop Up', phoneType: 'Recruiter', phoneNumber: recruiterPhoneNumber}));
  }
}
