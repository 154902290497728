export interface ILookup<T> {
    systemTechnology?: any;
    includeInPortal?: number;
    sortOrder?: number;
    id: T;
    name: string;
    shortName?: string;
    code?: string;
    requiresSpecialty?: boolean;
    parentId?: string
    type?: number
    Description? : string;
}
