<ng-container
  *ngIf="{
    recruiterAvatarConfiguration: recruiterAvatarConfiguration$ | async
  } as obs"
>
  <hc-tile-card
    [templateReference]="supportAvatarsTemplate"
    [cardConfig]="cardConfig"
  ></hc-tile-card>

  <ng-template #supportAvatarsTemplate>
    <div *ngIf="obs.recruiterAvatarConfiguration.length" class="avatar-container" >
      <div *ngFor="let avatarDetails of obs.recruiterAvatarConfiguration" class="avatar-row">
        <div class="avatar-pic">
          <hc-avatar
            [imageSource]="avatarDetails?.imageSource"
            borderColor="none"
            size="larger"
            [text]="avatarDetails?.text"
            [square]="true"
          ></hc-avatar>
        </div>
        <div class="avatar-details">
          <hc-heading *ngIf="avatarDetails?.jobTitle" size="headingSizeH6" >{{ avatarDetails.jobTitle }}</hc-heading>
          <hc-body *ngIf="avatarDetails?.name">{{ avatarDetails.name }}</hc-body>
          <hc-link *ngIf="avatarDetails?.email" size="small" url="mailto:{{avatarDetails.email}}" >{{ avatarDetails.email }}</hc-link>
          <hc-body *ngIf="avatarDetails?.phone" size="small" >{{ avatarDetails.phone }}</hc-body>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>