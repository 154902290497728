import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavHelper } from 'src/app/services';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { NurseModel } from 'src/app/common';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { SendVerificationEmail } from 'src/app/store/userContext/userContext.actions';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.scss'],
})
export class VerifyUserComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  userEmail: string;

  nurse$: Observable<NurseModel> = this.store.select(selectNurseData);

  nurseId: number;

  constructor(
    private _navHelper: NavHelper,
    public snackBar: MatSnackBar,
    private store: Store,
  ) {}

  ngOnInit() {
    this.nurse$
      .pipe(
        filter((nurse) => nurse !== null),
        takeUntil(this.destroy$),
      )
      .subscribe((nurseData: NurseModel) => {
        if (nurseData) {
          this.userEmail = nurseData.email;
          this.nurseId = nurseData.id;

          if (!nurseData.isVerified) {
            this.store.dispatch(new SendVerificationEmail());
          } else {
            this._navHelper.goToDashBoard();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateToVerifySupport() {
    this._navHelper.goToVerifySupport();
  }

  resendEmail() {
    this.store.dispatch(new SendVerificationEmail(true));
  }
}
