export enum PortalCategoryCode {
  Profiled = 948050002,
  CompleteProfile = 948050000,
  PayVisibility = 948050001,
  Credentialing = 948050003,
  Immigration = 948050004,
  Arrivals = 948050006,
  License = 948050005,
  OptionalForSubmission = 948050007,
}

export const portalCategoryCodeToNameMap = new Map<PortalCategoryCode, string>([
  [PortalCategoryCode.Profiled, 'Profiled'],
  [PortalCategoryCode.CompleteProfile, 'Complete Profile'],
  [PortalCategoryCode.PayVisibility, 'Pay Visibility'],
  [PortalCategoryCode.Credentialing, 'Credentialing'],
  [PortalCategoryCode.Immigration, 'Immigration'],
  [PortalCategoryCode.Arrivals, 'Arrivals'],
  [PortalCategoryCode.License, 'License'],
  [PortalCategoryCode.OptionalForSubmission, 'Optional For Submission'],
]);
