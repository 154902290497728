import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NavHelper } from 'src/app/services';
import { IJob, ILookup, IUserModel, NurseModel } from 'src/app/common';
import { CardComponentConfig } from '../../cards/card-template/card-template.component';
import { UntypedFormControl } from '@angular/forms';
import { IAppState } from 'src/app/store/app/app.state';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import {
  selectNurseData,
  selectUserData,
} from 'src/app/store/userContext/userContext.selectors';
import {
  selectSavedJobs,
  selectSavedJobsLoading,
} from 'src/app/store/jobs/jobs.selectors';
import { JobDataModel } from 'src/app/common/models/job-data-model';
import {
  GetSavedJobs,
  SetSavedJob,
  UpdateJobNotifications,
} from 'src/app/store/jobs/jobs.actions';
import { SavedJobsViewed } from 'src/app/store/segment/segment.actions';
import { ICardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import {
  selectYesNoOnlyLookup,
  selectYesNoLookup,
  selectSpecialtyLookup,
  selectProfessionLookup,
  selectStateLookup,
  selectLookupsLoading,
} from 'src/app/store/lookups/lookups.selectors';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import { mapJobToCardConfig } from '../../job-card-utils/job-card-utils';
import { JobAreaContext } from 'src/app/services/job-area-context.service';
import { ContractType } from 'src/app/common/contracts/contract-type';
import { LOCAL_BADGE, TRAVEL_BADGE } from 'src/app/common/models/badge';

@Component({
  selector: 'app-saved-jobs',
  templateUrl: './saved-jobs.component.html',
  styleUrls: ['./saved-jobs.component.scss'],
})
export class SavedJobsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @Input() setTabIndex: (index: any) => void;
  @Input() completedProfile: boolean;
  jobs: IJob[];
  availableJobs: IJob[] = [];
  unavailableJobs: IJob[] = [];
  jobConfig: CardComponentConfig = {
    showStatus: false,
    showInfoDetails: true,
    showTransferApplication: false,
    showBadges: true,
    showLinks: false,
    useEmitter: true,
  };
  merlinId: string;
  checkboxControl = new UntypedFormControl('');

  user$: Observable<IUserModel> = this._store.select(selectUserData);
  nurse$: Observable<NurseModel> = this._store.select(selectNurseData);
  savedJobs$: Observable<JobDataModel> = this._store.select(selectSavedJobs);
  savedJobsLoading$: Observable<boolean> = this._store.select(
    selectSavedJobsLoading,
  );
  yesNoOnlyLookup$: Observable<Map<string, ILookup<string>>> =
    this._store.select(selectYesNoOnlyLookup);
  yesNoLookup$: Observable<Map<string, ILookup<string>>> =
    this._store.select(selectYesNoLookup);
  specialtyLookup$: Observable<Map<string, ILookup<string>>> =
    this._store.select(selectSpecialtyLookup);
  professionLookup$: Observable<Map<string, ILookup<string>>> =
    this._store.select(selectProfessionLookup);
  stateLookup$: Observable<Map<string, ILookup<string>>> =
    this._store.select(selectStateLookup);
  lookupsLoading$: Observable<boolean> =
    this._store.select(selectLookupsLoading);

  availableSavedJobCardConfigs: ICardConfig[] = [];
  unavailableSavedJobCardConfigs: ICardConfig[] = [];

  constructor(
    private _nav: NavHelper,
    private _store: Store<IAppState>,
    private _jobAreaContext: JobAreaContext,
  ) {}

  ngOnInit() {
    this.addUserSubscription();
    this.addNurseSubscription();
    this.addSavedJobsSubscription();
    this._store.dispatch(new GetSavedJobs());
    this._store.dispatch(new SavedJobsViewed());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addUserSubscription(): void {
    this.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userData: IUserModel) => {
        if (userData) {
          this.checkboxControl.setValue(userData.jobNotificationsActive);
        }
      });
  }

  addNurseSubscription(): void {
    this.nurse$
      .pipe(takeUntil(this.destroy$))
      .subscribe((nurseData: NurseModel) => {
        if (nurseData) {
          this.merlinId = nurseData.merlinId;
        }
      });
  }

  addSavedJobsSubscription(): void {
    combineLatest([
      this.savedJobs$,
      this.savedJobsLoading$,
      this.lookupsLoading$,
    ])
      .pipe(
        skipWhile(
          ([savedJobs, savedJobsLoading, lookupsLoading]: [
            JobDataModel,
            boolean,
            boolean,
          ]) => !savedJobs && savedJobsLoading && lookupsLoading,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(([savedJobs, ,]) => {
        if (savedJobs) {
          this.jobs = savedJobs.jobs;
          this.availableJobs = this.jobs?.filter((x) => !x.closed);
          this.unavailableJobs = this.jobs?.filter((x) => x.closed);

          this.availableSavedJobCardConfigs = this.mapJobsToCardConfigs(
            this.availableJobs,
          );
          this.unavailableSavedJobCardConfigs = this.mapJobsToCardConfigs(
            this.unavailableJobs,
          );
        }
      });
  }

  mapJobsToCardConfigs(jobs: IJob[]): ICardConfig[] {
    const jobCardConfigs: ICardConfig[] = [];

    combineLatest([
      this.yesNoLookup$,
      this.specialtyLookup$,
      this.professionLookup$,
      this.stateLookup$,
    ])
      .pipe(take(1))
      .subscribe(
        ([yesNoLookup, specialtyLookup, professionLookup, stateLookup]) => {
          jobs?.forEach((job) => {
            const badgeDetails = this._jobAreaContext
              .getJobBadges(false, job)
              .map((x) => x.badge);
            const primaryBadge =
              badgeDetails.shift() ??
              (job.contractType === ContractType.Local
                ? LOCAL_BADGE
                : TRAVEL_BADGE);
            jobCardConfigs.push(
              mapJobToCardConfig(
                job,
                { yesNoLookup, specialtyLookup, professionLookup, stateLookup },
                badgeDetails,
                primaryBadge,
                true,
              ),
            );
          });
        },
      );

    return jobCardConfigs;
  }

  updateSavedJob(job: IJob | ICardConfig): void {
    const cardConfig = { ...job } as ICardConfig;
    this._store.dispatch(
      new SetSavedJob({
        job: { id: cardConfig.cardData['jobId'] } as IJob,
        saveValue: cardConfig.isCardActionActive,
        contractType: cardConfig.cardData['contractType'],
      }),
    );
  }

  activateJobNotifications(event: any): void {
    this.checkboxControl.disable();
    this._store.dispatch(
      new UpdateJobNotifications({
        merlinId: this.merlinId,
        value: event.checked,
      }),
    );
    this.checkboxControl.enable();
  }

  navigateToJob(job: string | ICardConfig): void {
    this._nav.goToJobsSpecificCustomParams(
      (job as ICardConfig).cardData['jobId'],
      { contractType: (job as ICardConfig).cardData['contractType'] },
    );
  }

  navigateToTasksList(): void {
    this._nav.goToTasks();
  }
}
