<ng-container
  *ngIf="{
    submittals: submittals$ | async,
    taskLoading: tasksLoading$ | async,
    submittalLoading: submittalsLoading$ | async
  } as obs"
>
  <app-loading-screen-spinner
    [show]="obs.taskLoading || obs.submittalLoading"
  ></app-loading-screen-spinner>
  <div
    class="margin-header"
    *ngIf="
      (toDoTasks?.length > 0 && !showApplyTasksOnly) || showApplyTasksOnly;
      else taskListHeader
    "
  ></div>
  <div class="tasks-list-page">
    <div class="task-list-container">
      <div>
        <hc-heading
          class="task-list-title"
          [size]="headingSizeH3"
          styles="margin-bottom: 8px;"
          >My Application Profile</hc-heading
        >
        <div class="center global-subheader global-font-12">
          <hc-body [subdued]="true">
            Complete these tasks in order to apply to jobs & strengthen your
            chances of being selected for an assignment
          </hc-body>
        </div>
        <div class="progress-container" *ngIf="progressValue !== undefined">
          <div class="progress-text-container">
            <hc-body [size]="bodySizeSmall" [subdued]="true"
              >{{ completedItems }} of {{ totalItems }} tasks complete
            </hc-body>
          </div>
          <hc-progress-bar
            mode="determinate"
            [value]="progressValue"
          ></hc-progress-bar>
        </div>
      </div>
      <div id="todo-tasks" *ngIf="toDoTasks?.length > 0" class="task-list">
        <hc-heading [size]="headingSizeH5">To Do</hc-heading>
        <div class="center global-subheader global-font-12">
          <hc-body [size]="bodySizeSmall" [subdued]="true">
            Task times are estimated. This section shows tasks not started as
            well as tasks that are in progress, but not complete.
          </hc-body>
        </div>
        <div *ngFor="let task of toDoTasks">
          <hc-task-card
            [status]="
              mapStatus.has(task.status) ? mapStatus.get(task.status) : 0
            "
            [title]="task.friendlyName"
            (cardClicked)="_taskNav.doTask(task, tasks.length === 1)"
          >
          </hc-task-card>
        </div>
      </div>
      <div
        id="pending-tasks"
        *ngIf="pendingTasks?.length > 0"
        class="task-list"
      >
        <div class="task-list-tooltip">
          <hc-heading [size]="headingSizeH5">Pending</hc-heading>
        </div>
        <div class="center global-subheader global-font-12">
          <hc-body [size]="bodySizeSmall" [subdued]="true">
            Tasks pending review are being assessed by our Health Carousel team.
            Once reviewed, they will either mark the task as complete or let you
            know if corrections are needed.
          </hc-body>
        </div>
        <div *ngFor="let task of pendingTasks">
          <hc-task-card
            [status]="
              mapStatus.has(task.status) ? mapStatus.get(task.status) : 0
            "
            [title]="task.friendlyName ?? task.name"
            [additionalTitle]="task.pendingTaskInfo"
            (cardClicked)="_taskNav.doTask(task, tasks.length === 1)"
          >
          </hc-task-card>
        </div>
      </div>
      <div
        id="completed-tasks"
        *ngIf="completedTasks?.length > 0"
        class="task-list"
      >
        <hc-heading [size]="headingSizeH5">Completed</hc-heading>
        <div class="center global-subheader global-font-12">
          <hc-body [size]="bodySizeSmall" [subdued]="true">
            These tasks are complete, however you can click into them to make
            changes as needed.
          </hc-body>
        </div>
        <div *ngFor="let task of completedTasks">
          <hc-task-card
            [status]="
              mapStatus.has(task.status) ? mapStatus.get(task.status) : 0
            "
            [title]="task.friendlyName ?? task.name"
            (cardClicked)="_taskNav.doTask(task, tasks.length === 1)"
          >
          </hc-task-card>
        </div>
      </div>
    </div>
    <ng-template #taskListHeader>
      <div id="taskListHeader" class="header-container">
        <div class="header-content">
          <hc-heading [size]="headingSizeH3"> Nice Work! </hc-heading>
          <hc-body>
            Now that your profile is complete, let’s find your perfect role.
          </hc-body>
        </div>
        <div class="submit-buttons">
          <hc-button
            id="FinishMyApplicationID"
            *ngIf="obs.submittals > 0"
            [appearance]="primaryButtonAppearance"
            segmentTrackCTA
            (buttonClick)="cancel()"
            [alwaysDisplayFullWidth]="true"
            >Finish My Applications</hc-button
          >
          <hc-button
            class="secondaryButton"
            [appearance]="
              obs.submittals > 0
                ? secondaryButtonAppearence
                : primaryButtonAppearance
            "
            segmentTrackCTA
            (buttonClick)="goTo()"
            [alwaysDisplayFullWidth]="true"
            >Go to Job Search</hc-button
          >
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
