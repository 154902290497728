<div fxLayout="column" class="scroll">
    <div *ngFor="let item of navItems" id="{{item.id}}" class="row-formatting a selectedNavigationItem" (click)="item.func()"
      fxLayoutAlign="start center" matRipple [matRippleCentered]="true">
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
        {{item.name}}
      </div>
      <div fxLayoutAlign="center center">
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>

    <ng-template #deleteAccountFlow>
      <app-delete-account-modal></app-delete-account-modal>
    </ng-template>
  
  </div>