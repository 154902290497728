import { Action } from '@ngrx/store';
import { Education } from 'src/app/common/models/education';
import { GenericCompletionReturnObject } from 'src/app/common/models/generic-completion-return-model';

export enum EEducationActions {
  GetEducationList = '[EDUCATION] get education list',
  GetEducationListSuccess = '[EDUCATION] get education list success',
  GetEducationListError = '[EDUCATION] get education list error',
  GetEducation = '[EDUCATION] get education',
  GetEducationSuccess = '[EDUCATION] get education success',
  GetEducationError = '[EDUCATION] get education error',
  AddEducation = '[EDUCATION] add education',
  AddEducationSuccess = '[EDUCATION] add education success',
  AddEducationError = '[EDUCATION] add education error',
  AddEducationFileError = '[EDUCATION] add education file error',
  AddEducationReset = '[EDUCATION] add education reset',
  UpdateEducation = '[EDUCATION] update education',
  UpdateEducationSuccess = '[EDUCATION] update education success',
  UpdateEducationError = '[EDUCATION] update education error',
  UpdateEducationFileError = '[EDUCATION] update education file error',
  UpdateEducationReset = '[EDUCATION] update education reset',
  DeleteEducation = '[EDUCATION] delete education',
  DeleteEducationSuccess = '[EDUCATION] delete education success',
  DeleteEducationError = '[EDUCATION] delete education error',
  DeleteEducationReset = '[EDUCATION] delete education reset',
  DeleteEducationQualification = '[EDUCATION] delete education qualification',
  GetCreateDocumentObservable = '[EDUCATION] get create document observable',
  GetUpdateDocumentObservable = '[EDUCATION] get license update document observable',
  ResetEducationExpiryDate = '[EDUCATION] reset education expiry date',
}

export class GetEducationList implements Action {
  public readonly type = EEducationActions.GetEducationList;

  constructor() {}
}
export class GetEducationListSuccess implements Action {
  public readonly type = EEducationActions.GetEducationListSuccess;

  constructor(
    public payload: { educations: Education[]; expirationDate: Date }
  ) {}
}
export class GetEducationListError implements Action {
  public readonly type = EEducationActions.GetEducationListError;

  constructor(public error: Error) {}
}

export class GetEducation implements Action {
  public readonly type = EEducationActions.GetEducation;

  constructor(public educationId: string) {}
}
export class GetEducationSuccess implements Action {
  public readonly type = EEducationActions.GetEducationSuccess;

  constructor(
    public payload: {
      education: Education;
      expirationDate: Date;
      fileUrl?: string;
    }
  ) {}
}
export class GetEducationError implements Action {
  public readonly type = EEducationActions.GetEducationError;

  constructor(public error: Error) {}
}

export class AddEducation implements Action {
  public readonly type = EEducationActions.AddEducation;

  constructor(public payload: { education: Education; files?: File[] }) {}
}
export class AddEducationSuccess implements Action {
  public readonly type = EEducationActions.AddEducationSuccess;

  constructor(public payload: { education: Education }) {}
}
export class AddEducationFileError implements Action {
  public readonly type = EEducationActions.AddEducationFileError;

  constructor(public payload: { education: Education }, public error: Error) {}
}
export class AddEducationError implements Action {
  public readonly type = EEducationActions.AddEducationError;

  constructor(public error: Error) {}
}
export class AddEducationReset implements Action {
  public readonly type = EEducationActions.AddEducationReset;
  constructor() {}
}

export class UpdateEducation implements Action {
  public readonly type = EEducationActions.UpdateEducation;

  constructor(
    public payload: {
      education: Education;
      files?: File[];
      isDeletingFile?: boolean;
    }
  ) {}
}
export class UpdateEducationSuccess implements Action {
  public readonly type = EEducationActions.UpdateEducationSuccess;

  constructor(public payload: { education: Education }) {}
}
export class UpdateEducationFileError implements Action {
  public readonly type = EEducationActions.UpdateEducationFileError;

  constructor(public payload: {education: Education}, public error: Error) {}
}
export class UpdateEducationError implements Action {
  public readonly type = EEducationActions.UpdateEducationError;

  constructor(public error: Error) {}
}
export class UpdateEducationReset implements Action {
  public readonly type = EEducationActions.UpdateEducationReset;
  constructor() {}
}

export class DeleteEducation implements Action {
  public readonly type = EEducationActions.DeleteEducation;

  constructor(public payload: { id: string; qualificationId?: string }) {}
}

export class ResetEducationExpiryDate implements Action {
  public readonly type = EEducationActions.ResetEducationExpiryDate;

  constructor() {}
}

export class DeleteEducationSuccess implements Action {
  public readonly type = EEducationActions.DeleteEducationSuccess;

  constructor(public id: string) {}
}
export class DeleteEducationError implements Action {
  public readonly type = EEducationActions.DeleteEducationError;

  constructor(public error: Error) {}
}
export class DeleteEducationReset implements Action {
  public readonly type = EEducationActions.DeleteEducationReset;
  constructor() {}
}

export class DeleteEducationQualification implements Action {
  public readonly type = EEducationActions.DeleteEducationQualification;

  constructor(
    public payload: { educationId: string; qualificationId: string }
  ) {}
}

export class GetCreateDocumentObservable implements Action {
  public readonly type = EEducationActions.GetCreateDocumentObservable;

  constructor(public payload: { education: Education; files: File[] }) {}
}

export class GetUpdateDocumentObservable implements Action {
  public readonly type = EEducationActions.GetUpdateDocumentObservable;

  constructor(
    public payload: {
      education: Education;
      files: File[];
      isDeleting?: boolean;
      updateResponse: GenericCompletionReturnObject<number>;
    }
  ) {}
}

export type EducationActions =
  | GetEducationList
  | GetEducationListSuccess
  | GetEducationListError
  | GetEducation
  | GetEducationSuccess
  | GetEducationError
  | AddEducation
  | AddEducationSuccess
  | AddEducationError
  | AddEducationFileError
  | AddEducationReset
  | UpdateEducation
  | UpdateEducationSuccess
  | UpdateEducationError
  | UpdateEducationFileError
  | UpdateEducationReset
  | DeleteEducation
  | DeleteEducationSuccess
  | DeleteEducationError
  | DeleteEducationReset
  | DeleteEducationQualification
  | GetCreateDocumentObservable
  | GetUpdateDocumentObservable
  | ResetEducationExpiryDate;
