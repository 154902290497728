<div fxFlex fxLayout="column" id="main-content" fxLayoutGap="16px">
  <div fxLayout="column" class="top-section">
    <div *ngIf="data != null" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
      <mat-icon class="check material-icons md-48">check_circle</mat-icon>
      <div class="top-header">
        Great! We've got your {{data.name1}}
      </div>
      <button id="taskCompletionAddAnother" *ngIf="data.name2 !=  null" mat-flat-button class="full-width" color="primary" (click)="addAnother()">
        ADD ANOTHER {{data.name2 | uppercase}}
      </button>
      <button id="taskCompletionToTasks" *ngIf="_taskNav.returnToPayInfoJob" mat-flat-button class="full-width" color="primary" (click)="handleTasksNavigation()">
        SHOW ME PAY INFORMATION
      </button>
      <button id="taskCompletionSearchJobs" *ngIf="_taskNav.returnToApplyJob" mat-flat-button class="full-width" color="primary" (click)="handleTasksNavigation()">
        BACK TO APPLICATION
      </button>
      <button id="taskCompletionToTasks" *ngIf="!_taskNav.returnToPayInfoJob && !_taskNav.returnToApplyJob && data.name2 != null" mat-button class="full-width" color="primary" (click)="handleTasksNavigation()">
        BACK TO PROFILE TASKS
      </button>
      <button id="taskCompletionToTasks" *ngIf="!_taskNav.returnToPayInfoJob && !_taskNav.returnToApplyJob && data.name2 == null" mat-flat-button class="full-width" color="primary" (click)="handleTasksNavigation()">
        BACK TO PROFILE TASKS
      </button>
    </div>

    <div *ngIf="data == null" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
      <mat-icon class="check material-icons md-48">check_circle</mat-icon>
      <div class="top-header">
        Great! You've Completed All Your Tasks
      </div>
      <button id="taskCompletionSearchJobs" *ngIf="_taskNav.returnToPayInfoJob" mat-flat-button class="full-width" color="primary" (click)="handleTasksNavigation()">
        SHOW ME PAY INFORMATION
      </button>
      <button id="taskCompletionSearchJobs" *ngIf="_taskNav.returnToApplyJob" mat-flat-button class="full-width" color="primary" (click)="handleTasksNavigation()">
        BACK TO APPLICATION
      </button>
      <button id="taskCompletionSearchJobs" *ngIf="!_taskNav.returnToPayInfoJob && !_taskNav.returnToApplyJob" mat-flat-button class="full-width" color="primary" (click)="searchForJobs()">
        SEARCH FOR JOBS
      </button>
      <button id="taskCompletionHome" *ngIf="!_taskNav.returnToApplyJob" mat-button class="full-width" color="primary" (click)="goToHome()">
        HOME
      </button>
    </div>
  </div>
</div>