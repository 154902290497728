<div class="padding full-height" fxLayout="row">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" fxFlex="1 0 100%" class="info-card full-height">
    <mat-icon class="material-icons md-24 global-icon-gray">{{model.icon}}</mat-icon>
    <div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="2px">
      <div class="grey global-font-12">
        {{model.title | uppercase}}
      </div>
      <div class="global-font-14 global-weight-500">
        {{model.data}}
      </div>
    </div>

  </div>
</div>