import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HcinEducation, UserContextIN, HcinContactModel, IFileUploadOptions, FileCategoriesNames } from '../common';
import { CurrentAssigneeInformation } from '../common/models/recruiter';
import { ApiOptions } from './nurse-portal-api.service';
import { v4 as uuidv4 } from 'uuid';
import { DocumentUploaded } from '../store/segment/segment.actions';
import { IAppState } from '../store/app/app.state';
import { Store } from '@ngrx/store';


@Injectable({
    providedIn: 'root'
  })
  export class HCINPortalAPIService {
    private httpOptions: {
      headers?: HttpHeaders | {
          [header: string]: string | string[];
      };
  };
  
    constructor (private _http: HttpClient, private _store: Store<IAppState>) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': environment.internationalApimSubscriptionKey
        })
      };
    }

    private _determineFileName(
      options: IFileUploadOptions,
      file: File,
      counter: number): string {
      if (file.name.length > 50) {
        const fileExt = file.name.split('.').pop();
        if (options.itemId) {
          return `${options.itemId}_${counter}.${fileExt}`;
        } else {
          return `${uuidv4()}.${fileExt}`;
        }
      }
      return file.name;
    }
  
    private _getHeaders(options?: ApiOptions): HttpHeaders {
      let headers = new HttpHeaders();
  
      if (options) {
        if (options.showBusyIndicator === false) {
          headers = headers.append('ignoreProgressBar', '');
        }
  
        if (options.useServiceWorker === false) {
          headers = headers.append('ngsw-bypass', 'true');
        }
      }
  
      return headers;
    }

    getLookups(): Observable<any> {
      return this._http.get<any>(`${environment.internationalApiBaseUrl}/Lookups`,
      this.httpOptions);
    }

    getUser(): Observable<UserContextIN> {
      return this._http.get<UserContextIN>(`${environment.internationalApiBaseUrl}/UserIdentity/me`,
      this.httpOptions);
    }

    getCurrentAssigneeInformation(): Observable<CurrentAssigneeInformation> {
      return this._http.get<CurrentAssigneeInformation>(`${environment.internationalApiBaseUrl}/CurrentAssignee`);
    }

    getEducations(): Observable<HcinEducation[]> {
      return this._http.get<HcinEducation[]>(`${environment.internationalApiBaseUrl}/Education`);
    }

    getNurseState(): Observable<any> {
      return this._http.get<HcinContactModel>(`${environment.internationalApiBaseUrl}/Contacts`, this.httpOptions);
    }

    updateAboutMe(model: HcinContactModel): Observable<any> {
      return this._http.put<HcinContactModel>(`${environment.internationalApiBaseUrl}/Contacts`, model, this.httpOptions);
    }

    public fileUpload(
      options: IFileUploadOptions,
      files: Array<File>
    ): Observable<any> {
      const apiOptions = new ApiOptions().byPassServiceWorker();
      const formData = new FormData();
      const myHeaders = this._getHeaders(apiOptions);
      formData.append('options', JSON.stringify(options));
      files.forEach((o, counter) => {
        const newFileName = this._determineFileName(options, o, counter);
        formData.append('uploadedFile', o, newFileName);
      });
      return this._http
        .post<any>(`${environment.internationalApiBaseUrl}/Files`, formData, { headers: myHeaders, observe: 'response' })
        .pipe(
          tap((response) => {
            if (response.status == 200 || response.returnValue || response.result) {
              const docInfos = response.returnValue || response.result;
              if (Array.isArray(docInfos)) {
                docInfos.forEach((docInfo) => {
                  if (docInfo) {
                    const category = FileCategoriesNames.get(docInfo.category);
                    const payload = {
                      documentId: docInfo.id,
                      documentName: docInfo.fileName,
                      documentType: category,
                      documentURL: docInfo.fileDownloadUrl,
                    };
                    this._store.dispatch(new DocumentUploaded(payload));
                  }
                });
              } else if (docInfos?.id) {
                const category = FileCategoriesNames.get(docInfos.category);
                const payload = {
                  documentId: docInfos.id,
                  documentName: docInfos.fileName,
                  documentType: category,
                  documentURL: docInfos.fileDownloadUrl,
                };
                this._store.dispatch(new DocumentUploaded(payload));
              }
            }
          })
        );
    }
    
  }