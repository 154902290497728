import { Injectable } from '@angular/core';
import { extensionList, profileExtensionList } from 'src/app/common/models/extension-list';
import { MatDialog } from '@angular/material/dialog';
import { BadExtensionComponent } from 'src/app/components/shared/bad-extension-popup/bad-extension-popup.component';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { IFileUploadOptions, TaskCompletionReturnObject } from '../common';
import { NursePortalApi } from './nurse-portal-api.service';
import { BadProfileExtensionComponent } from '../components/shared/bad-extension-profile-popup/bad-extension-profile-popup.component';
import FileSaver from 'file-saver';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';



@Injectable()
export class DocumentHelperService {
  extList = extensionList;
  profileExtList = profileExtensionList;

  isNative:boolean = Capacitor.isNativePlatform();

  constructor(
    private _api: NursePortalApi,
    private _dialog: MatDialog,
    private fileOpener: FileOpener) { }

  handleFileInput(fileList: FileList, input: HTMLInputElement): File[] {
    const ext = fileList[0].name.substr(fileList[0].name.lastIndexOf('.') + 1).toLowerCase();
    if (!this.extList.includes(ext)) {
      input.value = null;
      this._dialog.open(BadExtensionComponent);
      return [];
    } else {
      if (fileList != null && fileList.length > 0) {
        return Array.from(fileList);
      }
    }
  }

  handleProfileInput(fileList: FileList, input: HTMLInputElement): File[] {
    const ext = fileList[0].name.substr(fileList[0].name.lastIndexOf('.') + 1).toLowerCase();
    if (!this.profileExtList.includes(ext)) {
      input.value = null;
      this._dialog.open(BadProfileExtensionComponent);
      return [];
    } else {
      if (fileList != null && fileList.length > 0) {
        return Array.from(fileList);
      }
    }
  }

  handleProfilePictureInput(file: File, input: HTMLInputElement): File[] {
    const ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    if (!this.profileExtList.includes(ext)) {
      input.value = null;
      this._dialog.open(BadProfileExtensionComponent);
      return [];
    } else if (file != null) {
      return [file];
    }
  }

  handleMultiFileInput(fileList: FileList, input: HTMLInputElement, front: boolean): File[] {
    const ext = fileList[0].name.substr(fileList[0].name.lastIndexOf('.') + 1).toLowerCase();
    if (!this.extList.includes(ext)) {
      input.value = null;
      this._dialog.open(BadExtensionComponent);
      return [];
    } else {
      if (fileList != null && fileList.length > 0) {
        if (front) {
          return Array.from(fileList);
        } else {
          return Array.from(fileList);
        } 
      }
    }
  }

  _getDocumentObservable(options: IFileUploadOptions, files: Array<File>): Observable<any> {
    const deleteOldFile = options?.isDeleting && options?.qualificationId;
    if (!files || files.length === 0) {
      if (deleteOldFile) {
        return forkJoin([of(new TaskCompletionReturnObject()), this._api.deleteQualification(options.qualificationId)]);
      }
      return of(new TaskCompletionReturnObject());
    }

    if (deleteOldFile) {
      return forkJoin([this._api.fileUpload(options, files), this._api.deleteQualification(options.qualificationId)]);
    }

    return this._api.fileUpload(options, files);
  }

  getFileBlob(data: Response, docName: string): Blob {
    const binarydata = [];
    binarydata.push(data.body);

    const split = docName.split('.');
    const extension = split[split.length - 1];
    let type = '';
    switch (extension) {
      case 'pdf': {
        type = 'application/pdf';
        break;
      }
      case 'doc': {
        type = 'application/msword';
        break;
      }
      case 'docx': {
        type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      }
      case 'png': {
        type = 'image/png';
        break;
      }
      case 'jpeg':
      case 'jpg': {
        type = 'image/jpeg';
        break;
      }
    }

    return new Blob(binarydata, { type: type });
  }

  convertBlobToBase64(blob: Blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async writeFileMobile(data: Blob, filename: string) {
    const base64File = await this.convertBlobToBase64(data) as string;
    const savedFile = await Filesystem.writeFile({
      path: filename,
      data: base64File,
      directory: Directory.Documents,
    });

    this.fileOpener.open(savedFile.uri, data.type);
  }

  async downloadFile(data: Response, docName: string) {
    const blob = this.getFileBlob(data, docName);
    if(this.isNative) {
      await this.writeFileMobile(blob, docName);
    } else {
      FileSaver.saveAs(blob, docName);
    }
  }

  getDocumentUrlSubject(document: string, id: string): BehaviorSubject<string> {
    const imageUrl = new BehaviorSubject(null);
    if (document) {
      this._api.getDocument(id).subscribe(o => {
        const fileBlob = this.getFileBlob(o, document);
        const reader = new FileReader();

        reader.readAsDataURL(fileBlob);
        reader.onloadend = function () {
          imageUrl.next(reader.result);
        };
      },
        (error: unknown) => console.log(error),
        () => { });
    }

    return imageUrl;
  }

  getDocument(document: string, id: string) {
    if (document != null) {
      this._api.getDocument(id).subscribe(o => {
        this.downloadFile(o, document);
      },
        (error: unknown) => console.log(error),
        () => { });
    }
  }

  getFileName(document: string): string {
    const fileName = document.split('/').pop();
    return fileName;
  }

  async fetchFile(url: RequestInfo) {
    const res = await fetch(url);
    return await res.blob();
  }

}
