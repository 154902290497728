import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { AppUrls } from 'src/app/app-urls';
import { NotificationService } from 'hc-design-system-lib';
import { taskEndpoints } from '../common/models/task-entities';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app/app.state';
import { GetDashboardInfo } from '../store/dashboard/dashboard.actions';
import { ErrorMessages } from '../common/error-messages';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private _notificationService: NotificationService,
    private _store: Store<IAppState>
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event) => {
          const response = event as HttpResponse<any>;
          if (
            (req.method === 'PUT' ||
              req.method === 'POST' ||
              req.method === 'DELETE') &&
            (response.status === 200 ||
              response.status === 204 ||
              response.status === 201) &&
            taskEndpoints.find((endpoint) => req.url.includes(endpoint)) !==
              undefined
          ) {
            this._store.dispatch(new GetDashboardInfo());
          }
          if (req.method === 'PUT' && event.type === 4) {
            if (response.status === 200) {
              if (!this.matchesIgnorableUrl(response.url)) {
                this._notificationService.showNotification(
                  'Your response was saved successfully.',
                  'success',
                  3500
                );
              }
            }
          }
        },
        (err: HttpErrorResponse) => {
          const config: MatSnackBarConfig = {
            duration: 10000,
          };
          if (err.status === 400 && err.error.modelState != null) {
            const errors = this._getValidationErrors(err.error);
            const msg = errors.join('\n');
            this._notificationService.showNotification(
              msg,
              'error',
              config.duration
            );
          } else if (
            err instanceof HttpErrorResponse &&
            err.url.indexOf('TimeAutomation') <= 0 &&
            (err.status === 400 || err.status >= 500)
          ) {
            this._notificationService.showNotification(
              ErrorMessages.httpError,
              'error',
              config.duration
            );
            console.log(err);
          }
        }
      )
    );
  }

  private matchesIgnorableUrl(url: string): boolean {
    const exceptions = [
      `/${AppUrls.PROFILE}/review-update`,
      '/job/update-job-alert',
      '/token',
      '/certifications/',
      '/license'
    ];
    let matches = false;
    for (let exception of exceptions) {
      if (url.includes(exception)) {
        matches = true;
        break;
      }
    }
    return matches;
  }

  private _getValidationErrors(error: any): string[] {
    let validationErrors: string[] = [];
    for (const errorArray in error.modelState) {
      if (error.modelState.hasOwnProperty(errorArray)) {
        validationErrors = error.modelState[errorArray];
      }
    }
    return validationErrors;
  }
}
