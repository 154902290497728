import {
  BodySize,
  DialogService,
  FileUploadComponent,
} from 'hc-design-system-lib';
import { IFile } from 'hc-design-system-lib/lib/components/form/file-upload/file-upload.interfaces';
import { IDropdownData } from 'hc-design-system-lib/lib/components/form/form.interfaces';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import {
  filter,
  skipWhile,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { Education, EducationForm, ILookup } from 'src/app/common';
import { convertIntoDropdownData } from 'src/app/common/functions/dropdown-helpers';
import { autocompleteValidator } from 'src/app/common/validators/autocompleteValidator';
import {
  selectEducationLoading,
  selectEducationResult,
  selectEducationFileUrl,
  selectDeleteEducationResult,
  selectAddEducationResult,
  selectUpdateEducationResult,
  selectEducationSaving,
} from 'src/app/store/education/education.selectors';
import { selectDegreeLookup } from 'src/app/store/lookups/lookups.selectors';
import {
  ButtonAppearance,
  ButtonColor,
} from 'hc-design-system-lib/lib/components/button/button.enums';
import {
  AddEducation,
  DeleteEducation,
  UpdateEducation,
} from 'src/app/store/education/education.actions';
import { AngularticsEventTrack } from 'src/app/store/angulartics2/angulartics2.actions';
import { defaultTruncationSettings, generateFileTruncationSettings } from 'src/app/common/functions/filename-manipulators';

import moment from 'moment';
import { selectIsMobile } from 'src/app/store/ui/ui.selectors';

@Component({
  selector: 'app-education-modal',
  templateUrl: './education-modal.component.html',
  styleUrls: ['./education-modal.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class EducationModalComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();

  // Used to clearFiles() if adding another since just resetting the control does not update the file upload component
  @ViewChild(FileUploadComponent) set fileUploadSetter(
    ref: FileUploadComponent
  ) {
    if (ref) {
      this.fileUpload = ref;
    }
  }
  private fileUpload: FileUploadComponent;

  // Used for resetForm since the validators do not reset properly with just a FormGroup .reset()
  // https://github.com/angular/components/issues/4190
  @ViewChild('formDirective') set formDirectiveSetter(ref: NgForm) {
    if (ref) {
      this.formDirective = ref;
    }
  }
  private formDirective: NgForm;

  bodySize = BodySize.Small;
  primaryButtonAppearance = ButtonAppearance.Primary;
  secondaryButtonAppearance = ButtonAppearance.Secondary;
  textButtonAppearance = ButtonAppearance.Text;
  redButton = ButtonColor.Red;
  showDeleteConfirmation: boolean = false;
  showWarningConfirmation: boolean = false;
  addingAnother: boolean = false;

  form: FormGroup<EducationForm>;
  education?: Education;
  hasQualificationOnLoad: boolean = false;
  prepopulatedFile: IFile;
  files: File[] = [];
  minDate = new Date(1900, 0, 1); // Date to restrict form to 4 year date
  maxDate = new Date();
  fileTruncationFunction = new BehaviorSubject(generateFileTruncationSettings());

  degrees: IDropdownData[];
  degreeLookup$: Observable<Map<number, ILookup<number>>> = this._store.pipe(
    select(selectDegreeLookup)
  );

  educationLoading$: Observable<boolean> = this._store.pipe(
    select(selectEducationLoading)
  );
  educationSaving$: Observable<boolean> = this._store.pipe(
    select(selectEducationSaving)
  );
  education$: Observable<Education> = this._store.pipe(
    select(selectEducationResult)
  );
  fileUrl$: Observable<string> = this._store.pipe(
    select(selectEducationFileUrl),
    filter((fileUrl: string) => fileUrl !== null)
  );
  isMobile$ = this._store.pipe(
    select(selectIsMobile)
  );
  addEducationResult$: Observable<string> = this._store.pipe(
    select(selectAddEducationResult),
    filter((response: string) => response !== null)
  );
  updateEducationResult$: Observable<string> = this._store.pipe(
    select(selectUpdateEducationResult),
    filter((response: string) => response !== null)
  );
  deleteEducationResult$: Observable<string> = this._store.pipe(
    select(selectDeleteEducationResult),
    filter((response: string) => response !== null)
  );

  constructor(
    private _store: Store,
    private _dialogService: DialogService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this._dialogService
      .updateOpenDialog({})
      ?.componentInstance.closeActionClicked.pipe(
        withLatestFrom(this.educationSaving$),
        skipWhile(([_click, educationSaving]) => educationSaving),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.showDeleteConfirmation) this.cancel();
      });

    this.initiateObservables();
    this.subscribeToIsMobileSelector();
    this.addEducationAddSubscription();
    this.addEducationUpdateSubscription();
    this.addEducationDeleteSubscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  subscribeToIsMobileSelector(): void {
    this.isMobile$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile) => {
        this.fileTruncationFunction.next(generateFileTruncationSettings({
          maxLength: isMobile? 
            defaultTruncationSettings.mobileMaxLength:
            defaultTruncationSettings.maxLength
          })
        );
      });
  }

  initiateObservables(): void {
    combineLatest([
      this.educationLoading$,
      this.education$,
      this.fileUrl$,
      this.degreeLookup$,
    ])
      .pipe(
        filter(
          ([educationLoading, , , degrees]) => !educationLoading && !!degrees
        ),
        take(1),
        tap(([, education, fileUrl, degrees]) => {
          this.education = education;
          this.degrees = Array.from(degrees.values(), (degree) =>
            convertIntoDropdownData(degree, 'name')
          );

          this.hasQualificationOnLoad = this.education?.sharepointURL !== '';
          if (education && education.sharepointURL && fileUrl) {
            this.prepopulatedFile = {
              fileName: education.sharepointURL.split('/').pop(),
              fileUrl: fileUrl,
            };
          }

          this.createForm(education);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  addEducationAddSubscription(): void {
    this.addEducationResult$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.addingAnother) {
        this.hasQualificationOnLoad = false;
        this.addingAnother = false;
        this.education = null;
        this.deleteUploadedFile();
        this.form.reset();
        this.formDirective.resetForm();
        this.fileUpload.clearFiles();
      } else {
        this._dialogService.closeAll();
      }
    });
  }

  addEducationUpdateSubscription(): void {
    this.updateEducationResult$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this._dialogService.closeAll());
  }

  addEducationDeleteSubscription(): void {
    this.deleteEducationResult$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this._dialogService.closeAll());
  }

  createForm(education?: Education): void {
    this.form = this._formBuilder.group<EducationForm>({
      degree: new FormControl(
        education?.degree
          ? this.degrees.find((d) => d.value.id === education?.degree)
          : null,
        {
          validators: [
            Validators.required,
            autocompleteValidator(this.degrees),
          ],
          nonNullable: true,
        }
      ),
      schoolAttended: new FormControl(education?.schoolAttended ?? '', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      graduationDate: new FormControl(
        education?.graduationDate
          ? moment(education?.graduationDate?.toString())
          : null,
        {
          validators: [Validators.required],
          nonNullable: true,
        }
      ),
      fileUpload: new FormControl(),
    });
  }

  deleteEducation(): void {
    const id = this.education.id;
    this._store.dispatch(
      new DeleteEducation({
        id,
        qualificationId: this.education?.qualificationId ?? null,
      })
    );
  }

  cancel(confirmed?: boolean): void {
    if (this.form?.dirty && !confirmed) {
      this.showWarning();
    } else {
      this._dialogService.closeAll();
    }
  }

  save(addingAnother?: boolean): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      if (addingAnother) {
        this.addingAnother = true;
      }
      const model = this.prepareSave();

      if (!this.education) {
        this._store.dispatch(
          new AngularticsEventTrack(
            'Save',
            'Complete Profile',
            'Added Education'
          )
        );
        this._store.dispatch(
          new AddEducation({
            education: JSON.parse(JSON.stringify(model)),
            files: this.files,
          })
        );
      } else {
        this._store.dispatch(
          new UpdateEducation({
            education: JSON.parse(JSON.stringify(model)),
            files: this.files,
            isDeletingFile: this.isDeletingFile,
          })
        );
      }
    }
  }

  prepareSave(): Education {
    const model = this.education ? { ...this.education } : new Education();

    if (this.isDeletingFile) {
      delete model.sharepointURL;
    }

    model.schoolAttended = this.form.controls.schoolAttended.value;
    model.graduationDate = new Date(
      this.form.controls.graduationDate.value.format('YYYY/MM/DD')
    );
    model.graduationDate.setDate(1); // set to first of the month
    model.degree = this.form.controls.degree.value.value.id;

    model.degreeStringName = this.form.controls.degree.value.value.name;

    // we don't use these anymore in OD, but the API requires them so just setting to empty string values + city specifically breaks the API if it's not at least a character
    model.city = this.education?.city ?? ' ';
    model.country = this.education?.country ?? '';
    model.state = this.education?.state ?? '';
    return model;
  }

  handleFileInput(event: HcEvent): void {
    if (event?.eventValue?.length) {
      this.files = Array.from(event.eventValue);
    } else {
      this.deleteUploadedFile();
    }
  }

  deleteUploadedFile(): void {
    this.files = [];
    this.prepopulatedFile = null;
  }

  get isDeletingFile(): boolean {
    return (
      this.hasQualificationOnLoad &&
      this.prepopulatedFile === null &&
      this.files.length === 0
    );
  }

  showDelete(): void {
    this.showDeleteConfirmation = !this.showDeleteConfirmation;
    if (this.showDeleteConfirmation) {
      this._dialogService
        .updateOpenDialog({
          title: 'Are you sure you want to delete this record?',
          separatedHeader: false,
          noStyling: false,
          isResponsive: false,
        })
        .componentInstance.closeActionClicked.pipe(
          withLatestFrom(this.educationSaving$),
          skipWhile(([_click, educationSaving]) => educationSaving),
          take(1),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.showDeleteConfirmation = !this.showDeleteConfirmation;
          this._dialogService.updateOpenDialog({
            title: 'Edit Education',
            separatedHeader: true,
            noStyling: true,
            isResponsive: true,
          });
        });
    } else {
      this._dialogService.updateOpenDialog({
        title: 'Edit Education',
        separatedHeader: true,
        noStyling: true,
        isResponsive: true,
      });
    }
  }

  showWarning(): void {
    this.showWarningConfirmation = !this.showWarningConfirmation;
    if (this.showWarningConfirmation) {
      this._dialogService.updateOpenDialog({
        title: 'Leave without saving?',
        separatedHeader: false,
        noStyling: false,
        isResponsive: false,
      });
    } else {
      this._dialogService.updateOpenDialog({
        title: `${this.education ? 'Edit' : 'Add'} Education`,
        separatedHeader: true,
        noStyling: true,
        isResponsive: true,
      });
    }
  }
}
