import { Component, OnInit, EventEmitter, Inject, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Interview } from 'src/app/common/models/interview-model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NursePortalApi } from 'src/app/services';

@Component({
  selector: 'app-interview-popup',
  templateUrl: './interview-popup.component.html',
  styleUrls: ['./interview-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InterviewPopupComponent implements OnInit {
  form: UntypedFormGroup;
  interview: Interview;
  saving: boolean = false;

  constructor(
    private _fb: UntypedFormBuilder,
    private _api: NursePortalApi,
    public dialogRef: MatDialogRef<InterviewPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Interview
  ) {}

  ngOnInit() {
    this.interview = this.data;
    this._createForm();
  }

  confirm() {
    this.saving = true;
    const model = this._prepareSave();
    this._api.updateInterviewNotes(model).subscribe(
      (o) => {
        this.saving = false;
        this.dialogRef.close(model);
      },
      (err) => {
        this.saving = false;
        console.log(err);
      },
      () => {}
    );
  }

  cancel() {
    this.dialogRef.close();
  }

  private _createForm() {
    this.form = this._fb.group({
      assignmentid: new UntypedFormControl(this.interview.assignmentid, [Validators.required]),
      interviewDate: new UntypedFormControl(this.interview.interviewDate, [Validators.required]),
      interviewer: new UntypedFormControl(this.interview.interviewer, [Validators.required]),
      interviewNotes: new UntypedFormControl(this.interview.interviewNotes, [Validators.required]),
    });
  }

  private _prepareSave(): Interview {
    const model = this.form.value as Interview;
    return model;
  }
}
