import { createSelector } from '@ngrx/store';
import { checkIfDateIsAfter } from 'src/app/common/functions/date-manipulations';
import { IAppState } from 'src/app/store/app/app.state';
import { IFlagsState } from './flags.state';

const selectFlagsState = (state: IAppState) => state.flags;

export const selectFlags = createSelector(
  selectFlagsState,
  (state: IFlagsState) => state.appFlags.data
);

export const selectFlagsLoading = createSelector(
  selectFlagsState,
  (state: IFlagsState) => state.appFlags.loading
);

export const selectSpecificFlag = ((flagName: string) => createSelector(
  selectFlagsState,
  (state: IFlagsState) => state.appFlags.data ? state.appFlags.data.get(flagName) : null
));

export const selectIsSpecificFlagOn = ((flagName: string) => createSelector(
  selectFlagsState,
  (state: IFlagsState) => {
    if (!state.appFlags.data) {
      return null;
    }
    const flag = state.appFlags.data.get(flagName)
    if (!flag) {
      return false;
    }
    return flag.enabled && flag.variation.toLowerCase() === "on";
  }
));

export const selectIsSpecificExperimentOnTreatment = ((flagName: string) => createSelector(
  selectFlagsState,
  (state: IFlagsState) =>  {
    if(!state.appFlags.data) {
      return null;
    }
    const flag = state.appFlags.data.get(flagName)
    if(!flag) {
      return false;
    }
    return flag.variation.toLowerCase() === "treatment";
  }
));

export const selectFlagsExpired = createSelector(
  selectFlagsState,
  (state: IFlagsState) => {
    return checkIfDateIsAfter(new Date(), state.appFlags?.expirationDate);
  }
);

export const selectFlagsExpirationDate = createSelector(
  selectFlagsState,
  (state: IFlagsState) => {
    return state.appFlags?.expirationDate;
  }
)


