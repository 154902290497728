import { FormControl } from '@angular/forms';
import { ZipCodeModel } from './zip-code-model';
import { IDropdownData } from 'hc-design-system-lib/lib/components/form/form.interfaces';

export class PreEmploymentQuestionnaire {
  id: string; // GUID
  workAuthorization: number;
  licenseRevoked: number;
  negligenceLiability: number;
  restrictions: number;
  workExperience: number;
  voluntaryDisclosures: number;
  licenseRevokedDetails: string;
  negligenceLiabilityDetails: string;
  restrictionsDetails: string;
  workExperienceDetails: string;
  voluntaryDisclosuresDetails: string;
  taxResidence: number;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipCode: string;
  zip: ZipCodeModel;
  signature: string;
}

export class PreEmploymentQuestionnaireForm {
  workAuthorization: FormControl<number>;
  licenseRevoked: FormControl<number>;
  licenseRevokedDetails: FormControl<string>;
  negligenceLiability: FormControl<number>;
  negligenceLiabilityDetails: FormControl<string>;
  restrictions: FormControl<number>;
  restrictionsDetails: FormControl<string>;
  workExperience: FormControl<number>;
  workExperienceDetails: FormControl<string>;
  voluntaryDisclosures: FormControl<number>;
  voluntaryDisclosuresDetails: FormControl<string>;
  taxResidence: FormControl<number>;
  streetAddress1: FormControl<string>;
  streetAddress2: FormControl<string>;
  city: FormControl<string>;
  state: FormControl<IDropdownData>;
  zipCode: FormControl<IDropdownData>;
  signature: FormControl<string>;
}
