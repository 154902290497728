import { Injectable, OnDestroy } from '@angular/core';
import { IJob, IJobFilter, ILookups } from '../common';
import { NursePortalApi } from './nurse-portal-api.service';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SimilarJobSeenIds } from '../common/models/similar-jobs-manager';
import { select, Store } from '@ngrx/store';
import { selectLookups } from '../store/lookups/lookups.selectors';
import { IAppState } from '../store/app/app.state';
import { BadgeColor } from 'hc-design-system-lib';
import { JobBadgeData, JobBadge } from '../common/models/job-badge-data';
import { ContractType } from '../common/contracts/contract-type';
import { LOCAL_BADGE, TRAVEL_BADGE } from '../common/models/badge';


@Injectable({
  providedIn: 'root',
})
export class JobAreaContext implements OnDestroy {
  
  //TODO: remove these
  selectedJob: IJob = null;
  isSelectedJobReccomended: boolean = false;
  filters: IJobFilter;
  seenJobsMap: Map<string, IJob> = new Map();
  seenJobsQueue: string[] = [];
  seenJobsSize = 10;
  seenIds = new SimilarJobSeenIds();


  lookups: ILookups;
  lookupsSubscription: Subscription;
  lookups$: Observable<ILookups> = this._store.pipe(
    select(selectLookups)
  );

  constructor(
    private _api: NursePortalApi, //TODO: remove
    private _store: Store<IAppState>
  ) {
    this.initializeLookups();
  }

  ngOnDestroy(): void {
      this.lookupsSubscription?.unsubscribe();
  }

  initializeLookups(): void {
    this.lookupsSubscription = this.lookups$.subscribe((lookups) => {
      this.lookups = lookups;
    });
  }

  //TODO: remove
  getJob(id: string): Observable<any> {
    if (this.seenJobsMap.get(id) == null) {
      const obs = this._api.getJob(id);
      return obs.pipe(
        tap((o: IJob) => {
          this.pushJob(o);
          this.selectedJob = o;
        })
      );
    } else {
      this.selectedJob = this.seenJobsMap.get(id);
      return of(this.selectedJob);
    }
  }

  //TODO: remove
  pushJob(job: IJob): void {
    if (this.seenJobsMap.size === this.seenJobsSize) {
      // Remove the oldest item from the stack of seen jobs
      const oldestId = this.seenJobsQueue.shift();
      this.seenJobsMap.delete(oldestId);
    }
    this.seenJobsQueue.push(job.id);
    this.seenJobsMap.set(job.id, job);
  }

  getSpecialty(id: string, shortForm: boolean = true): string {
    const x = this.lookups.specialtyLookup.get(id);
    return x != null ? (shortForm ? x.shortName : x.name) : '';
  }

  getShift(id: number, anyShift: boolean = false): string {
    // Update this to correctly map shifts
    const x = this.lookups.shiftLookup.get(id);
    return x != null ? (!anyShift ? x.name : 'n/a') : 'Any Shift';
  }

  getHoursPerWeek(id: number): any {
    return this.lookups.weeklyHoursLookup.get(this.convertToHoursPerWeek(id));
  }

  convertToHoursPerWeek(input: number): any {
    if (input < 36) {
      return 948050000;
    } else if (input === 36) {
      return 948050001;
    } else if (input === 40) {
      return 948050002;
    } else if (input === 48) {
      return 948050003;
    } else if (input > 48) {
      return 948050004;
    }
  }

  getProfession(id: string, longForm: boolean = false): string {
    const x = this.lookups.professionLookup.get(id);
    return x != null ? (!longForm ? x.shortName : x.name) : 'No Profession';
  }

  getFrequency(id: number): string {
    const x = this.lookups.frequencyLookup.get(id);
    return x != null ? x.name : 'No Frequency';
  }

  getState(id: string, abbrev: boolean = true, nationWide = false): string {
    const x = this.lookups.stateLookup.get(id);
    return x != null ? (abbrev ? x.name : x.code) : nationWide ? 'Nationwide' : 'NO STATE';
  }

  //TODO: remove
  setFilters(model: IJobFilter) {
    this.filters = model;
  }

  isNullOrEmpty(thing: any): boolean {
    if (typeof thing === 'string') {
      return thing.trim() === '' || thing == null;
    } else {
      return thing == null;
    }
  }

  getJobImageBadges(isSpecificJob: boolean, job: IJob): JobBadgeData[] {
    let badges = this.getJobBadges(isSpecificJob, job);
    badges = badges.slice(0, 3); // take only up to the first three badges

    badges.forEach(badge => {
      switch(badge.badge.title) {
        case JobBadge.ANCCMagnet:
          badge.imagePath = '/assets/img/job-search-badges/Badge_ANCCMagnet.svg';
          break;
        case JobBadge.Crisis:
          badge.imagePath = '/assets/img/job-search-badges/Badge_Crisis.svg';
          break;
        case JobBadge.FirstTimeTraveler:
          badge.imagePath = '/assets/img/job-search-badges/Badge_FirstTimeTraveler.svg';
          break;
        case JobBadge.JointCommission:
          badge.imagePath = '/assets/img/job-search-badges/Badge_JointCommission.svg';
          break;
        case JobBadge.PPUSAAdvance:
          badge.imagePath = '/assets/img/job-search-badges/Badge_PPUSAAdvance.svg';
          break;
        case JobBadge.Teaching:
          badge.imagePath = '/assets/img/job-search-badges/Badge_Teaching.svg';
          break;
        case JobBadge.Trauma:
          badge.imagePath = '/assets/img/job-search-badges/Badge_Trauma.svg';
          break;
        default:
          break;
      }
    });

    return badges;
  }

  getJobBadges(isSpecificJob: boolean, job: IJob): JobBadgeData[] {
    let jobBadges: JobBadgeData[] = [];

    // Display the “Crisis” badge when the Job Order field “hc_hotjjob is “Yes”.
    if (this.lookups.yesNoIdLookup.get(job.crisis) === 'Yes') {
      jobBadges.push(
        {
          badge: { title: JobBadge.Crisis, color: BadgeColor.Red },
          sortPriority: 100 + (this.filters != null && this.filters.crisis ? 100 : 0),
        }
      );
    }
    // Display the “First Travelers” badge when there is a "Submittal" Compliance Requirement
    // with the Requirement Name of "Travel Experience Not Required" OR “Travel Experience Preferred”.
    if (this.lookups.yesNoIdLookup.get(job.firstTimeTraveler) === 'Yes') {
      let sortMod = 0;
      if (this.filters !== undefined && this.filters.firstTimeTraveler) { sortMod = 100; }
      jobBadges.push(
        {
          badge: { title: JobBadge.FirstTimeTraveler, color: BadgeColor.Green },
          sortPriority: 95 + sortMod
        }
      );
    }
    // Display the “PPUSA Advance” badge when the Job Order field hc_ppusaadvancecode is “Yes”.
    if (job.ppUsaAdvanceCode === 948050000) {
      jobBadges.push(
        {
          badge: { title: JobBadge.PPUSAAdvance, color: BadgeColor.PrimaryBlue },
          sortPriority: 94
        }
      );
    }

    if (!isSpecificJob) {
      // Display the “Joint Commission Accreditation” badge when the Account field “hc_jointcommissionaccreditationdate” is not NULL.
      if (job.hospitalInfo?.jointCommission === true) {
        jobBadges.push(
          {
            badge: { title: JobBadge.JointCommission, color: BadgeColor.Yellow },
            sortPriority: 99
          }
        );
      }
      // Display the “Trauma Center” badge when the Account field “hc_traumacentercode” is not NULL.
      if (job.hospitalInfo?.traumaLevel !== undefined) {
        let sortMod = 0;
        if (this.filters !== undefined && this.filters.traumaLevel) { sortMod = 100; }
        jobBadges.push(
          {
            badge: { title: JobBadge.Trauma, color: BadgeColor.Purple },
            sortPriority: 98 + sortMod
          }
        );
      }
      // Display the “Teaching” badge when the Account field “hc_teachingfacility” is “Yes”.
      if (this.lookups.yesNoIdLookup.get(job.hospitalInfo?.teachingFacility) === 'Yes') {
        let sortMod = 0;
        if (this.filters !== undefined && this.filters.teachingFacility) { sortMod = 100; }
        jobBadges.push(
          {
            badge: { title: JobBadge.Teaching, color: BadgeColor.Red },
            sortPriority: 97 + sortMod
          }
        );
      }
      // Display the “ANCC Magnet Status” badge when the Account field “hc_anccmagnethospitalcode” is “Yes”.
      if (job.hospitalInfo?.awards === 'ANCC') {
        jobBadges.push(
          {
            badge: { title: JobBadge.ANCCMagnet, color: BadgeColor.Green },
            sortPriority: 96
          }
        );
      }
    }

    if (job.contractType != null) {
      if (job.contractType == ContractType.Travel) {
        jobBadges.push(
        {
          badge: TRAVEL_BADGE,
          sortPriority: 101
        }
      );
      } else {
        jobBadges.push(
          {
            badge: LOCAL_BADGE,
            sortPriority: 101
          }
        );
      }
    }

    // Sort the results
    jobBadges.sort((a, b) => b.sortPriority - a.sortPriority);

    return jobBadges;
  }
}
