import { UserContext } from './user-context.service';
import { NursePortalApi } from './nurse-portal-api.service';
import { NavHelper } from './nav-helper.service';
import { VersionService } from './version.service';

import { AppState } from './app-state.service';
import { PopupService } from '../common/popup/popup.service';
import { JobAreaContext } from './job-area-context.service';
import { FormHeaderService } from './form-header.service';
import { DocumentHelperService } from './document-helper-service';
import { BasicSnackBarService } from './basic-snack-bar.service';
import { UsersApiService } from './users-api.service';
import { FeatureFlagService } from './feature-flags.service';
import { SegmentService } from './segment.service';
import { GuestApiService } from './guest-api.service';
import { AuthenticationService } from './authentication.service';
import { HCINPortalAPIService } from './hcin-portal-api.service';
export { AppState } from './app-state.service';

export * from './user-context.service';
export * from './nurse-portal-api.service';
export * from './nav-helper.service';
export * from './form-header.service';
export * from './version.service';
export * from './authentication.service';
export * from './document-helper-service';
export * from './hcin-portal-api.service';
export * from './users-api.service';

export const services = [
    AuthenticationService,
    UserContext,
    NursePortalApi,
    NavHelper,
    VersionService,
    AppState,
    PopupService,
    JobAreaContext,
    FormHeaderService,
    DocumentHelperService,
    BasicSnackBarService,
    UsersApiService,
    FeatureFlagService,
    SegmentService,
    GuestApiService,
    HCINPortalAPIService
];
