import { ERecruiterActions, RecruiterActions } from "./recruiter.actions";
import { initialRecruiterState, IRecruiterState } from "./recruiter.state";

export function recruiterReducer(
  state = initialRecruiterState,
  action: RecruiterActions
): IRecruiterState {
  switch (action.type) {
    case ERecruiterActions.GetRecruiter:
      return {
        ...state,
        recruiterData: {
          ...state.recruiterData,
          error: null,
          loading: true
        }
      };

    case ERecruiterActions.GetRecruiterSuccess:
      return {
        ...state,
        recruiterData: {
          data: action.recruiterData,
          error: null,
          loading: false
        }
      };

    case ERecruiterActions.GetRecruiterError:
      return {
        ...state,
        recruiterData: {
          ...state.recruiterData,
          error: action.error,
          loading: false
        }
      };

    case ERecruiterActions.GetPayRecruiter:
      return {
        ...state,
        payRecruiter: {
          ...state.payRecruiter,
          error: null,
          loading: true
        }
      };
    case ERecruiterActions.GetPayRecruiterSuccess:
      return {
        ...state,
        payRecruiter: {
          data: action.response,
          error: null,
          loading: false
        }
      };
    case ERecruiterActions.GetPayRecruiterError:
      return {
        ...state,
        payRecruiter: {
          ...state.payRecruiter,
          error: action.error,
          loading: false
        }
      };
    case ERecruiterActions.GetDefaultReferralRecruiter:
      return {
        ...state,
        defaultReferralRecruiterData: {
          ...state.defaultReferralRecruiterData,
          error: null,
          loading: true
        }
      };
    case ERecruiterActions.GetDefaultReferralRecruiterSuccess:
      return {
        ...state,
        defaultReferralRecruiterData: {
          data: action.recruiterData,
          error: null,
          loading: false
        }
      };
    case ERecruiterActions.GetDefaultReferralRecruiterError:
      return {
        ...state,
        defaultReferralRecruiterData: {
          ...state.defaultReferralRecruiterData,
          error: action.error,
          loading: false
        }
      };

      case ERecruiterActions.GetCurrentAssigneeIN:
        return {
          ...state,
          currentAssigneeIN: {
            ...state.currentAssigneeIN,
            error: null,
            loading: true
          }
        };

      case ERecruiterActions.GetCurrentAssigneeINSuccess:
        return {
          ...state,
          currentAssigneeIN: {
            data: action.currentAssigneeIN,
            error: null,
            loading: false
          }
        };

      case ERecruiterActions.GetCurrentAssigneeINError:
        return {
          ...state,
          currentAssigneeIN: {
            ...state.currentAssigneeIN,
            error: action.error,
            loading: false
          }
        };

    default:
      return state;
  }
}
