import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavHelper } from 'src/app/services';
import { TaskCompletionPopUpReturnIds } from 'src/app/common';
import { TaskNavigatorService } from 'src/app/services/task-navigator.service';
import { ActivatedRoute } from '@angular/router';

export class TaskCompletionPopUpComponentDataObject {
  name1: string;
  name2?: string;
}

@Component({
  selector: 'app-task-completion-pop-up',
  templateUrl: './task-completion.pop-up.html',
  styleUrls: ['./task-completion.pop-up.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TaskCompletionPopUpComponent {
  constructor(
    public dialogRef: MatDialogRef<TaskCompletionPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _navHelper: NavHelper,
    private _taskNav: TaskNavigatorService,
    public _route: ActivatedRoute
  ) {
  }

  /*
    Refer to Task-Navigator Service for Task Navigation Path Information
  */

  addAnother(): void {
    this.dialogRef.close(TaskCompletionPopUpReturnIds.addAnother);
  }

  cancel(): void {
    if (this._taskNav.checkPaySessionData()) {
      this._taskNav.postTaskNavigationCancel();
      this.dialogRef.close(TaskCompletionPopUpReturnIds.payTaskNavigation);
    } else {
      this.dialogRef.close(TaskCompletionPopUpReturnIds.close);
    }
  }

  goToHome(): void {
    this._navHelper.goToDashBoard();
    this.dialogRef.close(TaskCompletionPopUpReturnIds.goToDashBoard);
  }

  handleTasksNavigation(): void {
    this.dialogRef.close(this._taskNav.postTaskNavigation());
  }

  searchForJobs(): void {
    this._navHelper.goToJobSearch();
    this.dialogRef.close(TaskCompletionPopUpReturnIds.searchForJobs);
  }
}
