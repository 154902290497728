import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from "rxjs";
import { catchError, concatMap, exhaustMap, map } from "rxjs/operators";
import { NursePortalApi } from "src/app/services";
import { ELocationActions, LocationActions, GetLocationSuccess, GetLocationError, GetLocation } from "./location.actions";

@Injectable({
  providedIn: 'root'
})
export class LocationEffects {
  constructor(private actions$: Actions, private _api: NursePortalApi,
  ) { }

  getLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LocationActions>(ELocationActions.GetLocation),
      map((action: GetLocation) => action.input),
      concatMap((input: string) =>
        this._api.getLocationsByName(input).pipe(
          map((location: any []) => new GetLocationSuccess(location)),
          catchError((error: Error) => of(new GetLocationError(error))),
        )
      )
    );
  });
}
