export enum FileUploadTargets {
    Bulk = 0,
    Certification = 1,
    WorkHistory = 2,
    License = 3,
    Education = 4,
    ProfilePicture = 5,
    CovidVaccination = 6,
    FluVaccination = 7,
}

export interface IFileUploadOptions {
    itemId?: string;
    qualificationId?: string;
    isDeleting?: boolean;
    target: FileUploadTargets;
}

export enum FileCategories {
    BackgroundAndVerifications = 948050001,
    Employment = 948050005,
    FacilitySpecificRequirements = 948050004,
    LicenseAndCertifications = 948050000,
    MedicalAndOccupationalHealth = 948050003,
    SkillsTestingAndTraining = 948050002,
    Other = 0
}

export const FileCategoriesNames = new Map<number, string>([
    [FileCategories.BackgroundAndVerifications, 'Background and Verifications'],
    [FileCategories.Employment, 'Employment'],
    [FileCategories.FacilitySpecificRequirements, 'Facility Specific Requirements'],
    [FileCategories.LicenseAndCertifications, 'License and Certifications'],
    [FileCategories.MedicalAndOccupationalHealth, 'Medical and Occupational Health'],
    [FileCategories.SkillsTestingAndTraining, 'Skills Testing and Training'],
    [FileCategories.Other, 'Other']
])