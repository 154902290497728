export const timeMobileQuickLink = {
  icon: 'access_time',
  text: 'Time Keeping',
};
export const expenseManagementQuickLink = {
  icon: 'attach_money',
  text: 'Expenses',
};
export const shareDocumentsQuickLink = {
  icon: 'folder_open',
  text: 'Share Documents',
};
export const jobPreferencesQuickLink = {
  icon: 'assignment',
  text: 'Job Preferences',
};
export const contactPreferencesQuickLink = {
  icon: 'contact_mail',
  text: 'Contact Preferences',
};
