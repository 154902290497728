import { Component, OnInit, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit {
  @Input() onConfirm: EventEmitter<DeleteConfirmationContext>;
  show: boolean;
  context: DeleteConfirmationContext;

  ngOnInit() {
    this.onConfirm.subscribe(e => {
      this.context = e;
      this.show = true;
    });
  }

  confirm() {
    this.show = false;
    this.context.onConfirm();
  }

  cancel() {
    this.show = false;
  }
}

export class DeleteConfirmationContext {
  title: string;
  text: string;
  onConfirm: () => void;
}