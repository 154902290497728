<a mat-list-item [ngStyle]="{'padding-left': (depth * 0) + 'px'}" (click)="onItemSelected(item)" [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}"
    class="menu-list-item">
    <mat-icon class="routeIcon">{{item.iconName}}</mat-icon>
    {{item.displayName}}
    <span fxFlex *ngIf="item.children && item.children.length">
        <span fxFlex></span>
        <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
            expand_more
        </mat-icon>
    </span>
</a>
<div *ngIf="expanded">
    <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
    </app-menu-list-item>
</div>