import { Action } from '@ngrx/store';
import { CandidateSkill } from 'src/app/common/models/candidate-skill';
import { CandidateSkillSummary } from 'src/app/common/models/candidate-skill-summary-model';
import { Question } from 'src/app/common/models/question';
import { Skill } from 'src/app/common/models/skill';
import { SkillDictionary } from 'src/app/common/models/skill-dictionary';
import { SkillSummary } from 'src/app/common/models/skill-summary';

export enum ESkillsChecklistActions {
  GetSkill = '[SKILL] get skill',
  GetSkillSuccess = '[SKILL] get skill success',
  GetSkillError = '[SKILL] get skill error',
  GetSkills = '[SKILLS_CHECKLIST] get skills summary',
  GetSkillsSuccess = '[SKILLS_CHECKLIST] get skills summary success',
  GetSkillsError = '[SKILLS_CHECKLIST] get skills summary error',
  GetCandidateSkill = '[SKILL] get candidate skill',
  GetCandidateSkillSuccess = '[SKILL] get candidate skill success',
  GetCandidateSkillError = '[SKILL] get candidate skill error',
  GetCandidateSkills = '[SKILLS_CHECKLIST] get candidate skills summary',
  GetCandidateSkillsSuccess = '[SKILLS_CHECKLIST] get candidate skills summary success',
  GetCandidateSkillsError = '[SKILLS_CHECKLIST] get candidate skills summary error',
  GetSkillDictionary = '[SKILLS CHECKLIST] get skill dictionary',
  GetSkillDictionarySuccess = '[SKILLS CHECKLIST] get skill dictionary success',
  GetSkillDictionaryError = '[SKILLS CHECKLIST] get skill dictionary error',
  GetNurseApiSkillQuestions = '[SKILL] get nurse api skill questions',
  GetNurseApiSkillQuestionsSuccess = '[SKILL] get nurse api skill questions success',
  GetNurseApiSkillQuestionsError = '[SKILL] get nurse api skill questions error',
  UpdateSkillQuestions = '[SKILL] update nurse api skill question',
  UpdateSkillQuestionsSuccess = '[SKILL] update nurse api skill question success',
  UpdateSkillQuestionsError = '[SKILL] update nurse api skill question error',
  UpdateSkillDictionaryFilter = '[SKILLS CHECKLIST] update skill dictionary filter',
}

export class GetSkill implements Action {
  public readonly type = ESkillsChecklistActions.GetSkill;

  constructor(public payload: number) {}
}
export class GetSkillSuccess implements Action {
  public readonly type = ESkillsChecklistActions.GetSkillSuccess;

  constructor(public response: Skill) {}
}
export class GetSkillError implements Action {
  public readonly type = ESkillsChecklistActions.GetSkillError;

  constructor(public error: Error) {}
}

export class GetSkills implements Action {
  public readonly type = ESkillsChecklistActions.GetSkills;
}
export class GetSkillsSuccess implements Action {
  public readonly type = ESkillsChecklistActions.GetSkillsSuccess;

  constructor(public payload: SkillSummary[]) {}
}
export class GetSkillsError implements Action {
  public readonly type = ESkillsChecklistActions.GetSkillsError;

  constructor(public error: Error) {}
}

export class GetCandidateSkill implements Action {
  public readonly type = ESkillsChecklistActions.GetCandidateSkill;

  constructor(public payload: string) {}
}
export class GetCandidateSkillSuccess implements Action {
  public readonly type = ESkillsChecklistActions.GetCandidateSkillSuccess;

  constructor(public response: CandidateSkill) {}
}
export class GetCandidateSkillError implements Action {
  public readonly type = ESkillsChecklistActions.GetCandidateSkillError;

  constructor(public error: Error) {}
}

export class GetCandidateSkills implements Action {
  public readonly type = ESkillsChecklistActions.GetCandidateSkills;
}
export class GetCandidateSkillsSuccess implements Action {
  public readonly type = ESkillsChecklistActions.GetCandidateSkillsSuccess;

  constructor(public payload: Map<number, CandidateSkillSummary>) {}
}
export class GetCandidateSkillsError implements Action {
  public readonly type = ESkillsChecklistActions.GetCandidateSkillsError;

  constructor(public error: Error) {}
}

export class GetSkillDictionary implements Action {
  public readonly type = ESkillsChecklistActions.GetSkillDictionary;
}
export class GetSkillDictionarySuccess implements Action {
  public readonly type = ESkillsChecklistActions.GetSkillDictionarySuccess;

  constructor(public payload: SkillDictionary) {}
}
export class GetSkillDictionaryError implements Action {
  public readonly type = ESkillsChecklistActions.GetSkillDictionaryError;

  constructor(public error: Error) {}
}

export class GetNurseApiSkillQuestions implements Action {
  public readonly type = ESkillsChecklistActions.GetNurseApiSkillQuestions;

  constructor(public payload: string) {}
}
export class GetNurseApiSkillQuestionsSuccess implements Action {
  public readonly type =
    ESkillsChecklistActions.GetNurseApiSkillQuestionsSuccess;

  constructor(public response: Question[]) {}
}
export class GetNurseApiSkillQuestionsError implements Action {
  public readonly type = ESkillsChecklistActions.GetNurseApiSkillQuestionsError;

  constructor(public error: Error) {}
}

export class UpdateSkillQuestions implements Action {
  public readonly type = ESkillsChecklistActions.UpdateSkillQuestions;

  constructor(
    public skillForm: CandidateSkill,
    public candidateSkillId?: string,
    public skillVersion?: number
  ) {}
}
export class UpdateSkillQuestionsSuccess implements Action {
  public readonly type = ESkillsChecklistActions.UpdateSkillQuestionsSuccess;

  constructor(public response?: CandidateSkill) {}
}
export class UpdateSkillQuestionsError implements Action {
  public readonly type = ESkillsChecklistActions.UpdateSkillQuestionsError;

  constructor(public error: Error) {}
}

export class UpdateSkillDictionaryFilter implements Action {
  public readonly type = ESkillsChecklistActions.UpdateSkillDictionaryFilter;

  constructor(public filter: string) {}
}

export type SkillsChecklistActions =
  | GetSkill
  | GetSkillSuccess
  | GetSkillError
  | GetSkills
  | GetSkillsSuccess
  | GetSkillsError
  | GetCandidateSkill
  | GetCandidateSkillSuccess
  | GetCandidateSkillError
  | GetCandidateSkills
  | GetCandidateSkillsSuccess
  | GetCandidateSkillsError
  | GetSkillDictionary
  | GetSkillDictionarySuccess
  | GetSkillDictionaryError
  | GetNurseApiSkillQuestions
  | GetNurseApiSkillQuestionsSuccess
  | GetNurseApiSkillQuestionsError
  | UpdateSkillQuestions
  | UpdateSkillQuestionsSuccess
  | UpdateSkillQuestionsError
  | UpdateSkillDictionaryFilter;
