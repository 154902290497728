<div
  class="job-search-layout"
  [class.extra-padding]="(recruiter$ | async)?.userId"
  [class.overflow]="(jobFilter$ | async) === null"
>
  <app-job-search-form id="job-search-form"></app-job-search-form>
  <app-job-search-results-v2
    id="job-search-results"
  ></app-job-search-results-v2>
</div>
