import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-error',
  templateUrl: './snack-bar-error.component.html',
  styleUrls: ['./snack-bar-error.component.scss']
})
export class SnackBarErrorComponent implements OnInit {

  constructor(public sbRef: MatSnackBarRef<SnackBarErrorComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) {
      this.message = data.message;
      this.email = data.email;
     }

    message: string;
    email: string;
  ngOnInit(): void {
  }

}
