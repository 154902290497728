import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IJob, IJobFilterAPI, ILookups } from 'src/app/common';
import { IAppState } from 'src/app/store/app/app.state';
import {
  selectAvailableJobs,
  selectAvailableJobsLoading,
  selectJobFilterV2,
  selectSavedJobs,
  selectSimilarJobs,
  selectSimilarJobsLoading,
} from 'src/app/store/jobs/jobs.selectors';
import { selectIsMobileNonTablet } from 'src/app/store/ui/ui.selectors';
import { CardComponentConfig } from '../../cards/card-template/card-template.component';
import {
  GetAvailableJobs,
  SetJobFilterV2,
  SetSavedAvailableJob,
} from 'src/app/store/jobs/jobs.actions';
import { NavHelper } from 'src/app/services';
import { ContractType } from 'src/app/common/contracts/contract-type';
import { JobDataModel } from 'src/app/common/models/job-data-model';
import {
  CardElevation,
  DialogService,
  IDialogParameters,
  NotificationService,
} from 'hc-design-system-lib';
import { ICardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { mapJobToCardConfig } from '../../job-card-utils/job-card-utils';
import { LOCAL_BADGE, TRAVEL_BADGE } from 'src/app/common/models/badge';
import { JobAreaContext } from 'src/app/services/job-area-context.service';
import {
  selectLookups,
  selectLookupsLoading,
} from 'src/app/store/lookups/lookups.selectors';
import { Recruiter } from 'src/app/common/models/recruiter';
import { selectRecruiterData } from 'src/app/store/recruiter/recruiter.selectors';
import { BodySize } from 'hc-design-system-lib/lib/typography/components/body/body.component';
import { HeadingSize } from 'hc-design-system-lib/lib/typography/components/heading/heading.component';
import { selectCandidateFacilitiesCount } from '../../../../store/facilities/facilities.selectors';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';

export const enum SORT_ORDER {
  StartDate = 1,
  MostRecent = 2,
  HighestPay = 3,
}

@Component({
  selector: 'app-job-search-results-v2',
  templateUrl: './job-search-results.component.html',
  styleUrls: ['./job-search-results.component.scss'],
})
export class JobSearchResultsV2Component implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @ViewChild('recommendedDialogTemplate')
  recommendedDialogTemplate: TemplateRef<any>;

  jobConfig: CardComponentConfig = {
    showStatus: false,
    showInfoDetails: true,
    showTransferApplication: false,
    showBadges: true,
    showLinks: false,
    useEmitter: true,
  };
  jobSearchResults: IJob[] = [];
  similarJobsResults: IJob[] = [];
  similarJobsCardConfigs: ICardConfig[] = [];
  moreJobsToLoad = false;
  grid = {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
    all: 0,
  };

  gridBreakpoints = {
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1350,
  };
  small = BodySize.Small;
  h5 = HeadingSize.H5;
  START_DATE = SORT_ORDER.StartDate;
  MOST_RECENT = SORT_ORDER.MostRecent;
  HIGHEST_PAY = SORT_ORDER.HighestPay;

  isMobile$ = this._store.select(selectIsMobileNonTablet);
  lookups$: Observable<ILookups> = this._store.select(selectLookups);
  lookupsLoading$: Observable<boolean> =
    this._store.select(selectLookupsLoading);
  recruiter$: Observable<Recruiter> = this._store.select(selectRecruiterData);

  jobFilter$: Observable<IJobFilterAPI> = this._store.select(selectJobFilterV2);

  availableJobsLoading$: Observable<boolean> = this._store.select(
    selectAvailableJobsLoading,
  );
  availableJobs$: Observable<JobDataModel> =
    this._store.select(selectAvailableJobs);

  similarJobsLoading$: Observable<boolean> = this._store.select(
    selectSimilarJobsLoading,
  );
  similarJobs$: Observable<JobDataModel> =
    this._store.select(selectSimilarJobs);
  candidateFacilitiesCount$: Observable<number> = this._store.select(
    selectCandidateFacilitiesCount,
  );

  savedJobs$ = this._store.select(selectSavedJobs);

  constructor(
    public _ctx: JobAreaContext,
    private _store: Store<IAppState>,
    private _navHelper: NavHelper,
    private _dialogService: DialogService,
    private _notifService: NotificationService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.availableJobs$,
      this.availableJobsLoading$,
      this.jobFilter$,
      this.savedJobs$,
    ])
      .pipe(
        filter(
          ([, jobSearchLoading, jobFilter, ]: [
            JobDataModel,
            boolean,
            IJobFilterAPI,
            JobDataModel,
          ]) => !jobSearchLoading && !!jobFilter,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(
        ([jobSearchResults, , , savedJobs]: [
          JobDataModel,
          boolean,
          IJobFilterAPI,
          JobDataModel,
        ]) => {
          const jobs = jobSearchResults?.jobs ?? [];

          if (jobs) {
            this.jobSearchResults = [...jobs].map((job) => {
              const jobSaved = savedJobs?.jobs?.some(
                (savedJob) =>
                  savedJob.id === job.id &&
                  savedJob.contractType === job.contractType,
              ) ?? false;
              return { ...job, saved: jobSaved };
            });
          }

          this.moreJobsToLoad =
            this.jobSearchResults?.length < (jobSearchResults?.totalCount ?? 0);
        },
      );

    combineLatest([
      this.similarJobs$,
      this.similarJobsLoading$,
      this.savedJobs$,
      this.lookups$,
      this.lookupsLoading$,
    ])
      .pipe(
        filter(
          ([, similarJobsLoading, , , lookupsLoading]: [
            JobDataModel,
            boolean,
            JobDataModel,
            ILookups,
            boolean,
          ]) => !similarJobsLoading && !lookupsLoading,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(
        ([similarJobs, , savedJobs, lookups, ,]: [
          JobDataModel,
          boolean,
          JobDataModel,
          ILookups,
          boolean,
        ]) => {
          this.similarJobsResults = similarJobs?.jobs?.map((job) => {
            const jobSaved = savedJobs.jobs.some(
              (savedJob) =>
                savedJob.id === job.id &&
                savedJob.contractType === job.contractType,
            );
            return { ...job, saved: jobSaved };
          });

          this.similarJobsCardConfigs = this.similarJobsResults?.map(
            (similarJob) => {
              const jobBadges = this._ctx
                .getJobBadges(false, similarJob)
                .map((badge) => badge.badge)
                .filter(
                  (badge) =>
                    ![
                      ContractType.Local.toString(),
                      ContractType.Travel.toString(),
                    ].includes(badge.title),
                );

              return mapJobToCardConfig(
                similarJob,
                {
                  yesNoLookup: lookups.yesNoLookup,
                  specialtyLookup: lookups.specialtyLookup,
                  professionLookup: lookups.professionLookup,
                  stateLookup: lookups.stateLookup,
                },
                jobBadges,
                similarJob.contractType === ContractType.Local
                  ? LOCAL_BADGE
                  : TRAVEL_BADGE,
                true,
              );
            },
          );
        },
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  viewSpecificJobCarousel(event: HcEvent, jobFilter: IJobFilterAPI) {
    const jobData = event?.data?.jobCardData?.jobData;
    const jobId = jobData['jobId'] || null;

    this.viewSpecificJob(jobId, jobFilter);
  }

  updateSaveJobCarousel(event: HcEvent, jobs: IJob[]) {
    const jobEventInfo = event?.data?.jobCardData?.jobData;
    const jobId = jobEventInfo['jobId'] || null;

    const job = jobs?.find((j) => j.id === jobId);

    if (job) {
      this.updateSaveJob(job);
    }
  }

  viewSpecificJob(id: string, jobFilter: IJobFilterAPI) {
    this._navHelper.goToJobsSpecificCustomParams(id, {
      contractType: ContractType[jobFilter.contractType],
      fromSearch: true,
    });
  }

  updateSaveJob(job: IJob): void {
    this._store.dispatch(
      new SetSavedAvailableJob({
        job,
        saveValue: !job.saved,
        contractType: job.contractType,
      }),
    );
  }

  showRecommendationDialog(): void {
    const dialogData: IDialogParameters = {
      title: 'Job Recommendations',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.recommendedDialogTemplate,
    };
    this._dialogService.showDialog(dialogData);
  }

  loadMoreJobs(jobFilter: IJobFilterAPI): void {
    const f = {
      ...jobFilter,
      page: jobFilter.page + 1,
      reload: false,
    };
    this._store.dispatch(new GetAvailableJobs(f));
    this._store.dispatch(new SetJobFilterV2(f));
  }

  setOrderBy(jobFilter: IJobFilterAPI, orderNumber: number): void {
    const tempFilter = {
      ...jobFilter,
      orderBy: orderNumber,
      page: 1,
      reload: true,
    };
    this._store.dispatch(new GetAvailableJobs(tempFilter));
    this._store.dispatch(new SetJobFilterV2(tempFilter));
  }

  sortByButtonTitle(jobFilter: IJobFilterAPI): string {
    switch (jobFilter.orderBy) {
      case SORT_ORDER.HighestPay:
        return 'Highest Pay';
      case SORT_ORDER.StartDate:
        return 'Start Date';
      case SORT_ORDER.MostRecent:
        return 'Most Recent';
      default:
        return '';
    }
  }

  resetSearch(): void {
    this._store.dispatch(new SetJobFilterV2(null));
  }
}
