import { CandidateFacility } from 'src/app/common/models/candidate-facility';
import { Facility } from 'src/app/common/models/facility';
import { FacilityApplication } from 'src/app/common/models/facility-application';
import { IDataState, initializeData } from 'src/app/store/app/app.models';
import { FacilityProximitySearch } from '../../common/contracts/facility-proximity-search';

export interface FacilitiesState {
  facilitiesData: IDataState<Facility[]>;
  candidateFacilitiesData: IDataState<CandidateFacility[]>;
  candidateFacilitiesParameters: FacilityProximitySearch;
  facilityApplications: IDataState<FacilityApplication[]>;
}

export const initialFacilitiesState: FacilitiesState = {
  facilitiesData: initializeData(),
  candidateFacilitiesData: initializeData(),
  facilityApplications: initializeData(),
  candidateFacilitiesParameters: {
    location: null,
    facility: null
  },
};
