export abstract class AppUrls {
  public static readonly ABOUT_ME = 'about-me';
  public static readonly APPLICATION = 'application';
  public static readonly APPLICATION_REVIEW = 'application-review';
  public static readonly APP_DOWN = 'app-down';
  public static readonly CERTIFICATIONS = 'certifications';
  public static readonly CONGRATULATIONS = 'congratulations';
  public static readonly CONTACT_SELECT = 'contact-select';
  public static readonly DASHBOARD = 'dashboard';
  public static readonly DOCUMENTS = 'documents';
  public static readonly DROP_AND_GO = 'drop-and-go';
  public static readonly EDUCATION = 'educations';
  public static readonly ENROLL = 'enroll';
  public static readonly ERROR = 'error';
  public static readonly ON_ASSIGNMENT = 'on-assignment';
  public static readonly EXPENSE_MANAGEMENT = 'expense-management';
  public static readonly INACTIVE_USER = 'inactive-user';
  public static readonly INTERNAL_LOGIN = 'internal-login';
  public static readonly JOBS = 'jobs';
  public static readonly JOB_SEARCH = 'job-search';
  public static readonly LICENSES = 'licenses';
  public static readonly MATCHED_JOBS = 'matched-jobs';
  public static readonly MULTIPLE_ACCOUNTS_FOUND = 'multiple-accounts-found';
  public static readonly MY_APPLICATIONS = 'my-applications';
  public static readonly MY_SUBMITTALS = 'my-submittals';
  public static readonly NURSYS_SETUP = 'nursys-setup';
  public static readonly PROFESSIONAL_OVERVIEW = `professional-overview`;
  public static readonly PROFILE = 'profile';
  public static readonly PROFILEMENU = 'profile-menu';
  public static readonly QUESTIONNAIRE = 'questionnaire';
  public static readonly REFERENCES = 'references';
  public static readonly REGISTRATION = 'registration';
  public static readonly SAVED_JOBS = 'saved-jobs';
  public static readonly SETTINGS = 'settings';
  public static readonly SIMILAR_JOBS = 'similar-jobs';
  public static readonly SKILLS = 'skills';
  public static readonly SUMMARY_EDIT = `${AppUrls.ABOUT_ME}/summary`;
  public static readonly SUPPORT = 'support';
  public static readonly TASKS = 'tasks';
  public static readonly TECHNOLOGY = 'technology';
  public static readonly TIME_TRACKING = 'time-tracking';
  public static readonly TIME_TRACKING_LOGIN = 'time-tracking/log-in';
  public static readonly WORK_HISTORY = 'work-history';
  public static readonly WELCOME = 'welcome';
  public static readonly VERIFY = 'verify';
  public static readonly VERIFY_USER = `user`;
  public static readonly VERIFY_SUPPORT = `support`;
  public static readonly VERIFY_CHECK = `check`;
  public static readonly VERIFY_SELECTION = `selection`;
  public static readonly VERIFY_SMS = `sms`;
  public static readonly VERIFY_REPORT = `report`;
  public static readonly PREFERENCES = `preferences`;
  public static readonly JOB_PREFERENCES = `job-preferences`;
  public static readonly CONTACT_PREFERENCES = `contact-preferences`;
  public static readonly COVID = `covid-vaccine`;
  public static readonly FLU = `flu-vaccine`;
  public static readonly RESET_PASSWORD = `resetpassword`;
  public static readonly REDIRECT = 'redirect';
  public static readonly NO_CONNECTION = 'no-connection';
  public static readonly LOGOUT = 'logout';
  public static readonly LOGIN_ERROR = 'login-error';
}

export abstract class RouteTitles {
  public static readonly ABOUT_ME = 'About Me';
  public static readonly APPLICATION = 'Application';
  public static readonly APPLICATION_REVIEW = 'Application Review';
  public static readonly APP_DOWN = 'App Down';
  public static readonly CERTIFICATIONS = 'Certifications';
  public static readonly CONGRATULATIONS = 'Congratulations';
  public static readonly CONTACT_SELECT = 'Contact Select';
  public static readonly DASHBOARD = 'Dashboard';
  public static readonly DOCUMENTS = 'Documents';
  public static readonly DROP_AND_GO = 'Drop And Go';
  public static readonly EDUCATION = 'Education';
  public static readonly ENROLL = 'Enroll';
  public static readonly ERROR = 'Error';
  public static readonly ON_ASSIGNMENT = 'On Assignment';
  public static readonly EXPENSE_MANAGEMENT = 'Expense Management';
  public static readonly INACTIVE_USER = 'Inactive User';
  public static readonly INTERNAL_LOGIN = 'Internal Login';
  public static readonly JOBS = 'Jobs';
  public static readonly JOB = 'Job';
  public static readonly JOB_SEARCH = 'Job Search';
  public static readonly LICENSES = 'Licenses';
  public static readonly MATCHED_JOBS = 'Matched Jobs';
  public static readonly MULTIPLE_ACCOUNTS_FOUND = 'Multiple Accounts Found';
  public static readonly MY_APPLICATIONS = 'My applications';
  public static readonly MY_SUBMITTALS = 'My submittals';
  public static readonly NURSYS_SETUP = 'Nursys Setup';
  public static readonly PROFESSIONAL_OVERVIEW = `Professional Overview`;
  public static readonly PROFILE = 'Profile';
  public static readonly PROFILEMENU = 'Profile Menu';
  public static readonly QUESTIONNAIRE = 'Questionnaire';
  public static readonly REFERENCES = 'References';
  public static readonly REGISTRATION = 'Registration';
  public static readonly SAVED_JOBS = 'Saved Jobs';
  public static readonly SETTINGS = 'Settings';
  public static readonly SIMILAR_JOBS = 'Similar Jobs';
  public static readonly SKILLS = 'Skills';
  public static readonly SUMMARY_EDIT = `Summary`;
  public static readonly SUPPORT = 'Support';
  public static readonly TASKS = 'Tasks';
  public static readonly TECHNOLOGY = 'Technology';
  public static readonly TIME_TRACKING = 'Time Tracking';
  public static readonly TIME_TRACKING_LOGIN = 'Time Tracking Log In';
  public static readonly WORK_HISTORY = 'Work History';
  public static readonly WELCOME = 'Welcome';
  public static readonly VERIFY = 'Verify';
  public static readonly VERIFY_USER = `User`;
  public static readonly VERIFY_SUPPORT = `Support`;
  public static readonly VERIFY_CHECK = `Check`;
  public static readonly VERIFY_SELECTION = `Selection`;
  public static readonly VERIFY_SMS = `sms`;
  public static readonly PREFERENCES = `Preferences`;
  public static readonly JOB_PREFERENCES = `Job Preferences`;
  public static readonly COVID = `Covid Vaccine`;
  public static readonly FLU = `Flu Vaccine`;
  public static readonly RESET_PASSWORD = `Reset Password`;
  public static readonly REDIRECT = 'redirect';
  public static readonly AUTO_OFFER_CONFIRMATION = 'Auto Offer Confirmation';
  public static readonly ADD_REFERENCE = 'Add Reference';
  public static readonly EDIT_REFERENCE = 'Edit Reference';
  public static readonly ADD_EDUCATION = 'Add Education';
  public static readonly SKILL_ASSESSMENT = 'Skill Assessment';
  public static readonly ADD_CERTIFICATIONS = 'Add Certifications';
  public static readonly EDIT_CERTIFICATIONS = 'Edit Certifications';
  public static readonly ADD_TECHNOLOGY = 'Add Technology';
  public static readonly EDIT_TECHNOLOGY = 'Edit Technology';
  public static readonly ADD_WORK_HISTORY = 'Add Work History';
  public static readonly EDIT_WORK_HISTORY = 'Edit Work History';
  public static readonly NO_CONNECTION = 'No Internet Connection';
  public static readonly LOGIN_ERROR = 'Login Error';
}
