<div fxFlex class="view-verify" fxLayout="column">
  <span class="global-font-20 verify-header"> Report an issue </span>
  <span class="support-description"> Let us know if parts of our app didn't work the way you thought it would or if you are experiencing a technical issue. </span>
  <form [formGroup]="verifySupportReport">
    <mat-form-field>
      <input matInput required placeholder="Name" formControlName="name" />
    </mat-form-field>
    <mat-form-field>
      <input matInput required placeholder="Email" formControlName="email" />
    </mat-form-field>
    <mat-form-field>
      <textarea matInput required placeholder="Description of Issue" formControlName="description"></textarea>
    </mat-form-field>
  </form>
  <button mat-raised-button color="accent" [disabled]="verificationSupportEmailLoading$ | async" type="submit" (click)="onSubmit()">Send Issue</button>
</div>
