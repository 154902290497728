import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IFeatureFlag } from '../common/contracts/feature-flag';
import {
  Experiment,
  ExperimentClient,
  ExperimentUser,
  Variant,
} from '@amplitude/experiment-js-client';
import { environment } from 'src/environments/environment';

export enum featureFlagNames {
  useNursys = 'usenursys',
  guestServiceSkillsChecklist = 'guest-service-skills-checklist',
  dashboardLayoutVariants = 'dashboard-layout-variants',
  aATestPOCExperiment = 'a-a-test-poc',
  communicationPreferences = 'communication-preferences',
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  experiment: ExperimentClient;
  experimentUser: ExperimentUser;
  experimentReady: boolean = false;
  eventsToFireWhenReady: string[] = []; //variable to store exposure events that occur before the experiment is ready

  async initializeAndGetFeatureFlags(): Promise<Map<string, IFeatureFlag>> {
    const flags = await this.wrapInitializeAndGetFeatureFlags();
    const mappedFlags = this.mapFlags(flags);
    this.experimentReady = true;
    // If any exposure events occurred before the experiment was ready, fire them now
    this.eventsToFireWhenReady.forEach(e => this.getVariant(e));
    return mappedFlags;
  }

  fetchAllFlagValues(): Observable<Map<string, IFeatureFlag>> {
    const flags = this.wrapFetchAllFlagValues();
    const mappedFlags = this.mapFlags(flags);

    return of(mappedFlags);
  }

  fetchVariant(flagName: string): Observable<string> {
    const variant = this.getVariant(flagName);
    const mappedVariant = this.mapVariant(variant);
    return of(mappedVariant);
  }

  // mapping functions
  mapFlag(
    flag: { key: string; value: string },
    flagName: string
  ): IFeatureFlag {
    return {
      flagName: flagName,
      enabled: flag.value?.toLowerCase() === 'on',
      variation: flag.key?.toLowerCase(),
    };
  }

  mapFlags(flags: object): Map<string, IFeatureFlag> {
    let mappedFlags = new Map();

    Object.keys(flags).forEach((flagName: string) => {
      mappedFlags.set(flagName, this.mapFlag(flags[flagName], flagName));
    });

    return mappedFlags;
  }

  mapVariant(variant: any): string {
    if(!variant) {
      return null;
    }
    return variant.value;
  }

  // wrapper functions for provider-specific code
  async wrapInitializeAndGetFeatureFlags(): Promise<any> {

    // Initialize experiment
    this.experiment = Experiment.initialize(environment.amplitudeAPIKey, {
      fetchOnStart: true,
      exposureTrackingProvider: {
        track: (exposure) => {
            Segment.track('$exposure', exposure)
        }
      }
    });

    // Set up user object to fetch user specific variants
    this.experimentUser = {
      user_id: Segment.user().id(),
      device_id: Segment.user().anonymousId(),
    };

    // Start experiment
    if (this.experiment) {
      return this.experiment.start(this.experimentUser).then(async () => {
        // Fetch all flag variants for user
        const flags = await this.wrapFetchAllFlagValues();
        return flags;
      });
    }
  }

  async wrapFetchAllFlagValues(): Promise<any> {
    return (await this.experiment.fetch(this.experimentUser)).all();
  }

  getVariant(flagName): Variant {
    if(this.experimentReady) {
      return this.wrapGetVariant(flagName);
    } else {
      this.eventsToFireWhenReady.push(flagName);
      return null;
    }
  }

  wrapGetVariant(flagName): Variant {
    return this.experiment.variant(flagName);
  }
}
