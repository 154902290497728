import { FormControl } from "@angular/forms";
import { IDropdownData } from "hc-design-system-lib/lib/components/form/form.interfaces";
import { Moment } from "moment";
import { TaskStatusConstants } from "./task-status";

export class Certification {
  id: string;
  candidateId: string;
  certificationId: string;
  expirationDate: Date;
  statusId: number;
  qualificationStatusId: TaskStatusConstants;
  type?: number;
  document?: string;
  documentId?: number;
  files?: Array<string> = [];
  qualificationId?: string;
  issuingBodyId: string;
  issuingDate?: Date;
}

export class CertificationForm {
  certificationId: FormControl<IDropdownData>;
  expirationDate: FormControl<Moment>
  issuingBodyId: FormControl<IDropdownData>;
  issuingDate: FormControl<Moment>;
  fileUploadFront: FormControl<File[]>;
  fileUploadBack: FormControl<File[]>;
  fileUploadPreview: FormControl<File[]>;
}
