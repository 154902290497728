import { createSelector } from '@ngrx/store';
import { checkIfDateIsAfter } from 'src/app/common/functions/date-manipulations';
import { NurseTask } from 'src/app/common/models/db-objects';
import { TaskStatusConstants } from 'src/app/common/models/task-status';
import { IAppState } from 'src/app/store/app/app.state';
import { TasksState } from './tasks.state';
import { TaskEntities } from 'src/app/common/models/task-entities';
import moment, { Moment } from 'moment';

const selectTasksState = (state: IAppState) => state.tasks;

export const selectTasks = createSelector(selectTasksState, (state: TasksState) => state.tasks.data);
export const selectPreviousTasks = createSelector(selectTasksState, (state: TasksState) => state.tasks.previousData);
export const selectTasksLoading = createSelector(selectTasksState, (state: TasksState) => state.tasks.loading);

export const selectTasksWithTextInfo = createSelector(selectTasks, (tasks: NurseTask[]) =>
  tasks?.map((task) => {
    if (task.status !== TaskStatusConstants.complete && task.status !== TaskStatusConstants.pending) {
      return addTimeEstimateToTaskName(task);
    } else if (task.status === TaskStatusConstants.pending) {
      return addPendingTaskInfo(task);
    } else {
      return task;
    }
  })
);

export const selectApplyTasks = createSelector(selectTasksWithTextInfo, (tasks: NurseTask[]) => tasks?.filter((task) => task.requiredForApply) ?? []);

export const selectTasksExpirationDate = createSelector(selectTasksState, (state: TasksState) => state.tasks?.expirationDate);
export const selectTasksExpired = createSelector(selectTasksState, (state: TasksState) => {
  return checkIfDateIsAfter(new Date(), state.tasks?.expirationDate);
});

export const selectToDoApplyTasks = createSelector(selectApplyTasks, (tasks: NurseTask[]) =>
  tasks.filter((t) => t.status !== TaskStatusConstants.complete && t.status !== TaskStatusConstants.pending)
);

export const selectToDoTasks = createSelector(selectTasks, (tasks: NurseTask[]) =>
  tasks?.filter((t) => t.status !== TaskStatusConstants.complete && t.status !== TaskStatusConstants.pending)
);

export const selectToDoCertTasks = createSelector(selectToDoTasks, (tasks: NurseTask[]): NurseTask[] =>
  tasks?.filter((t) => t.description?.includes('certification') && t.status !== TaskStatusConstants.inProgress)
);

export const selectTaskPathsMap = createSelector(selectTasksState, (state: TasksState) => new Map((state.tasks.data?.filter((task) => task.path > 0).map((task) => [task.path, true]))));

export const selectFluVaccine = createSelector(selectTasksState, (state: TasksState) => state.fluVaccine);
export const selectFluVaccineData = createSelector(selectTasksState, (state: TasksState) => state.fluVaccine.data);
export const selectFluVaccineError = createSelector(selectTasksState, (state: TasksState) => state.fluVaccine.error);
export const selectFluVaccineLoading = createSelector(selectTasksState, (state: TasksState) => state.fluVaccine.loading);

export const selectUpdateFluVaccine = createSelector(selectTasksState, (state: TasksState) => state.updateFluVaccine);
export const selectUpdateFluVaccineLoading = createSelector(selectTasksState, (state: TasksState) => state.updateFluVaccine.loading);

export const selectCovidVaccine = createSelector(selectTasksState, (state: TasksState) => state.covidVaccine);
export const selectCovidVaccineData = createSelector(selectTasksState, (state: TasksState) => state.covidVaccine.data);
export const selectCovidVaccineError = createSelector(selectTasksState, (state: TasksState) => state.covidVaccine.error);
export const selectCovidVaccineLoading = createSelector(selectTasksState, (state: TasksState) => state.covidVaccine.loading);

export const selectCovidVaccineUpdate = createSelector(selectTasksState, (state: TasksState) => state.updateCovidVaccine);
export const selectCovidVaccineUpdateLoading = createSelector(selectTasksState, (state: TasksState) => state.updateCovidVaccine.loading);

function addTimeEstimateToTaskName(task: NurseTask): NurseTask {
  let newTask = new NurseTask();
  Object.assign(newTask, task);
  newTask.friendlyName ??= newTask.name;
  switch (newTask.value[0]) {
    case TaskEntities.contactAboutMe:
      newTask.friendlyName = newTask.friendlyName + ' (1 minute)';
      break;
    case TaskEntities.contactProfessionalOverview:
      newTask.friendlyName = newTask.friendlyName + ' (5 minutes)';
      break;
    case TaskEntities.employmentHistory:
      newTask.friendlyName = newTask.friendlyName + ' (10 minutes)';
      break;
    case TaskEntities.skills:
      newTask.friendlyName = newTask.friendlyName + ' (30 minutes)';
      break;
    case TaskEntities.backgroundQuestionnaire:
      newTask.friendlyName = newTask.friendlyName + ' (1 minute)';
      break;
    case TaskEntities.license:
      newTask.friendlyName = newTask.friendlyName + ' (2 minutes)';
      break;
    case TaskEntities.candidateQualifications:
      if (newTask.value.length > 1) {
        if (newTask.value[1] === TaskEntities.covidVaccine || newTask.value[1] === TaskEntities.fluVaccine) {
          newTask.friendlyName = newTask.friendlyName + ' (1 minute)';
        }
      }
      break;
    case TaskEntities.fluVaccine:
      newTask.friendlyName = newTask.friendlyName + ' (1 minute)';
      break;
    case TaskEntities.certification:
      newTask.friendlyName = newTask.friendlyName + ' (1 minute)';
      break;
    case TaskEntities.education:
      newTask.friendlyName = newTask.friendlyName + ' (2 minutes)';
      break;
    case TaskEntities.reference:
      newTask.friendlyName = newTask.friendlyName + ' (5 minutes)';
      break;
    case TaskEntities.candidateTechnologySkill:
      newTask.friendlyName = newTask.friendlyName + ' (1 minute)';
      break;
    case TaskEntities.jobPreference:
      newTask.friendlyName = newTask.friendlyName + ' (1 minute)';
      break;
  }

  return newTask;
}
function addPendingTaskInfo(task: NurseTask): NurseTask {
  const numBusinessDaysToAdd: number = 2;
  const pendingReferenceTaskInfo = 'Waiting for responses';

  let newTask = new NurseTask();
  Object.assign(newTask, task);

  // While possible to add multiple options to a task, currently no future plans to add additional options to a reference task
  if (newTask.value[0] === TaskEntities.reference) {
    newTask.pendingTaskInfo = pendingReferenceTaskInfo;
  } else {
    newTask.pendingTaskInfo = addBusinessDays(newTask.lastUpdatedDate, numBusinessDaysToAdd);
  }

  return newTask;
}
function addBusinessDays(originalDate: Date, numDaysToAdd: number): string {
  const sunday: number = 0;
  const saturday: number = 6;

  const localDate: Moment = moment(originalDate).local();

  while (numDaysToAdd > 0) {
    localDate.add(1, 'days');
    if (localDate.day() !== sunday && localDate.day() !== saturday) {
      numDaysToAdd--;
    }
  }

  // Adding +1 to the month because January starts at 0
  return `Est. review by ${localDate.month() + 1}/${localDate.date()}`;
}
