import {
  IDropdownData,
  IHierarchicalDropdownNode,
} from 'hc-design-system-lib/lib/components/form/form.interfaces';

export function convertIntoDropdownData(
  v: any,
  keyProperty: string
): IDropdownData {
  return {
    key: v[keyProperty],
    value: v,
  };
}

export function sortDropdownValuesByName(
  a: IDropdownData,
  b: IDropdownData
): number {
  const nameA = a.key.toUpperCase();
  const nameB = b.key.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export function certificationLookupDropdownSort(
  arr: IDropdownData[]
): IDropdownData[] {
  arr = arr.sort((a, b) =>
    a.value.certification.localeCompare(b.value.certification)
  );
  const one = arr.filter((o) => o.value.certification === 'BLS')[0];
  const two = arr.filter((o) => o.value.certification === 'ACLS')[0];
  const three = arr.filter((o) => o.value.certification === 'PALS')[0];
  const four = arr.filter((o) => o.value.certification === 'TNCC')[0];
  const five = arr.filter((o) => o.value.certification === 'NRP')[0];
  if (five !== undefined) {
    arr.splice(arr.indexOf(five), 1);
    arr.unshift(five);
  }
  if (four !== undefined) {
    arr.splice(arr.indexOf(four), 1);
    arr.unshift(four);
  }
  if (three !== undefined) {
    arr.splice(arr.indexOf(three), 1);
    arr.unshift(three);
  }
  if (two !== undefined) {
    arr.splice(arr.indexOf(two), 1);
    arr.unshift(two);
  }
  if (one !== undefined) {
    arr.splice(arr.indexOf(one), 1);
    arr.unshift(one);
  }
  return arr;
}

export function getHierarchyNodeByType(
  hierarchy: IHierarchicalDropdownNode,
  type: string
): IHierarchicalDropdownNode {
  let result: IHierarchicalDropdownNode | null = null;

  if (hierarchy?.type?.toLowerCase() === type?.toLowerCase()) {
    result = hierarchy;
  } else if (hierarchy?.children) {
    hierarchy.children.some((node) => {
      result = getHierarchyNodeByType(node, type);
      return result;
    });
  }

  return result;
}

export function getHierarchyTreeById(
  hierarchy: IHierarchicalDropdownNode[],
  id: string
): IHierarchicalDropdownNode[] {
  let result: IHierarchicalDropdownNode | null = null;

  hierarchy?.some((h) => {
    let children: IHierarchicalDropdownNode[];
    if (h.id === id) {
      result = h;
      return result;
    } else if (
      h.children &&
      (children = getHierarchyTreeById(h.children, id))
    ) {
      result = { ...h, children: children };
      return result;
    }
  });

  return result && [result];
}

export function getHierarchyTreeByName(
  hierarchy: IHierarchicalDropdownNode[],
  name: string
): IHierarchicalDropdownNode[] {
  let result: IHierarchicalDropdownNode | null = null;

  hierarchy?.some((h) => {
    let children: IHierarchicalDropdownNode[];
    if (h.name?.trim().toLowerCase() === name?.trim().toLowerCase()) {
      result = h;
      return result;
    } else if (
      h.children &&
      (children = getHierarchyTreeByName(h.children, name))
    ) {
      result = { ...h, children: children };
      return result;
    }
  });

  return result && [result];
}
