<div fxFlex fxLayout="column" id="main-content" fxLayoutGap="16px">
    <div fxLayout="column" class="top-section">
        <div fxLayoutAlign="end end">
            <button id="crisisPopupClose" mat-icon-button class="global-x-btn">
                <mat-icon (click)="cancel()">close</mat-icon>
            </button>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
            <div class="top-header">
                <b>What is a Crisis Need Assignment?</b>
                <p>As the COVID-19 pandemic creates surges in patient census, we have client hospitals asking for immediate help from RNs.  
                    We want to do everything we can to provide staffing quickly to these hospitals with urgent needs, which are indicated by the crisis badge.
                </p></div>
        </div>
    </div>
</div>


