import { EFacilitiesActions, FacilitiesActions } from './facilities.actions';
import { FacilitiesState, initialFacilitiesState } from './facilities.state';

export function facilitiesReducer(
  state = initialFacilitiesState,
  action: FacilitiesActions
): FacilitiesState {
  switch (action.type) {
    case EFacilitiesActions.GetFacilities:
      return {
        ...state,
        facilitiesData: {
          ...state.facilitiesData,
          error: null,
          loading: true,
        },
      };
    case EFacilitiesActions.GetFacilitiesSuccess:
      return {
        ...state,
        facilitiesData: {
          data: action.facilities,
          error: null,
          loading: false,
        },
      };
    case EFacilitiesActions.GetFacilitiesError:
      return {
        ...state,
        facilitiesData: {
          data: null,
          error: action.error,
          loading: false,
        },
      };
    case EFacilitiesActions.SearchFacilitiesByProximity:
      return {
        ...state,
        candidateFacilitiesData: {
          ...state.candidateFacilitiesData,
          error: null,
          loading: true,
        },
        candidateFacilitiesParameters: action.parameters
      };
    case EFacilitiesActions.SearchFacilitiesByProximitySuccess:
      return {
        ...state,
        candidateFacilitiesData: {
          data: action.facilities,
          error: null,
          loading: false,
        },
      };
    case EFacilitiesActions.SearchFacilitiesByProximityError:
      return {
        ...state,
        candidateFacilitiesData: {
          data: null,
          error: action.error,
          loading: false,
        },
      };
    case EFacilitiesActions.GetFacilityApplications:
      return {
        ...state,
        facilityApplications: {
          ...state.facilityApplications,
          error: null,
          loading: true,
        },
      };
    case EFacilitiesActions.GetFacilityApplicationsSuccess:
      return {
        ...state,
        facilityApplications: {
          data: action.facilityApplications,
          error: null,
          loading: false,
        },
      };
    case EFacilitiesActions.GetFacilityApplicationsError:
      return {
        ...state,
        facilityApplications: {
          data: null,
          error: action.error,
          loading: false,
        },
      };
    case EFacilitiesActions.PostFacilityApplicationSuccess:
      return {
        ...state,
        facilityApplications: {
          data: [...state.facilityApplications.data, action.facilityApplication],
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
}
