import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { IAppState } from 'src/app/store/app/app.state';
import { selectCanSeeTravel, selectAccountStateDataLoading } from 'src/app/store/userContext/userContext.selectors';
import { AppUrls } from '../app-urls';

@Injectable()
export class TravelGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _store: Store<IAppState>,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | UrlTree {
    const canSeeTravel$: Observable<boolean> = this._store.select(selectCanSeeTravel);
    const accountStateDataLoading$: Observable<boolean> = this._store.select(selectAccountStateDataLoading);

    return combineLatest([canSeeTravel$, accountStateDataLoading$]).pipe(
      skipWhile(([canSeeTravel, accountStateDataLoading]: [boolean, boolean]) => accountStateDataLoading),
      map(([canSeeTravel, accountStateDataLoading]: [boolean, boolean]) => {
        if (canSeeTravel === false) {
          return this._router.parseUrl(`/${AppUrls.DASHBOARD}`);
        }
        return canSeeTravel;
      }),
    );
  }
}
