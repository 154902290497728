import { Component, OnDestroy, OnInit } from '@angular/core';
import { Nav, NavCondition } from 'src/app/common/models/nav';
import { Store } from "@ngrx/store";
import { IAppState } from 'src/app/store/app/app.state';
import { NavigationItemClicked } from 'src/app/store/segment/segment.actions';
import { environment } from 'src/environments/environment';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { selectCanSeeTravel } from 'src/app/store/userContext/userContext.selectors';
import { allProfileNavItems } from './profile-menu.constants';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit, OnDestroy {

  canSeeTravel$: Observable<boolean> = this._store.select(selectCanSeeTravel).pipe(
    filter(s => (s !== null && s !== undefined))
  );

  private readonly destroy$: Subject<void> = new Subject<void>();
  allNavItems: Nav[] = allProfileNavItems;

  navItems: Nav[] = [];

  constructor(private _store: Store<IAppState>) { }

  ngOnInit() {
    this.setUpCanSeeSubscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setUpCanSeeSubscription() {
    this.canSeeTravel$
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (canSeeTravel) => {
        this.setUpNavItems(canSeeTravel);
      },
    );
  }

  setUpNavItems(canSeeTravel) {
    this.navItems = this.allNavItems.filter(i => 
      (
        i.condition === undefined || 
        (i.condition == NavCondition.TRAVEL && canSeeTravel)
      )
    );
  }

  segmentTrackNavigation(item: Nav): void{
    const navigationUrl = environment.appBaseUrl + item.nav;
    this._store.dispatch(new NavigationItemClicked({navigationText: item.name, navigationPlacement: 'Profile Menu Navigation', navigationUrl}));
  }
}
