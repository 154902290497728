import { Component, OnInit } from '@angular/core';
import { FormHeaderService, NavHelper } from 'src/app/services';
import {
  selectSkillDictionaryData,
  selectSkillDictionaryError,
  selectSkillDictionaryLoading,
  selectSkillDictonaryAccordionFilteredData,
} from 'src/app/store/skillsChecklist/skillsChecklist.selectors';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { combineLatest, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { NotificationService } from 'hc-design-system-lib';
import { ErrorMessages } from 'src/app/common/error-messages';
import {
  GetCandidateSkills,
  GetSkillDictionary,
  UpdateSkillDictionaryFilter,
} from 'src/app/store/skillsChecklist/skillsChecklist.actions';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-skills-check-list',
  templateUrl: './skills-check-list.component.html',
  styleUrls: ['./skills-check-list.component.scss'],
})
export class SkillsCheckListComponent implements OnInit {
  private readonly destroy$: Subject<void> = new Subject<void>();

  skillFilterControl: FormControl<string> = new FormControl<string>('');

  skillDictionary$ = this._store.pipe(select(selectSkillDictionaryData));
  skillDictionaryLoading$ = this._store.pipe(
    select(selectSkillDictionaryLoading)
  );
  skillDictionaryError$ = this._store.pipe(select(selectSkillDictionaryError));

  skillDictionaryAccordionFiltered$ = this._store.pipe(
    select(selectSkillDictonaryAccordionFilteredData)
  );

  constructor(
    public _formHeaderService: FormHeaderService,
    private _store: Store<IAppState>,
    private _navHelper: NavHelper,
    private _notificationService: NotificationService
  ) {}

  ngOnInit() {
    this._store.dispatch(new GetCandidateSkills());
    this._store.dispatch(new GetSkillDictionary());
    this._store.dispatch(new UpdateSkillDictionaryFilter(''));

    this._formHeaderService.resetFormHeaderAttributes({
      title: 'Skills Checklist',
    });

    this.handleGuestServiceApiErrors();
    this.skillFilterControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this._store.dispatch(new UpdateSkillDictionaryFilter(value));
      });
  }

  handleGuestServiceApiErrors() {
    combineLatest([this.skillDictionary$, this.skillDictionaryError$])
      .pipe(
        filter(
          ([skillDictionary, skillDictionaryError]) =>
            !!(skillDictionary || skillDictionaryError)
        ),
        take(1)
      )
      .subscribe(([, skillDictionaryError]) => {
        if (skillDictionaryError) {
          this._notificationService.showNotification(
            ErrorMessages.httpError,
            'error'
          );
          this._navHelper.goToDashBoard();
        }
      });
  }

  navToSkill(id): void {
    this._navHelper.goToSkill(id);
  }

  scrollSkillInput(): void {
    document.querySelector('#skillFilterInput').scrollIntoView(true);
  }
}
