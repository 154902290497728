import { DashboardObject } from 'src/app/components';
import { IDataState, initializeData } from 'src/app/store/app/app.models';

export interface DashboardState {
  dashboard: IDataState<DashboardObject>;
}

export const initialDashboardState: DashboardState = {
  dashboard: initializeData(),
};
