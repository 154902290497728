import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { InputIcon } from 'hc-design-system-lib/lib/components/form/form.enums';
import {
  DocumentHelperService,
  FormHeaderAttributes,
} from 'src/app/services';
import { FileUploadTargets, IFileUploadOptions } from 'src/app/common';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import {
  BodySize,
  CardElevation,
  DialogService,
  IDialogParameters,
} from 'hc-design-system-lib';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { VaccineTask } from 'src/app/common/models/db-objects';
import {
  selectCovidVaccine,
  selectCovidVaccineData,
  selectCovidVaccineError,
  selectCovidVaccineLoading,
  selectCovidVaccineUpdateLoading,
  selectCovidVaccineUpdate
} from 'src/app/store/tasks/tasks.selectors';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { GetCovidVaccine, UploadCovidVaccine } from 'src/app/store/tasks/tasks.actions';
import { IDataState } from 'src/app/store/app/app.models';
import { defaultTruncationSettings, generateFileTruncationSettings } from 'src/app/common/functions/filename-manipulators';
import { selectIsMobile } from 'src/app/store/ui/ui.selectors';
import { SetScreenWidth } from 'src/app/store/ui/ui.actions';

@Component({
  selector: 'app-covid-vaccine',
  templateUrl: './covid-vaccine.component.html',
  styleUrls: ['./covid-vaccine.component.scss'],
  providers: [[DocumentHelperService]],
})
export class CovidVaccineComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();
  files: Array<File> = [];
  isSaving = false;
  uploadedFileUrlSubscription: any;
  prepopulatedFile: { fileName: string; fileUrl: string };
  documentUrl: string;
  qualificationID: string;
  smallBodySize = BodySize.Small;
  microBodySize = BodySize.Micro;
  fileTruncationFunction = new BehaviorSubject(generateFileTruncationSettings());

  constructor(
    private _fb: UntypedFormBuilder,
    private _dialogService: DialogService,
    private _store: Store<IAppState>
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  covidVaccine$: Observable<IDataState<VaccineTask>> = this._store.pipe(
    select(selectCovidVaccine)
  );

  covidVaccineError$: Observable<Error> = this._store.pipe(
    select(selectCovidVaccineError)
  );

  covidVaccineLoading$: Observable<boolean> = this._store.pipe(
    select(selectCovidVaccineLoading)
  );

 updateCovidVaccineSaving$: Observable<boolean> = this._store.pipe(
    select(selectCovidVaccineUpdateLoading)
  );

  covidVaccineData$: Observable<VaccineTask> = this._store.pipe(
    select(selectCovidVaccineData)
  );

  updateCovidVaccine$: Observable<IDataState<VaccineTask>> = this._store.pipe(
    select(selectCovidVaccineUpdate)
  );

  isMobile$ = this._store.pipe(
    select(selectIsMobile)
  );

  primaryButtonAppearance = ButtonAppearance.Primary;
  secondaryButtonAppearance = ButtonAppearance.Secondary;
  locationIcon = InputIcon.LocationPin;
  form: UntypedFormGroup;
  isDesktop = true;
  formModified: boolean;
  backDialogSubscription: Subscription;
  formHeaderAttributes: FormHeaderAttributes;

  ngOnInit() {
    this._store.dispatch(new GetCovidVaccine());
    this.addCovidVaccineSubscription();
    this.subscribeToIsMobileSelector()
    this.formModified = false;
  }

  @HostListener('window:resize', ['$event'])
  OnResize(event): void {
    this._store.dispatch(new SetScreenWidth(window.innerWidth));
  }

  @ViewChild('alternateProofTemplate')
  alternateProofTemplate: TemplateRef<any>;

  _createForm() {
    this.form = this._fb.group({
      fileUpload: new UntypedFormControl('', [Validators.required]),
    });

    this.formHeaderAttributes = {
      form: this.form,
      title: 'COVID-19 Vaccine Status',
      showSaveButton: false,
    };
  }

  addCovidVaccineSubscription(): void {
    combineLatest([this.covidVaccineData$, this.covidVaccineLoading$])
      .pipe(
        skipWhile(([, covidVaccineLoading]) => covidVaccineLoading),
        takeUntil(this.destroy$)
      )
      .subscribe(([covidVaccine]: [VaccineTask, boolean]) => {
        if (covidVaccine != null) {
          this.prepopulatedFile = covidVaccine.documentUrl ? { fileName: this.getFileName(covidVaccine.documentUrl), fileUrl: covidVaccine.documentUrl } : null;
          this.qualificationID = covidVaccine.qualificationID;
        } else {
          this.qualificationID = null;
          this.prepopulatedFile = null;
        }
        this._createForm();
      });
  }

  subscribeToIsMobileSelector(): void {
    this.isMobile$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile) => {
        this.fileTruncationFunction.next(generateFileTruncationSettings({
          maxLength: isMobile? 
            defaultTruncationSettings.mobileMaxLength:
            defaultTruncationSettings.maxLength
          })
        );
        this.isDesktop = !isMobile;
      });
  }

  onSave(isValid: boolean) {
    if (isValid) {
      const options: IFileUploadOptions = {
        target: FileUploadTargets.CovidVaccination,
        qualificationId: this.qualificationID,
        isDeleting: this.qualificationID !== null,
      };
      this._store.dispatch(new UploadCovidVaccine(options, this.files));
    }
  }

  handleFileInput(event: HcEvent) {
    this.prepopulatedFile = null;
    this.form.controls.fileUpload.markAsTouched();
    this.form.markAllAsTouched();
    this.form.markAsDirty();
    if (event?.eventValue?.length) {
      this.files = Array.from(event.eventValue);
    } else {
      this.files = [];
    }
  }

  getFileName(document: string): string {
    const fileName = document.split('/').pop();
    return fileName;
  }

  showAlternateProofModal(): void {
    const dialogData: IDialogParameters = {
      title: 'Alternative Proof',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.alternateProofTemplate,
    };
    this._dialogService.showDialog(dialogData);
  }
  
  hasPrepopulatedFile(): boolean {
    return this.prepopulatedFile != null;
  }
}
