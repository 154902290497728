import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NursePortalApi } from 'src/app/services';
import {
  ETechnologyActions,
  TechnologyActions,
  GetTechnologySuccess,
  GetTechnologyError,
  AddTechnology,
  AddTechnologySuccess,
  AddTechnologyError,
  DeleteTechnology,
  DeleteTechnologySuccess,
  DeleteTechnologyError,
  UpdateTechnology,
  UpdateTechnologySuccess,
  UpdateTechnologyError,
  GetTechnology,
  ResetDeleteTechnology,
  ResetUpdateTechnology,
  ResetAddTechnology,
  GetTechnologyByID,
  GetTechnologyByIDSuccess,
} from './technology.actions';
import { Technology } from 'src/app/common';
import { TaskCompletionReturnObject } from 'src/app/common/models/task-competion-return-object';
import { Store } from '@ngrx/store';
import { IAppState } from '../app/app.state';
import { selectTechnology} from './technology.selectors';
import { NotificationService } from 'hc-design-system-lib';

@Injectable({
  providedIn: 'root',
})
export class TechnologyEffects {
  constructor(private actions$: Actions, private _api: NursePortalApi, private _store: Store<IAppState>,private _notificationService: NotificationService) {}

  getTechnology$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TechnologyActions>(ETechnologyActions.GetTechnology),
      exhaustMap(() =>
        this._api.getTechnology().pipe(
          map((technologyListData: Technology[]) => new GetTechnologySuccess(technologyListData)),
          catchError((error: Error) => of(new GetTechnologyError(error)))
        )
      )
    );
  });

  getTechnologyByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TechnologyActions>(ETechnologyActions.GetTechnologyByID),
      withLatestFrom(this._store.select(selectTechnology)),
      switchMap(([action,techList]: [GetTechnologyByID, Technology[]]) => {
        if (!action.technologyId) {
          return of(new GetTechnologyByIDSuccess(null));
        }
        else
        {
          return of(new GetTechnologyByIDSuccess(techList.find(x => x.id == action.technologyId)));
        }
      })
    )
  );

  addTechnology$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TechnologyActions>(ETechnologyActions.AddTechnology),
      exhaustMap((action: AddTechnology) =>
        this._api.addBulkTechnology(action.payload).pipe(
          map((response: TaskCompletionReturnObject) => new AddTechnologySuccess(response, action.payload)),
          catchError((error: Error) => of(new AddTechnologyError(error)))
        )
      )
    );
  });

  deleteTechnology$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TechnologyActions>(ETechnologyActions.DeleteTechnology),
      exhaustMap((action: DeleteTechnology) =>
        this._api.deleteTechnology(action.payload.id).pipe(
          map((response: TaskCompletionReturnObject) => new DeleteTechnologySuccess(response, action.payload)),
          catchError((error: Error) => of(new DeleteTechnologyError(error)))
        )
      )
    );
  });

  updateTechnology$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TechnologyActions>(ETechnologyActions.UpdateTechnology),
      exhaustMap((action: UpdateTechnology) =>
        this._api.updateTechnology(action.payload).pipe(
          map((setTechnologyData: TaskCompletionReturnObject) => new UpdateTechnologySuccess(setTechnologyData)),
          catchError((error: Error) => of(new UpdateTechnologyError(error)))
        )
      )
    );
  });
  getTechnologyOnChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TechnologyActions>(ETechnologyActions.AddTechnologySuccess, ETechnologyActions.DeleteTechnologySuccess, ETechnologyActions.UpdateTechnologySuccess),
      map(() => new GetTechnology())
    );
  });

  resetDeleteTechnologyOnDelete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TechnologyActions>(ETechnologyActions.DeleteTechnologySuccess),
      map(() => new ResetDeleteTechnology())
    );
  });

  resetUpdateTechnologyOnUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TechnologyActions>(ETechnologyActions.UpdateTechnologySuccess),
      map(() => new ResetUpdateTechnology())
    );
  });

  resetAddTechnologyOnAdd$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TechnologyActions>(ETechnologyActions.AddTechnologySuccess),
      map(() => new ResetAddTechnology())
    );
  });

  technologySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<TechnologyActions>(ETechnologyActions.AddTechnologySuccess,ETechnologyActions.UpdateTechnologySuccess),
        tap(() => this._notificationService.showNotification('Your EMR Software has been successfully saved.', 'success'))
      ),
    { dispatch: false }
  );

  technologyError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<TechnologyActions>(ETechnologyActions.AddTechnologyError,ETechnologyActions.UpdateTechnologyError, ETechnologyActions.DeleteTechnologyError),
        tap(() => this._notificationService.showNotification('We were unable to save your changes. Please try again later.', 'error'))
      ),
    { dispatch: false }
  );

  technologyDelete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<TechnologyActions>(ETechnologyActions.DeleteTechnologySuccess),
        tap(() => this._notificationService.showNotification('Your EMR Software was deleted.', 'success'))
      ),
    { dispatch: false }
  );



}
