<div fxLayout="column" class=" viewport standard-padding" fxFlex>
  <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
    <img height="250" src="/assets/img/notFoundIcon.svg" alt="Empty Hospital Bed with Nurse Will Return Soon sign.svg" />
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
      <div class="header centerText">
        No Internet Connection
      </div>
      <div class="centerText">Please connect to a network and the application will automatically reload</div>
    </div>
  </div>
</div>
