import { ApplicationReviewModel, IJob, IJobFilterAPI, ISimilarJobsByIdResponseModel, Submittal, TaskCompletionReturnObject } from 'src/app/common';
import { ContractType } from 'src/app/common/contracts/contract-type';
import { JobDataModel } from 'src/app/common/models/job-data-model';
import { JobPreferenceReturn } from 'src/app/common/models/job-preference';
import { initializeData, IDataState } from 'src/app/store/app/app.models';

export interface JobsState {
  jobPreferences: IDataState<JobPreferenceReturn>;
  jobRecommendationsByPreference: IDataState<IJob[]>;
  jobSubmittals: IDataState<Submittal[]>;
  lastArchivedSubmittal: IDataState<Submittal>;
  jobFilterV2: IDataState<IJobFilterAPI>;
  contractType: IDataState<ContractType>;
  availableJobs: IDataState<{totalCount: number, jobs: IJob[]}>;
  similarJobs: IDataState<{totalCount: number, jobs: IJob[]}>;
  similarJobsByJobId: IDataState<ISimilarJobsByIdResponseModel>;
  similarJobsByFacility: IDataState<IJob[]>;
  setJobPreferencesResult: IDataState<JobPreferenceReturn>;
  locationsByName: IDataState<any[]>;
  specificJob: IDataState<IJob>;
  specificJobMapUrl: IDataState<string>;
  hasAutoOfferAssignments: IDataState<boolean>;
  specificSavedJob: IDataState<number>;
  savedJobs: IDataState<JobDataModel>;
  recommendedMatchedJobs: IDataState<IJob[]>;
  applyToJobResult: IDataState<number>;
  updateApplicationResult: IDataState<number>;
  application: IDataState<ApplicationReviewModel>;
  updateProfileReviewResult: IDataState<TaskCompletionReturnObject>;
}

export const initialJobsState: JobsState = {
  jobPreferences: initializeData(),
  setJobPreferencesResult: initializeData(),
  jobRecommendationsByPreference: initializeData(),
  jobSubmittals: initializeData(),
  lastArchivedSubmittal: initializeData(),
  jobFilterV2: initializeData(),
  contractType: initializeData(),
  availableJobs: initializeData(),
  similarJobs: initializeData(),
  similarJobsByJobId: initializeData(),
  similarJobsByFacility: initializeData(),
  locationsByName: initializeData(),
  specificJob: initializeData(),
  specificJobMapUrl: initializeData(),
  hasAutoOfferAssignments: initializeData(),
  specificSavedJob: initializeData(),
  savedJobs: initializeData(),
  recommendedMatchedJobs: initializeData(),
  applyToJobResult: initializeData(),
  updateApplicationResult: initializeData(),
  application: initializeData(),
  updateProfileReviewResult: initializeData(),
};
