import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CandidateSkillSummary } from '../common/models/candidate-skill-summary-model';
import { SkillSummary } from '../common/models/skill-summary';
import { Skill } from '../common/models/skill';
import { CandidateSkill } from '../common/models/candidate-skill';
import {
  CreateCandidateSkillRequest,
  UpdateCandidateSkillRequest,
} from '../common/models/skill-question-form';

@Injectable({
  providedIn: 'root',
})
export class GuestApiService {
  private httpOptions: { headers: HttpHeaders };

  constructor(private _http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': environment.ocpGuestApimSubscriptionKey,
      }),
    };
  }

  getSkills(): Observable<any> {
    return this._http.get<SkillSummary>(
      `${environment.guestApiBaseUrl}/v1/skills`,
      this.httpOptions
    );
  }

  getCandidateSkills(email: string): Observable<CandidateSkillSummary[]> {
    const encodedEmail = btoa(email);
    return this._http.get<CandidateSkillSummary[]>(
      `${environment.guestApiBaseUrl}/v1/candidate-skills?candidate=${encodedEmail}`,
      this.httpOptions
    );
  }

  getSkillById(id: number): Observable<Skill> {
    return this._http.get<Skill>(
      `${environment.guestApiBaseUrl}/v1/skills/${id}`,
      this.httpOptions
    );
  }

  getCandidateSkillById(candidateSkillId: string): Observable<CandidateSkill> {
    return this._http.get<CandidateSkill>(
      `${environment.guestApiBaseUrl}/v1/candidate-skills/${candidateSkillId}`,
      this.httpOptions
    );
  }

  createCandidateSkill(
    skillForm: CreateCandidateSkillRequest
  ): Observable<CandidateSkill> {
    let postHeaders = this.httpOptions.headers.append('email', skillForm.email);
    return this._http.post<CandidateSkill>(
      `${environment.guestApiBaseUrl}/v1/candidate-skills/create`,
      skillForm,
      { headers: postHeaders }
    );
  }

  updateCandidateSkill(
    skillForm: UpdateCandidateSkillRequest,
    candidateSkillId: string,
    email: string
  ): Observable<CandidateSkill> {
    let postHeaders = this.httpOptions.headers.append('email', email);
    return this._http.patch<CandidateSkill>(
      `${environment.guestApiBaseUrl}/v1/candidate-skills/${candidateSkillId}`,
      skillForm,
      { headers: postHeaders }
    );
  }
}
