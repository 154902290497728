import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AppUrls } from 'src/app/app-urls';
import { IJobFilter } from 'src/app/common';
import { JobBadgeData } from 'src/app/common/models/job-badge-data';
import { NavHelper } from 'src/app/services';
import { JobAreaContext } from 'src/app/services/job-area-context.service';

@Component({
  selector: 'app-card-template',
  templateUrl: './card-template.component.html',
  styleUrls: ['./card-template.component.scss']
})
export class CardTemplateComponent implements OnInit {
  @Input() model: CardModel;
  @Input() config: CardComponentConfig;
  @Input() disabled: boolean;
  @Input() Filters: IJobFilter;
  @Input() recommendedJob = false;
  @Output() jobSelected = new EventEmitter<void>();

  id: string;
  showStatusBar: boolean;
  showInfo: boolean;
  showBadges: boolean;
  showLinks: boolean;
  showTransferApp: boolean;
  icons: string[] = [];
  jobName: string;
  onlyPanels = false;

  constructor(public _ctx: JobAreaContext,
    private _nav: NavHelper,
    private _router: Router,
    ) { }

  ngOnInit() {
    this.id = this.model.jobId;
    this.showInfo =  this.config.showInfoDetails;
    this.showTransferApp = this.config.showTransferApplication;
    this.showStatusBar = this.config.showStatus;
    this.showBadges = this.model.badges.length !== 0 ? true : false;
    this.showLinks = this.model.links.length !== 0 ? true : false;
    if (!this.showBadges && !this.showLinks) {
      this.onlyPanels = true;
    }
  }


  clickEvent(event: any) {
    event.stopPropagation();
  }

  viewSpecificJob() {
    //everything else

    if (this.config.useEmitter) {
      this.jobSelected.emit();
      return;
    }

    if (this._router.url.includes('/job-search?index=1') || !this._router.url.includes('/job-search')) {
      this._nav.goToJobsSpecific(this.id, this.recommendedJob);
    } else {
      this._router.navigateByUrl(`${AppUrls.JOBS}?focusJob=${this.id}`).then(o =>
        this._nav.goToJobsSpecific(this.id, this.recommendedJob)
      );
    }
  }

  formatDate(date: any) {
    let d = new Date(date);
    var hours = d.getHours();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours == 0 ? 12 : hours;
    var minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${hours}:${minutes} ${ampm}`;
  }
}

export class CardComponentConfig {
  showStatus = false;
  showBadges = false;
  showInfoDetails = false;
  showTransferApplication = true;
  showLinks = false;
  useEmitter? = false;
}

export class CardFooterPanel {
  label: string;
  value: string;
}

export class CardModel {
  constructor() {
    this.leftPanel = new CardFooterPanel();
    this.centerPanel = new CardFooterPanel();
    this.rightPanel = new CardFooterPanel();
    this.icons = [];
    this.badges = [];
    this.status = new CardStatusModel();
    this.links = [];
  }
  jobId: string;
  title: string;
  subtitle: string;
  location: string;
  icons: CardIconModel[];
  badges: JobBadgeData[];
  leftPanel: CardFooterPanel;
  centerPanel: CardFooterPanel;
  rightPanel: CardFooterPanel;
  status: CardStatusModel;
  links: CardLinkModel[];
  transferApp: TransferModel;
  noLongerAvailable: boolean;
  noLongerAvailableDate: Date;
}

export class TransferModel {
  transferName: string;
  transferFunction: (event: any, id: string) => void;
  jobOrderInactiveDate?: Date;
  matchingInactiveJobOrder: string;
}

export class CardStatusModel {
  statusText: string;
  statusDescription: string;
  statusIcon: string;
  statusIconFunction: () => void;
  cssClassName: string;
}

export class CardIconModel {
  iconName: string;
  className: string;
  clickFunction: (event: any) => void;
}

export class CardLinkModel {
  linkName: string;
  showLink: boolean;
  linkFunction: (event: any) => void;
}
