import { Certification } from 'src/app/common';
import { initializeData, IDataState } from 'src/app/store/app/app.models';

export interface ICertificationsState {
  certifications: IDataState<Certification[]>;
  populatedCertification: IDataState<any>;
  certificationAction: IDataState<any>;
}

export const initialCertificationsState = () => ({
  certifications: initializeData(),
  populatedCertification: initializeData(),
  certificationAction: initializeData(),
});
