<div class="viewport">
  <div fxLayout="column" class="body" fxFlex fxLayoutGap="64px">
    <div fxLayoutAlign="end center">
      <button id="mulitpleFoundSignIn" mat-raised-button color="primary" (click)="logout()">Sign In</button>
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
      <img height="250" src="/assets/img/notFoundIcon.svg" />
      <div class="text-area" fxLayout="column" fxLayoutGap="32px">
        <div>
          Oops! Your email is already active. We are reviewing your information and will notify you when this is resolved. Thank you for your patience.
        </div>
        <mat-divider></mat-divider>
        <div>
          If you have questions contact support at <a
            href="mailto:ondemandsupport@healthcarousel.com">ondemandsupport@healthcarousel.com</a>
        </div>

      </div>
    </div>
  </div>
</div>