<ng-container *ngIf="{
  job: job$ | async,
  application: application$ | async,
  tasks: tasks$ | async,
  formSaving: applyProcessSaving$ | async
} as observables">
  <app-loading-screen-spinner [show]="!form"></app-loading-screen-spinner>
  <app-loading-screen-spinner [show]="observables.formSaving" message="Submitting..."></app-loading-screen-spinner>
  <div *ngIf="!successfulSubmission; else successMessage">
    <hc-heading [size]="headingSizeH6" class="form-heading">Review Information & Submit</hc-heading>
    <form class="base-form tasks-responsive-modal-form" [formGroup]="form" *ngIf="form">
      <div class="tasks-responsive-modal-form__inputs">
        <hc-masked-input
          id="phoneNumberInput"
          placeholder="Enter Phone Number"
          label="Phone Number"
          [errorMessage]="getErrorMessage(form?.controls?.phone, 'phone number')"
          [control]="form?.controls?.phone"
          [required]=true
          [mask]="phoneMask"
          [disabled]="observables.formSaving"
        ></hc-masked-input>
        <div class="estimated-start-date">
          <hc-label class="label-text" isInputLabel=true>
            Estimated Start Date
          </hc-label>
          <div class="estimated-start-date-content">
            {{estimatedStartDate}}
          </div>
        </div>
        <hc-date-picker
          id="desiredStartDate"
          placeholder="Desired Start Date"
          label="Desired Start Date"
          [errorMessage]="getErrorMessage(form?.controls?.startDate, 'start date')"
          [control]="form?.controls?.startDate"
          [required]=true
          [disabled]="observables.formSaving"
        ></hc-date-picker>
        <hc-input
          class="requested-time-off"
          id="requestedTimeOff"
          label="Request Time Off"
          placeholder="Enter requested time off dates"
          [control]="form?.controls?.requestedTimeOff"
          [hint]=requestTimeOffNote
          [disabled]="observables.formSaving"
        ></hc-input>
      </div>
      <div class="tasks-responsive-modal-form__buttons">
        <hc-button
          segmentTrackCTA
          (click)="onSubmit(observables.tasks)"
          [disabled]="!form?.valid || observables.formSaving"
          alwaysDisplayFullWidth=true
        >
          Submit Application
        </hc-button>
        <hc-button
          class="tasks-responsive-modal-form__buttons__cancel"
          segmentTrackCTA
          (click)="cancel()"
          alwaysDisplayFullWidth=true
          [appearance]="secondaryButtonAppearance"
          [disabled]="observables.formSaving"
        >
          Cancel
        </hc-button>
      </div>
    </form>
  </div>
  <ng-template #successMessage>
    <app-application-success></app-application-success>
  </ng-template>
</ng-container>
