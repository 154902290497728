import { Component, OnDestroy, OnInit } from '@angular/core';
import { NurseTask } from 'src/app/common/models/db-objects';
import { ActivatedRoute } from '@angular/router';
import { TaskStatusConstants } from 'src/app/common/models/task-status';
import { FormHeaderService, NavHelper } from 'src/app/services';
import { TaskNavigatorService } from 'src/app/services/task-navigator.service';
import { TaskStatus } from 'hc-design-system-lib/lib/components/cards/task-card/task-card.component';
import { BadgeColor, BodySize, HeadingSize, LinkTarget } from 'hc-design-system-lib';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IAppState } from 'src/app/store/app/app.state';
import { selectTasksWithTextInfo, selectTasksLoading } from 'src/app/store/tasks/tasks.selectors';
import { GetTasks } from 'src/app/store/tasks/tasks.actions';
import { NurseModel } from 'src/app/common';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { GetNurse } from 'src/app/store/userContext/userContext.actions';
import { ProfileTasksViewed, TasksViewed } from 'src/app/store/segment/segment.actions';
import { DashboardObject } from 'src/app/modules/dashboard/pages/dashboard/dashboard.component';
import { selectInProgressJobSubmittals, selectJobSubmittalsLoading } from 'src/app/store/jobs/jobs.selectors';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss'],
})
export class TasksListComponent implements OnInit, OnDestroy {
  readonly mapStatus = new Map<TaskStatusConstants, TaskStatus>([
    [TaskStatusConstants.inProgress, TaskStatus.InProgress],
    [TaskStatusConstants.pending, TaskStatus.Pending],
    [TaskStatusConstants.complete, TaskStatus.Completed],
  ]);
  readonly badgeOrangeColor = BadgeColor.Orange;
  readonly headingSizeH5 = HeadingSize.H5;
  readonly headingSizeH4 = HeadingSize.H4;
  readonly headingSizeH3 = HeadingSize.H3;
  readonly headingSizeH2 = HeadingSize.H2;
  readonly bodySizeSmall = BodySize.Small;
  readonly linkTarget = LinkTarget.Self;

  nurse: NurseModel;
  nurseSubscription: Subscription;
  nurse$: Observable<NurseModel> = this._store.pipe(select(selectNurseData));

  tasks: NurseTask[];
  tasksSubscription: Subscription;
  tasks$: Observable<NurseTask[]> = this._store.pipe(select(selectTasksWithTextInfo));
  tasksLoading$: Observable<boolean> = this._store.pipe(select(selectTasksLoading));

  submittals$: Observable<number> = this._store.pipe(select(selectInProgressJobSubmittals));

  submittalsLoading$: Observable<boolean> = this._store.pipe(select(selectJobSubmittalsLoading));

  toDoTasks: NurseTask[] = [];
  pendingTasks: NurseTask[] = [];
  completedTasks: NurseTask[] = [];

  totalItems: number;
  completedItems: number;

  title = 'Tasks';
  TaskStatusConstants: typeof TaskStatusConstants = TaskStatusConstants;
  showPayTasksOnly: boolean;
  showApplyTasksOnly: boolean;
  dashboardObject: DashboardObject;
  progressValue: number;

  primaryButtonAppearance = ButtonAppearance.Primary;
  secondaryButtonAppearence = ButtonAppearance.Secondary;

  constructor(
    private _route: ActivatedRoute,
    private _nav: NavHelper,
    private _taskNav: TaskNavigatorService,
    private _store: Store<IAppState>,
    public _formHeaderService: FormHeaderService,
  ) {}

  ngOnInit() {
    this._store.dispatch(new ProfileTasksViewed());
    this.showPayTasksOnly = this._route.snapshot.queryParamMap.get('pay') === 'true';
    this.showApplyTasksOnly = this._route.snapshot.queryParamMap.get('apply') === 'true';
    this._store.dispatch(new TasksViewed(this.showPayTasksOnly ? 'pay' : this.showApplyTasksOnly ? 'apply' : 'all'));

    this.addNurseSubscription();
    this.addTasksSubscription();

    this._store.dispatch(new GetTasks(true));
  }

  ngOnDestroy(): void {
    this.nurseSubscription?.unsubscribe();
    this.tasksSubscription?.unsubscribe();
  }

  addNurseSubscription(): void {
    this.nurseSubscription = this.nurse$.subscribe((nurseData: NurseModel) => {
      if (nurseData) {
        this.nurse = nurseData;
      } else {
        this._store.dispatch(new GetNurse());
      }
    });
  }

  addTasksSubscription(): void {
    this.tasksSubscription = this.tasks$.subscribe((tasks: any[]) => {
      if (tasks) {
        let taskList = tasks;

        if (this.showPayTasksOnly) {
          taskList = taskList.filter((x) => x.requiredForPay);
        }
        if (this.showApplyTasksOnly) {
          taskList = taskList.filter((x) => x.status !== TaskStatusConstants.pending && x.requiredForApply === true);
          this._taskNav.applyTasksRemaining = taskList;
          this._taskNav.setupPopupState();

          this.title = 'Profile Tasks for Applying';
        }

        this.tasks = taskList;
        this._formHeaderService.resetFormHeaderAttributes({
          title: this.title,
        });

        this.getToDoTasks();
        this.getPendingTasks();
        this.getCompletedTasks();
        this.getProgressValue();
      }
    });
  }

  getToDoTasks(): void {
    this.toDoTasks = this.tasks.filter((t) => t.status !== TaskStatusConstants.complete && t.status !== TaskStatusConstants.pending);
  }

  getPendingTasks(): void {
    this.pendingTasks = this.tasks.filter((t) => t.status === TaskStatusConstants.pending);
  }

  getCompletedTasks(): void {
    this.completedTasks = this.tasks.filter((t) => t.status === TaskStatusConstants.complete);
  }

  getProgressValue(): void {
    this.totalItems = this.tasks.length;
    this.completedItems = this.completedTasks.length + this.pendingTasks.length;

    if (this.totalItems && this.completedItems) {
      this.progressValue = (this.completedItems / this.totalItems) * 100;
    } else {
      this.progressValue = 0;
    }
  }

  onButtonClick(): void {
    if (this.showApplyTasksOnly) {
      this.returnToJob();
    } else {
      this._nav.goToJobSearch();
    }
  }

  goTo() {
    this._nav.goToJobSearch();
  }

  returnToJob() {
    if (sessionStorage.getItem('InProgressApplication')) {
      this._nav.goToApplicationReview(sessionStorage.getItem('InProgressApplication'));
    } else {
      this._nav.goToJobSearch();
    }
  }

  returnToJobDetails() {
    this._nav.goToJobsSpecific(sessionStorage.getItem('InProgressApplication'));
    this._taskNav.removeApplyJobTag();
  }

  cancel(): void {
    this._nav.goToSubmittals();
  }
}
