import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PortalStatuses } from 'src/app/common/models/submittal-constants';

@Component({
  selector: 'app-status-info-popup',
  templateUrl: './status-info-popup.component.html',
  styleUrls: ['./status-info-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StatusInfoPopupComponent implements OnInit {
  portalStatuses = PortalStatuses;
  constructor(public dialogRef: MatDialogRef<StatusInfoPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: number) {}

  ngOnInit() {}
  // Workaround for angular component issue #13870
  disableAnimation = true;
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  cancel() {
    this.dialogRef.close();
  }
}
