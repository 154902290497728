import {
  EWorkHistoryContextActions,
  WorkHistoryContextActions,
} from './workHistoryContext.actions';
import {
  initialWorkHistoryContextState,
  IWorkHistoryContextState,
} from './workHistoryContext.state';

export function workHistoryReducer(
  state = initialWorkHistoryContextState,
  action: WorkHistoryContextActions
): IWorkHistoryContextState {
  switch (action.type) {
    case EWorkHistoryContextActions.GetWorkHistoryList:
      return {
        ...state,
        workHistoryList: {
          ...state.workHistoryList,
          error: null,
          loading: true,
        },
        workHistoryAction: {
          requestId: null,
          data: null,
          error: null,
          loading: false,
        },
      };
    case EWorkHistoryContextActions.GetWorkHistoryListSuccess:
      return {
        ...state,
        workHistoryList: {
          data: action.payload.workHistoryList,
          expirationDate: action.payload.expirationDate,
          error: null,
          loading: false,
        },
      };
    case EWorkHistoryContextActions.GetWorkHistoryListError:
      return {
        ...state,
        workHistoryList: {
          ...state.workHistoryList,
          expirationDate: null,
          error: action.error,
          loading: false,
        },
      };

    case EWorkHistoryContextActions.GetWorkHistoryById:
      return {
        ...state,
        workHistory: {
          ...state.workHistory,
          error: null,
          loading: true,
        },
        workHistoryAction: {
          requestId: null,
          data: null,
          error: null,
          loading: false,
        },
      };
    case EWorkHistoryContextActions.GetWorkHistoryByIdSuccess:
      return {
        ...state,
        workHistory: {
          data: action.payload.workHistory,
          expirationDate: action.payload.expirationDate,
          error: null,
          loading: false,
        },
      };
    case EWorkHistoryContextActions.GetWorkHistoryByIdError:
      return {
        ...state,
        workHistory: {
          ...state.workHistory,
          expirationDate: null,
          error: action.error,
          loading: false,
        },
      };

    case EWorkHistoryContextActions.DeleteWorkHistory:
      return {
        ...state,
        workHistoryAction: {
          ...state.workHistoryAction,
          requestId: action.id,
          data: null,
          error: null,
          loading: true,
        },
        workHistory: {
          data: action.workHistoryToUpdate ?? null,
          error: null,
          loading: false,
          expirationDate: null,
        },
      };
    case EWorkHistoryContextActions.DeleteWorkHistorySuccess:
      return {
        ...state,
        workHistoryAction: {
          ...state.workHistoryAction,
          data: { payload: action.payload, type: action.type },
          error: null,
          loading: false,
        },
        workHistory: {
          data: null,
          error: null,
          loading: false,
          expirationDate: null,
        },
      };
    case EWorkHistoryContextActions.DeleteWorkHistoryError:
      return {
        ...state,
        workHistoryAction: {
          ...state.workHistoryAction,
          error: action.error,
          loading: false,
        },
      };

    case EWorkHistoryContextActions.UpdateWorkHistory:
      return {
        ...state,
        workHistoryAction: {
          ...state.workHistoryAction,
          requestId: action.workHistory.id,
          data: null,
          error: null,
          loading: true,
        },
      };
    case EWorkHistoryContextActions.UpdateWorkHistorySuccess:
      return {
        ...state,
        workHistoryAction: {
          ...state.workHistoryAction,
          data: { payload: action.payload, type: action.type },
          error: null,
          loading: false,
        },
        workHistory: {
          data: null,
          error: null,
          loading: false,
          expirationDate: null,
        },
      };
    case EWorkHistoryContextActions.UpdateWorkHistoryError:
      return {
        ...state,
        workHistoryAction: {
          ...state.workHistoryAction,
          error: action.error,
          loading: false,
        },
      };

    case EWorkHistoryContextActions.AddWorkHistory:
      return {
        ...state,
        workHistoryAction: {
          ...state.workHistoryAction,
          data: null,
          error: null,
          loading: true,
        },
      };
    case EWorkHistoryContextActions.AddWorkHistorySuccess:
      return {
        ...state,
        workHistoryAction: {
          ...state.workHistoryAction,
          data: { payload: action.payload, type: action.type },
          error: null,
          loading: false,
        },
      };
    case EWorkHistoryContextActions.AddWorkHistoryError:
      return {
        ...state,
        workHistoryAction: {
          ...state.workHistoryAction,
          error: action.error,
          loading: false,
        },
      };

    case EWorkHistoryContextActions.GetFacilityList:
      return {
        ...state,
        facilityResultList: {
          ...state.facilityResultList,
          error: null,
          loading: true,
        },
      };
    case EWorkHistoryContextActions.GetFacilityListSuccess:
      return {
        ...state,
        facilityResultList: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case EWorkHistoryContextActions.GetFacilityListError:
      return {
        ...state,
        facilityResultList: {
          ...state.facilityResultList,
          error: action.error,
          loading: false,
        },
      };
    case EWorkHistoryContextActions.ClearWorkHistory:
      return {
        ...state,
        workHistory: {
          ...state.workHistory,
          data: null,
          expirationDate: null
        },
      };

    default:
      return state;
  }
}
