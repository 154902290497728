import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import {
  BodySize,
  CardElevation,
  DialogService,
  HeadingSize,
  IDialogParameters,
  LinkSize,
  LinkTarget,
} from 'hc-design-system-lib';
import {
  ICardConfig,
  ITileCardConfig,
} from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import { Observable, of, combineLatest, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  ILookups,
  PortalStatuses,
  Submittal,
  SubmittalLinks,
} from 'src/app/common';
import { SubmittalsService } from 'src/app/services/submittals.service';
import { GetJobSubmittals } from 'src/app/store/jobs/jobs.actions';
import {
  selectJobSubmittals,
  selectJobSubmittalsLoading,
} from 'src/app/store/jobs/jobs.selectors';
import { selectLookups } from 'src/app/store/lookups/lookups.selectors';

@Component({
  selector: 'app-current-submission-carousel',
  templateUrl: './current-submission-carousel.component.html',
})
export class CurrentSubmissionCarouselComponent implements OnInit {
  @Input() hiddenStatuses: PortalStatuses[] = [];
  @Input() hiddenLinks: SubmittalLinks[] = [];
  @Input() completedTasks: boolean;
  @ViewChild('filDialogTemplate')
  filDialogTemplate: TemplateRef<any>;

  linkTarget: LinkTarget = LinkTarget.Self;
  linkSize: LinkSize = LinkSize.Body;
  bodySize: BodySize = BodySize.Body;
  headingSize: HeadingSize = HeadingSize.H2;
  headingSizeH3: HeadingSize = HeadingSize.H3;
  headingSizeH6: HeadingSize = HeadingSize.H6;
  submittalCards$: Observable<ICardConfig[]> = of([]);
  jobSubmittals$: Observable<Submittal[]> = this._store.pipe(
    select(selectJobSubmittals)
  );
  jobSubmittalsLoading$: Observable<boolean> = this._store.pipe(
    select(selectJobSubmittalsLoading)
  );
  lookups: ILookups;
  lookupsSubscription: Subscription;
  lookups$: Observable<ILookups> = this._store.pipe(select(selectLookups));

  constructor(
    private _store: Store,
    public _dialog: MatDialog,
    private _dialogService: DialogService,
    private _submittalsService: SubmittalsService
  ) {}

  currentSubmissionTileDetails: ITileCardConfig = {
    title: 'Current Submissions',
    bodyText: null,
    promptText: 'Learn how First in Line works',
    linkDetails: {
      title: 'View All',
      routerLink: '/my-submittals',
    },
  };

  ngOnInit() {
    this.initializeLookups();
  }

  ngOnDestroy(): void {
    this.lookupsSubscription?.unsubscribe();
  }

  initializeLookups(): void {
    this.lookupsSubscription = this.lookups$.subscribe((lookups) => {
      this.submittalCards$ = this.getJobSubmittals();
    });
  }

  getJobSubmittals(): Observable<ICardConfig[]> {
    return combineLatest([this.jobSubmittals$]).pipe(
      tap(([jobSubmittals]) => {
        if (jobSubmittals === null) {
          this._store.dispatch(new GetJobSubmittals());
        }
      }),
      map(([jobSubmittals]) => this.mapSubmittalsToCards(jobSubmittals))
    );
  }

  mapSubmittalsToCards(jobSubmittals) {
    const submittalDetails: ICardConfig[] = [];
    if (jobSubmittals && jobSubmittals.length > 0) {
      jobSubmittals.forEach((submittal: Submittal) => {
        const mappedCard = this._submittalsService.mapSubmittalToCard(
          submittal,
          this.hiddenLinks,
          this.hiddenStatuses
        );
        if (mappedCard) {
          submittalDetails.push(mappedCard);
        }
      });
    }
    return submittalDetails;
  }

  submittalCardLinkClicked(event) {
    this._submittalsService.submittalCardLinkClicked(
      event,
      this.completedTasks
    );
  }

  submittalCardHelp(event) {
    this._submittalsService.submittalCardHelp(event);
  }

  jobCardClicked(event: HcEvent, recommended = false): void {
    this._submittalsService.jobCardClicked(event, recommended);
  }

  showFirstInLineModal(): void {
    const dialogData: IDialogParameters = {
      title: 'First in Line',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.filDialogTemplate,
    };
    this._dialogService.showDialog(dialogData);
  }
}
