import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { FileUploadTargets, IFileUploadOptions } from 'src/app/common';
import { Education, HcinEducation, mapHcinToHctnEducation } from 'src/app/common/models/education';
import { DocumentHelperService, HCINPortalAPIService, NursePortalApi } from 'src/app/services';
import {
  GetEducationList,
  GetEducationListSuccess,
  GetEducationListError,
  EducationActions,
  EEducationActions,
  AddEducation,
  AddEducationSuccess,
  AddEducationError,
  AddEducationFileError,
  UpdateEducation,
  UpdateEducationSuccess,
  UpdateEducationError,
  UpdateEducationFileError,
  DeleteEducation,
  DeleteEducationSuccess,
  DeleteEducationError,
  GetEducation,
  GetEducationSuccess,
  GetEducationError,
  DeleteEducationQualification,
  GetUpdateDocumentObservable,
  GetCreateDocumentObservable,
  DeleteEducationReset,
  UpdateEducationReset,
  AddEducationReset,
} from 'src/app/store/education/education.actions';
import { IAppState } from '../app/app.state';
import { Store } from '@ngrx/store';
import {
  selectEducationExpirationDate,
  selectEducationExpired,
  selectEducationFileUrl,
  selectEducationListExpirationDate,
  selectEducationListExpired,
  selectEducationListResult,
  selectEducationResult,
} from './education.selectors';
import { getDateInFutureWithMinutes } from 'src/app/common/functions/date-manipulations';
import { GenericCompletionReturnObject } from 'src/app/common/models/generic-completion-return-model';
import { NotificationService } from 'hc-design-system-lib';
import { selectCanSeeInternational } from '../userContext/userContext.selectors';

@Injectable({
  providedIn: 'root',
})
export class EducationEffects {
  constructor(
    private actions$: Actions,
    private _store: Store<IAppState>,
    private _notificationService: NotificationService,
    private _api: NursePortalApi,
    private _hcinApi: HCINPortalAPIService,
    private _documentHelperService: DocumentHelperService
  ) {}

  getEducationList$ = createEffect(() => { 
      return this.actions$.pipe(
      ofType<EducationActions>(
        EEducationActions.GetEducationList, 
        EEducationActions.AddEducationSuccess, 
        EEducationActions.AddEducationFileError, 
        EEducationActions.UpdateEducationFileError
      ),
      concatLatestFrom(() => [
        this._store.select(selectCanSeeInternational), 
        this._store.select(selectEducationListResult), 
        this._store.select(selectEducationListExpired), 
        this._store.select(selectEducationListExpirationDate)
      ]),
      exhaustMap(([_action, canSeeInternational, educations, expired, expirationDate]: [GetEducationList, boolean, Education[], boolean, Date]) => {
        if(canSeeInternational) {
          return this._hcinApi.getEducations().pipe(
            map((hcinEducations: HcinEducation[]) => {
              educations = hcinEducations.map(mapHcinToHctnEducation);
              return new GetEducationListSuccess({ educations: educations ?? [], expirationDate: getDateInFutureWithMinutes(10) })
            }),
            catchError((error: Error) => of(new GetEducationListError(error)))
          );
        }

        if (!expired) {
          return of(new GetEducationListSuccess({ educations, expirationDate }));
        }

        return this._api.getEducation().pipe(
          map((educations: Education[]) => new GetEducationListSuccess({ educations: educations ?? [], expirationDate: getDateInFutureWithMinutes(10) })),
          catchError((error: Error) => of(new GetEducationListError(error)))
        );
      })
    ) 
  });

  getEducation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<EducationActions>(EEducationActions.GetEducation),
      concatLatestFrom(() => [
        this._store.select(selectEducationResult),
        this._store.select(selectEducationExpired),
        this._store.select(selectEducationExpirationDate),
        this._store.select(selectEducationFileUrl)
      ]),
      exhaustMap(([action, education, expired, expirationDate, fileUrl]: [GetEducation, Education, boolean, Date, string]) => {
        if (!action.educationId) {
          return of(new GetEducationSuccess({ education: null, expirationDate: null }));
        }

        if (!expired && action.educationId === education?.id) {
          return of(new GetEducationSuccess({ education, expirationDate, fileUrl }));
        }

        return this._api.getEducation(action.educationId).pipe(
          switchMap((educationData: Education[]) => {
            const education = educationData[0];
            return education.sharepointURL
              ? this._documentHelperService.getDocumentUrlSubject(education.sharepointURL, education.qualificationId).pipe(
                  filter((resp) => !!resp),
                  take(1),
                  switchMap((fileUrl) => of(new GetEducationSuccess({ education, expirationDate: getDateInFutureWithMinutes(10), fileUrl })))
                )
              : of(new GetEducationSuccess({ education, expirationDate: getDateInFutureWithMinutes(10) }));
          }),
          catchError((error: Error) => of(new GetEducationError(error)))
        );
      })
    )
  });

  addEducation$ = createEffect(() => { 
    return this.actions$.pipe(
      ofType<EducationActions>(EEducationActions.AddEducation),
      exhaustMap((action: AddEducation) =>
        this._api.addEducation(action.payload.education).pipe(
          map(
            (addResponse: GenericCompletionReturnObject<string>) =>
              new GetCreateDocumentObservable({ education: { ...action.payload.education, id: addResponse.returnValue }, files: action.payload.files })
          ),
          catchError((error: Error) => of(new AddEducationError(error)))
        )
      )
    ) 
  });

  resetAddEducation$ = createEffect(() => { 
    return this.actions$.pipe(
      ofType<EducationActions>(EEducationActions.AddEducationSuccess, EEducationActions.AddEducationFileError),
      exhaustMap((_action: AddEducationSuccess) => of(new AddEducationReset()))
    ) 
  });

  getCreateDocumentObservable$ = createEffect(() => { 
    return this.actions$.pipe(
      ofType<EducationActions>(EEducationActions.GetCreateDocumentObservable),
      switchMap((action: GetUpdateDocumentObservable) => {
        const options: IFileUploadOptions = {
          itemId: action.payload.education.id,
          target: FileUploadTargets.Education,
        };

        return this._documentHelperService._getDocumentObservable(options, action.payload.files).pipe(
          filter((response) => !!response),
          take(1),
          switchMap((_response) => of(new AddEducationSuccess({ education: action.payload.education }))),
          catchError((error) => of(new AddEducationFileError({education: action.payload.education}, error)))
        );
      })
    ) 
  });

  updateEducation$ = createEffect(() => { 
      return this.actions$.pipe(
      ofType<EducationActions>(EEducationActions.UpdateEducation),
      exhaustMap((action: UpdateEducation) =>
        this._api.updateEducation(action.payload.education).pipe(
          map(
            (updateResponse: GenericCompletionReturnObject<number>) =>
              new GetUpdateDocumentObservable({
                education: { ...action.payload.education },
                files: action.payload.files,
                isDeleting: action.payload.isDeletingFile,
                updateResponse: updateResponse,
              })
          ),
          catchError((error: Error) => of(new UpdateEducationError(error)))
        )
      )
    ) 
  });

  resetUpdateEducation$ = createEffect(() => { 
      return this.actions$.pipe(
      ofType<EducationActions>(EEducationActions.UpdateEducationSuccess, EEducationActions.UpdateEducationFileError),
      exhaustMap((_action: UpdateEducationSuccess) => of(new UpdateEducationReset()))
    ) 
  });

  getUpdateDocumentObservable$ = createEffect(() => { 
    return this.actions$.pipe(
      ofType<EducationActions>(EEducationActions.GetUpdateDocumentObservable),
      switchMap((action: GetUpdateDocumentObservable) => {
        const options: IFileUploadOptions = {
          itemId: action.payload.education.id,
          target: FileUploadTargets.Education,
          isDeleting: action.payload.isDeleting,
          qualificationId: action.payload.education.qualificationId,
        };

        return this._documentHelperService._getDocumentObservable(options, action.payload.files).pipe(
          filter((response) => !!response),
          take(1),
          switchMap((_response) => of(new UpdateEducationSuccess({ education: action.payload.education }))),
          catchError((error) => of(new UpdateEducationFileError({ education: action.payload.education }, error)))
        );
      })
    ) 
  });

  deleteEducation$ = createEffect(() => { 
      return this.actions$.pipe(
      ofType<EducationActions>(EEducationActions.DeleteEducation),
      exhaustMap((action: DeleteEducation) =>
        this._api.deleteEducation(action.payload.id).pipe(
          switchMap((_deleteResponse: GenericCompletionReturnObject<number>) =>
            action.payload.qualificationId
              ? of(new DeleteEducationQualification({ educationId: action.payload.id, qualificationId: action.payload.qualificationId }))
              : of(new DeleteEducationSuccess(action.payload.id))
          ),
          catchError((error: Error) => of(new DeleteEducationError(error)))
        )
      )
    ) 
  });

  resetDeleteEducation$ = createEffect(() => { 
      return this.actions$.pipe(
      ofType<EducationActions>(EEducationActions.DeleteEducationSuccess),
      exhaustMap((_action: DeleteEducationSuccess) => of(new DeleteEducationReset()))
    ) 
  });

  deleteQualification$ = createEffect(() => { 
      return this.actions$.pipe(
      ofType<EducationActions>(EEducationActions.DeleteEducationQualification),
      exhaustMap((action: DeleteEducationQualification) =>
        this._api.deleteQualification(action.payload.qualificationId).pipe(
          switchMap(() => of(new DeleteEducationSuccess(action.payload.educationId))),
          catchError((error: Error) => of(new DeleteEducationError(error)))
        )
      )
    ) 
  });

  addOrUpdateEducationSuccessNotification$ = createEffect(
    () => { 
        return this.actions$.pipe(
        ofType<EducationActions>(EEducationActions.AddEducationSuccess, EEducationActions.UpdateEducationSuccess),
        tap(() => this._notificationService.showNotification('Your education was saved.', 'success'))
      ) 
    },
    { dispatch: false }
  );

  deleteEducationSuccessNotification$ = createEffect(
    () => { 
        return this.actions$.pipe(
        ofType<EducationActions>(EEducationActions.DeleteEducationSuccess),
        tap(() => this._notificationService.showNotification('Your education was deleted.', 'success'))
      ) 
    },
    { dispatch: false }
  );

  addOrUpdateEducationFileError$ = createEffect(
    () => { 
        return this.actions$.pipe(
        ofType<EducationActions>(EEducationActions.AddEducationFileError, EEducationActions.UpdateEducationFileError),
        tap(() => this._notificationService.showNotification('We were able to save your changes, but there was something wrong with the attachment. Please try again later.', 'error'))
      ) 
    },
    {
      dispatch: false,
    }
  );

  addOrUpdateOrDeleteEducationError$ = createEffect(
    () => { 
        return this.actions$.pipe(
        ofType<EducationActions>(EEducationActions.AddEducationError, EEducationActions.UpdateEducationError, EEducationActions.DeleteEducationError),
        tap(() => this._notificationService.showNotification('We were unable to save your changes. Please try again later.', 'error'))
      ) 
    },
    {
      dispatch: false,
    }
  );
}
