import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppUrls } from '../app-urls';
import { UserContext, NursePortalApi } from '../services';
import { VerifyContextService } from '../services/verify-context.service';
import { NurseModel } from '../common';

@Injectable({
  providedIn: 'root'
})
export class InactiveUserGuard implements CanActivate {


  constructor(
    private _userContext: UserContext,
    private _router: Router,
    private _api: NursePortalApi,
    private _verifyCtx: VerifyContextService
  ) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let nurse = this._userContext.nurse;
    if (nurse == null) {
      return this._userContext.refreshNurse().pipe(map(o => {
        nurse = this._userContext.nurse;
        return this.decide(nurse);
      }));
    } else {
      return this.decide(nurse);
    }
  }


  decide(model: NurseModel): any {
    if (!model.inactive) {
      return this._router.parseUrl(AppUrls.DASHBOARD);
    } else {
      return true;
    }
  }
}
