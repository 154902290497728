import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AccordionIconColor,
  BodySize,
  CardElevation,
  DialogService,
  HeadingSize,
  IDialogParameters,
  LoadingBarHeight,
  LoadingBarLength,
  NotificationService,
} from 'hc-design-system-lib';
import {
  IBadgeDetails,
  IIconDetailItem,
} from 'hc-design-system-lib/lib/components/banner/banner.interfaces';
import {
  ButtonAppearance,
  ButtonSize,
} from 'hc-design-system-lib/lib/components/button/button.enums';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import moment from 'moment';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, skipWhile, take, takeUntil, tap } from 'rxjs/operators';
import {
  IFilterGeneral,
  IJob,
  IJobFilter,
  ILookup,
  IProfessionalHierarchy,
  NurseModel,
} from 'src/app/common';
import { RequirementCategoryIds } from 'src/app/common/contracts/requirement-category-id';
import { ErrorMessages } from 'src/app/common/error-messages';
import { NurseTask } from 'src/app/common/models/db-objects';
import { JobInfoData } from 'src/app/common/models/info-card-data';
import { JobRequirementData } from 'src/app/common/models/job-requirement-data';
import { Recruiter } from 'src/app/common/models/recruiter';
import { RecruiterTaskCreationModel } from 'src/app/common/models/recruiter-task-creation-model';
import { TaskStatusConstants } from 'src/app/common/models/task-status';
import {
  ContactRecruiterPopUpComponent,
  ContactRecruiterType,
} from 'src/app/components/shared/contact-recruiter-pop-up/contact-recruiter-pop-up.component';
import { FormHeaderService, NavHelper } from 'src/app/services';
import { BasicSnackBarService } from 'src/app/services/basic-snack-bar.service';
import { JobAreaContext } from 'src/app/services/job-area-context.service';
import { AppEntryService } from 'src/app/shared/app-entry-service';
import { AngularticsEventTrack } from 'src/app/store/angulartics2/angulartics2.actions';
import {
  ArchiveSubmittal,
  CupidEventRecord,
  GetJobSubmittals,
  GetMapUrlForFacility,
  GetSpecificJob,
  SetJobFilterV2,
  SetSavedJob,
} from 'src/app/store/jobs/jobs.actions';
import {
  selectApplyProcessSaving,
  selectApplyToJobResult,
  selectSimilarJobsByFacilityId,
  selectSimilarJobsByJobId,
  selectSpecificJob,
  selectSpecificJobFromAvailableJobs,
  selectSpecificJobLoading,
  selectSpecificJobMapUrl,
  selectSpecificJobIsSaved,
} from 'src/app/store/jobs/jobs.selectors';
import {
  selectCompactCardDisplayStatusesLookup,
  selectProfessionalHierarchy,
  selectProfessionLookup,
  selectShiftLookup,
  selectSpecialtyLookup,
  selectStateLookup,
  selectTraumaLevelLookup,
  selectWeeklyHoursLookup,
  selectYesNoIdLookup,
  selectYesNoLookup,
} from 'src/app/store/lookups/lookups.selectors';
import { CreatePayPackageTask } from 'src/app/store/recruiter/recruiter.actions';
import { selectRecruiterData } from 'src/app/store/recruiter/recruiter.selectors';
import {
  ApplicationStarted,
  CallToActionClicked,
  ExternalLinkClicked,
  FacilityViewed,
} from 'src/app/store/segment/segment.actions';
import { GetTasks } from 'src/app/store/tasks/tasks.actions';
import {
  selectApplyTasks,
  selectToDoApplyTasks,
} from 'src/app/store/tasks/tasks.selectors';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { environment } from 'src/environments/environment';
import { CardComponentConfig } from '../../cards/card-template/card-template.component';
import { SimilarJobLookupType } from '../similar-jobs-list/similar-jobs-list.component';
import { DisclaimerPopupComponent } from './disclaimer-popup/disclaimer-popup.component';
import { PayDisplay, PayDisplayCalculator } from './pay/pay-display-calculator';
import { UrlHelper } from 'src/app/common/UrlHelper';
import { ContractType } from 'src/app/common/contracts/contract-type';
import { LOCAL_BADGE, TRAVEL_BADGE } from 'src/app/common/models/badge';
import { SearchFilterService } from '../../../../services/search-filter-service';

enum JobStatus {
  Closed,
  NotApplied,
  ApplicationInProgress,
  ApplicationComplete,
  ApplicationWithdrawn,
  ApplicationPreviouslyInProgressWithdrawn,
}

@Component({
  selector: 'app-specific-job',
  templateUrl: './specific-job.component.html',
  styleUrls: ['./specific-job.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpecificJobComponent implements OnInit, OnDestroy, AfterViewInit {
  // View children
  @ViewChild('reviewApplicationDialogTemplate')
  reviewApplicationDialogTemplate: TemplateRef<any>;

  @ViewChild('startApplicationDialogTemplate')
  startApplicationDialogTemplate: TemplateRef<any>;

  @ViewChild('customHeaderTemplate')
  headerTemplate: TemplateRef<any>;

  // Constants
  microBodySize = BodySize.Micro;
  headingSizeH2 = HeadingSize.H2;
  headingSizeH3 = HeadingSize.H3;
  headingSizeH5 = HeadingSize.H5;
  headingSizeH6 = HeadingSize.H6;
  cardElevation = CardElevation.None;
  buttonSizeNarrow = ButtonSize.Narrow;
  buttonAppearanceText = ButtonAppearance.Text;
  accordionIconDarkGray = AccordionIconColor.DarkGray;
  loadingHeightMedium = LoadingBarHeight.Medium;
  loadingHeightSmall = LoadingBarHeight.Small;
  loadingHeightSmallest = LoadingBarHeight.Smallest;
  loadingPercentLarge = LoadingBarLength.PercentLarge;
  loadingBarLong = LoadingBarLength.Long;
  loadingBarMedium = LoadingBarLength.Medium;
  loadingCircleSmall = LoadingBarLength.LoadingCircleSmall;

  // Variables
  jobConfig: CardComponentConfig;
  jobStatus: JobStatus;
  isSaving = false;
  stateLookup: Map<string, ILookup<string>>;
  traumaLevelLookup: Map<number, ILookup<string>>;
  yesNoLookup: Map<string, ILookup<string>>;
  yesNoIdLookup: Map<number, string>;
  shiftLookup: Map<number, ILookup<number>>;
  specialtyLookup: Map<string, ILookup<string>>;
  professionLookup: Map<string, ILookup<string>>;
  weeklyHoursLookup: Map<number, ILookup<number>>;
  professionHierarchy: IProfessionalHierarchy[];
  compactCardDisplayStatuses: Map<number, string>;
  lookupsSubscription: Subscription;
  requirements: JobRequirementData[];
  contractType: ContractType = null;
  specificJobRelatedData: {
    facility: any;
    profession: any;
    specialty: any;
    state: any;
    shift: any;
    traumaLevel: any;
    hospital: any;
    city: any;
  } = {
    facility: null,
    profession: null,
    specialty: null,
    state: null,
    shift: null,
    traumaLevel: null,
    hospital: null,
    city: null,
  };
  specificJobPreload: IJob;
  facilityData: JobInfoData[] = [];
  stateLicenseData: JobInfoData[] = [];
  paymentDisplayType: PayDisplay;
  primaryButtonText: string;
  jobBannerStatus: string;
  jobName: string;
  jobLocation: string;
  estWeeklyPay: string | null;
  estHourlyPay: number;
  estOvertimePay: number;
  jobBadges: IBadgeDetails[] = [];
  bannerPrimaryBadge: IBadgeDetails = null;
  bannerRibbonText: string[] = [];
  bannerDetailSectionConfig: IIconDetailItem[] = [];
  elizaRecruiterData: Recruiter;
  elizaAutoOpened = false;

  // Observables
  compactCardDisplayStatuses$: Observable<Map<number, string>> =
    this._store.select(selectCompactCardDisplayStatusesLookup);
  professionLookup$: Observable<Map<string, ILookup<string>>> =
    this._store.select(selectProfessionLookup);
  shiftLookup$: Observable<Map<number, ILookup<number>>> =
    this._store.select(selectShiftLookup);
  specialtyLookup$: Observable<Map<string, ILookup<string>>> =
    this._store.select(selectSpecialtyLookup);
  stateLookup$: Observable<Map<string, ILookup<string>>> =
    this._store.select(selectStateLookup);
  traumaLevelLookup$: Observable<Map<number, ILookup<string>>> =
    this._store.select(selectTraumaLevelLookup);
  yesNoLookup$: Observable<Map<string, ILookup<string>>> =
    this._store.select(selectYesNoLookup);
  weeklyHoursLookup$: Observable<Map<number, ILookup<number>>> =
    this._store.select(selectWeeklyHoursLookup);
  yesNoIdLookup$: Observable<Map<number, string>> =
    this._store.select(selectYesNoIdLookup);
  professionHierarchy$ = this._store.select(selectProfessionalHierarchy);
  nurse$: Observable<NurseModel> = this._store.select(selectNurseData);
  recruiter$: Observable<Recruiter> = this._store.select(selectRecruiterData);
  similarJobsByJobId$: Observable<IJob[]> = this._store
    .select(selectSimilarJobsByJobId)
    .pipe(filter((responseModel) => !!responseModel));
  similarJobsByFacilityId$: Observable<IJob[]> = this._store.select(
    selectSimilarJobsByFacilityId,
  );
  allApplyTasks$: Observable<NurseTask[]> =
    this._store.select(selectApplyTasks);
  toDoApplyTasks$: Observable<NurseTask[]> = this._store
    .select(selectToDoApplyTasks)
    .pipe(
      tap((tasks) => this.populateTasks(tasks)),
      filter((tasks) => !!tasks),
    );
  specificJobLoading$: Observable<boolean> = this._store.select(
    selectSpecificJobLoading,
  );
  specificJob$: Observable<IJob> = this._store.select(selectSpecificJob);
  specificJobMapUrl$: Observable<string> = this._store.select(
    selectSpecificJobMapUrl,
  );
  specificJobIsSaved$: Observable<boolean> = this._store.select(
    selectSpecificJobIsSaved,
  );
  applyToJobResult$: Observable<any> = this._store.select(
    selectApplyToJobResult,
  );
  applicationSaving$: Observable<boolean> = this._store.select(
    selectApplyProcessSaving,
  );

  // subscriptions and subjects
  private readonly destroy$ = new Subject<void>();

  get jobRequirements() {
    return this.requirements;
  }

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _location: Location,
    private _dialog: MatDialog,
    private _basicSnackBar: BasicSnackBarService,
    private _appEntryService: AppEntryService,
    private _store: Store,
    private _navHelper: NavHelper,
    private _payDisplayCalculator: PayDisplayCalculator,
    private _jobAreaContext: JobAreaContext,
    private _dialogService: DialogService,
    private _formHeaderService: FormHeaderService,
    private _notificationService: NotificationService,
  ) {}

  // This exists so we can use enums in the view
  PayDisplay = PayDisplay;
  ContractType = ContractType;
  ContactRecruiterType = ContactRecruiterType;
  SimilarJobLookupType = SimilarJobLookupType;

  ngOnInit() {
    this._formHeaderService.resetFormHeaderAttributes({
      showBackButton: true,
      backAction: () => this.goBack(),
    });

    this.addApplyToJobResultSubscription();
    this.addSpecificJobSubscription();

    this.jobConfig = {
      showStatus: false,
      showInfoDetails: true,
      showTransferApplication: false,
      showBadges: true,
      showLinks: false,
      useEmitter: true,
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._dialogService.closeAll();
  }

  ngAfterViewInit(): void {
    this._formHeaderService.setCustomContent(this.headerTemplate);
    combineLatest([
      this.specificJob$,
      this.specificJobLoading$,
      this.allApplyTasks$,
    ])
      .pipe(
        skipWhile(
          ([job, jobLoading]) =>
            jobLoading ||
            !job ||
            (job && !this.specificJobInStoreMatchesParam(job)),
        ),
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe(([, , allApplyTasks]) => {
        if (allApplyTasks.length === 0) {
          this._notificationService.showNotification(
            'Profile information not available. Please try again. If the problem persists, contact your recruiter.',
            'error',
          );
          return;
        }
        this.checkForApplicationReviewDialogParams();
      });
  }

  setContractType() {
    if (UrlHelper.GetContractType() === ContractType.Local) {
      this.contractType = ContractType.Local;
      this.bannerPrimaryBadge = LOCAL_BADGE;
    } else {
      this.contractType = ContractType.Travel;
      this.bannerPrimaryBadge = TRAVEL_BADGE;
    }
  }

  checkForApplicationReviewDialogParams(): void {
    if (this._route.snapshot.queryParams['FinishApplication'] === 'true') {
      if (this._route.snapshot.queryParams['CompletedTask'] === 'true') {
        this.showReviewApplicationDialog();
      } else {
        this.showStartApplicationDialog();
      }
    }
  }

  populateSpecificJob(
    job: IJob,
    jobLoading: boolean,
    specificJobPreload: IJob,
  ): void {
    if (
      (this.specificJobInStoreMatchesParam(job) || specificJobPreload) &&
      !job?.notFound
    ) {
      if (jobLoading && specificJobPreload) {
        job = null;
      }

      this.setJobInformation(job ?? specificJobPreload);
    }
  }

  populateTasks(tasks): void {
    if (!tasks) {
      this._store.dispatch(new GetTasks());
    }
  }

  specificJobInStoreMatchesParam(job: IJob): boolean {
    return job?.id?.toLowerCase() === this._route.snapshot.params['id'];
  }

  private setJobInformation(job: IJob): void {
    this.pullInRelatedData(job);
    this.setJobName();
    this.setJobLocation();
    this.setJobStatus(job);
    this.setPrimaryButtonText();
    this.saveJobIfInterestedParamIsPresent(job);
    this.setBannerRibbonText(job);
    this.setBannerIconDetails(job);
    this.setJobRequirements(job);
    this.setStateLicenseData(job);
    this.createInfoCardData(job);
    this.setBannerMessage(job);
    this.jobBadges = this._jobAreaContext
      .getJobBadges(true, job)
      .map((x) => x.badge);

    if (job.hospitalInfo?.fullAddress) {
      this._store.dispatch(
        new GetMapUrlForFacility(`${job.hospitalInfo.fullAddress}`),
      );
    }

    // Determine which message/section to show for the pay
    this.paymentDisplayType = this._payDisplayCalculator.DetermineDisplayType(
      job,
      this.yesNoIdLookup,
      this.contractType,
    );
    this.setEstWeeklyPay(job);
    this.setEstHourlyPay(job);
    this.setEstOvertimePay(job);
  }

  addSpecificJobSubscription(): void {
    this.specificJob$.pipe(takeUntil(this.destroy$)).subscribe((job) => {
      if (!this.specificJobInStoreMatchesParam(job)) {
        this._store.dispatch(
          new GetSpecificJob(this._route.snapshot.params['id']),
        );
      } else if (job?.notFound) {
        this.showJobNotFoundDialog();
      }
    });

    let specificJobPreload$ = this._store
      .select(
        selectSpecificJobFromAvailableJobs(this._route.snapshot.params['id']),
      )
      .pipe(take(1));

    combineLatest([
      this.weeklyHoursLookup$,
      this.compactCardDisplayStatuses$,
      this.professionLookup$,
      this.specialtyLookup$,
      this.shiftLookup$,
      this.traumaLevelLookup$,
      this.stateLookup$,
      this.yesNoLookup$,
      this.yesNoIdLookup$,
      this.professionHierarchy$,
      this.specificJob$,
      this.specificJobLoading$,
      this.recruiter$,
      specificJobPreload$,
    ])
      .pipe(
        skipWhile(
          ([
            _weeklyHours,
            _compactCard,
            professionLookup,
            _specLookup,
            _shiftLookup,
            _traumaLookup,
            _stateLookup,
            _yesNoLookup,
            _yesNoIdLookup,
            professionHierarchy,
            _specificJob,
            _specificJobLoading,
            _recruiter,
            _specificJobPreload,
          ]: [
            Map<number, ILookup<number>>,
            Map<number, string>,
            Map<string, ILookup<string>>,
            Map<string, ILookup<string>>,
            Map<number, ILookup<number>>,
            Map<number, ILookup<string>>,
            Map<string, ILookup<string>>,
            Map<string, ILookup<string>>,
            Map<number, string>,
            IProfessionalHierarchy[],
            IJob,
            boolean,
            Recruiter,
            IJob,
          ]) => !professionLookup || !professionHierarchy,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(
        ([
          weeklyHoursLookup,
          compactCardDisplayStatuses,
          professionLookup,
          specialtyLookup,
          shiftLookup,
          traumaLevelLookup,
          stateLookup,
          yesNoLookup,
          yesNoIdLookup,
          professionHierarchy,
          specificJob,
          specificJobLoading,
          recruiterEliza,
          specificJobPreload,
        ]: [
          Map<number, ILookup<number>>,
          Map<number, string>,
          Map<string, ILookup<string>>,
          Map<string, ILookup<string>>,
          Map<number, ILookup<number>>,
          Map<number, ILookup<string>>,
          Map<string, ILookup<string>>,
          Map<string, ILookup<string>>,
          Map<number, string>,
          IProfessionalHierarchy[],
          IJob,
          boolean,
          Recruiter,
          IJob,
        ]) => {
          this.weeklyHoursLookup = weeklyHoursLookup;
          this.compactCardDisplayStatuses = compactCardDisplayStatuses;
          this.professionLookup = professionLookup;
          this.specialtyLookup = specialtyLookup;
          this.shiftLookup = shiftLookup;
          this.traumaLevelLookup = traumaLevelLookup;
          this.stateLookup = stateLookup;
          this.yesNoLookup = yesNoLookup;
          this.yesNoIdLookup = yesNoIdLookup;
          this.professionHierarchy = professionHierarchy;

          this.specificJobPreload = specificJobPreload;
          this.setContractType();
          this.populateSpecificJob(
            specificJob,
            specificJobLoading,
            specificJobPreload,
          );
          // This is in place temporarily so that the Eliza Bot only shows for nurses without recruiters
          this.elizaRecruiterData = recruiterEliza;
          if (this.elizaRecruiterData != null) {
            if (
              this.elizaRecruiterData.id !==
              '00000000-0000-0000-0000-000000000000'
            ) {
              try {
                // @ts-ignore
                zE('messenger', 'hide');
              } catch {
                // ZenDesk Not Loaded Yet
              }
            } else {
              try {
                // @ts-ignore
                zE('messenger', 'show');

                if (this.elizaAutoOpened === false) {
                  this.elizaAutoOpened = true;
                  let timeout;

                  const openEliza = function () {
                    // @ts-ignore
                    zE('messenger', 'open');
                    window.removeEventListener('mousemove', mouseMoveListener);
                    window.removeEventListener('keydown', keydownListener);
                  };

                  function startInactiveTimeout() {
                    timeout = setTimeout(openEliza, 8000); // 8 seconds in milliseconds
                  }

                  function mouseMoveListener() {
                    clearTimeout(timeout);
                    startInactiveTimeout();
                  }

                  window.addEventListener('mousemove', mouseMoveListener);

                  function keydownListener() {
                    clearTimeout(timeout);
                    startInactiveTimeout();
                  }

                  window.addEventListener('keydown', keydownListener);

                  startInactiveTimeout();
                }
              } catch {
                // ZenDesk Not Loaded Yet
              }
            }
          }
        },
      );
  }

  addApplyToJobResultSubscription(): void {
    combineLatest([
      this.specificJob$,
      this.toDoApplyTasks$,
      this.applicationSaving$,
      this.applyToJobResult$,
    ])
      .pipe(
        skipWhile(([, , saving, result]) => saving || !result),
        takeUntil(this.destroy$),
      )
      .subscribe(([job, toDoApplyTasks, , result]) => {
        if (result && !this.hasCompletedAllTasks(toDoApplyTasks)) {
          if (result === 1) {
            // When Applying Successfully if "transferring" an application we have to inactivate
            if (sessionStorage.getItem('transferredApplicationId') !== null) {
              const transferredApplicationIds = sessionStorage
                .getItem('transferredApplicationId')
                .split(':');
              const transferredApplicationId = transferredApplicationIds[0];
              const matchingJobId = transferredApplicationIds[1];

              if (matchingJobId.toLowerCase() === job.id.toLowerCase()) {
                this._store.dispatch(
                  new ArchiveSubmittal({ id: transferredApplicationId }),
                );
                sessionStorage.setItem('transferredApplicationId', null);
              }
              this._store.dispatch(new GetSpecificJob(job.id));
              this._store.dispatch(new GetJobSubmittals(true));
            } else {
              this._basicSnackBar.displayEvent.emit({
                text: ErrorMessages.applicationNotCreated,
              });
            }
          }
        }
      });
  }

  setJobStatus(job: IJob) {
    if (job.closed) {
      this.jobStatus = JobStatus.Closed;
    } else if (job.hasWithdrawn && job.hasCompletedApplication) {
      this.jobStatus = JobStatus.ApplicationWithdrawn;
    } else if (job.hasWithdrawn && !job.hasCompletedApplication) {
      this.jobStatus = JobStatus.ApplicationPreviouslyInProgressWithdrawn;
    } else if (job.hasStarted && !job.applied) {
      this.jobStatus = JobStatus.ApplicationInProgress;
    } else if (!job.applied) {
      this.jobStatus = JobStatus.NotApplied;
    } else {
      this.jobStatus = JobStatus.ApplicationComplete;
    }
  }

  hasCompletedAllTasks(toDoApplyTasks: NurseTask[]): boolean {
    return !toDoApplyTasks?.find(
      (t) => t.status !== TaskStatusConstants.complete,
    );
  }

  setBannerMessage(job: IJob): void {
    if (job?.closed || job?.hasWithdrawn) {
      if (job.hasWithdrawn && job.hasCompletedApplication) {
        this.jobBannerStatus = `APPLICATION WITHDRAWN: ${moment(
          job.withdrawnDate,
        ).format('MM/DD')}`;
      } else if (job.closed) {
        this.jobBannerStatus = `NO LONGER AVAILABLE: ${moment(
          job.closedDate,
        ).format('MM/DD h:mm a')}`;
      }
    } else {
      this.jobBannerStatus = null;
    }
  }

  setPrimaryButtonText() {
    switch (this.jobStatus) {
      case JobStatus.NotApplied:
        this.primaryButtonText = 'Apply Now';
        break;
      case JobStatus.ApplicationPreviouslyInProgressWithdrawn:
        this.primaryButtonText = 'Apply Now';
        break;
      case JobStatus.ApplicationInProgress:
        this.primaryButtonText = 'Finish My Application';
        break;
      case JobStatus.ApplicationComplete:
        this.primaryButtonText = 'Application Status';
        break;
      case JobStatus.Closed:
        this.primaryButtonText = 'Search Similar Jobs';
        break;
      case JobStatus.ApplicationWithdrawn:
        this.primaryButtonText = null;
        break;
    }
  }

  setJobName(): void {
    if (
      this.specificJobRelatedData.specialty
    ) {
      this.jobName = `${this.specificJobRelatedData.specialty.name} Travel ${this.specificJobRelatedData.profession.name}`;
    } else {
      this.jobName = `Travel ${this.specificJobRelatedData.profession?.name}`;
    }
  }

  setJobLocation(): void {
    this.jobLocation = `${this.specificJobRelatedData.hospital} | ${this.specificJobRelatedData.city}, ${this.specificJobRelatedData.state?.code}`;
  }

  setBannerRibbonText(job: IJob) {
    this.bannerRibbonText = [];
    if (job.createdOn) {
      this.bannerRibbonText.push(
        'Posted ' + moment(job.createdOn).format('MMM D'),
      );
    }
    if (job.modifiedOn) {
      this.bannerRibbonText.push(
        'Updated ' + moment(job.modifiedOn).format('MMM D'),
      );
    }
    if (job.name) {
      this.bannerRibbonText.push('Job ID: ' + job.name);
    }
  }

  setBannerIconDetails(job: IJob) {
    this.bannerDetailSectionConfig = [];

    if (this.specificJobRelatedData.shift?.name) {
      this.bannerDetailSectionConfig.push({
        icon: 'brightness_medium',
        text: this.specificJobRelatedData.shift.name,
      });
    }

    if (job.startDate || job.assignmentLength) {
      let text: string;
      if (job.startDate) {
        text = this.getStartDate(job.startDate);
        text += job.assignmentLength ? ` (${job.assignmentLength} weeks)` : '';
      } else {
        text = job.assignmentLength + ' weeks';
      }

      this.bannerDetailSectionConfig.push({
        icon: 'calendar_month',
        text,
        infoOverlay: {
          text: 'Although the employer would like to have the position filled in this time frame, it can always be negotiated by 2-3 weeks.',
          heading: 'Start date',
        },
      });
    }

    if (job.hoursPerWeek) {
      this.bannerDetailSectionConfig.push({
        icon: 'schedule',
        text: job.hoursPerWeek + ' hours/week',
      });
    }

    if (job.quantityOpen) {
      this.bannerDetailSectionConfig.push({
        icon: 'people',
        text: `${job.quantityOpen} ${
          job.quantityOpen === 1 ? 'Position' : 'Positions'
        } Available`,
      });
    }
  }

  saveJobIfInterestedParamIsPresent(job: IJob) {
    if (
      this._route.snapshot.queryParams['interested'] === 'true' &&
      !job.saved
    ) {
      this.saveJob(job);
    }
  }

  goBack(): void {
    if (this._appEntryService.justCameFromOutside()) {
      this._navHelper.goToJobSearch();
    } else {
      this._location.back();
    }
  }

  saveJob(job: IJob): void {
    this._store.dispatch(
      new AngularticsEventTrack('Save', 'Job Searching', 'Save Job'),
    );
    this.isSaving = true;
    this._store.dispatch(
      new SetSavedJob({
        job,
        saveValue: true,
        contractType: this.contractType,
      }),
    );
    this.isSaving = false;
    if (job.recommendedJob) {
      this._store.dispatch(new CupidEventRecord(job.id));
    }
  }

  unSaveJob(job: IJob): void {
    this.isSaving = true;
    this._store.dispatch(
      new SetSavedJob({
        job,
        saveValue: false,
        contractType: this.contractType,
      }),
    );
    this.isSaving = false;
  }

  private _prepareSave(job: IJob): IJobFilter {
    const model = new IJobFilter();
    model.specialties =
      job.specialty != null
        ? [new IFilterGeneral(this.specialtyLookup?.get(job.specialty))]
        : null;
    model.profession =
      job.profession != null ? new IFilterGeneral(job.profession) : null;
    model.shift = job.shift != null ? [new IFilterGeneral(job.shift)] : null;
    const hoursPerWeek = this._jobAreaContext.getHoursPerWeek(job.hoursPerWeek);
    model.hoursPerWeek =
      job.hoursPerWeek != null
        ? [new IFilterGeneral(hoursPerWeek.id, hoursPerWeek.name)]
        : null;
    model.crisis = job.crisis != null ? new IFilterGeneral(job.crisis) : null;
    const location =
      job.city != null
        ? `${job.city}, ${this.specificJobRelatedData.state?.code}, USA`
        : `${this.specificJobRelatedData.state?.code}, USA`;
    model.locations.push(location);
    model.firstTimeTraveler =
      job.firstTimeTraveler != null
        ? new IFilterGeneral(job.firstTimeTraveler)
        : null;
    model.localTraveler =
      job.localTraveler != null ? new IFilterGeneral(job.localTraveler) : null;
    model.facilities = [
      { facilityId: job.hospitalId, facilityName: job?.hospital },
    ];
    model.contractType = this.contractType;

    return model;
  }

  primaryButtonAction(
    job: IJob,
    tasks: NurseTask[],
    allApplyTasks: NurseTask[],
  ): void {
    if (allApplyTasks.length === 0) {
      this._notificationService.showNotification(
        'Profile information not available. Please try again. If the problem persists, contact your recruiter.',
        'error',
      );
      return;
    }
    switch (this.jobStatus) {
      case JobStatus.NotApplied:
      case JobStatus.ApplicationPreviouslyInProgressWithdrawn:
        this.applyWithNoAssignment(job, tasks);
        break;
      case JobStatus.ApplicationInProgress:
      case JobStatus.ApplicationComplete:
        this.appliedApplicationNavigation(job, tasks);
        break;
      case JobStatus.Closed:
      default:
        this.viewSimilarJobs(job);
    }
  }

  appliedApplicationNavigation(job: IJob, tasks: NurseTask[]): void {
    const pathUrl = environment.appBaseUrl + this._router.url;
    const pageTitle = this._route.routeConfig.title as string;
    this.logSegmentEvent(job);
    if (!this.hasCompletedAllTasks(tasks) && job.applied) {
      this._store.dispatch(
        new CallToActionClicked({
          pageURL: pathUrl,
          pageTitle,
          destinationURL: environment.appBaseUrl + '/tasks',
          ctaText: this.primaryButtonText,
        }),
      );
      this.showStartApplicationDialog();
    } else if (
      job.interviewTypeComplianceRequirement &&
      job.interviewTypeComplianceRequirement.name ===
        'Interview Process - Auto-Offer, No Interview'
    ) {
      // Path down the new components
      this._store.dispatch(
        new CallToActionClicked({
          pageURL: pathUrl,
          pageTitle,
          destinationURL:
            environment.appBaseUrl + '/jobs/auto-offer-confirmation',
          ctaText: this.primaryButtonText,
        }),
      );
      this._navHelper.goToAutoOfferConfirmation(job.id);
    } else if (job.applied && job.hasStarted) {
      this._store.dispatch(
        new CallToActionClicked({
          pageURL: pathUrl,
          pageTitle,
          destinationURL: pathUrl,
          ctaText: this.primaryButtonText,
        }),
      );
      this.showReviewApplicationDialog();
    } else {
      this._store.dispatch(
        new CallToActionClicked({
          pageURL: pathUrl,
          pageTitle,
          destinationURL: environment.appBaseUrl + '/submittals',
          ctaText: this.primaryButtonText,
        }),
      );
      this._navHelper.goToSubmittals();
    }
  }

  applyWithNoAssignment(job: IJob, tasks: NurseTask[]): void {
    const pathUrl = environment.appBaseUrl + this._router.url;
    const pageTitle = this._route.routeConfig.title as string;
    const ctaText = this.primaryButtonText;
    this.logSegmentEvent(job);
    this._store.dispatch(
      new AngularticsEventTrack('Click', 'Job Searching', 'Save Job'),
    );

    // If requires profile information popup
    if (!this.hasCompletedAllTasks(tasks)) {
      // Submit Partial Application -- For Applicants with Incomplete Tasks
      this._store.dispatch(
        new CallToActionClicked({
          pageURL: pathUrl,
          pageTitle,
          destinationURL: environment.appBaseUrl + '/tasks',
          ctaText,
        }),
      );
      this.showStartApplicationDialog();
    } else if (
      job.interviewTypeComplianceRequirement &&
      job.interviewTypeComplianceRequirement.name ===
        'Interview Process - Auto-Offer, No Interview'
    ) {
      // If profile info not required, application review screen
      this._store.dispatch(
        new CallToActionClicked({
          pageURL: pathUrl,
          pageTitle,
          destinationURL:
            environment.appBaseUrl + '/jobs/auto-offer-confirmation',
          ctaText,
        }),
      );
      this._navHelper.goToAutoOfferConfirmation(job.id);
    } else {
      this._store.dispatch(
        new CallToActionClicked({
          pageURL: pathUrl,
          pageTitle,
          destinationURL: pathUrl,
          ctaText,
        }),
      );
      this.showReviewApplicationDialog();
    }
  }

  setEstWeeklyPay(job: IJob) {
    const pay = this.getPayDisplayValue(this.contractType, job);
    if (pay === '????' || pay === '----') {
      this.estWeeklyPay = null;
    } else {
      this.estWeeklyPay = pay + ' Est. Weekly Pay';
    }
  }

  getPayDisplayValue(contractType: ContractType, job: IJob): string {
    if (this.paymentDisplayType === PayDisplay.PayIsVisible) {
      return this.getPayValue(contractType, job);
    }
    return '????';
  }

  getPayValue(contractType: ContractType, job: IJob): string {
    switch (contractType) {
      case ContractType.Local:
        if (job.localEstWeeklyGross) {
          return this.localizePayValue(job.localEstWeeklyGross);
        }
        break;
      case ContractType.Travel:
      default:
        if (job.estGrossPay) {
          return this.localizePayValue(job.estGrossPay);
        }
        break;
    }
    return '----';
  }

  setEstHourlyPay(job: IJob) {
    this.estHourlyPay =
      this.contractType === ContractType.Travel
        ? job.estHourlyPay
        : job.localEstHourlyPay;
  }

  setEstOvertimePay(job: IJob) {
    this.estOvertimePay =
      this.contractType === ContractType.Travel
        ? job.estOvertimePay
        : job.localEstOvertimePay;
  }

  localizePayValue(pay: number) {
    return pay.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  setJobRequirements(job: IJob) {
    this.requirements = [];

    const experienceReqs = [];
    const referenceReqs = [];
    const skillsChecklistReqs = [];

    job.complianceRequirements?.forEach((req) => {
      switch (req.category.toLowerCase()) {
        case RequirementCategoryIds.References:
          referenceReqs.push(req);
          break;
        case RequirementCategoryIds.SkillsChecklist:
          skillsChecklistReqs.push(req);
          break;
        default:
          experienceReqs.push(req);
          break;
      }
    });

    if (experienceReqs.length) {
      this.requirements.push({
        group: 'Experience',
        requirements: experienceReqs.map((r) => r.friendlyName ?? r.name),
      });
    }
    if (job.covidRequirements?.length) {
      this.requirements.push({
        group: 'Vaccination',
        requirements: ['Documents required.'],
      });
    }
    if (referenceReqs.length) {
      this.requirements.push({
        group: 'References',
        requirements: [this.getReferenceRequirement(referenceReqs)],
      });
    }
    if (job.certificationRequirements?.length) {
      const certString = job.certificationRequirements
        .map((o) => o.name)
        .join(', ');
      this.requirements.push({
        group: 'Certificates',
        requirements: [certString],
      });
    }
    if (skillsChecklistReqs.length) {
      this.requirements.push({
        group: 'Skills Checklist',
        requirements: skillsChecklistReqs.map(
          (r) => r.friendlyName ?? r.name + ' Skills Checklist',
        ),
      });
    }
    if (job.interviewTypeComplianceRequirement) {
      this.requirements.push({
        group: 'Interview Process',
        requirements: [
          job.interviewTypeComplianceRequirement.friendlyName ??
            job.interviewTypeComplianceRequirement.name,
        ],
      });
    }
  }

  getReferenceRequirement(refRequirements: any[]): string {
    // Determine the number of references required for the job by extracting the greatest
    // 'Reference #_' requirement
    const numberOfReferences = refRequirements
      .filter((x) => x.name.startsWith('Reference #'))
      .reduce((refNum, x) => Math.max(+x.name.split('#')[1], refNum), 0);

    return numberOfReferences.toString();
  }

  setStateLicenseData(job: IJob) {
    this.stateLicenseData = [];

    if (!job.stateRequirement) {
      return;
    }

    if (job.stateRequirement.compact) {
      this.stateLicenseData.push({
        title: 'Compact',
        data: this.compactCardDisplayStatuses?.get(
          job.stateRequirement.compact,
        ),
      });
    }

    if (job.stateRequirement.walkThrough) {
      this.stateLicenseData.push({
        title: 'Walk-Through',
        data: this.yesNoIdLookup.get(job.stateRequirement.walkThrough),
      });
    }

    if (job.stateRequirement.permTimeframe) {
      this.stateLicenseData.push({
        title: 'Perm Timeframe',
        data: job.stateRequirement.permTimeframe,
      });
    }

    if (job.stateRequirement.tempTimeframe) {
      this.stateLicenseData.push({
        title: 'Temp Timeframe',
        data: job.stateRequirement.tempTimeframe,
      });
    }
  }

  createInfoCardData(job: IJob): void {
    const yes = +this.yesNoLookup?.get('Yes').id;
    const trauma: JobInfoData = {
      icon: 'local_hospital',
      data: this.specificJobRelatedData.traumaLevel?.name,
      shouldShow: job.hospitalInfo?.traumaLevel != null,
    };
    const teaching: JobInfoData = {
      icon: 'school',
      data: 'Teaching Facility',
      shouldShow: job.hospitalInfo?.teachingFacility === yes,
    };
    const facilityType: JobInfoData = {
      icon: 'apartment',
      data: job.hospitalInfo?.hospitalType,
      shouldShow: job.hospitalInfo?.hospitalType != null,
    };
    const beds: JobInfoData = {
      icon: 'local_hotel',
      data: `${job.hospitalInfo?.staffedBeds} Beds`,
      shouldShow:
        job.hospitalInfo?.staffedBeds != null &&
        job.hospitalInfo?.staffedBeds > 0,
    };
    this.facilityData = [trauma, teaching, facilityType, beds];

    this.facilityData = this.facilityData.filter((o) => o.shouldShow !== false);
  }

  openDisclaimer(): void {
    this._dialog.open(DisclaimerPopupComponent, {
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      restoreFocus: false,
    });
  }

  viewSimilarJob(id: string): void {
    this._navHelper.goToJobsSpecific(id);
  }

  contactRecruiter(
    job: IJob,
    recruiter: Recruiter,
    nurse: NurseModel,
    contactRecruiterType: ContactRecruiterType,
  ): void {
    if (contactRecruiterType === ContactRecruiterType.UnavailablePay) {
      this.recordRecruiterTask(job.id);
    }

    const jobInfo = [{ id: job.id, name: job.name }];

    this._dialog.open(ContactRecruiterPopUpComponent, {
      data: {
        a: recruiter,
        b: jobInfo,
        contactRecruiterType: contactRecruiterType,
        contractType: this.contractType,
      },
      panelClass: 'contact-recruiter-dialog',
      autoFocus: false,
    });
    const title = this._route.routeConfig?.title as string;
    const pageUrl = environment.appBaseUrl + this._router.url;
    this._store.dispatch(
      new CallToActionClicked({
        pageURL: pageUrl,
        pageTitle: title,
        destinationURL: pageUrl,
        ctaText: 'Contact Recruiter',
      }),
    );
  }

  viewSimilarJobs(job: IJob): void {
    this._navHelper.goToSimilarJobs(job.id, this.contractType);
  }

  goToBenefits(): void {
    const benefitsLink = environment.marketingBaseUrl + '/benefits';
    const currentUrl = environment.appBaseUrl + this._router.url;
    this._store.dispatch(
      new ExternalLinkClicked(currentUrl, 'open_in_new icon', benefitsLink),
    );
    window.open(benefitsLink, '_blank');
  }

  goToStateBoardSite(job: IJob): void {
    const stateBoardLink = job.stateRequirement.stateBoardLink;
    const currentUrl = environment.appBaseUrl + this._router.url;
    this._store.dispatch(
      new ExternalLinkClicked(currentUrl, 'open_in_new icon', stateBoardLink),
    );
    window.open(stateBoardLink, '_blank');
  }

  pullInRelatedData(job: IJob) {
    this.specificJobRelatedData.profession = this.professionLookup.get(
      job.profession,
    );
    this.specificJobRelatedData.specialty = this.specialtyLookup.get(
      job.specialty,
    );
    this.specificJobRelatedData.state = this.stateLookup.get(job.state);
    this.specificJobRelatedData.shift = this.shiftLookup.get(job.shift);
    this.specificJobRelatedData.traumaLevel = this.traumaLevelLookup.get(
      job.hospitalInfo?.traumaLevel,
    );
    this.specificJobRelatedData.hospital = job.hospital;
    this.specificJobRelatedData.city = job.city;
  }

  getStartDate(date: Date): string {
    if (typeof date === 'undefined') {
      return 'Soon';
    }
    const today = new Date();
    const startDate = new Date(date);
    if (startDate <= today) {
      return 'ASAP';
    }
    return moment(startDate).format('MMM D');
  }

  recordRecruiterTask(jobId: string): void {
    const taskModel: RecruiterTaskCreationModel = { jobId: jobId };
    this._store.dispatch(new CreatePayPackageTask(taskModel));
  }

  showJobNotFoundDialog(): void {
    const dialogData: IDialogParameters = {
      title: `This job is no longer available`,
      text:
        this._route.snapshot.queryParams['fromSearch'] === 'true'
          ? 'We regret to inform you that this job is no longer available. Please go back to your job search or navigate to your dashboard for more options.'
          : // eslint-disable-next-line max-len
            'We regret to inform you that this job is no longer available. Please feel free to explore other opportunities by visiting the job search page or navigate to your dashboard for more options.',
      showCloseIcon: false,
      icon: undefined,
      width: '344px',
      elevation: CardElevation.Default,
      isResponsive: false,
      options: {
        primary: {
          returnValue: 'true',
          text:
            this._route.snapshot.queryParams['fromSearch'] === 'true'
              ? 'Go back to your Job Search'
              : 'Go to Job Search',
        },
        secondary: { returnValue: 'false', text: 'Go to your Dashboard' },
      },
    };
    this._dialogService
      .showDialog(dialogData, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response === 'true') {
          if (this._route.snapshot.queryParams['fromSearch'] === 'true') {
            const service = new SearchFilterService(
              this.professionLookup,
              this.specialtyLookup,
            );
            const searchFilters = service.getSearchParams();
            this._navHelper.goToJobSearchFilters(searchFilters);
          } else {
            this._store.dispatch(new SetJobFilterV2(null));
            this._navHelper.goToJobSearch();
          }
        } else if (response === 'false') {
          this._navHelper.goToDashBoard();
        }
      });
  }

  showReviewApplicationDialog(): void {
    const dialogData: IDialogParameters = {
      title: `Apply to ${this.jobName}`,
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.reviewApplicationDialogTemplate,
      separatedHeader: true,
      noStyling: true,
      isResponsive: true,
    };
    this._dialogService.showDialog(dialogData, true);
  }

  showStartApplicationDialog(): void {
    const dialogData: IDialogParameters = {
      title: '',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.startApplicationDialogTemplate,
      separatedHeader: false,
      noStyling: true,
      isResponsive: true,
    };
    this._dialogService.showDialog(dialogData, true);
  }

  logSegmentEvent(job: IJob) {
    if (
      !job.applied ||
      (job.applied && job.hasWithdrawn && !job.hasCompletedApplication)
    ) {
      this._store.dispatch(new ApplicationStarted(job?.id));
    }
  }

  showRequirementsSection(job: IJob): boolean {
    return (
      job.certificationRequirements?.length > 0 ||
      job.complianceRequirements?.length > 0 ||
      job.covidRequirements?.length > 0 ||
      !!job.interviewTypeComplianceRequirement
    );
  }

  showFacilityDetailsSection(job: IJob): boolean {
    return (
      job.hospitalInfo?.staffedBeds != null ||
      job.hospitalInfo?.emrSystem != null ||
      job.hospitalInfo?.erVisitsPerYear != null ||
      job.hospitalInfo?.surgeriesPerYear != null ||
      job.hospitalInfo?.birthsPerYear != null ||
      job.hospitalInfo?.hospitalType != null ||
      job.hospitalInfo?.traumaLevel != null
    );
  }

  tabChanged(event: HcEvent, job: IJob) {
    if (event.eventValue.tabIndex === 1) {
      this._store.dispatch(new FacilityViewed(job.hospitalInfo));
    }
  }
}
