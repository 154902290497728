<div fxLayout="column" class="scroll profile-menu-container">
  <div *ngFor="let item of navItems" class="row-formatting a selectedNavigationItem profile-menu-item" [routerLink]="item.nav" (click)="segmentTrackNavigation(item)"
    fxLayoutAlign="start center" matRipple [matRippleCentered]="true">
    <div fxLayout="row" fxLayoutAlign="start center" class="profile-menu-item-name" fxFlex>
      {{item.name}}
    </div>
    <div fxLayoutAlign="center center">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
</div>