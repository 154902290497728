import { createSelector } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { JobsState } from 'src/app/store/jobs/jobs.state';
import { checkIfDateIsAfter } from 'src/app/common/functions/date-manipulations';
import {
  AssignmentReasonSubStagesArray,
  AssignmentStageConstants,
  IJob,
} from 'src/app/common';
import { JobDataModel } from 'src/app/common/models/job-data-model';

const selectJobsState = (state: IAppState) => state.jobs;

export const selectJobPreferences = createSelector(
  selectJobsState,
  (state: JobsState) => state.jobPreferences.data,
);

export const selectAvailableJobs = createSelector(
  selectJobsState,
  (state: JobsState) => {
    // set saved boolean from list of saved jobs in state
    return {
      ...state?.availableJobs?.data,
      jobs: state?.availableJobs?.data?.jobs?.map(availableJob => {
        return {
          ...availableJob,
        saved: state?.savedJobs?.data?.jobs?.some(savedJob => savedJob.id === availableJob.id)
        };
      })
    };
  }
);

export const selectAvailableJobsLoading = createSelector(
  selectJobsState,
  (state: JobsState) => state.availableJobs.loading,
);

export const selectSavedJobs = createSelector(
  selectJobsState,
  (state: JobsState) => state.savedJobs.data,
);

export const selectSavedJobsLoading = createSelector(
  selectJobsState,
  (state: JobsState) => state.savedJobs.loading,
);

export const selectSimilarJobs = createSelector(
  selectJobsState,
  (state: JobsState) => state.similarJobs.data,
);

export const selectSimilarJobsLoading = createSelector(
  selectJobsState,
  (state: JobsState) => state.similarJobs.loading,
);

export const selectSimilarJobsByJobId = createSelector(
  selectJobsState,
  (state: JobsState) => state.similarJobsByJobId.data?.results?.jobs,
);

export const selectSimilarJobsByJobIdLoading = createSelector(
  selectJobsState,
  (state: JobsState) => state.similarJobsByJobId.loading,
);

export const selectJobPreferencesLoading = createSelector(
  selectJobsState,
  (state: JobsState) => state.jobPreferences.loading,
);

export const selectSetJobPreferences = createSelector(
  selectJobsState,
  (state: JobsState) => state.setJobPreferencesResult,
);


export const selectSetJobPreferencesResult = createSelector(
  selectJobsState,
  (state: JobsState) => state.setJobPreferencesResult.data,
);

export const selectSetJobPreferencesResultLoading = createSelector(
  selectJobsState,
  (state: JobsState) => state.setJobPreferencesResult.loading,
);

export const selectRecommendedJobsByPreference = createSelector(
  selectJobsState,
  (state: JobsState) => state.jobRecommendationsByPreference.data,
);

export const selectRecommendedJobsByPreferenceLoading = createSelector(
  selectJobsState,
  (state: JobsState) => state.jobRecommendationsByPreference.loading,
);

export const selectJobSubmittals = createSelector(
  selectJobsState,
  (state: JobsState) => state.jobSubmittals.data,
);

export const selectJobSubmittalsLoading = createSelector(
  selectJobsState,
  (state: JobsState) => state.jobSubmittals.loading,
);

export const selectJobSubmittalsExpired = createSelector(
  selectJobsState,
  (state: JobsState) => {
    return checkIfDateIsAfter(new Date(), state.jobSubmittals?.expirationDate);
  },
);

export const selectJobSubmittalsExpirationDate = createSelector(
  selectJobsState,
  (state: JobsState) => {
    return state.jobSubmittals?.expirationDate;
  },
);

export const selectLastArchivedSubmittal = createSelector(
  selectJobsState,
  (state: JobsState) => {
    return state.lastArchivedSubmittal?.data;
  },
);

export const selectJobFilterV2 = createSelector(
  selectJobsState,
  (state: JobsState) => state.jobFilterV2.data,
);

export const selectGetLocationsByNameResult = createSelector(
  selectJobsState,
  (state: JobsState) => state.locationsByName.data,
);

export const selectSpecificJob = createSelector(
  selectJobsState,
  (state: JobsState) => state.specificJob.data,
);

export const selectSpecificJobMapUrl = createSelector(
  selectJobsState,
  (state: JobsState) => state.specificJobMapUrl.data,
);

export const selectSimilarJobsByFacilityId = createSelector(
  selectJobsState,
  (state: JobsState) => state.similarJobsByFacility.data,
);

export const selectSimilarJobsByFacilityIdLoading = createSelector(
  selectJobsState,
  (state: JobsState) => state.similarJobsByFacility.loading,
);

export const selectSpecificJobLoading = createSelector(
  selectJobsState,
  (state: JobsState) => state.specificJob.loading,
);

export const selectSpecificJobError = createSelector(
  selectJobsState,
  (state: JobsState) => state.specificJob.error,
);

export const selectHasAutoOfferAssignmentsData = createSelector(
  selectJobsState,
  (state: JobsState) => state.hasAutoOfferAssignments.data,
);

export const selectHasAutoOfferAssignmentsLoading = createSelector(
  selectJobsState,
  (state: JobsState) => state.hasAutoOfferAssignments.loading,
);

export const selectApplyToJobResult = createSelector(
  selectJobsState,
  (state: JobsState) => state.applyToJobResult.data,
);

export const selectApplyProcessSaving = createSelector(
  selectJobsState,
  (state: JobsState) =>
    (state.applyToJobResult.loading ||
      state.updateProfileReviewResult.loading ||
      state.updateApplicationResult.loading) &&
    !(
      state.applyToJobResult.error ||
      state.updateProfileReviewResult.error ||
      state.updateApplicationResult.error
    ),
);

export const selectUpdateApplicationResult = createSelector(
  selectJobsState,
  (state: JobsState) => state.updateApplicationResult.data,
);

export const selectApplicationReview = createSelector(
  selectJobsState,
  (state: JobsState) => state.application.data,
);

export const selectApplicationLoading = createSelector(
  selectJobsState,
  (state: JobsState) => state.application.loading,
);

export const selectUpdateProfileReviewResult = createSelector(
  selectJobsState,
  (state: JobsState) => state.updateProfileReviewResult.data,
);

export const selectSpecificJobIsSaved = createSelector(
  selectSpecificJob,
  selectSavedJobs,
  (specificJob: IJob, savedJobs: JobDataModel) => {
    return !!savedJobs?.jobs.find(
      (savedJob) => savedJob?.id === specificJob?.id,
    );
  },
);

export const selectInProgressJobSubmittals = createSelector(
  selectJobsState,
  (state: JobsState) =>
    state.jobSubmittals.data?.filter(
      (o) =>
        o.assnStage == AssignmentStageConstants.Application &&
        !AssignmentReasonSubStagesArray.includes(o.assnSubstage),
    )?.length,
);

export const selectContractType = createSelector(
  selectJobsState,
  (state: JobsState) => state.contractType.data,
);

export const selectSpecificJobFromAvailableJobs = (jobId: string) =>
  createSelector(
    selectAvailableJobs,
    selectSimilarJobs,
    selectSimilarJobsByJobId,
    selectSimilarJobsByFacilityId,
    selectRecommendedJobsByPreference,
    (
      availableJobsData: { totalCount: number; jobs: IJob[] },
      similarJobsData: { totalCount: number; jobs: IJob[] },
      similarJobsByJobIdData: IJob[],
      similarJobsByFacilityIdData: IJob[],
      recommendedJobsData: IJob[],
    ) =>
      [
        ...(availableJobsData?.jobs ?? []),
        ...(similarJobsData?.jobs ?? []),
        ...(similarJobsByJobIdData ?? []),
        ...(similarJobsByFacilityIdData ?? []),
        ...(recommendedJobsData ?? []),
      ]?.find((j) => j.id === jobId) ?? null,
  );
