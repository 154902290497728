<div class="submissions-carousel-container" *ngIf="(jobSubmittalsLoading$ | async) || (submittalCards$ | async)?.length > 0">
  <div class="submissions-carousel-heading" fxLayoutAlign="space-between start">
    <div class="left-heading-column">
      <hc-heading [size]="headingSize">Current Submissions</hc-heading>
      <div class="heading-container">
        <div>
          <hc-body
            [size]="bodySize"
          >
            Based on your
            <hc-link
              [size]="linkSize"
              [target]="linkTarget"
              [routerLink]="['/job-preferences']"
              [url]="null"
            >Preferences</hc-link> and
            <hc-link
              [size]="linkSize"
              [target]="linkTarget"
              [routerLink]="['/profile-menu']"
              [url]="null"
            >Profile</hc-link>.
          </hc-body>
        </div>
        <div segmentTrackCTA ctaText="Learn how First in Line works"  class="fil-link" (click)="showFirstInLineModal()">
          <mat-icon>lightbulb_outline</mat-icon><span>Learn how First in Line works</span>
        </div>
      </div>
    </div>
    <hc-link
      [size]="linkSize"
      [target]="linkTarget"
      [routerLink]="['/my-submittals']"
      [url]="null"
      >View All
    </hc-link>
  </div>
  <hc-card-carousel
    [isLoading]="(jobSubmittalsLoading$ | async)"
    [skeletonCardsCount]="3"
    [cardData]="submittalCards$ | async"
    (cardClicked)="jobCardClicked($event)"
    (cardActionClicked)="submittalCardHelp($event)"
    (cardLinkClicked)="submittalCardLinkClicked($event)"
  ></hc-card-carousel>
</div>
<ng-template #filDialogTemplate>
  <app-fil-pop-up></app-fil-pop-up>
</ng-template>