import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { FormHeaderAttributes } from 'src/app/services';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { map, skipWhile, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  ILookup,
  IProfessionalHierarchy,
  NurseProfileModel,
  ProfessionalOverviewModel,
} from 'src/app/common';
import { autocompleteValidator } from 'src/app/common/validators/autocompleteValidator';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import {
  selectGetSummaryPrompts,
  selectProfessionalOverview,
  selectProfessionalOverviewFormDataLoading,
  selectUpdateSummary,
  selectUpdateSummaryLoading,
  selectUserProfessionSector,
} from 'src/app/store/userContext/userContext.selectors';
import {
  selectWorkExperienceLookup,
  selectSpecialtyLookup,
  selectProfessionLookup,
  selectTravelExperienceLookup,
  selectProfessionalHierarchy,
  selectProfessionSpecialtyRequirement,
} from 'src/app/store/lookups/lookups.selectors';
import {
  GetSummaryPrompts,
  UpdateSummaryData,
} from 'src/app/store/userContext/userContext.actions';
import {
  IDropdownData,
  IHierarchicalDropdownNode,
  IRadioButtonOption,
} from 'hc-design-system-lib/lib/components/form/form.interfaces';
import {
  convertIntoDropdownData,
  getHierarchyTreeById,
  getHierarchyNodeByType,
} from 'src/app/common/functions/dropdown-helpers';
import {
  BodySize,
  DropdownComponent,
  HeadingSize,
  RadioButtonComponent,
} from 'hc-design-system-lib';
import { selectFlagsLoading } from 'src/app/store/flags/flags.selectors';
import {
  PROFESSION_HIERARCHY_NODE_TYPES,
  PROFESSION_HIERARCHY_SECTORS,
} from 'src/app/common/constants';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';

@Component({
  selector: 'app-profession-overview',
  templateUrl: './professional-overview.component.html',
  styleUrls: ['./professional-overview.component.scss'],
})
export class ProfessionalOverviewComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  private professionSectorElement: ElementRef;
  @ViewChild('professionSector', { read: ElementRef })
  set professionSectorElementSet(content: ElementRef<RadioButtonComponent>) {
    if (content && !this.professionSectorElement) {
      this.professionSectorElement = content;

      this.professionSectorElement?.nativeElement
        ?.querySelector('label')
        .insertAdjacentHTML(
          'beforeend',
          '\n<span class="sub-label">Contact your recruiter to change your Profession Sector.</span>'
        );
    }
  }

  private specialtyDropdown: DropdownComponent;
  @ViewChild('specialtyDropdown')
  set specialtyDropdownElementSet(content: DropdownComponent) {
    if (content && !this.specialtyDropdown) {
      this.specialtyDropdown = content;
    }
  }

  bodyTextSize = BodySize.Small;
  headingTextSize = HeadingSize.H6;

  form: UntypedFormGroup;
  formHeaderAttributes: FormHeaderAttributes;
  model: ProfessionalOverviewModel = null;

  yearsOfExperienceValues: IDropdownData[];
  travelExperienceValues: IDropdownData[];
  specialties: IDropdownData[];

  specialtyRequired = false;
  NoTravelXP_ID = 948050000;

  existingProfessionalHierarchy: IHierarchicalDropdownNode[];

  professionSector: string;
  radioOptions: IRadioButtonOption[] = [
    {
      text: PROFESSION_HIERARCHY_SECTORS.Allied,
      value: PROFESSION_HIERARCHY_SECTORS.Allied,
      disabled: true,
    },
    {
      text: PROFESSION_HIERARCHY_SECTORS.Nursing,
      value: PROFESSION_HIERARCHY_SECTORS.Nursing,
      disabled: true,
    },
  ];

  professionHierarchyLookup$: Observable<IProfessionalHierarchy[]> =
    this._store.pipe(select(selectProfessionalHierarchy));
  professionSector$: Observable<string> = this._store.pipe(
    select(selectUserProfessionSector)
  );
  flagsLoading$: Observable<boolean> = this._store.pipe(
    select(selectFlagsLoading)
  );
  allProfessionHierarchy: IHierarchicalDropdownNode[] = [];

  specialtyLookup$: Observable<Map<string, ILookup<string>>> = this._store.pipe(
    select(selectSpecialtyLookup)
  );
  professionLookup$: Observable<Map<string, ILookup<string>>> =
    this._store.pipe(select(selectProfessionLookup));
  travelExperienceLookup$: Observable<Map<number, ILookup<number>>> =
    this._store.pipe(select(selectTravelExperienceLookup));
  workExperienceLookup$: Observable<Map<number, ILookup<number>>> =
    this._store.pipe(select(selectWorkExperienceLookup));

  updateSummarySaving$: Observable<boolean> = this._store.pipe(
    select(selectUpdateSummaryLoading)
  );
  updateSummary$: Observable<any> = this._store.pipe(
    select(selectUpdateSummary)
  );

  professionalOverview$: Observable<NurseProfileModel> = this._store.pipe(
    select(selectProfessionalOverview)
  );
  summaryPrompts$: Observable<string[]> = this._store.pipe(
    select(selectGetSummaryPrompts)
  );
  formLoading$: Observable<boolean> = this._store.pipe(
    select(selectProfessionalOverviewFormDataLoading)
  );

  constructor(
    private _fb: UntypedFormBuilder,
    private _store: Store<IAppState>
  ) {
    this._store.dispatch(new GetSummaryPrompts());
  }

  ngOnInit(): void {
    this.initializePageObservables();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializePageObservables(): void {
    combineLatest([
      combineLatest([
        this.professionalOverview$,
        this.formLoading$,
        this.professionSector$,
        this.flagsLoading$,
      ]),
      combineLatest([
        this.specialtyLookup$,
        this.professionLookup$,
        this.travelExperienceLookup$,
        this.workExperienceLookup$,
        this.professionHierarchyLookup$,
      ]),
    ])
      .pipe(
        map(([overviewData, lookups]) => [...overviewData, ...lookups]),
        skipWhile(
          ([
            professionalOverview,
            formLoading,
            ,
            flagsLoading,
            ,
            ,
            travelExperience,
            workExperience,
            professionHierarchy,
          ]: [
            NurseProfileModel,
            boolean,
            string,
            boolean,
            Map<string, ILookup<string>>,
            Map<string, ILookup<string>>,
            Map<string, ILookup<string>>,
            Map<string, ILookup<string>>,
            IProfessionalHierarchy[]
          ]) =>
            !professionalOverview ||
            formLoading ||
            flagsLoading ||
            !travelExperience ||
            !workExperience ||
            professionHierarchy === null
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(
        ([
          professionalOverview,
          ,
          professionSector,
          ,
          specialties,
          professions,
          travelExperience,
          workExperience,
          professionalHierarchy,
        ]) => {
          this.model = professionalOverview;
          this.convertExperienceLookups(travelExperience, workExperience);
          this.allProfessionHierarchy =
            professionalHierarchy as IHierarchicalDropdownNode[];
          this.setProfessionSectorControl(professionSector);
          this._createForm();
          this.initAlliedListeners();
        }
      );
  }

  convertExperienceLookups(
    travelExperience: Map<string, ILookup<string>>,
    workExperience: Map<string, ILookup<string>>
  ) {
    this.yearsOfExperienceValues = Array.from(workExperience.values(), (p) =>
      convertIntoDropdownData(p, 'name')
    );
    this.travelExperienceValues = Array.from(
      [
        {
          id: this.NoTravelXP_ID,
          name: 'None',
          sortOrder: 0,
        } as ILookup<number>,
        ...travelExperience.values(),
      ],
      (p) => convertIntoDropdownData(p, 'name')
    );
  }

  initAlliedListeners(): void {
    this.form.controls.professionHierarchy.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        switchMap((val: IHierarchicalDropdownNode) => {
          const profession = getHierarchyNodeByType(
            val,
            PROFESSION_HIERARCHY_NODE_TYPES.Profession
          );

          return combineLatest([
            of(profession),
            this._store.pipe(
              take(1),
              select(selectProfessionSpecialtyRequirement(profession?.id))
            ),
          ]);
        })
      )
      .subscribe(([profession, specialtyRequired]) => {
        this.specialtyRequired = specialtyRequired;
        this.specialties = profession?.children?.map((s) =>
          convertIntoDropdownData(s, 'name')
        );
        this.updateSpecialtyValidators();
      });
  }

  _createForm() {
    this.form = this._fb.group({
      sizzle: new UntypedFormControl(this.model.sizzle, [
        Validators.minLength(150),
      ]),
      travelExperience: new UntypedFormControl(
        this.travelExperienceValues.find(
          (te) => te.value.id === this.model.travelExperience
        ),
        [Validators.required]
      ),
      specialtyExperience: new UntypedFormControl(
        this.yearsOfExperienceValues.find(
          (yoe) => yoe.value.id === this.model.specialtyExperience
        )
      ),
      workExperience: new UntypedFormControl(
        this.yearsOfExperienceValues.find(
          (yoe) => yoe.value.id === this.model.workExperience
        ),
        [Validators.required]
      ),
    });

    this.addNewFormControls();

    this.formHeaderAttributes = {
      form: this.form,
      title: 'Professional Overview',
      showSaveButton: false,
    };
  }

  private _prepareSave() {
    const model = this.form.value as ProfessionalOverviewModel;

    model.travelExperience =
      +this.form.controls.travelExperience.value?.value?.id;
    model.workExperience = +this.form.controls.workExperience.value?.value?.id;
    model.specialtyExperience = null;

    const profession = getHierarchyNodeByType(
      this.form.controls.professionHierarchy.value,
      PROFESSION_HIERARCHY_NODE_TYPES.Profession
    );

    model.profession = profession?.id;
    model.userProfession = profession?.name;

    if (this.form.controls.specialtyDto.value) {
      model.specialtyDto = this.form.controls.specialtyDto.value?.value;
      model.specialtyExperience =
        +this.form.controls.specialtyExperience?.value?.value?.id;

      model.userSpecialty = model.specialtyDto;
    }

    return model;
  }

  onSave(isValid: boolean): void {
    if (isValid) {
      const model = this._prepareSave();
      this._store.dispatch(new UpdateSummaryData(model));
    }
  }

  updateSpecialtyValidators() {
    if (this.specialtyRequired) {
      this.form.controls.specialtyDto.setValidators([
        Validators.required,
        autocompleteValidator(this.specialties),
      ]);
    } else {
      this.form.controls.specialtyDto.setValidators([]);
    }

    this.form.controls.specialtyDto.updateValueAndValidity();
    this.form.controls.specialtyExperience.updateValueAndValidity();
  }

  addNewFormControls() {
    let currentSpecialtyDropdown: IDropdownData = null;

    const existingProfessionHierarchy = getHierarchyTreeById(
      this.existingProfessionalHierarchy,
      this.model?.profession
    );

    if (existingProfessionHierarchy) {
      const specialties = getHierarchyNodeByType(
        existingProfessionHierarchy[0],
        PROFESSION_HIERARCHY_NODE_TYPES.Profession
      )?.children;

      if (specialties) {
        this.specialties = specialties.map((s) =>
          convertIntoDropdownData(s, 'name')
        );

        if (this.model?.specialtyId) {
          currentSpecialtyDropdown =
            this.specialties.find(
              (s) => s.value.id === this.model?.specialtyId
            ) ?? null;
        }
      }
    }

    this.form.addControl(
      'professionHierarchy',
      new FormControl<IHierarchicalDropdownNode>(
        existingProfessionHierarchy ? existingProfessionHierarchy[0] : null,
        [Validators.required]
      )
    );
    this.form.addControl(
      'specialtyDto',
      new FormControl<IDropdownData>(currentSpecialtyDropdown, [])
    );

    if (this.specialties) {
      this.form.controls.specialtyDto.addValidators([
        autocompleteValidator(this.specialties),
      ]);

      if (this.specialtyRequired) {
        this.form.controls.specialtyDto.addValidators([Validators.required]);
        this.form.controls.specialtyExperience.addValidators([
          Validators.required,
        ]);
      }
    }
  }

  setProfessionSectorControl(professionSector: string) {
    if (professionSector) {
      this.professionSector = professionSector;

      this.existingProfessionalHierarchy = this.allProfessionHierarchy.find(
        (h) => h.name.toLowerCase() === this.professionSector.toLowerCase()
      )?.children;
    } else {
      const nursingHierarchy = this.allProfessionHierarchy.find(
        (s) =>
          s.name.toLowerCase() ===
          PROFESSION_HIERARCHY_SECTORS.Nursing.toLowerCase()
      )?.children;
      const alliedHierarchy = this.allProfessionHierarchy.find(
        (s) =>
          s.name.toLowerCase() ===
          PROFESSION_HIERARCHY_SECTORS.Allied.toLowerCase()
      )?.children;

      const nursingSelectedHierarchy = getHierarchyTreeById(
        nursingHierarchy,
        this.model?.profession
      );
      const nonNursingSelectedHierarchy = getHierarchyTreeById(
        alliedHierarchy,
        this.model?.profession
      );

      if (nursingSelectedHierarchy) {
        this.existingProfessionalHierarchy = nursingHierarchy;
        this.professionSector = PROFESSION_HIERARCHY_SECTORS.Nursing;
        this.specialtyRequired = true;
      } else if (nonNursingSelectedHierarchy) {
        this.existingProfessionalHierarchy = alliedHierarchy;
        this.professionSector = PROFESSION_HIERARCHY_SECTORS.Allied;
        this.specialtyRequired = false;
      }
    }
  }

  get professionSectorControl(): FormControl<string> {
    return new FormControl<string>(this.professionSector);
  }

  professionChanged() {
    this.form.controls.specialtyDto.setValue(null);
    this.form.controls.specialtyExperience.setValue(null);
    this.form.controls.specialtyExperience.setValidators([]);

    if (this.specialtyDropdown) {
      this.specialtyDropdown.required = this.specialtyRequired;
      this.specialtyDropdown.setRequiredState();
    }

    this.form.controls.specialtyDto.updateValueAndValidity();
    this.form.controls.specialtyExperience.updateValueAndValidity();
  }

  specialtyChanged(event: HcEvent) {
    this.form.controls.specialtyExperience.setValue(null);

    if (this.specialtyRequired && event.eventValue) {
      this.form.controls.specialtyExperience.setValidators([
        Validators.required,
      ]);
    } else {
      this.form.controls.specialtyExperience.setValidators([]);
    }
    this.form.controls.specialtyExperience.updateValueAndValidity();
  }
}
