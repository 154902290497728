import { ContractType } from "../contracts/contract-type";
import { AssignmentSources } from "./submittal-constants";

export class Submittal {
  id: string;
  assignmentSource?: AssignmentSources;
  assnStage: string;
  assnSubstage: string;
  jobOrderId: string;
  profession: string;
  specialty: string;
  workSite?: string;
  workSiteId: string;
  state: string;
  city: string;
  startDate?: Date;
  portalStatus: number;
  jobName: string;
  assnName: string;
  createdOn: Date;
  shift?: number;
  hoursPerWeek?: number;
  statusLastUpdated: boolean;
  estimatedFeedback?: Date;
  estimatedFeedback2?: Date;
  estimatedFeedback3?: Date;
  interviewDate?: Date;
  interviewer?: string;
  interviewNotes?: string;
  archived: string;
  job: SubmittalJob;
  jobOrderInactiveDate?: Date;
  matchingInactiveJobOrder?: string;
  submittalWithdrawnDate?: Date;
  contractType?: ContractType;
}

export class SubmittalJob {
  id: string;
  quantityRequired?: number;
  quantityOpen?: number;
  estGrossPay?: any;
  localEstWeeklyGross?: any;
  closed: boolean;
}

export class DisplaySubmittal extends Submittal {
  jobName: string;
  location: string;
  workSite: string;
}

export enum SubmittalLinks {
  viewSimilarJobs = 'VIEW_SIMILAR_JOBS',
  interview = 'INTERVIEW',
  archive = 'ARCHIVE',
  withdrawApplication = 'WITHDRAW_APPLICATION',
  finishApplication = 'FINISH_APPLICATION',
  deleteApplication = 'DELETE_APPLICATION',
  transferApplication = 'TRANSFER_APPLICATION'
}
