<ng-container
  *ngIf="{
    job: specificJob$ | async,
    jobLoading: specificJobLoading$ | async,
    tasksLoading: tasksLoading$ | async,
    applicationTasks: applicationTasks$ | async,
    applicationSaving: applicationSaving$ | async
  } as obs"
>
  <app-loading-screen-spinner
    [show]="obs.tasksLoading || obs.jobLoading"
  ></app-loading-screen-spinner>
  <div
    *ngIf="!obs.tasksLoading && !obs.jobLoading"
    class="tasks-container tasks-full-height"
    fxLayout="column"
    fxLayoutGap="30px"
  >
    <div class="progress-container" fxLayout="column" fxLayoutGap="25px">
      <hc-heading [size]="headingSizeH6">Apply to {{ jobName }}</hc-heading>
      <div fxFlex="none" fxLayout="column">
        <hc-body [size]="bodySizeSmall">Application Progress</hc-body>
        <div fxLayoutGap="11px" fxLayoutAlign="start center" class="full-width">
          <hc-progress-bar
            mode="determinate"
            [value]="getProgressValue(obs.applicationTasks)"
            fxFlex="noshrink"
          ></hc-progress-bar>
          <hc-body
            >{{ completedTasks(obs.applicationTasks)?.length ?? 0 }}/{{
              obs.applicationTasks.length
            }}</hc-body
          >
        </div>
      </div>
    </div>
    <div
      *ngIf="
        completedTasks(obs.applicationTasks)?.length ===
        obs.applicationTasks.length
      "
      fxLayoutGap="22px"
      fxLayout.lt-md="column"
      fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-between center"
    >
      <hc-body style="font-weight: bold">
        Nice work! Review your application, then you'll be able to submit.
      </hc-body>
      <hc-button
        id="review-application-button"
        [disabled]="obs.applicationSaving"
        [appearance]="primaryButtonAppearance"
        [alwaysDisplayFullWidth]="true"
        (buttonClick)="showReviewApplicationDialog()"
        class="app-buttons"
      >
        Review Application
      </hc-button>
    </div>
    <div fxLayout="column" fxLayoutGap="8px">
      <hc-task-card
        *ngFor="let task of nonPendingTasks(obs.applicationTasks)"
        [status]="mapStatus.has(task.status) ? mapStatus.get(task.status) : 0"
        [title]="task.friendlyName ?? task.name"
        [additionalTitle]="task.pendingTaskInfo"
        (cardClicked)="goToTask(task, obs.applicationTasks, obs.job.id)"
      >
      </hc-task-card>
    </div>
    <div
      id="pending-tasks"
      *ngIf="pendingTasks(obs.applicationTasks)?.length"
      fxLayout="column"
      fxLayoutGap="18px"
    >
      <div fxLayout="column" fxLayoutGap="5px">
        <hc-heading [size]="headingSizeH5">Pending</hc-heading>
        <hc-body [size]="bodySizeMicro">
          Tasks pending review are being assessed by our Health Carousel team.
          Once reviewed, they will either mark the task as complete or let you
          know if corrections are needed.
        </hc-body>
      </div>
      <div fxLayout="column" fxLayoutGap="8px">
        <hc-task-card
          *ngFor="let task of pendingTasks(obs.applicationTasks)"
          [status]="mapStatus.has(task.status) ? mapStatus.get(task.status) : 0"
          [title]="task.friendlyName ?? task.name"
          [additionalTitle]="task.pendingTaskInfo"
          (cardClicked)="goToTask(task, obs.applicationTasks, obs.job.id)"
        >
        </hc-task-card>
      </div>
    </div>
    <hc-button
      id="save-button"
      [appearance]="primaryButtonAppearance"
      [alwaysDisplayFullWidth]="true"
      (buttonClick)="showApplicationSavedDialog()"
      class="app-buttons"
    >
      Save & Complete Later
    </hc-button>
  </div>

  <ng-template #applicationSavedTemplate>
    <div class="base-form tasks-responsive-modal-form">
      <hc-body [size]="bodySize">
        Your application is saved. You can navigate to the
        <b>Applications</b> page to complete the application when you are ready.
      </hc-body>
      <div class="tasks-responsive-modal-form__buttons">
        <hc-button
          id="view-applications-button"
          ngClass.gt-sm="app-buttons"
          (buttonClick)="goToApplications()"
          [appearance]="primaryButtonAppearance"
          [alwaysDisplayFullWidth]="true"
        >
          View My Applications
        </hc-button>
        <hc-button
          id="job-search-button"
          ngClass.gt-sm="app-buttons"
          class="tasks-responsive-modal-form__buttons__cancel"
          (buttonClick)="goToSearch()"
          [appearance]="secondaryButtonAppearance"
          [alwaysDisplayFullWidth]="true"
        >
          Go To Job Search
        </hc-button>
      </div>
    </div>
  </ng-template>

  <ng-template #applicationWarningTemplate>
    <div class="tasks-modal-warning-confirmation">
      <hc-body [size]="bodySize">
        You're almost there! Complete your remaining tasks and submit your
        application for this job.
      </hc-body>
      <hc-body [size]="bodySize">
        Or, complete the application later through the <b>Applications</b> page.
      </hc-body>
      <div
        class="tasks-modal-warning-confirmation__buttons tasks-modal-warning-confirmation__buttons-row"
      >
        <hc-button
          id="warnConfirmLeaveButton"
          ngClass.gt-sm="app-buttons"
          (buttonClick)="closeApplicationWarning()"
          [appearance]="primaryButtonAppearance"
          [alwaysDisplayFullWidth]="true"
        >
          Continue Application
        </hc-button>
        <hc-button
          id="warnConfirmCancelButton"
          ngClass.gt-sm="app-buttons"
          class="tasks-responsive-modal-form__buttons__cancel"
          (buttonClick)="discard()"
          [appearance]="secondaryButtonAppearance"
          [alwaysDisplayFullWidth]="true"
        >
          Leave this page
        </hc-button>
      </div>
    </div>
  </ng-template>

  <ng-template #reviewApplicationDialogTemplate>
    <app-application-review [isDialog]="true"></app-application-review>
  </ng-template>
</ng-container>
