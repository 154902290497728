<mat-action-list>
  <button id="bottomSheetDelete" mat-list-item class="red" (click)="done('delete')">
    DELETE
  </button>
  <button id="bottomSheetPause" mat-list-item class="green" *ngIf="showPause" (click)="done('pause')">
    PAUSE
  </button>
  <button id="bottomSheetCancel" mat-list-item (click)="done('cancel')">
    CANCEL
  </button>

</mat-action-list>