<ng-container *ngIf="immigrationStages && currentStageId">
    <app-progress-tracker
        [currentStepId]="currentStageId"
        [steps]="immigrationStages"
        [infoDialogTemplate]="infoDialogTemplate"
        title="Immigration Stage"
        infoDialogTitle="Immigration Stages"
        subtitle="Track your immigration stage progress from Visa Filing Prep to Visa Issuance">
    </app-progress-tracker>

    <ng-template #infoDialogTemplate>
        <hc-body class="dialog-body-container top-margin">
            <ng-container *ngFor="let stage of infoDialogContent.stages">
                <h5><mat-icon class="stage-icon">{{ stage.icon }}</mat-icon>{{ stage.title }}</h5>
                <p>{{ stage.text }}</p>
            </ng-container>
        </hc-body>
    </ng-template>
</ng-container>