<div fxFlex fxLayout="column" id="main-content">
  <div fxLayout="column" class="top-section">
    <div fxLayoutAlign="end end">
      <button id="additionalInfoPopupClose" mat-icon-button class="global-x-btn">
        <mat-icon (click)="cancel()">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="main" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="4px">
    <div class="title">{{title}}</div>
    <div class="header">{{header}}</div>
    <div class="content">
      {{content}}
    </div>
  </div>
</div>