import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotificationService } from 'hc-design-system-lib';
import { environment } from 'src/environments/environment';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { IUserModel } from 'src/app/common';
import { selectUserData } from 'src/app/store/userContext/userContext.selectors';
import { switchMap, take } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class InternalUserInterceptor implements HttpInterceptor {

  user$: Observable<IUserModel> = this._store.pipe(select(selectUserData));

  constructor(
    private _notificationService: NotificationService,
    private _store: Store<IAppState>
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const postOrPutOrPatchOrDelete = req.method === 'PUT' || req.method === 'POST' || req.method === 'PATCH' || req.method === 'DELETE';
    const contactsSearch = req.url === `${environment.apiBaseUrl}contacts/search`;
    const contactSelect = req.url === `${environment.apiBaseUrl}contacts/select`;
    const jobSearch = req.url === `${environment.apiBaseUrl}job/available-jobs`;
    const similarJob = req.url === `${environment.apiBaseUrl}job/similar-jobs`;

    return this.user$.pipe(
      take(1),
      switchMap((userData: IUserModel) => {
        const isInternal = userData ? userData.isInternal : false;
        if (postOrPutOrPatchOrDelete && isInternal && !contactsSearch && !contactSelect && !jobSearch && !similarJob) {
          this._notificationService.showNotification('Updating requests are not allowed as an impersonating user.', 'error', 3500);
          return;
        } else {
          return next.handle(req);
        }
      })
    );
  }
}
