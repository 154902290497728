import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AppUrls } from '../app-urls';
import { Store } from '@ngrx/store';
import { Observable, skipWhile, map } from 'rxjs';
import { featureFlagNames } from '../services/feature-flags.service';
import { selectIsSpecificFlagOn } from '../store/flags/flags.selectors';

@Injectable({
  providedIn: 'root',
})
export class JobPreferencesRegistrationGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _store: Store,
  ) {}

  contactPreferencesOn$: Observable<boolean> = this._store
    .select(selectIsSpecificFlagOn(featureFlagNames.communicationPreferences))
    .pipe(skipWhile((contactPreferencesOn) => contactPreferencesOn === null));

  canActivate(next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.contactPreferencesOn$.pipe(
      map((contactPreferencesOn) => {
        return !contactPreferencesOn || next.queryParamMap.get('registrationPath') === 'true'
          ? true
          : this._router.parseUrl(`${AppUrls.PREFERENCES}/${AppUrls.JOB_PREFERENCES}`);
      }),
    );
  }
}
