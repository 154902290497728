import { Component, Inject, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './additional-info-popup.component.html',
  styleUrls: ['./additional-info-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdditionalInfoPopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AdditionalInfoPopupComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  title = this.data.title;
  header = this.data.header;
  content = this.data.content;
  info = this.data.info;

  ngOnInit(): void {
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
