<hc-navigation
  class="hc-main-navigation"
  [logoImageSource]="navbarLogo"
  [profile]="hideProfile ? null : navigationProfile"
  [profileMenuItems]="profileMenuItems"
  [signOutItem]="signOutItem"
  [tabbarItems]="tabBarItems"
  [tabbarActiveItemIndex]="tabbarActiveItemIndex"
  [showNavbar]="!hideNavbar"
  [showTabbar]="!hideTabBar && !isOnRegistrationPath"
  [showProfileMenu]="!hideProfile && !isOnRegistrationPath"
  [showFormHeader]="showFormHeader"
  [secondaryAction]="supportButton"
  (profileMenuItemClick)="navigate($event, 'Top Navigation')"
  (tabbarItemClick)="navigate($event, 'Tabbar Navigation')"
  (secondaryActionClick)="navigate(supportButtonClickEvent, 'Top Navigation')"
  [class.sidenav-content-padding-0]="shouldDisableNavContentPadding()"
>
  <app-form-header
    form-header
    #headerRef
  >
  </app-form-header>

  <hc-card page [styles]="cardStyles">
    <div fxFlex fxLayout="column" class="main-content">
      <div class="loading-overlay" *ngIf="loading">
        <app-loading-screen-spinner [show]="true"></app-loading-screen-spinner>
        <md-progress-bar mode="indeterminate"></md-progress-bar>
      </div>
      <router-outlet
        *ngIf="!loading"
        (activate)="onActivate($event)"
        class="navigation-router"
      ></router-outlet>
    </div>
  </hc-card>
</hc-navigation>

<app-delete-confirmation
  [onConfirm]="deleteConfirmationEvent"
></app-delete-confirmation>
<app-basic-snack-bar
  [displayEvent]="basicSnackBarEmitter"
></app-basic-snack-bar>

<input
  class="no-display"
  id="hidden-name"
  *ngIf="(nurse$ | async)"
  [ngModel]="(nurse$ | async)?.firstName + ' ' + (nurse$ | async)?.lastName"
/>
<input class="no-display" id="hidden-merlinId" *ngIf="(nurse$ | async)" [ngModel]="(nurse$ | async)?.merlinId" />
<input class="no-display" id="hidden-hubSpotId" *ngIf="(nurse$ | async)" [ngModel]="(nurse$ | async)?.hubSpotId" />
<input class="no-display" id="hidden-email" *ngIf="(nurse$ | async)" [ngModel]="(nurse$ | async)?.email" />
<ng-template #specialtyPopup>
  <app-specialty-popup></app-specialty-popup>
</ng-template>