import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ITileCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { Observable } from 'rxjs';
import { AppUrls } from 'src/app/app-urls';
import { Shifts } from 'src/app/common/contracts/shifts-id';
import { JobPreferenceReturn } from 'src/app/common/models/job-preference';
import {
  selectJobPreferences,
  selectJobPreferencesLoading,
} from 'src/app/store/jobs/jobs.selectors';

@Component({
  selector: 'app-job-preferences-tile',
  templateUrl: './job-preferences-tile.component.html',
  styleUrls: ['./job-preferences-tile.component.scss'],
})
export class JobPreferencesTileComponent {
  jobPreferences$: Observable<JobPreferenceReturn> = this._store.pipe(
    select(selectJobPreferences)
  );
  jobPreferencesLoading$: Observable<boolean> = this._store.pipe(
    select(selectJobPreferencesLoading)
  );

  tileConfig: ITileCardConfig = {
    title: 'Job Preferences',
    linkDetails: {
      title: 'Update Preferences',
      routerLink: `../${AppUrls.JOB_PREFERENCES}`,
    },
  };

  constructor(private _store: Store) {}

  getJobPreferencesPay(pay: number) {
    return (
      pay?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }) ?? '---'
    );
  }

  getJobPreferencesShift(shift: number) {
    return Shifts[shift]?.toString() ?? '---';
  }

  getJobPreferencesLocations(jobPreferences: JobPreferenceReturn) {
    if (
      !jobPreferences?.locationDetails?.length &&
      !jobPreferences?.submitToCompactState
    )
      return '---';
    else if (jobPreferences?.locationDetails?.length) {
      return jobPreferences.locationDetails
        .map((location) => location.location.replace(', USA', ''))
        .join(', ');
    } else {
      return 'Compact License states';
    }
  }
}
