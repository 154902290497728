import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { filter, map } from 'rxjs/operators';
import { DocumentHelperService } from '../services';

/**
 * This is used to intercept calls to get things from the assets folder specifically for On-Assignment, to allow capacitor to intervene
 *
 * @export
 * @class AssetInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class AssetInterceptor implements HttpInterceptor {

  constructor(private documentHelper: DocumentHelperService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //Check for pathing of assets/
    if(request.url.includes('assets/')){
      //Verify that we're in native app mode
      if(Capacitor.isNativePlatform()){
        //pull the filename off of the document
        const pathSegments = request.url.split('/');
        const filename = pathSegments[pathSegments.length - 1];
        //Clone the request and pipe it to document helper to utilize capacitor
        return next.handle(request.clone()).pipe(
          filter((event) => event instanceof HttpResponse),
          map((response: HttpResponse<Blob>) => {
            if (response.body instanceof Blob) {
              // If the response is a Blob, return it as-is
              this.documentHelper.writeFileMobile(response.body,filename);
              return response;
            }
          })
        );
      }
    }
    return next.handle(request);
  }
}
