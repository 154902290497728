import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { CallToActionClicked } from 'src/app/store/segment/segment.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss']
})
export class EmptyListComponent implements OnInit {
  @Input() word: string;
  @Input() extended: string;
  @Input() optionalText: string;
  @Input() buttonText: string;
  @Input() buttonAction: () => {};
  @Input() secondaryActionText: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store<IAppState>,) { }

  ngOnInit() {
  }

  getButtonText(): string {
    if (this.buttonText) {
      return this.buttonText;
    } else {
      return `+ Add ${this.word}`.toUpperCase();
    }
  }

  hasSecondaryAction(): boolean {
    if (this.secondaryActionText) {
      return true;
    }
    return false;
  }

  onButtonClick() {
    if (typeof this.buttonAction === 'function') {
      this.buttonAction();
    } else {
      this.addManually();
    }
  }

  addManually() {
    const pageUrl = environment.appBaseUrl + this._router.url;
    this._store.dispatch(new CallToActionClicked({ pageURL: pageUrl, pageTitle: this._route.routeConfig.title as string, destinationURL: pageUrl + '/add', ctaText: this.hasSecondaryAction() ? this.secondaryActionText : this.getButtonText() }));
    this._router.navigate(['add'], { relativeTo: this._route });
  }

}
