<ng-container>
  <app-loading-screen-spinner
  *ngIf="deleteRequestIsLoading$ | async"
  [show]="true"
></app-loading-screen-spinner>
<div id='requestView' *ngIf="deletionStage.value === STEP_REQUEST">
  <div class="padded-container">
    <hc-body>
      Are you sure you want to delete your account? This will delete your account along with associated personal data. <br/><br/>
      
      If you choose to proceed, <b>we will send a verification code to the email address associated with your account </b> to verify the deletion. 
    </hc-body>
    <div class="button-container">
      <hc-button
        [appearance]="primaryButtonAppearance"
        id="request-delete"
        (buttonClick)="requestDelete()"
      >
        Yes, continue
      </hc-button>
      <hc-button
        [appearance]="secondaryButtonAppearance"
        id="cancel-request"
        (buttonClick)="cancelRequest()"
      >
        Cancel
      </hc-button>
    </div>
  </div>
</div>

<div id='confirmView' *ngIf="deletionStage.value === STEP_CONFIRM">
  <div class="padded-container">
    <hc-body>
      Enter the verification code you received via email to complete your account deletion.
    </hc-body>
    <div class="form-container">
      <hc-input
        [control]="verificationCodeForm"
        [errorMessage]="getErrorMessage(verificationCodeForm)"
        placeholder="Enter code"
        label="Verification code"
        id="verification-form-field"
      ></hc-input>
    </div>
    <hc-body [size]="microFont">
      Didn’t receive a code? Check your spam folder or <span id="resend-code" (click)="requestDelete()">resend the code</span>
    </hc-body>
    <div class="button-container">
      <hc-button
      [appearance]="primaryButtonAppearance"
      (buttonClick)="confirmDelete()"
      [disabled]="verificationCodeForm.invalid"
      id="submit-deletion-code"
      >
        Delete Account
      </hc-button>
      <hc-button
        [appearance]="secondaryButtonAppearance"
        id="cancel-deletion"
        (buttonClick)="cancelRequest()"
      >
        Cancel
      </hc-button>
    </div>
  </div>
</div>

<div id='completeView' *ngIf="deletionStage.value === STEP_COMPLETE">
  <div class="padded-container">
    <hc-body>
      Your account has been submitted for deletion and will process within 14 days.
    </hc-body>
    <div class="button-container">
    <hc-button
      [appearance]="primaryButtonAppearance"
      id="close-final"
      (buttonClick)="logoutUser()"
    >
      Close
    </hc-button>
    </div>
  </div>
</div>

<div id='errorView' *ngIf="deletionStage.value === STEP_ERROR">
  <div class="padded-container">
    <hc-body>
      There was an error processing your request. Please contact support for assistance.
    </hc-body>
    <div class="button-container">
      <hc-button
        [appearance]="primaryButtonAppearance"
        id="close-error"
        (buttonClick)="cancelRequest()"
      >
        Close
      </hc-button>
      </div>
  </div>
</div>
  
</ng-container>