export enum TaskStatusConstants {
    complete = 948050002,
    inProgress = 948050016,
    actionRequired = 948050006,
    expired = 948050015,
    expiring = 948050020,
    pending = 948050003,
};


