import { Component, HostBinding, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavItem } from 'src/app/common';
import { NavHelper } from 'src/app/services';

@Component({
    selector: 'app-menu-list-item',
    templateUrl: './menu-list-item.component.html',
    styleUrls: ['./menu-list-item.component.scss'],
    animations: [
        trigger('indicatorRotate', [
            state('collapsed', style({ transform: 'rotate(0deg)' })),
            state('expanded', style({ transform: 'rotate(180deg)' })),
            transition('expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
            ),
        ])
    ]
})
export class MenuListItemComponent implements OnInit {
    expanded: boolean;
    @Output() closeNav = new EventEmitter<boolean>();
    @Input() item: NavItem;
    @Input() depth: number;

    constructor(public router: Router,
        public navService: NavHelper) {
        if (this.depth === undefined) {
            this.depth = 0;
        }
    }

    ngOnInit() {
    }

    onItemSelected(item: NavItem) {
        if (!item.children || !item.children.length) {
            this.router.navigate([item.route]);
            this.navService.closeNav();
        }
        if (item.children && item.children.length) {
            this.expanded = !this.expanded;
        }
    }
}

