import { formatCurrency } from '@angular/common';
import { IBadgeDetails, ICardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import moment from 'moment';
import { IJob, ILookup } from 'src/app/common';
import { ContractType } from 'src/app/common/contracts/contract-type';
import { BadgeInfo } from 'src/app/common/models/badge';

interface IMapJobToCardConfigLookups {
  yesNoLookup: Map<string, ILookup<string>>;
  specialtyLookup: Map<string, ILookup<string>>;
  professionLookup: Map<string, ILookup<string>>;
  stateLookup: Map<string, ILookup<string>>;
};

interface IJobDetails {
  primaryBadgeDetails: BadgeInfo;
  jobName: string;
  jobState: string;
  showBadge: boolean;
  showPay: boolean;
  pay?: number;
  badgeDetails?: IBadgeDetails[];
}

export function mapJobToCardConfig(
  job: IJob,
  lookups: IMapJobToCardConfigLookups,
  badgeDetails: IBadgeDetails[],
  primaryBadge?: BadgeInfo,
  isResponsive: boolean = false,
): ICardConfig {
  const specialty = lookups.specialtyLookup.get(job.specialty);
  const profession = lookups.professionLookup.get(job.profession);
  const jobName = getJobName(profession, specialty);
  const jobState = lookups.stateLookup.get(job.state)?.code;
  const yesLookupId = lookups.yesNoLookup.get('Yes').id as unknown as number;

  const shouldShowPay = job?.showPay === yesLookupId;
  const pay = job.contractType === ContractType.Local ?
    job.estLocalGrossPay || job.localEstWeeklyGross :
    job.estTravelGrossPay || job.estGrossPay;

  return buildJobCardDetails(
    job,
    {
      primaryBadgeDetails: primaryBadge,
      jobName,
      jobState,
      showBadge: true,
      showPay: shouldShowPay,
      pay,
      badgeDetails
    },
    isResponsive
  );
}

export function buildJobCardDetails(job: IJob, jobDetails: IJobDetails, isResponsive?: boolean) {
  const { jobName, jobState, showPay, pay, showBadge, primaryBadgeDetails, badgeDetails } = jobDetails;

  return {
    title: jobName,
    secondaryText: job.hospital,
    tertiaryText: `${job.city}, ${jobState}`,
    detailSectionConfig: [
      {
        sectionHeading: 'Est. Weekly Pay',
        sectionText: showPay ? getFormattedPay(pay) : null
      },
      {
        sectionHeading: 'Start Date',
        sectionText: getStartDate(job.startDate)
      },
      {
        sectionHeading: 'Duration',
        sectionText: `${job.assignmentLength} Weeks`
      }
    ],
    primaryBadge: showBadge ? primaryBadgeDetails : null,
    badgeDetails: badgeDetails,
    isCardActionActive: job.saved,
    cardData: {
      jobId: job.id,
      contractType: job.contractType,
    },
    isResponsive: isResponsive
  }
}

function getJobName(profession: ILookup<string>, speciality?: ILookup<string>): string {
  if (speciality?.name) {
    return speciality.name + ' Travel ' + profession.shortName;
  }

  return 'Travel ' + profession?.shortName;
}

function getFormattedPay(pay): string {
  return pay ? formatCurrency(pay, 'en-US', '$', 'USD', '1.0-0') : null;
}

function getStartDate(date: Date): string {
  if (typeof date === 'undefined') {
    return 'Soon';
  }
  const today = new Date();
  const startDate = new Date(date);
  if (startDate <= today) {
    return 'ASAP';
  }
  return moment(startDate).format('MM/DD/yyyy');
}
