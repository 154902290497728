import { Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Angulartics2Actions,
  EAngulartics2Actions,
  AngularticsEventTrack,
} from 'src/app/store/angulartics2/angulartics2.actions';
import { map, tap } from 'rxjs/operators';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { NurseModel } from 'src/app/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Angulartics2Effects {
  constructor(
    private actions$: Actions,
    protected _angulartics2: Angulartics2,
    protected _store: Store,
  ) {}

  eventTrack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<Angulartics2Actions>(EAngulartics2Actions.AngularticsEventTrack),
        tap((eventData: AngularticsEventTrack) => {
          const { action, category, label } = eventData;
          this._store
            .select(selectNurseData)
            .pipe(
              map((nurseData: NurseModel) => {
                const properties = {
                  category,
                  label,
                  userId: nurseData.merlinId,
                };
                this._angulartics2.eventTrack.next({ action, properties });
              }),
            )
            .subscribe();
        }),
      );
    },
    { dispatch: false },
  );
}
