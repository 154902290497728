import { Action } from "@ngrx/store";

export enum EUiActions {
  SetScreenWidth = '[UI] set screen width',
}

export class SetScreenWidth implements Action {
  public readonly type = EUiActions.SetScreenWidth;

  constructor(public payload: number) {}
}

export type UiActions =
  | SetScreenWidth
  ;