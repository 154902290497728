import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { ZipCodeModel } from 'src/app/common/models/zip-code-model';
import { remoteAutocompleteValidator } from 'src/app/common/validators/autocompleteValidator';
import { NursePortalApi } from 'src/app/services';

// Note: The formatting of the items in the list is found in AutoCompleteComponent.getName(...)
@Component({
  selector: 'app-zip-code-lookup',
  templateUrl: './zip-code-lookup.component.html',
  styleUrls: ['./zip-code-lookup.component.scss']
})
export class ZipCodeLookupComponent implements OnInit {
  _shouldValidate: boolean;
  @Input() form: UntypedFormGroup;
  @Input() placeholder: string;
  @Input() controlName: string;
  @Input() set shouldValidate(value: boolean) {
    this._shouldValidate = value;
    this._setValidation(value);
  }
  get shouldValidate() {
    return this._shouldValidate;
  }
  @Output() zipSelected: EventEmitter<ZipCodeModel> = new EventEmitter();

  filteredZipCodes: Observable<any[]>;

  constructor(private _api: NursePortalApi) {}

  ngOnInit(): void {
    this.filteredZipCodes = this.form.controls[this.controlName].valueChanges.pipe(
      startWith(''),
      debounceTime(300), // delay emits for throttling calls
      switchMap(value => {
        if (value !== '') {
          return this._lookupZipCodes(value);
        } else {
          return of(null);
        }
      })
    );
    this._setValidation(true);
  }

  _setValidation(shouldValidate: boolean) {
    if (!this.form) {
      return;
    }

    if (shouldValidate) {
      this.form.controls[this.controlName].setValidators(remoteAutocompleteValidator(this._isValidZip, this));
    } else {
      this.form.controls[this.controlName].clearValidators();
    }
  }

   // Callback for validating the zip autocomplete
   _isValidZip(fieldValue: any, control: ZipCodeLookupComponent ) {
     if (control !== undefined && !control._shouldValidate) {
        return null;
     }


    // If the zip isn't an valid autocomplete selection, the fieldValue is just what's in the control
    if ((typeof fieldValue === 'string' || fieldValue instanceof String) && fieldValue !== '' && fieldValue != null) {
      return  {'invalidZip': {value: fieldValue}};
    }

    // If it is an autocomplete selection, it comes back as an object.
    const zipModel = new ZipCodeModel();
    Object.assign(zipModel, fieldValue);
    if (zipModel.zipCode?.length < 5 && zipModel.zipCode?.length > 0
      ) {
      return {'invalidZip': {value: zipModel.zipCode}};
    }

    return null;
  }

  _lookupZipCodes(value: any): Observable<any> {
    const zip = value.toString();

    if (zip === undefined || zip.length < 3) {
      return of(null);
    }

    if (isNaN(zip)) {
      return of(null);
    }

    return this._api.getZipCodeInfo(value).pipe(
      map(results => results),
      catchError(_ => {
        return of(null);
      })
    );
  }

  invokeOptionSelectedCallback(item: any) {
    const zipCode = item.option.value as ZipCodeModel;
    this.zipSelected.emit(zipCode);
  }

  displayZipFn(item?: any): string | undefined {
    return item ? item.zipCode : undefined;
  }
}
