import { JobPreferenceReturn } from 'src/app/common/models/job-preference';
import { EJobsActions, JobsActions } from 'src/app/store/jobs/jobs.actions';
import { initialJobsState, JobsState } from 'src/app/store/jobs/jobs.state';
import { IJob } from 'src/app/common';

export function jobsReducer(
  state = initialJobsState,
  action: JobsActions,
): JobsState {
  switch (action.type) {
    case EJobsActions.GetJobPreferences:
      return {
        ...state,
        jobPreferences: {
          ...state.jobPreferences,
          error: null,
          loading: true,
        },
      };
    case EJobsActions.GetJobPreferencesSuccess:
      return {
        ...state,
        jobPreferences: {
          data: action.payload ?? ({} as JobPreferenceReturn),
          error: null,
          loading: false,
        },
      };
    case EJobsActions.GetJobPreferencesError:
      return {
        ...state,
        jobPreferences: {
          ...state.jobPreferences,
          error: action.error,
          loading: false,
        },
      };
    case EJobsActions.GetAvailableJobs:
    case EJobsActions.SetSavedAvailableJob:
      return {
        ...state,
        availableJobs: {
          ...state.availableJobs,
          error: null,
          loading: true,
        },
      };
    case EJobsActions.GetAvailableJobsSuccess: {
      let data;

      if (action.reload) {
        data = action.payload;
      } else {
        data = {
          ...state.availableJobs.data,
          jobs: [...state.availableJobs.data.jobs, ...action.payload.jobs],
        };
      }

      return {
        ...state,
        availableJobs: {
          data: data,
          error: null,
          loading: false,
        },
      };
    }
    case EJobsActions.GetAvailableJobsError:
      return {
        ...state,
        availableJobs: {
          ...state.availableJobs,
          error: action.error,
          loading: false,
        },
      };
    case EJobsActions.GetSimilarJobs:
    case EJobsActions.SetSavedSimilarJob:
      return {
        ...state,
        similarJobs: {
          ...state.similarJobs,
          error: null,
          loading: true,
        },
      };
    case EJobsActions.GetSimilarJobsSuccess:
      return {
        ...state,
        similarJobs: {
          data: action.payload,
          error: null,
          loading: true,
        },
      };
    case EJobsActions.GetSimilarJobsSuccessMapped:
      return {
        ...state,
        similarJobs: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case EJobsActions.GetSimilarJobsError:
      return {
        ...state,
        similarJobs: {
          ...state.similarJobs,
          error: action.error,
          loading: false,
        },
      };
    case EJobsActions.GetSimilarJobsByJobId:
      return {
        ...state,
        similarJobsByJobId: {
          data: null,
          error: null,
          loading: true,
        },
      };
    case EJobsActions.GetSimilarJobsByJobIdSuccess:
      return {
        ...state,
        similarJobsByJobId: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case EJobsActions.GetSimilarJobsByJobIdError:
      return {
        ...state,
        similarJobsByJobId: {
          ...state.similarJobsByJobId,
          error: action.error,
          loading: false,
        },
      };
    case EJobsActions.SetJobPreferences: {
      // Update local, add or remove
      let updatedJobPreferences: JobPreferenceReturn = null;
      if (action.payload) {
        // Adding Job Preferences
        updatedJobPreferences = { ...action.payload, contactId: null };
      }
      return {
        ...state,
        setJobPreferencesResult: {
          data: updatedJobPreferences,
          error: null,
          loading: true,
        },
        jobPreferences: {
          data: updatedJobPreferences,
          error: null,
          loading: true,
        },
      };
    }
    case EJobsActions.SetJobPreferencesSuccess:
      return {
        ...state,
        setJobPreferencesResult: {
          data: action.response,
          error: null,
          loading: false,
        },
        jobPreferences: {
          data: action.response,
          error: null,
          loading: false,
        },
      };
    case EJobsActions.SetJobPreferencesError:
      return {
        ...state,
        setJobPreferencesResult: {
          data: null,
          error: action.error,
          loading: false,
        },
        jobPreferences: {
          data: null,
          error: action.error,
          loading: false,
        },
      };
    case EJobsActions.ResetJobPreferenceSubmitResult:
      return {
        ...state,
        setJobPreferencesResult: {
          data: null,
          error: null,
          loading: state.setJobPreferencesResult.loading,
        },
      };
    case EJobsActions.GetRecommendedJobsByPreference:
    case EJobsActions.SetSavedRecommendedJob:
      return {
        ...state,
        jobRecommendationsByPreference: {
          ...state.jobRecommendationsByPreference,
          error: null,
          loading: true,
        },
      };
    case EJobsActions.GetRecommendedJobsByPreferenceSuccess:
      return {
        ...state,
        jobRecommendationsByPreference: {
          data: action.payload ?? [],
          error: null,
          loading: false,
        },
      };
    case EJobsActions.GetRecommendedJobsByPreferenceError:
      return {
        ...state,
        jobRecommendationsByPreference: {
          ...state.jobRecommendationsByPreference,
          error: action.error,
          loading: false,
        },
      };
    case EJobsActions.GetJobSubmittals:
      return {
        ...state,
        jobSubmittals: {
          ...state.jobSubmittals,
          error: null,
          loading: true,
          expirationDate: null,
        },
      };
    case EJobsActions.GetJobSubmittalsSuccess:
      return {
        ...state,
        jobSubmittals: {
          data: action.payload.submittals ?? [],
          error: null,
          loading: false,
          expirationDate: action.payload.expiration,
        },
      };
    case EJobsActions.GetJobSubmittalsError:
      return {
        ...state,
        jobSubmittals: {
          ...state.jobSubmittals,
          error: action.error,
          loading: false,
          expirationDate: null,
        },
      };
    case EJobsActions.ArchiveSubmittal:
      return {
        ...state,
        jobSubmittals: {
          ...state.jobSubmittals,
          error: null,
          loading: true,
        },
        lastArchivedSubmittal: {
          data: null,
          error: null,
          loading: true,
        },
      };
    case EJobsActions.ArchiveSubmittalSuccess: {
      const archived = state.jobSubmittals.data.find(
        (sub) => sub.id === action.payload.id,
      );
      return {
        ...state,
        jobSubmittals: {
          data: state.jobSubmittals.data.filter(
            (sub) => sub.id !== action.payload.id,
          ),
          error: null,
          loading: false,
        },
        lastArchivedSubmittal: {
          data: archived,
          error: null,
          loading: false,
        },
      };
    }
    case EJobsActions.ArchiveSubmittalError:
      return {
        ...state,
        jobSubmittals: {
          ...state.jobSubmittals,
          error: action.error,
          loading: false,
        },
        lastArchivedSubmittal: {
          data: null,
          error: action.error,
          loading: false,
        },
      };
    case EJobsActions.GetSavedJobs:
      return {
        ...state,
        savedJobs: {
          ...state.savedJobs,
          error: null,
          loading: true,
        },
      };
    case EJobsActions.GetSavedJobsSuccess:
      return {
        ...state,
        savedJobs: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case EJobsActions.GetSavedJobsError:
      return {
        ...state,
        savedJobs: {
          ...state.savedJobs,
          error: action.error,
          loading: false,
        },
      };
    case EJobsActions.SetSavedJob: {
      return {
        ...state,
        savedJobs: {
          ...state.savedJobs,
          error: null,
          loading: true,
        },
        specificSavedJob: {
          data: null,
          error: null,
          loading: true,
        },
      };
    }
    case EJobsActions.SetSavedJobSuccess: {
      // Update local, add or remove
      let updatedJobsArray;
      let totalCount;
      if (state.savedJobs?.data?.jobs) {
        if (action.payload.saveValue) {
          // Adding Saved Job
          const updatedJob = {
            ...action.payload.job,
            saved: true,
            contractType: action.payload.contractType,
          };
          updatedJobsArray = [...state.savedJobs.data.jobs, updatedJob];
        } else {
          // Removing Saved Job
          updatedJobsArray = [...state.savedJobs.data.jobs].filter(
            (j) =>
              !(
                j.id === action.payload.job.id &&
                j.contractType === action.payload.contractType
              ),
          );
        }
        totalCount = updatedJobsArray.length;
      }

      let updatedAvailableJobs;
      if (state.availableJobs?.data?.jobs) {
        updatedAvailableJobs = state.availableJobs.data.jobs.map((job) => {
          if (action.payload.contractType) {
            return job.id === action.payload.job.id &&
              job.contractType === action.payload.contractType
              ? { ...job, saved: action.payload.saveValue }
              : job;
          } else {
            return job.id === action.payload.job.id
              ? { ...job, saved: action.payload.saveValue }
              : job;
          }
        });
      }
      return {
        ...state,
        savedJobs: {
          data: {
            jobs: updatedJobsArray,
            totalCount: totalCount,
          },
          error: null,
          loading: false,
        },
        availableJobs: {
          ...state.availableJobs,
          data: {
            ...state.availableJobs.data,
            jobs: updatedAvailableJobs,
          },
        },
        specificSavedJob: {
          data: action.payload.value,
          error: null,
          loading: false,
        },
      };
    }
    case EJobsActions.SetSavedJobError: {
      return {
        ...state,
        specificSavedJob: {
          data: null,
          error: action.errorPayload.error,
          loading: false,
        },
      };
    }

    case EJobsActions.SetSavedRecommendedJobSuccess: {
      const recommendedJob: IJob = {
        ...action.payload.job,
        saved: action.payload.saveValue,
        contractType: action.payload.contractType,
      };

      // Redux stores are immutable by nature
      const updatedRecommendedJobList: IJob[] =
        state.jobRecommendationsByPreference.data.map((job: IJob) =>
          job.id === recommendedJob.id ? recommendedJob : job,
        );

      return {
        ...state,
        jobRecommendationsByPreference: {
          data: updatedRecommendedJobList,
          error: null,
          loading: false,
        },
      };
    }

    case EJobsActions.SetSavedAvailableJobSuccess: {
      const updatedJobResults: IJob[] = state.availableJobs.data.jobs.map(
        (job) =>
          job.id === action.payload.job.id
            ? { ...job, saved: action.payload.saveValue }
            : job,
      );

      return {
        ...state,
        availableJobs: {
          ...state.availableJobs,
          data: {
            ...state.availableJobs.data,
            jobs: updatedJobResults,
          },
          error: null,
          loading: false,
        },
      };
    }

    case EJobsActions.SetSavedSimilarJobSuccess: {
      const updatedSimilarJobs: IJob[] = state.similarJobs.data.jobs.map(
        (job) => {
          if (action.payload.contractType) {
            return job.id === action.payload.job.id &&
              job.contractType === action.payload.contractType
              ? { ...job, saved: action.payload.saveValue }
              : job;
          }

          return job.id === action.payload.job.id
            ? { ...job, saved: action.payload.saveValue }
            : job;
        },
      );

      return {
        ...state,
        similarJobs: {
          ...state.similarJobs,
          data: {
            ...state.similarJobs.data,
            jobs: updatedSimilarJobs,
          },
          error: null,
          loading: false,
        },
      };
    }

    case EJobsActions.GetJobFilterV2:
      return {
        ...state,
        jobFilterV2: {
          ...state.jobFilterV2,
          error: null,
          loading: false,
        },
      };
    case EJobsActions.SetJobFilterV2:
      return {
        ...state,
        jobFilterV2: {
          data: action.filter,
          error: null,
          loading: false,
        },
      };

    case EJobsActions.GetLocationsByName:
      return {
        ...state,
        locationsByName: {
          data: null,
          error: null,
          loading: true,
        },
      };
    case EJobsActions.GetLocationsByNameSuccess:
      return {
        ...state,
        locationsByName: {
          data: action.response,
          error: null,
          loading: false,
        },
      };
    case EJobsActions.GetLocationsByNameError:
      return {
        ...state,
        locationsByName: {
          data: null,
          error: action.error,
          loading: false,
        },
      };
    case EJobsActions.GetSpecificJob:
      return {
        ...state,
        specificJobMapUrl: {
          data: null,
          loading: true,
          error: null,
        },
        specificJob: {
          data: {
            id: action.jobId,
            notFound: false,
          } as IJob,
          error: null,
          loading: true,
        },
      };

    case EJobsActions.ClearSpecificJob:
      return {
        ...state,
        specificJob: {
          data: null,
          error: null,
          loading: false,
        },
      };

    case EJobsActions.GetSpecificJobSuccess:
      return {
        ...state,
        specificJob: {
          ...state.specificJob,
          data: {
            ...action.payload,
            applied:
              action.payload.hasCompletedApplication &&
              !action.payload.hasWithdrawn,
            hasWithdrawn:
              action.payload.hasCompletedApplication &&
              action.payload.hasWithdrawn,
            notFound: false,
          },
          error: null,
          loading: false,
        },
      };

    case EJobsActions.GetSpecificJobError:
      return {
        ...state,
        specificJob: {
          ...state.specificJob,
          error: action.error,
          loading: false,
        },
      };

    case EJobsActions.GetSpecificJobNotFound:
      return {
        ...state,
        specificJob: {
          ...state.specificJob,
          data: {
            ...state.specificJob.data,
            notFound: action.found,
          },
        },
      };
    case EJobsActions.GetSimilarJobsByFacilityId:
      return {
        ...state,
        similarJobsByFacility: {
          data: null,
          error: null,
          loading: true,
        },
      };
    case EJobsActions.GetSimilarJobsByFacilityIdSuccess:
      return {
        ...state,
        similarJobsByFacility: {
          ...state.similarJobsByFacility,
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case EJobsActions.GetSimilarJobsByFacilityIdError:
      return {
        ...state,
        similarJobsByFacility: {
          ...state.similarJobsByFacility,
          error: action.error,
          loading: false,
        },
      };

    case EJobsActions.GetMapUrlForFacilitySuccess:
      return {
        ...state,
        specificJobMapUrl: {
          ...state.specificJobMapUrl,
          data: action.url,
        },
      };

    case EJobsActions.GetHasAutoOfferAssignments:
      return {
        ...state,
        hasAutoOfferAssignments: {
          data: null,
          error: null,
          loading: true,
        },
      };
    case EJobsActions.GetHasAutoOfferAssignmentsSuccess:
      return {
        ...state,
        hasAutoOfferAssignments: {
          data: action.response,
          error: null,
          loading: false,
        },
      };
    case EJobsActions.GetHasAutoOfferAssignmentsError:
      return {
        ...state,
        hasAutoOfferAssignments: {
          data: null,
          error: action.error,
          loading: false,
        },
      };
    case EJobsActions.GetRecommendedMatchedJobs:
      return {
        ...state,
        recommendedMatchedJobs: {
          ...state.recommendedMatchedJobs,
          error: null,
          loading: true,
        },
      };
    case EJobsActions.GetRecommendedMatchedJobsSuccess:
      return {
        ...state,
        recommendedMatchedJobs: {
          data: action.payload ?? [],
          error: null,
          loading: false,
        },
      };
    case EJobsActions.GetRecommendedMatchedJobsError:
      return {
        ...state,
        recommendedMatchedJobs: {
          ...state.recommendedMatchedJobs,
          error: action.error,
          loading: false,
        },
      };

    case EJobsActions.ApplyToSpecificJob:
      return {
        ...state,
        applyToJobResult: {
          ...state.applyToJobResult,
          error: null,
          loading: true,
        },
      };

    case EJobsActions.ApplyToSpecificJobSuccess:
      return {
        ...state,
        applyToJobResult: {
          data: action.response,
          error: null,
          loading: false,
        },
        specificJob: {
          data: { ...state.specificJob.data, applied: true, hasStarted: true },
          error: null,
          loading: false,
        },
      };

    case EJobsActions.ApplyToSpecificJobError:
      return {
        ...state,
        applyToJobResult: {
          ...state.applyToJobResult,
          error: action.error,
          loading: false,
        },
      };

    case EJobsActions.UpdateApplication:
      return {
        ...state,
        updateApplicationResult: {
          ...state.updateApplicationResult,
          error: null,
          loading: true,
        },
      };

    case EJobsActions.UpdateApplicationSuccess:
      return {
        ...state,
        updateApplicationResult: {
          data: action.response,
          error: null,
          loading: false,
        },
      };

    case EJobsActions.UpdateApplicationError:
      return {
        ...state,
        updateApplicationResult: {
          ...state.updateApplicationResult,
          error: action.error,
          loading: false,
        },
      };

    case EJobsActions.GetApplication:
      return {
        ...state,
        application: {
          ...state.application,
          error: null,
          loading: true,
        },
      };

    case EJobsActions.GetApplicationSuccess:
      return {
        ...state,
        application: {
          data: action.response,
          error: null,
          loading: false,
        },
      };

    case EJobsActions.GetApplicationError:
      return {
        ...state,
        application: {
          ...state.application,
          error: action.error,
          loading: false,
        },
      };

    case EJobsActions.ResetUpdateApplication:
      return {
        ...state,
        updateApplicationResult: {
          data: null,
          error: null,
          loading: false,
        },
      };

    case EJobsActions.ResetApplyToSpecificJob:
      return {
        ...state,
        applyToJobResult: {
          data: null,
          error: null,
          loading: false,
        },
      };

    case EJobsActions.UpdateProfileReviewData:
      return {
        ...state,
        updateProfileReviewResult: {
          ...state.updateProfileReviewResult,
          error: null,
          loading: true,
        },
      };

    case EJobsActions.UpdateProfileReviewDataSuccess:
      return {
        ...state,
        updateProfileReviewResult: {
          data: action.response,
          error: null,
          loading: false,
        },
      };

    case EJobsActions.UpdateProfileReviewDataError:
      return {
        ...state,
        updateProfileReviewResult: {
          ...state.updateProfileReviewResult,
          error: action.error,
          loading: false,
        },
      };

    case EJobsActions.ResetUpdateProfileReviewResult:
      return {
        ...state,
        updateProfileReviewResult: {
          data: null,
          error: null,
          loading: false,
        },
      };

    case EJobsActions.SetContractType:
      return {
        ...state,
        contractType: {
          ...state.contractType,
          data: action.contractType,
        },
      };

    default:
      return state;
  }
}
