import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { NursePortalApi } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class ZendeskSupportResolver implements Resolve<boolean> {

   constructor(private _api: NursePortalApi) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._api.getZendeskActive();
  }
}
