import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CrisisPopupComponent } from '../crisis-popup/crisis-popup.component';
import { JobAreaContext } from 'src/app/services/job-area-context.service';
import { IJobFilter, IFilterGeneral } from 'src/app/common';
import { NavHelper } from 'src/app/services';

@Component({
  selector: 'app-crisis-card',
  templateUrl: './crisis-card.component.html',
  styleUrls: ['./crisis-card.component.scss']
})
export class CrisisCardComponent implements OnInit {

  constructor(
    public _dialog: MatDialog,
    private _navHelper: NavHelper,
    private _ctx: JobAreaContext) {
  }

  ngOnInit() {
  }

  viewCrisisJobs() {
    const model = new IJobFilter;
    model.startDate = null;
    model.specialties = [];
    model.shift = null;
    model.hoursPerWeek = null;
    model.crisis = new IFilterGeneral(true, 'CRISIS', true);
    model.profession = null;
    model.id = null;
    this._ctx.setFilters(model);
    this._navHelper.goToJobs();
  }

  crisisDialog() {
    const dialogRef = this._dialog.open(CrisisPopupComponent, {});
  }
}
