import { ILookups, IProfessionalHierarchy } from 'src/app/common';
import { IDataState, initializeData } from 'src/app/store/app/app.models';

export interface ILookupsState {
  appLookups: IDataState<ILookups>;
  appLookupsIN: IDataState<ILookups>;
  professionalHierarchy: IDataState<IProfessionalHierarchy[]>;
}

export const initialLookupsState: ILookupsState = {
  appLookups: initializeData(),
  appLookupsIN: initializeData(),
  professionalHierarchy: initializeData(),
};
