import { BadgeColor, ICardDetails, LinkType } from 'hc-design-system-lib';
import { IResourceCardConfig, ITileCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { ButtonSize } from 'hc-design-system-lib/lib/components/button/button.enums';

export const setYourJobPreferencesDetails: ICardDetails = {
  heading: 'Set your job preferences',
  iconName: 'assignment_turned_in',
  body: 'Receive job recommendations personalized just for you plus shortcuts in this app.',
};

export const searchForJobsDetails: ICardDetails = {
  heading: 'Search for jobs',
  iconName: 'search',
  body: 'Discover meaningful jobs around the country that fit your needs.',
};

export const completeYourProfileDetails: ICardDetails = {
  heading: 'Complete your profile tasks',
  iconName: 'account_circle',
  body: 'Take about 30 minutes to get your profile ready to submit the moment you discover that perfect job.',
};

export const completeYourProfileTileDetails: ITileCardConfig = {
  title: 'Complete Your Application Profile!',
};

export const shareYourDocumentDetails: ICardDetails = {
  heading: 'Share documents',
  iconName: 'photo_camera',
  body: 'Save time building your profile by sharing your documents. We will manage them for you.',
};

export const timeKeepingDetails: ICardDetails = {
  heading: 'Timekeeping',
  iconName: 'access_time',
  body: '',
};

export const expenseManagementDetails: ICardDetails = {
  heading: 'Expense Management',
  iconName: 'account_balance',
  body: '',
};

export const firstInLineDetails: ICardDetails = {
  heading: 'Learn more about First in Line',
  iconName: 'lightbulb_outline',
  body: 'Experience the fastest and easiest way to land your top choice destinations once your profile and job preferences are complete.',
};

export const firstInLineTileDetails: ITileCardConfig = {
  title: 'First in Line',
  linkDetails: {
    title: 'Learn More',
    routerLink: '.'
  },
};

export const jobSearchDetails: ITileCardConfig = {
  title: 'Search for Jobs',
  titleIcon: 'search',
};


export const resourceCenterDetails: ITileCardConfig = {
  title: 'Resources',
};

export const RESOURCES: IResourceCardConfig[] = [
  {
    imageSource: 'assets/img/resources/hctn-od-resource-center-travel-nurse-academy.png',
    badgeDetails: {
      title: 'Travel Tips',
      color: BadgeColor.White,
    },
    headerText: 'Travel Nurse Academy: The Ultimate Guide for First Time Travelers',
    linkDetails: {
      url: 'https://www.hctravelnursing.com/travel-nurse-academy',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
  {
    imageSource: 'assets/img/resources/hctn-od-resource-center-benefits.png',
    badgeDetails: {
      title: 'Travel Benefits',
      color: BadgeColor.White,
    },
    headerText: 'Benefits: Check out our Full Circle of Support',
    linkDetails: {
      url: 'https://www.hctravelnursing.com/benefits',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
  {
    imageSource: 'assets/img/resources/hctn-od-resource-center-refer-a-friend.png',
    badgeDetails: {
      title: 'Travel Benefits',
      color: BadgeColor.White,
    },
    headerText: 'Refer-A-Friend and Get Paid!',
    linkDetails: {
      url: 'https://www.hctravelnursing.com/refer-a-friend',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
  {
    imageSource: 'assets/img/resources/hctn-od-resource-center-qin.png',
    badgeDetails: {
      title: 'Travel Benefits',
      color: BadgeColor.White,
    },
    headerText: 'What\'s a Clinical Support Team?',
    linkDetails: {
      url: 'https://www.hctravelnursing.com/qin',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
  {
    imageSource: 'assets/img/resources/hctn-od-resource-center-our-travelers.png',
    badgeDetails: {
      title: 'Travel Tips',
      color: BadgeColor.White,
    },
    headerText: 'Hear from Our Travelers',
    linkDetails: {
      url: 'https://www.hctravelnursing.com/our-travelers',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
  {
    imageSource: 'assets/img/resources/hctn-od-resource-center-housing.png',
    badgeDetails: {
      title: 'Travel Tips',
      color: BadgeColor.White,
    },
    headerText: 'Housing Considerations',
    linkDetails: {
      url: 'https://www.hctravelnursing.com/housing',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
  {
    imageSource: 'assets/img/resources/hctn-od-resource-center-salary.png',
    badgeDetails: {
      title: 'Travel Tips',
      color: BadgeColor.White,
    },
    headerText: 'Salary Questions Unlocked',
    linkDetails: {
      url: 'https://www.hctravelnursing.com/salary',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
  {
    imageSource: 'assets/img/resources/hcna-od-resource-center-clinical-ladder.jpg',
    badgeDetails: {
      title: 'Travel Nurse Careers',
      color: BadgeColor.White,
    },
    headerText: 'Travel Nurse Clinical Ladder Program',
    linkDetails: {
      url: 'https://www.hctravelnursing.com/clinical-ladder',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
  {
    imageSource: 'assets/img/resources/hcna-od-resource-center-work-study.jpg',
    badgeDetails: {
      title: 'Travel Nurse Careers',
      color: BadgeColor.White,
    },
    headerText: 'Travel Nurse Work Study Program',
    linkDetails: {
      url: 'https://www.hctravelnursing.com/work-study',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
];

export const IN_RESOURCES: IResourceCardConfig[] = [
  {
    imageSource: 'assets/img/resources/hctn-od-resource-center-travel-nurse-academy.png',
    badgeDetails: {
      title: 'Highlighted Resources',
      color: BadgeColor.White,
    },
    headerText: 'Ultimate Guide to the NCLEX',
    linkDetails: {
      url: 'https://www.passportusa.com/ultimate-guide-to-nclex',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
  {
    imageSource: 'assets/img/resources/hctn-od-resource-center-qin.png',
    badgeDetails: {
      title: 'Immigration Updates',
      color: BadgeColor.White,
    },
    headerText: 'Visa Bulletin',
    linkDetails: {
      url: 'https://www.passportusa.com/professionals/journey-to-the-us-subpage-visa-bulletin',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
  {
    imageSource: 'assets/img/resources/hcna-od-resource-center-work-study.jpg',
    badgeDetails: {
      title: 'Highlighted Resources',
      color: BadgeColor.White,
    },
    headerText: 'Resource Hub',
    linkDetails: {
      url: 'https://www.passportusa.com/resource-center/3rch-ushmeqtf-62h',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
  {
    imageSource: 'assets/img/resources/hctn-od-resource-center-refer-a-friend.png',
    badgeDetails: {
      title: 'Full Circle of Support',
      color: BadgeColor.White,
    },
    headerText: 'Referrals',
    linkDetails: {
      url: 'https://www.passportusa.com/professionals/referrals',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
  {
    imageSource: 'assets/img/resources/hctn-od-resource-center-benefits.png',
    badgeDetails: {
      title: 'Full Circle of Support',
      color: BadgeColor.White,
    },
    headerText: 'Benefits',
    linkDetails: {
      url: 'https://www.passportusa.com/professionals/delivering-the-american-dream-subpage-your-wellbeing',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
  {
    imageSource: 'assets/img/resources/hcna-od-resource-center-clinical-ladder.jpg',
    badgeDetails: {
      title: 'Accelerate Your Career',
      color: BadgeColor.White,
    },
    headerText: 'Clinical Ladder',
    linkDetails: {
      url: 'http://www.passportusa.com/clinical-ladder-program',
      title: null,
      linkType: LinkType.LinkButton,
      buttonOptions: {
        size: ButtonSize.Narrow,
      },
    }
  },
];
