import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-crisis-popup',
  templateUrl: './crisis-popup.component.html',
  styleUrls: ['./crisis-popup.component.scss']
})
export class CrisisPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CrisisPopupComponent>) { }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }

}
