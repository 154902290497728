<ng-container
  *ngIf="{
    isSaving: isSavingObs | async,
    isLoading: isLoadingObs | async
  } as obs"
>
  <form
    *ngIf="form"
    [formGroup]="form"
    #formDirective="ngForm"
    class="base-form"
    [class.tasks-responsive-modal-form]="isModal"
    [class.base-form--full-height]="takeUpFullHeight"
    cdkScrollable
  >
    <fieldset
      [disabled]="form.disabled"
      [class.tasks-form-grey-text]="form.disabled"
    >
      <legend></legend>
      <div
        class="base-form__inputs"
        [class.tasks-responsive-modal-form__inputs]="isModal"
      >
        <ng-content select="[formControls]"></ng-content>
      </div>
    </fieldset>
    <div
      class="base-form__buttons"
      [class.tasks-responsive-modal-form__buttons]="isModal"
    >
      <ng-content select="[formButtons]"></ng-content>
    </div>
  </form>
</ng-container>
