<div class="viewport">
    <div fxLayout="column" class="standard-padding" fxFlex fxLayoutGap="64px">
        <div fxLayoutAlign="end center">
            <button id="inactiveUserSignIn" mat-raised-button color="primary" (click)="logout()">Sign In</button>
        </div>

        <div class="text-area">
            Your On Demand account is currently locked. Please reach out to <span><a
                    href="mailto:ondemandsupport@healthcarousel.com">ondemandsupport@healthcarousel.com</a></span> for
            additonal help
        </div>

    </div>
</div>