import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  BodySize,
  DialogService,
} from 'hc-design-system-lib';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { Store } from '@ngrx/store';
import { selectDeleteUserError, selectDeleteUserLoading } from 'src/app/store/userContext/userContext.selectors';
import { DeleteUser, DeleteUserRequest } from 'src/app/store/userContext/userContext.actions';
import { filter, skipWhile, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountModalComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();
  deletionStage = new BehaviorSubject(0);

  deleteRequestIsLoading$ = this.store.select(selectDeleteUserLoading);
  deleteRequestError$ = this.store.select(selectDeleteUserError);
  
  verificationError: string;
  verificationCodeForm: FormControl<string>
  verificationCode: string;

  primaryButtonAppearance = ButtonAppearance.Primary;
  secondaryButtonAppearance = ButtonAppearance.Secondary;
  microFont = BodySize.Micro;
  
  readonly STEP_ERROR: number = -1;
  readonly STEP_REQUEST: number = 0;
  readonly STEP_CONFIRM: number = 1;
  readonly STEP_COMPLETE: number = 2;

  dialogData = {
    [this.STEP_ERROR]: {
      title: 'Error',
      useCustomClose: false,
    },
    [this.STEP_REQUEST]: {
      title: 'Delete Account?',
      useCustomClose: false,
    },
    [this.STEP_CONFIRM]: {
      title: 'Enter code',
      useCustomClose: false,
    },
    [this.STEP_COMPLETE]: {
      title: 'Account Deleted',
      useCustomClose: true,
    }
  }
  
  constructor(
    private store: Store,
    private _dialogService: DialogService,
    private _authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.setDialogDataSubscription();

    this.verificationCodeForm = new FormControl(
      this.verificationCode, 
      [Validators.required, Validators.pattern(/(\d){6}/), Validators.maxLength(6)]
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logoutUser() {
    this._authService.logout();
  }

  requestDelete() {
    this.store.dispatch(new DeleteUserRequest());
    this.verificationCodeForm.reset();
    this.updateFormState();
  }

  confirmDelete() {
    this.store.dispatch(new DeleteUser({ confirmationCode: this.verificationCodeForm.value}));
    this.updateFormState();
  }

  cancelRequest() {
    this._dialogService.closeAll();
  }

  private updateFormState () {
    this.deleteRequestIsLoading$.pipe(
      filter((isLoading) => !isLoading),
      take(1),
      switchMap(() => this.deleteRequestError$),
    ).subscribe((error?: HttpErrorResponse) => {
      if(error === null) {
        this.deletionStage.next(this.deletionStage.value + 1);
      } else if(error?.status === 400) {
        this.verificationCodeForm?.setErrors({'incorrect': true});
        this.deletionStage.next(this.STEP_CONFIRM);
      } else {
        this.deletionStage.next(this.STEP_ERROR);
      }
    })
  }

  private setDialogDataSubscription() {
    this.deletionStage.pipe(takeUntil(this.destroy$)).subscribe((currentStage) => {
      this._dialogService.updateOpenDialog(this.dialogData[currentStage])
    });

    this._dialogService.updateOpenDialog({})?.componentInstance
      .closeActionClicked.pipe(
        withLatestFrom(this.deleteRequestIsLoading$),
        skipWhile(([_click, loading]) => loading),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.deleteRequestIsLoading$ && this.deletionStage.value !== this.STEP_COMPLETE) {
          this.cancelRequest();
        } else if(this.deletionStage.value === this.STEP_COMPLETE) {
          this.logoutUser();
        }
      });
  }

  getErrorMessage(control: FormControl): string {
    if (control.hasError('pattern') || control.hasError('maxlength')) {
      return 'Invalid code';
    }
    if (control.hasError('required')) {
      return `Required`;
    }
    if (control.hasError('incorrect')) {
      return 'Incorrect code';
    }
  }
}