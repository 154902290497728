
import { NurseTask, VaccineTask } from "src/app/common/models/db-objects";
import { initializeData, IDataState } from "src/app/store/app/app.models";

export interface TasksState {
  tasks: IDataState<NurseTask[]>;
  fluVaccine: IDataState<VaccineTask>;
  updateFluVaccine: IDataState<VaccineTask>;
  covidVaccine: IDataState<VaccineTask>;
  updateCovidVaccine: IDataState<VaccineTask>;
}

export const initialTasksState: TasksState = {
    tasks: initializeData(),
    fluVaccine : initializeData(),
    updateFluVaccine: initializeData(),
    covidVaccine : initializeData(),
    updateCovidVaccine: initializeData(),
};
