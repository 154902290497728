<div *ngIf="show" class="delete-confirmation-overlay" (click)="cancel()">
  <div class="delete-confirmation-window" onclick="event.stopPropagation()">
    <div class="delete-confirmation-window-inner">
      <div class="delete-confirmation-window-title">
        <img src="assets/img/red-exclamation.svg" />
        <span>{{context.title}}</span>
        <img src="assets/img/x-button.svg" (click)="cancel()"/>
      </div>
      <div class="delete-confirmation-window-text">{{context.text}}</div>
      <div class="delete-confirmation-window-buttons">
        <button (click)="cancel()" class="delete-confirmation-window-cancel-button">Cancel</button>
        <button (click)="confirm()" class="delete-confirmation-window-delete-button">Delete</button>
      </div>
    </div>
  </div>
</div>
