import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormHeaderService, NavHelper } from 'src/app/services';
import { ILookup, IUserModel, NurseModel, PortalStatuses, Submittal, SubmittalLinks } from 'src/app/common';
import browser from 'browser-detect';
import { DomSanitizer } from '@angular/platform-browser';
import { ICardDetails } from 'hc-design-system-lib/lib/components/cards/dashboard-card/dashboard-card.component';
import { HeadingSize } from 'hc-design-system-lib/lib/typography/components/heading/heading.component';
import { BodySize } from 'hc-design-system-lib/lib/typography/components/body/body.component';
import { LinkSize, LinkTarget } from 'hc-design-system-lib/lib/typography/components/link/link.component';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { ICardConfig, IResourceCardConfig, ITileCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { Store } from '@ngrx/store';
import { SetUserIsNewToFalse } from 'src/app/store/userContext/userContext.actions';
import { IJobPreferences, JobPreferenceReturn } from 'src/app/common/models/job-preference';
import { GetJobPreferences, SetJobFilterV2 } from 'src/app/store/jobs/jobs.actions';
import { selectJobPreferences, selectJobPreferencesLoading, selectJobSubmittals, selectJobSubmittalsLoading } from 'src/app/store/jobs/jobs.selectors';
import { IAppState } from 'src/app/store/app/app.state';
import { GetDashboardInfo, GetResources, UpdateUserSeenDashboard } from 'src/app/store/dashboard/dashboard.actions';
import { selectDashboardObject, selectDashboardObjectLoading, selectIsLoadingResources, selectResources } from 'src/app/store/dashboard/dashboard.selectors';
import { 
  selectAccountStateData, 
  selectCanSeeInternational, 
  selectCanSeeTravel, 
  selectNurseData, 
  selectNurseDataLoading, 
  selectNurseINData, 
  selectUserData 
} from 'src/app/store/userContext/userContext.selectors';
import {
  completeYourProfileDetails,
  completeYourProfileTileDetails,
  expenseManagementDetails,
  firstInLineDetails,
  firstInLineTileDetails,
  jobSearchDetails,
  resourceCenterDetails,
  searchForJobsDetails,
  setYourJobPreferencesDetails,
  shareYourDocumentDetails,
  timeKeepingDetails,
} from './dashboard.constants';
import { distinctUntilChanged, filter, map, skipWhile, takeUntil, tap } from 'rxjs/operators';
import {
  selectLookupsLoading,
  selectProfessionLookup,
  selectSpecialtyLookup,
  selectStateLookup,
  selectYesNoLookup,
  selectYesNoOnlyLookup,
} from 'src/app/store/lookups/lookups.selectors';
import { CardElevation, DialogService, IDialogParameters } from 'hc-design-system-lib';
import { JobAreaContext } from 'src/app/services/job-area-context.service';
import moment from 'moment';
import { CallToActionClicked } from 'src/app/store/segment/segment.actions';
import { environment } from 'src/environments/environment';
import { AppUrls } from 'src/app/app-urls';
import { selectToDoApplyTasks } from 'src/app/store/tasks/tasks.selectors';
import { NurseTask } from 'src/app/common/models/db-objects';
import { selectIsMobile } from 'src/app/store/ui/ui.selectors';
import { selectRecruiterAvatarConfiguration } from 'src/app/store/recruiter/recruiter.selectors';
import { featureFlagNames } from 'src/app/services/feature-flags.service';
import { selectIsSpecificExperimentOnTreatment, selectIsSpecificFlagOn } from 'src/app/store/flags/flags.selectors';
import { GetVariant } from 'src/app/store/flags/flags.actions';
import { DOCUMENT } from '@angular/common';
import { IAccountStateModel } from 'src/app/common/models/account-state-model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'app-dashboard full-height',
  },
})
export class DashboardComponent implements OnInit, OnDestroy, AfterContentChecked {
  private readonly destroy$ = new Subject<void>();

  @ViewChild('filDialogTemplate')
  filDialogTemplate: TemplateRef<any>;
  @ViewChild('recommendedDialogTemplate')
  recommendedDialogTemplate: TemplateRef<any>;

  @ViewChild('jobSearchTemplate')
  jobSearchTemplate: TemplateRef<any>;

  grid = {
    xs: 1,
    sm: 3,
    md: 4,
    lg: 5,
    xl: 6,
    all: 0,
  };
  gridBreakpoints = {
    sm: 1280,
    md: 1570,
    lg: 1850,
    xl: 2150,
  };
  isTimeMobile: any;
  isExpenseManagementUser: any;
  showAdd2Home: boolean;
  browserInfo = browser();
  isSafari = this.browserInfo.name === 'safari';
  imageToShow: any = null;
  isSaving = false;
  canApply = false;
  scrollX = 0;
  dashboardObject = new DashboardObject();
  hasJobPreferences = true;
  hasPayTasks = false;
  hasProfileTasks = false;
  isRegistrationPath = false;
  hiddenSubmittalStatuses: PortalStatuses[] = [PortalStatuses.NoLongerAvailable, PortalStatuses.OfferDeclined];
  hiddenSubmittalLinks: SubmittalLinks[] = [SubmittalLinks.interview, SubmittalLinks.viewSimilarJobs, SubmittalLinks.archive];
  newHiddenSubmittalLinks: SubmittalLinks[] = [];
  userContext: IUserModel;

  accountState: IAccountStateModel;

  submittalCards$: Observable<ICardConfig[]>;

  jobPreferences$: Observable<JobPreferenceReturn> = this._store.select(selectJobPreferences);

  jobPreferencesLoading$: Observable<boolean> = this._store.select(selectJobPreferencesLoading);

  jobSubmittals$: Observable<Submittal[]> = this._store.select(selectJobSubmittals);

  jobSubmittalsLoading$: Observable<boolean> = this._store.select(selectJobSubmittalsLoading);

  dashboard$: Observable<DashboardObject> = this._store.select(selectDashboardObject);

  dashboardLoading$: Observable<boolean> = this._store.select(selectDashboardObjectLoading);

  resourcesLoading$: Observable<boolean> = this._store.select(selectIsLoadingResources);

  resources$: Observable<IResourceCardConfig[]> = this._store.select(selectResources);

  showSupportTeamTile$: Observable<boolean> = this._store
    .select(selectRecruiterAvatarConfiguration(this.sanitizer))
    .pipe(map((recruiter) => recruiter != null && recruiter != undefined));

  user$: Observable<IUserModel> = this._store.select(selectUserData);

  accountState$: Observable<IAccountStateModel> = this._store.select(selectAccountStateData);

  nurse$: Observable<NurseModel> = this._store.select(selectNurseData);
  nurseIN$: Observable<NurseModel> = this._store.select(selectNurseINData);

  nurseLoading$: Observable<boolean> = this._store.select(selectNurseDataLoading);

  toDoApplyTasks$: Observable<NurseTask[]> = this._store.select(selectToDoApplyTasks);

  isMobile$: Observable<boolean> = this._store.select(selectIsMobile);

  canSeeInternational$: Observable<boolean> = this._store.select(selectCanSeeInternational);
  canSeeTravel$: Observable<boolean> = this._store.select(selectCanSeeTravel);

  dashboardVariantsOn$: Observable<boolean> = this._store.select(selectIsSpecificExperimentOnTreatment(featureFlagNames.dashboardLayoutVariants));

  contactPreferencesOn: boolean = false;
  contactPreferencesOn$: Observable<boolean> = this._store.select(selectIsSpecificFlagOn(featureFlagNames.communicationPreferences)).pipe(
    skipWhile((contactPreferencesOn) => contactPreferencesOn === null),
    map((contactPreferencesOn) => (this.contactPreferencesOn = contactPreferencesOn)),
    takeUntil(this.destroy$),
  );

  aATestOn$: Observable<boolean> = this._store.select(selectIsSpecificExperimentOnTreatment(featureFlagNames.aATestPOCExperiment));

  yesNoOnlyLookup: Map<string, ILookup<string>>;
  yesNoLookup: Map<string, ILookup<string>>;
  specialtyLookup: Map<string, ILookup<string>>;
  professionLookup: Map<string, ILookup<string>>;
  stateLookup: Map<string, ILookup<string>>;

  jobPreferencesSubscription: Subscription;
  dashboardSubscription: Subscription;
  userSubscription: Subscription;

  accountStateSubscription: Subscription;

  nurseSubscription: Subscription;
  lookupsSubscription: Subscription;

  setYourJobPreferencesDetails: ICardDetails = setYourJobPreferencesDetails;
  searchForJobsDetails: ICardDetails = searchForJobsDetails;
  completeYourProfileDetails: ICardDetails = completeYourProfileDetails;
  completeYourProfileTileDetails = {
    cardConfig: completeYourProfileTileDetails,
    progressDetails: undefined,
  };
  shareYourDocumentDetails: ICardDetails = shareYourDocumentDetails;
  timeKeepingDetails: ICardDetails = timeKeepingDetails;
  expenseManagementDetails: ICardDetails = expenseManagementDetails;
  firstInLineDetails: ICardDetails = firstInLineDetails;
  firstInLineTileDetails: ITileCardConfig = firstInLineTileDetails;
  jobSearchDetails: ITileCardConfig = jobSearchDetails;
  resourceCenterDetails: ITileCardConfig = resourceCenterDetails;

  headingSize: HeadingSize = HeadingSize.H2;
  headingSizeH3: HeadingSize = HeadingSize.H3;
  headingSizeH6: HeadingSize = HeadingSize.H6;
  bodySize: BodySize = BodySize.Body;
  smallBodySize: BodySize = BodySize.Small;
  linkSize: LinkSize = LinkSize.Body;
  linkTarget: LinkTarget = LinkTarget.Self;
  buttonAppearance = {
    primary: ButtonAppearance.Primary,
    secondary: ButtonAppearance.Secondary,
    text: ButtonAppearance.Text,
  };

  hasTimeMobile: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _nav: NavHelper,
    public _ctx: JobAreaContext,
    private sanitizer: DomSanitizer,
    private _dialogService: DialogService,
    private _navHelper: NavHelper,
    public _formHeaderService: FormHeaderService,
    private _store: Store<IAppState>,
    private ref: ChangeDetectorRef,
    @Inject(DOCUMENT) readonly document: Document,
  ) {
    this.isRegistrationPath = this._route.snapshot.queryParams['registered'] === 'true';
  }

  get window(): Window {
    return this.document.defaultView;
  }

  ngOnInit() {
    this._formHeaderService.resetFormHeaderAttributes({
      title: `Welcome!`,
      showBackButton: false,
    });

    this.contactPreferencesOn$.subscribe();
    this.initializeLookupsSubscriptions();
    this.addDashboardSubscription();
    this.dispatchGetResources();
    this.addJobPreferencesSubscription();
    this.addNurseSubscription();
    this.addUserSubscription();
    this.addAccountStateSubscription();
    this.addToHomeForIOS();

    this._store.dispatch(new GetVariant(featureFlagNames.aATestPOCExperiment));
    this._store.dispatch(new GetVariant(featureFlagNames.dashboardLayoutVariants));
  }

  initializeLookupsSubscriptions(): void {
    this.lookupsSubscription = combineLatest([
      this._store.select(selectYesNoOnlyLookup),
      this._store.select(selectYesNoLookup),
      this._store.select(selectSpecialtyLookup),
      this._store.select(selectProfessionLookup),
      this._store.select(selectStateLookup),
      this._store.select(selectLookupsLoading),
    ])
      .pipe(
        skipWhile(
          ([yesNoOnlyLookup, yesNoLookup, specialtyLookup, professionLookup, stateLookup, loading]) =>
            loading && !!yesNoOnlyLookup && !yesNoLookup && !!specialtyLookup && !!professionLookup && !!stateLookup,
        ),
      )
      .subscribe(([yesNoOnlyLookup, yesNoLookup, specialtyLookup, professionLookup, stateLookup, loading]) => {
        this.yesNoOnlyLookup = yesNoOnlyLookup;
        this.yesNoLookup = yesNoLookup;
        this.specialtyLookup = specialtyLookup;
        this.professionLookup = professionLookup;
        this.stateLookup = stateLookup;
      });
  }

  // Resolves ExpressionChangedAfterItHasBeenCheckedError error in testing
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  addUserSubscription(): void {
    this.userSubscription = this.user$.subscribe((userData) => {
      this.userContext = userData;
    });
  }

  addAccountStateSubscription(): void {
    this.accountStateSubscription = this.accountState$.subscribe((accountStateData) => {
      this.accountState = accountStateData;
    });
  }

  dispatchGetResources(): void {
    this._store.dispatch(new GetResources());
  }

  addNurseSubscription(): void {
    this.nurseSubscription = combineLatest([
      this.nurse$, this.nurseIN$, this.canSeeInternational$, this.canSeeTravel$
    ])
    .pipe(
      filter(([ nurse, nurseIN, showInternational, showTravel ]) => {
        return !!nurse && !!nurseIN && (showInternational || showTravel);
      }),
      distinctUntilChanged())
      .subscribe(([
        nurse,
        nurseIN,
        showInternational,
        showTravel
      ]) => {
        let nurseData = showInternational && !showTravel ? nurseIN : nurse;
        this.hasTimeMobile = nurse.hasTimeMobile;

        if (nurseData) {
          this._formHeaderService.resetFormHeaderAttributes({
            title: `Welcome${nurse.isWelcomed ? ' back,' : ''} ${nurseData.firstName}!`,
            showBackButton: false,
          });
          this.setInitialInformation(nurseData.isNew, nurseData.recruiterId);
          this.initializeComponentResolves(nurseData);
        }
      });
  }

  addDashboardSubscription(): void {
    this.dashboardSubscription = combineLatest([this.dashboard$, this.dashboardLoading$])
      .pipe(filter(([_, dashboardLoading]) => !dashboardLoading))
      .subscribe(([dashboardInfo]) => {
        if (dashboardInfo == null) {
          this._store.dispatch(new GetDashboardInfo());
        }
        this.dashboardObject = dashboardInfo ?? this.dashboardObject;
        this.setDashboardData();
      });
  }

  addJobPreferencesSubscription(): void {
    this.jobPreferencesSubscription = combineLatest([this.jobPreferences$, this.jobPreferencesLoading$])
      .pipe(
        filter(([prefs, loading]: [IJobPreferences, boolean]) => !loading),
        map(([prefs, loading]: [IJobPreferences, boolean]) => prefs),
        tap((prefs: IJobPreferences) => {
          if (prefs == null) {
            this._store.dispatch(new GetJobPreferences());
          }
        }),
        filter((prefs: IJobPreferences) => prefs !== null),
      )
      .subscribe((prefs: IJobPreferences) => {
        const { locationDetails, shiftPreferences, weeklyPay } = prefs;

        this.hasJobPreferences = locationDetails?.length > 0 || shiftPreferences?.length > 0 || weeklyPay > 0;
      });
  }

  addToHomeForIOS() {
    // show add2home message for iOS
    if (this.isIos() && this.isSafari && !this.isInStandaloneMode() && localStorage.getItem('dismissAdd2Home') === null) {
      this.showAdd2Home = true;
      localStorage.setItem('dismissAdd2Home', 'true');
    } else {
      this.showAdd2Home = false;
    }
  }

  getClass(i: any): string {
    let classList = '';
    const breakpoint = 260;
    if (this.scrollX < breakpoint && i === 0) {
      classList = 'dotstyle-drawcircle-selected';
    } else if (this.scrollX > breakpoint && this.scrollX < breakpoint * 2 && i === 1) {
      classList = 'dotstyle-drawcircle-selected';
    } else if (this.scrollX > breakpoint * 2 && i === 2) {
      classList = 'dotstyle-drawcircle-selected';
    } else {
      classList = 'dotstyle-drawcircle-not-selected';
    }
    return classList;
  }

  goToBulkUpload() {
    const properties = this.getCtaProperties(shareYourDocumentDetails.heading, AppUrls.DROP_AND_GO);
    this._store.dispatch(new CallToActionClicked(properties));
    this._nav.goToBulkUpload();
  }

  initializeComponentResolves(nurseData: NurseModel) {
    this.imageToShow = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + nurseData.profilePicture);
    this.isTimeMobile = this.hasTimeMobile;
    this.isExpenseManagementUser = nurseData.hasExpenseManagement;
  }

  setDashboardData(): void {
    this.hasProfileTasks = this.dashboardObject.totalTasks - this.dashboardObject.completedTasks > 0;
    this.hasPayTasks = this.dashboardObject.totalPayTasks - this.dashboardObject.completedPayTasks > 0;
    if (this.isRegistrationPath || this.hasProfileTasks) {
      this.completeYourProfileDetails.progressDetails = {
        totalItems: this.dashboardObject.totalTasks ?? 0,
        completedItems: this.dashboardObject.completedTasks ?? 0,
      };
      this.completeYourProfileTileDetails.progressDetails = {
        totalItems: this.dashboardObject.totalTasks ?? 0,
        completedItems: this.dashboardObject.completedTasks ?? 0,
        value: (this.dashboardObject.completedTasks / this.dashboardObject.totalTasks) * 100,
      };
    }
  }

  isIos() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  isInStandaloneMode() {
    return 'standalone' in window.navigator && window.navigator['standalone'];
  }

  getStartDate(date: Date): string {
    if (typeof date === 'undefined') {
      return 'Soon';
    }
    const today = new Date();
    const startDate = new Date(date);
    if (startDate <= today) {
      return 'ASAP';
    }
    return moment(startDate).format('MM/DD/yyyy');
  }

  navigateToProfileMenu(): void {
    this._navHelper.goToProfileMenu();
  }

  navigateToTasksList(pay = false): void {
    const properties = this.getCtaProperties(completeYourProfileDetails.heading, AppUrls.TASKS);
    this._store.dispatch(new CallToActionClicked(properties));
    if (pay) {
      this._navHelper.goToPayTasks();
    } else {
      this._navHelper.goToTasks();
    }
  }

  navigateToTimeKeeping(): void {
    const properties = this.getCtaProperties(timeKeepingDetails.heading, AppUrls.TIME_TRACKING);
    this._store.dispatch(new CallToActionClicked(properties));
    this._navHelper.goToMainTime();
  }

  navigateToExpenseManagement(): void {
    const properties = this.getCtaProperties(expenseManagementDetails.heading, AppUrls.EXPENSE_MANAGEMENT);
    this._store.dispatch(new CallToActionClicked(properties));
    this._navHelper.goToExpenseSummary();
  }

  navigateToJobPreferences(): void {
    const properties = this.getCtaProperties(setYourJobPreferencesDetails.heading, AppUrls.JOB_PREFERENCES);
    this._store.dispatch(new CallToActionClicked(properties));
    this.contactPreferencesOn ? this._navHelper.goToNewJobPreferences() : this._navHelper.goToJobPreferences();
  }

  navigateToJobSearch(): void {
    const properties = this.getCtaProperties(searchForJobsDetails.heading, AppUrls.JOB_SEARCH);
    this._store.dispatch(new CallToActionClicked(properties));
    this._store.dispatch(new SetJobFilterV2(null));
    this._navHelper.goToJobSearch();
  }

  navigateToQuestionnaire(): void {
    this._navHelper.goToJobQuestionnaire();
  }

  navStart(): void {
    this.isSaving = true;
  }

  onScroll(ev: any): void {
    this.scrollX = ev.target.scrollLeft + window.innerWidth * 0.35;
  }

  setInitialInformation(isNew: boolean, recruiterId: string) {
    if (isNew) {
      this._store.dispatch(new SetUserIsNewToFalse());
      this._store.dispatch(new UpdateUserSeenDashboard());
    }
  }

  showFirstInLineModal(): void {
    const dialogData: IDialogParameters = {
      title: 'First in Line',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.filDialogTemplate,
    };
    this._dialogService.showDialog(dialogData);
  }

  ngOnDestroy(): void {
    this.jobPreferencesSubscription?.unsubscribe();
    this.dashboardSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
    this.accountStateSubscription?.unsubscribe();
    this.nurseSubscription?.unsubscribe();
    this.lookupsSubscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCtaProperties(ctaText, destinationUrl) {
    return {
      pageURL: environment.appBaseUrl + this._router.url,
      pageTitle: this._route.routeConfig.title as string,
      destinationURL: environment.appBaseUrl + `/${destinationUrl}`,
      ctaText: ctaText,
    };
  }

  linkClicked(event: any) {
    this.window.open(event.data.resourceCardData.resourceCardConfig.linkDetails.url, '_blank');
  }
}

export class DashboardObject {
  totalTasks: number = null;
  completedTasks: number = null;
  tasksPercent: number = null;
  totalPayTasks: number = null;
  completedPayTasks: number = null;
  payTasksPercent: number = null;
  resources: IResourceCardConfig[] = [];
  isLoadingResources = false;
  resourcesError: Error = null;
}
