<ng-container
  *ngIf="{
    certificationCards: certificationCards$ | async,
    certificationsLoading: certificationsLoading$ | async,
    toDoCertTasks: toDoCertTasks$ | async
  } as observables"
>
<div fxLayout="column" fxLayoutAlign="space-between" class="full-height tasks-container">
  <div class="list-container">
    <hc-body styles="padding: 0 0 32px 0" class="certification-list-copy">
      If you have any required certifications listed below, you must upload them in order to apply for jobs. Otherwise, you may upload any certifications relevant to your role.
    </hc-body>
    <div *ngIf="!!observables.toDoCertTasks?.length">
      <hc-heading styles="padding: 0 0 16px 0" [size]="headingSize6">
        Required Certifications
      </hc-heading>
      <div class="tasks-list-section tasks-add-button">
        <hc-button
          *ngFor="let task of observables.toDoCertTasks"
          [appearance]="buttonAppearanceSecondary"
          icon="add_circle"
          alignLeft="true"
          lightweightFont="true"
          (click)="openFormFromTask(task.complianceRequirementId)"
        >
        Add {{task.friendlyName}} Certification
      </hc-button>
      </div>
    </div>
    <div class="tasks-add-button tasks-add-button-with-top-padding">
      <hc-icon-prompt-button
        icon="add_circle"
        (click)="openAddForm()"
      >
        Add a Certification
      </hc-icon-prompt-button>
    </div>
    <div class="tasks-list-section">
      <hc-record-card
        *ngFor="let card of observables.certificationCards"
        [recordCardConfig]="card"
        (cardClicked)="openEditForm(card.id)"
      ></hc-record-card>
    </div>
  </div>

  <app-flow-handler
    [isListPage]="true"
    [showRegularSaveButton]="false"
  >
  </app-flow-handler>

  <ng-template #addCertForm>
    <app-certifications-modal [certToEdit]="editingCert" [prefilledCertId]="prefilledCertId"></app-certifications-modal>
  </ng-template>

</div>