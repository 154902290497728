import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { IAppState } from 'src/app/store/app/app.state';
import {
    selectCanSeeInternational,
    selectAccountStateDataLoading,
  } from 'src/app/store/userContext/userContext.selectors';
import { AppUrls } from '../app-urls';

@Injectable()
export class InternationalGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _store: Store<IAppState>
    ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | UrlTree {

    const canSeeInternational$: Observable<boolean> = this._store.pipe(select(selectCanSeeInternational));
    const accountStateDataLoading$: Observable<boolean> = this._store.pipe(select(selectAccountStateDataLoading));

    return combineLatest([canSeeInternational$, accountStateDataLoading$]).pipe(
        skipWhile(([canSeeInternational, accountStateDataLoading]: [boolean, boolean]) => accountStateDataLoading),
        map(([canSeeInternational, accountStateDataLoading]: [boolean, boolean]) => {
          if(canSeeInternational === false) {
            return this._router.parseUrl(`/${AppUrls.DASHBOARD}`);
          }
            return canSeeInternational;
        }));
  }
}
