<ng-container
  *ngIf="{
    tasksRemaining: toDoTasks$ | async,
    isSaving: isSavingObs | async
  } as obs"
>
  <div
    id="flow-button-section"
    class="flow-buttons"
    [ngClass]="{ 'list-flow-handler': isListPage }"
    fxFlex="noshrink"
    fxLayoutGap="20px"
    fxLayout.gt-xs="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign.lt-sm="center center"
    [fxLayoutAlign.gt-xs]="toDoTasksWithoutCurrentTask(obs.tasksRemaining).length > 0 && jobId ? 'space-between' : 'flex-end'"
    *ngIf="jobId || showRegularSaveButton"
  >
    <ng-container *ngIf="jobId">
      <hc-button id="flow-save-button" (buttonClick)="save()" [appearance]="primaryButton" [alwaysDisplayFullWidth]="true" fxFlexOrder.gt-xs="1" [disabled]="obs.isSaving">
        {{ obs.isSaving ? 'Saving' : toDoTasksWithoutCurrentTask(obs.tasksRemaining).length > 0 ? 'Save and Continue' : 'Save & Return to Application' }}
      </hc-button>
      <hc-button
        *ngIf="toDoTasksWithoutCurrentTask(obs.tasksRemaining).length > 0"
        id="flow-cancel-button"
        (buttonClick)="returnToApp(jobId)"
        [appearance]="secondaryButton"
        [alwaysDisplayFullWidth]="true"
        [disabled]="obs.isSaving"
      >
        Return to Application
      </hc-button>
    </ng-container>
    <ng-container *ngIf="!jobId && showRegularSaveButton">
      <hc-button
        segmentTrackCTA
        [destinationUrl]="segmentDestinationUrl"
        [appearance]="primaryButton"
        [alwaysDisplayFullWidth]="true"
        (buttonClick)="save()"
        [disabled]="obs.isSaving"
      >
        {{ obs.isSaving ? 'Saving' : saveButtonLabel ?? 'Save' }}
      </hc-button>
    </ng-container>
  </div>
</ng-container>
