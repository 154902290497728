<div fxFlex fxLayout="column" id="main-content" fxLayoutGap="16px">
        <div fxLayout="column" class="top-section">
            <div fxLayoutAlign="end end">
                <button id="confirmationPopup" mat-icon-button class="global-x-btn">
                    <mat-icon (click)="cancel()">close</mat-icon>
                </button>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
                <mat-icon class="check material-icons md-48">check_circle</mat-icon>
                <div class="top-header">
                    Your {{fileType}} has been successfully uploaded
                </div>
            </div>
        </div>
</div>