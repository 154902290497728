import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class ApiOptions {
  showBusyIndicator = true;
  useServiceWorker = true;
  ignoreBusy(): ApiOptions {
    this.showBusyIndicator = false;
    return this;
  }
  byPassServiceWorker(): ApiOptions {
    this.useServiceWorker = false;
    return this;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiClient {

  constructor(private _http: HttpClient) { }
  public get<T>(url: string, options?: ApiOptions): Observable<T> {
    const headers = this._getHeaders(options);
    return this._http.get<T>(url, {
      headers,
      observe: 'response'
    }).pipe(map(o => {
      return o.body;
    }));
  }
  public post<T>(url: string, body: any, options?: ApiOptions): Observable<T> {
    const myHeaders = this._getHeaders(options);
    return this._http.post<T>(url, body, {
      headers: myHeaders,
      observe: 'response'
    }).pipe(map(o => {
      return o.body;
    }));
  }
  private _getHeaders(options?: ApiOptions): HttpHeaders {
    let headers = new HttpHeaders();

    if (options) {
      if (options.showBusyIndicator === false) {
        headers = headers.append('ignoreProgressBar', '');
      }

      if (options.useServiceWorker === false) {
        headers = headers.append('ngsw-bypass', 'true');
      }
    }

    return headers;
  }
}
