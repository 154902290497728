import { createSelector } from "@ngrx/store";
import { IDataState } from "../app/app.models";
import { IAppState } from "../app/app.state";

const selectScreenWidth = (state: IAppState) => state.ui.screenWidth;

export const selectIsMobile = createSelector(
  selectScreenWidth,
  (screenWidth: IDataState<number>) => screenWidth.data < 959
);
export const selectIsMobileNonTablet = createSelector(
  selectScreenWidth,
  (screenWidth: IDataState<number>) => screenWidth.data < 600
)
export const selectIsTablet = createSelector(
  selectScreenWidth,
  (screenWidth: IDataState<number>) => screenWidth.data >= 600 && screenWidth.data < 959
);