<div fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="column" class="main-text" fxFlex>
    <div>
      1 Star - Limited or no experience
    </div>
    <div>
      2 Stars - Experienced, but may need review or supervision
    </div>
    <div>
      3 Stars - Able to function independently
    </div>
  </div>
  <div class="close" (click)="close()" fxLayoutAlign="center center">
     <button id="customSnackBarClose" mat-button>Close</button>
  </div>
</div>