import { FormControl } from '@angular/forms';
import { IDropdownData } from 'hc-design-system-lib/lib/components/form/form.interfaces';
import { Moment } from 'moment';

export class Education {
  id: string;
  candidateId: string;
  qualificationId?: string;
  sharepointURL: string;
  schoolAttended: string;
  graduationDate: Date;
  degree: number;
  city: string;
  state: string;
  country: string;
  degreeStringName?: string;
  schoolAttendedStringName?: string;
}

export class HcinEducation {
  id: string;
  contactId: string;
  qualificationId?: string;
  sharepointURL: string;
  institutionName: string;
  graduationDate: Date;
  major: number;
  city: string;
  state: string;
  country: string;
  degreeStringName?: string;
  schoolAttendedStringName?: string;
  educatedInEnglish: string;
  institutionType: number;
  educationLevelName: string;
  educationLevel: number;
}

export class EducationForm {
  degree: FormControl<IDropdownData | null>;
  schoolAttended: FormControl<string | null>;
  graduationDate: FormControl<Moment | null>;
  fileUpload: FormControl<FileList | null>;
}

export function mapHcinToHctnEducation(hcinEducation: HcinEducation): Education {

  let hctnEducation = new Education();
  for(const key in hcinEducation) {
    switch(key) {
      case "contactId":
        hctnEducation.candidateId = hcinEducation[key];
        break;
      case "institutionName":
        hctnEducation.schoolAttended = hcinEducation[key];
        break;
      case "educationLevel":
        hctnEducation.degree = hcinEducation[key];
        break;
      default: 
      hctnEducation[key] = hcinEducation[key];
    }
  }

  return hctnEducation;
}

export function mapHctnToHcinEducation(hctnEducation: Education): HcinEducation {

  let hcinEducation = new HcinEducation();
  for(const key in hctnEducation) {
    switch(key) {
      case "candidateId":
        hcinEducation.contactId = hctnEducation[key];
        break;
      case "schoolAttended":
        hcinEducation.institutionName = hctnEducation[key];
        break;
      case "degree":
        hcinEducation.educationLevel = hctnEducation[key];
        break;
      default: 
      hcinEducation[key] = hctnEducation[key];
    }
  }

  return hcinEducation;
}