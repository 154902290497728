<ng-container *ngIf="{
  toDoApplyTasks: toDoApplyTasks$ | async
} as observables">

  <app-loading-screen-spinner [show]="submittalsLoading$ | async"></app-loading-screen-spinner>

  <div class="scroll" fxLayout="column">
    <hc-body [size]="bodySize" class="submissionCopy">
      Estimated weekly rates are subject to change upon based on your Tax Home address that is on file.  Please reach out to your recruiter for any questions
    </hc-body>
    <hc-accordion [title]="'In Progress' + getSubmittalsCount('inProgress')" [expanded]="submittalsByType.inProgress?.length > 0">
      <div class="submittals">
        <app-new-submittal-card *ngFor="let submittal of submittalsByType.inProgress" [submittal]="submittal" [completedTasks]="observables.toDoApplyTasks?.length === 0"></app-new-submittal-card>
      </div>
      <div class="empty-state" *ngIf="submittalsByType.inProgress?.length == 0">
        No Applications In Progress
      </div>
    </hc-accordion>
    <hc-accordion [title]="'Submitted' + getSubmittalsCount('submitted')" [expanded]="(!submittalsByType.inProgress || submittalsByType.inProgress?.length == 0) && submittalsByType.submitted?.length > 0">
      <div class="submittals">
        <app-new-submittal-card *ngFor="let submittal of submittalsByType.submitted" [submittal]="submittal" [completedTasks]="observables.toDoApplyTasks?.length === 0"></app-new-submittal-card>
      </div>
      <div class="empty-state" *ngIf="submittalsByType.submitted?.length == 0">
        No Applications Submitted
      </div>
    </hc-accordion>
    <hc-accordion [title]="'Withdrawn' + getSubmittalsCount('withdrawn')">
      <div class="submittals">
        <app-new-submittal-card *ngFor="let submittal of submittalsByType.withdrawn" [submittal]="submittal" [completedTasks]="observables.toDoApplyTasks?.length === 0"></app-new-submittal-card>
      </div>
      <div class="empty-state" *ngIf="submittalsByType.withdrawn?.length == 0">
        No Applications Withdrawn
      </div>
    </hc-accordion>
    <hc-accordion [title]="'Closed' + getSubmittalsCount('closed')">
      <div class="submittals">
        <app-new-submittal-card *ngFor="let submittal of submittalsByType.closed" [submittal]="submittal" [completedTasks]="observables.toDoApplyTasks?.length === 0"></app-new-submittal-card>
      </div>
      <div class="empty-state" *ngIf="submittalsByType.closed?.length == 0">
        No Applications Closed
      </div>
    </hc-accordion>
  </div>
</ng-container>