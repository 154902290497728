import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bulk-upload-popup',
  templateUrl: './bulk-upload-popup.component.html',
  styleUrls: ['./bulk-upload-popup.component.scss']
})
export class BulkUploadPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BulkUploadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

}
