import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function birthDateValidator(): ValidatorFn {
  return (formControl: AbstractControl): ValidationErrors | null => {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('dateOfBirth').value) {
      const dob = moment(formControl.value);
      const currentDate = moment(new Date());
      const age = currentDate.diff(dob, 'years');

      if (age < 18 || age > 100) {
        return { validateBirthDate: true };
      }
    }

    return null;
  };
}
