import { Action } from '@ngrx/store';
import { FacilityProximitySearch } from 'src/app/common/contracts/facility-proximity-search';
import { CandidateFacility } from 'src/app/common/models/candidate-facility';
import { Facility } from 'src/app/common/models/facility';
import { FacilityApplication } from 'src/app/common/models/facility-application';

export enum EFacilitiesActions {
  GetFacilities = '[FACILITIES] get facilities',
  GetFacilitiesSuccess = '[FACILITIES] get facilities success',
  GetFacilitiesError = '[FACILITIES] get facilities error',
  SearchFacilitiesByProximity = '[FACILITIES] search facilities by proximity',
  SearchFacilitiesByProximitySuccess = '[FACILITIES] search facilities by proximity success',
  SearchFacilitiesByProximityError = '[FACILITIES] search facilities by proximity error',
  PostFacilityApplication = '[FACILITIES] post facility application',
  PostFacilityApplicationSuccess = '[FACILITIES] post facility application success',
  PostFacilityApplicationError = '[FACILITIES] post facility application error',
  GetFacilityApplications = '[FACILITIES] get facility applications',
  GetFacilityApplicationsSuccess = '[FACILITIES] get facility applications success',
  GetFacilityApplicationsError = '[FACILITIES] get facility applications error',
}

export class GetFacilities implements Action {
  public readonly type = EFacilitiesActions.GetFacilities;
  constructor(public input: string) {}
}

export class GetFacilitiesSuccess implements Action {
  public readonly type = EFacilitiesActions.GetFacilitiesSuccess;

  constructor(public facilities: Facility[]) {}
}

export class GetFacilitiesError implements Action {
  public readonly type = EFacilitiesActions.GetFacilitiesError;

  constructor(public error: Error) {}
}

export class SearchFacilitiesByProximity implements Action {
  public readonly type = EFacilitiesActions.SearchFacilitiesByProximity;
  constructor(public parameters: FacilityProximitySearch) {}
}

export class SearchFacilitiesByProximitySuccess implements Action {
  public readonly type = EFacilitiesActions.SearchFacilitiesByProximitySuccess;

  constructor(public facilities: CandidateFacility[]) {}
}

export class SearchFacilitiesByProximityError implements Action {
  public readonly type = EFacilitiesActions.SearchFacilitiesByProximityError;

  constructor(public error: Error) {}
}

export class PostFacilityApplication implements Action {
  public readonly type = EFacilitiesActions.PostFacilityApplication;
  constructor(public facilityId: string) {}
}

export class PostFacilityApplicationSuccess implements Action {
  public readonly type = EFacilitiesActions.PostFacilityApplicationSuccess;

  constructor(public facilityApplication: FacilityApplication) {}
}

export class PostFacilityApplicationError implements Action {
  public readonly type = EFacilitiesActions.PostFacilityApplicationError;

  constructor(public error: Error) {}
}

export class GetFacilityApplications implements Action {
  public readonly type = EFacilitiesActions.GetFacilityApplications;
}

export class GetFacilityApplicationsSuccess implements Action {
  public readonly type = EFacilitiesActions.GetFacilityApplicationsSuccess;

  constructor(public facilityApplications: FacilityApplication[]) {}
}

export class GetFacilityApplicationsError implements Action {
  public readonly type = EFacilitiesActions.GetFacilityApplicationsError;

  constructor(public error: Error) {}
}

export type FacilitiesActions =
  | GetFacilities
  | GetFacilitiesSuccess
  | GetFacilitiesError
  | SearchFacilitiesByProximity
  | SearchFacilitiesByProximitySuccess
  | SearchFacilitiesByProximityError
  | PostFacilityApplication
  | PostFacilityApplicationSuccess
  | PostFacilityApplicationError
  | GetFacilityApplications
  | GetFacilityApplicationsSuccess
  | GetFacilityApplicationsError;
