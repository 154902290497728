import { Component } from "@angular/core";
import { DialogService, HeadingSize, LinkTarget } from "hc-design-system-lib";

@Component({
  selector: 'app-fil-pop-up',
  templateUrl: './fil-pop-up.component.html',
  styleUrls: ['./fil-pop-up.component.scss']
})

export class FilPopUpComponent {
  headingSizeH6: HeadingSize = HeadingSize.H6;
  linkTarget: LinkTarget = LinkTarget.Self;

  constructor(
    public _dialogService: DialogService
  ) {}
}
