<ng-container
  *ngIf="{
    educationListResultLoading: educationListResultLoading$ | async,
    educationList: educationList$ | async,
    showTravel: canSeeTravel$ | async,
    showInternational: canSeeInternational$ | async
  } as observables"
>
  <app-loading-screen-spinner
    [show]="observables.educationListResultLoading"
  ></app-loading-screen-spinner>
  <div
    *ngIf="form?.controls && !observables.educationListResultLoading"
    class="tasks-container"
  >
    <div class="tasks-desc-section">
      <hc-body>Add your education history.</hc-body>
      <br *ngIf="observables.showTravel" />
      <hc-body *ngIf="observables.showTravel">
        Upload your resume and we'll create your education entries for you!
        Entries may take up to one day to display.
      </hc-body>
    </div>
    <hc-file-upload
      *ngIf="observables.showTravel"
      id="resumeUpload"
      class="tasks-upload-button"
      size="small"
      [label]="isDesktop ? 'Upload Resume' : 'Take photo or upload'"
      acceptedExtensions=".jpg, .jpeg, .png, .doc, .docx, .pdf"
      [allowMultipleFiles]="false"
      [errorMessage]="getErrorMessage(form?.controls?.fileUpload, 'a resume')"
      [control]="form?.controls?.fileUpload"
      [filenameMappingFunction]="fileTruncationFunction | async"
      [prepopulateWithFiles]="prepopulatedFile ? [prepopulatedFile] : []"
      (valueChanged)="handleFileInput($event)"
    ></hc-file-upload>

    <div class="tasks-add-button-desc" *ngIf="observables.showTravel">
      <hc-body>Or, enter your education history manually.</hc-body>
    </div>
    <div class="tasks-add-button">
      <hc-icon-prompt-button
        id="addEducationButton"
        segmentTrackCTA
        [ctaText]="'Add Education'"
        icon="add_circle"
        (click)="showEducationDialog()"
      >
        Add Education
      </hc-icon-prompt-button>
    </div>
    <div class="tasks-list-section">
      <hc-record-card
        *ngFor="let education of observables.educationList"
        (cardClicked)="observables.showTravel ? showEducationDialog(education) : null"
        [recordCardConfig]="getRecordCardConfig(education, observables.showTravel)"
        [ngClass]="{'education-default-cursor' : observables.showInternational}"
      ></hc-record-card>
    </div>
    <app-flow-handler
      [showRegularSaveButton]="false"
      [isListPage]="true"
    ></app-flow-handler>
  </div>

  <ng-template #educationDialogTemplate>
    <app-education-modal></app-education-modal>
  </ng-template>
</ng-container>
