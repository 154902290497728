import { createSelector } from '@ngrx/store';
import { checkIfDateIsAfter } from 'src/app/common/functions/date-manipulations';
import { IAppState } from '../app/app.state';
import { LicensesState } from './licenses.state';

const selectLicensesState = (state: IAppState) => state.licenses;

export const selectLicense = createSelector(selectLicensesState, (state: LicensesState) => state.license.data);

export const selectLicenseLoading = createSelector(selectLicensesState, (state: LicensesState) => state.license.loading);

export const selectLicenseError = createSelector(selectLicensesState, (state: LicensesState) => state.license.error);

export const selectLicenseExpired = createSelector(selectLicensesState, (state: LicensesState) => checkIfDateIsAfter(new Date(), state.license.expirationDate));

export const selectLicenseExpirationDate = createSelector(selectLicensesState, (state: LicensesState) => state.license.expirationDate);

export const selectLicenses = createSelector(selectLicensesState, (state: LicensesState) => state.licenses.data);

export const selectLicensesLoading = createSelector(selectLicensesState, (state: LicensesState) => state.licenses.loading);

export const selectLicensesError = createSelector(selectLicensesState, (state: LicensesState) => state.licenses.error);

export const selectLicensesExpired = createSelector(selectLicensesState, (state: LicensesState) => checkIfDateIsAfter(new Date(), state.licenses.expirationDate));

export const selectLicensesExpirationDate = createSelector(selectLicensesState, (state: LicensesState) => state.licenses.expirationDate);

export const selectNursysAddResponse = createSelector(selectLicensesState, (state: LicensesState) => state.nursysAdd.data);

export const selectNursysAddLoading = createSelector(selectLicensesState, (state: LicensesState) => state.nursysAdd.loading);

export const selectNursysAddError = createSelector(selectLicensesState, (state: LicensesState) => state.nursysAdd.error);

export const selectNursysUpdateResponse = createSelector(selectLicensesState, (state: LicensesState) => state.nursysUpdate.data);

export const selectNursysUpdateLoading = createSelector(selectLicensesState, (state: LicensesState) => state.nursysUpdate.loading);

export const selectNursysUpdateError = createSelector(selectLicensesState, (state: LicensesState) => state.nursysUpdate.error);

export const selectNursysLoading = createSelector(
  selectNursysAddLoading,
  selectNursysUpdateLoading,
  (addLoading: boolean, updateLoading: boolean) => addLoading || updateLoading
);

export const selectUpdateResponse = createSelector(selectLicensesState, (state: LicensesState) => state.updateResponse.data);

export const selectUpdateResponseLoading = createSelector(selectLicensesState, (state: LicensesState) => state.updateResponse.loading);

export const selectUpdateResponseError = createSelector(selectLicensesState, (state: LicensesState) => state.updateResponse.error);

export const selectFileUrl = createSelector(selectLicensesState, (state: LicensesState) => state.fileUrl.data);
