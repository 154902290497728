import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavHelper } from 'src/app/services';

@Component({
  selector: 'app-apply-pop-up',
  templateUrl: './apply-pop-up.component.html',
  styleUrls: ['./apply-pop-up.component.scss']
})
export class ApplyPopUpComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ApplyPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _navHelper: NavHelper
  ) {
  }
  ngOnInit() {
  }


  cancel(): void {
    this.dialogRef.close();
  }

  onClick(): void {
    this._navHelper.goToApplyTasks();
    this.cancel();
  }
}
