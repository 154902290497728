import { Action } from '@ngrx/store';

export enum EAngulartics2Actions {
    AngularticsEventTrack = '[ANGULARTICS2] event track'
}

export class AngularticsEventTrack implements Action {
    public readonly type = EAngulartics2Actions.AngularticsEventTrack;

    constructor(public action: string, public category: string, public label: string) { }
}

export type Angulartics2Actions = 
    | AngularticsEventTrack;