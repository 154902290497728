import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { DialogService, HeadingSize } from 'hc-design-system-lib';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { Observable, combineLatest } from 'rxjs';
import { skipWhile, take, tap } from 'rxjs/operators';
import { IJob, ILookup } from 'src/app/common';
import { UrlHelper } from 'src/app/common/UrlHelper';
import { ContractType } from 'src/app/common/contracts/contract-type';
import { NavHelper } from 'src/app/services';
import { IAppState } from 'src/app/store/app/app.state';
import { selectSpecificJob } from 'src/app/store/jobs/jobs.selectors';
import { selectProfessionLookup, selectSpecialtyLookup } from 'src/app/store/lookups/lookups.selectors';

@Component({
  selector: 'app-application-success',
  templateUrl: './application-success-popup.component.html',
  styleUrls: ['./application-success-popup.component.scss'],
})
export class ApplicationSuccessPopupComponent  implements OnInit {
  jobName: string;
  successHeadingSize: HeadingSize.H4;
  secondaryButtonAppearance = ButtonAppearance.Secondary;

  specialtyLookup$ = this._store.pipe(select(selectSpecialtyLookup));
  professionalLookup$ = this._store.pipe(select(selectProfessionLookup));
  job$: Observable<IJob> = this._store.pipe(select(selectSpecificJob));
  jobDetails: IJob;
  specialty: ILookup<string>;
  profession: ILookup<string>;
  contractType: string;

  constructor(
    private _navHelper: NavHelper,
    private _dialogService: DialogService,
    private _store: Store<IAppState>
  ) { }

  initializeObservables(): void {
    combineLatest([this.job$, this.specialtyLookup$, this.professionalLookup$])
      .pipe(
        skipWhile(([job, specialtyLookup, professionLookup]: [IJob, Map<string, ILookup<string>>, Map<string, ILookup<string>>]) =>
        !job
        && !specialtyLookup
        && !professionLookup),
        take(1),
        tap(([job, specialtyLookup, professionLookup]) => {
          this.initializeValues(job, specialtyLookup, professionLookup);
        })
      )
      .subscribe();
  }

  initializeValues(job: IJob, specialtyLookup: Map<string, ILookup<string>>, professionLookup: Map<string, ILookup<string>>) {
    this.jobDetails = job;
    this.specialty = specialtyLookup.get(job.specialty);
    this.profession = professionLookup.get(job.profession)
    this.contractType = this.getContractType();
  }

  getContractType() {
    return UrlHelper.GetContractType() === ContractType.Local ? ContractType.Local : ContractType.Travel;
  }

  ngOnInit() {   
    this.initializeObservables()
  }

  goToJobSearch(): void {
    this._navHelper.goToJobSearch();
    this._dialogService.closeAll();
  }

  goToApplications(): void {
    this._navHelper.goToSubmittals();
    this._dialogService.closeAll();
  }
}
