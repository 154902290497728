import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IJobFilterAPI } from '../common';
import { FacilityProximitySearch } from '../common/contracts/facility-proximity-search';
@Injectable({
    providedIn: 'root'
  })
  export class CandidateJobsApiService {
    private httpOptions: any;
  
    constructor (private _http: HttpClient) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': environment.ocpCandidateJobsApimSubscriptionKey
        })
      };
    }

    getFacilitiesBySearchString(searchString: string): Observable<any> {
      return this._http.get<any>(`${environment.candidateJobsApiBaseUrl}/v1/facilities/search?searchString=${searchString}`, this.httpOptions);
    }
    searchJobs(model: IJobFilterAPI): Observable<any> {
      return this._http.post(`${environment.candidateJobsApiBaseUrl}/v1/jobs/search`, model, this.httpOptions);
    }

    getFacilitiesByProximity(parameters: FacilityProximitySearch): Observable<any> {
      return this._http.post<any>(`${environment.candidateJobsApiBaseUrl}/v1/facilities/proximity-search`, parameters, this.httpOptions);
    }
  }