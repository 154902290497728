import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NurseModel, PreEmploymentQuestionnaire, PreEmploymentQuestionnaireForm } from 'src/app/common';
import { FormHeaderAttributes, FormHeaderService } from 'src/app/services';
import { combineLatest, Observable, Subject } from 'rxjs';
import { IDropdownData, IRadioButtonOption } from 'hc-design-system-lib/lib/components/form/form.interfaces';
import { distinctUntilChanged, filter, skipWhile, takeUntil, tap } from 'rxjs/operators';
import { HeadingSize } from 'hc-design-system-lib/lib/typography/components/heading/heading.component';
import { BodySize } from 'hc-design-system-lib/lib/typography/components/body/body.component';
import { LinkTarget } from 'hc-design-system-lib/lib/typography/components/link/link.component';
import { InputIcon } from 'hc-design-system-lib/lib/components/form/form.enums';
import { CardElevation } from 'hc-design-system-lib/lib/components/cards/card/card.component';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { selectStateDropdowns, selectYesNoOnlyRadios } from 'src/app/store/lookups/lookups.selectors';
import {
  selectNurseData,
  selectQuestionnaireData,
  selectQuestionnaireLoading,
  selectUpdateQuestionnaire,
  selectUpdateQuestionnaireLoading,
} from 'src/app/store/userContext/userContext.selectors';
import { selectZipCodeDropdowns } from 'src/app/store/sharedData/sharedData.selectors';
import { GetNurse, GetQuestionnaire, UpdateQuestionnaire } from 'src/app/store/userContext/userContext.actions';
import { GetZipCodeInfo } from 'src/app/store/sharedData/sharedData.actions';
import { IDataState } from 'src/app/store/app/app.models';
import { convertIntoDropdownData } from 'src/app/common/functions/dropdown-helpers';
import { autocompleteValidator } from 'src/app/common/validators/autocompleteValidator';
import { GenericCompletionReturnObject } from 'src/app/common/models/generic-completion-return-model';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  headingSizeH6 = HeadingSize.H6;
  bodySize = BodySize.Body;
  microBodySize = BodySize.Micro;
  linkTarget = LinkTarget.Blank;
  locationIcon = InputIcon.LocationPin;
  noneCardElevation = CardElevation.None;

  form: FormGroup<PreEmploymentQuestionnaireForm>;
  formHeaderAttributes: FormHeaderAttributes;
  model: PreEmploymentQuestionnaire = null;

  yesId: string;
  noId: string;

  stateDropdowns$: Observable<IDropdownData[]> = this._store.pipe(select(selectStateDropdowns));
  zipCodeDropdowns$: Observable<IDropdownData[]> = this._store.pipe(select(selectZipCodeDropdowns));
  yesNoOnlyRadios$: Observable<IRadioButtonOption[]> = this._store.pipe(select(selectYesNoOnlyRadios));

  nurse$: Observable<NurseModel> = this._store.pipe(select(selectNurseData));
  questionnaireData$: Observable<PreEmploymentQuestionnaire> = this._store.pipe(select(selectQuestionnaireData));
  questionnaireLoading$: Observable<boolean> = this._store.pipe(select(selectQuestionnaireLoading));
  updateQuestionnaire$: Observable<IDataState<GenericCompletionReturnObject<number>>> = this._store.pipe(select(selectUpdateQuestionnaire));
  updateQuestionnaireSaving$: Observable<boolean> = this._store.pipe(select(selectUpdateQuestionnaireLoading));

  constructor(private _fb: FormBuilder, public _formHeaderService: FormHeaderService, private _store: Store<IAppState>) {
  }

  ngOnInit() {
    this.initializePageObservables();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializePageObservables(): void {
    combineLatest([this.nurse$, this.questionnaireData$, this.questionnaireLoading$, this.stateDropdowns$, this.yesNoOnlyRadios$])
      .pipe(
        filter(([nurse, questionnaire, , , , ,]) => this.hasNurse(nurse) && this.hasQuestionnaire(questionnaire)),
        skipWhile(([, , questionnaireLoading, ,]) => questionnaireLoading),
        takeUntil(this.destroy$)
      )
      .subscribe(([nurse, questionnaire, , stateDropdowns, yesNoRadios]) => {
        this.yesId = yesNoRadios.find((x) => x.text === 'Yes').value;
        this.noId = yesNoRadios.find((x) => x.text === 'No').value;

        this._createForm(nurse, questionnaire, stateDropdowns);
        this._initListeners(stateDropdowns);
      });
  }

  hasNurse(nurse: NurseModel): boolean {
    if (nurse === null) {
      this._store.dispatch(new GetNurse());
      return false;
    } else {
      return true;
    }
  }

  hasQuestionnaire(questionnaire: PreEmploymentQuestionnaire): boolean {
    if (questionnaire === null) {
      this._store.dispatch(new GetQuestionnaire());
      return false;
    } else {
      return true;
    }
  }

  _createForm(nurse: NurseModel, questionnaire: PreEmploymentQuestionnaire, stateDropdowns: IDropdownData[]): void {
    let state: IDropdownData = null;
    let zipCode: IDropdownData = null;
    if (questionnaire.zip) {
      zipCode = convertIntoDropdownData(questionnaire.zip, 'zipCode');
    }
    if (questionnaire.state && stateDropdowns) {
      const lookupState = stateDropdowns.find((s) => s.value.id === questionnaire.state);
      if (lookupState) {
        state = lookupState;
      }
    }

    this.form = this._fb.group({
      workAuthorization: this._fb.control(questionnaire.workAuthorization, [Validators.required]),
      licenseRevoked: this._fb.control(questionnaire.licenseRevoked, [Validators.required]),
      licenseRevokedDetails: this._fb.control(questionnaire.licenseRevokedDetails),
      negligenceLiability: this._fb.control(questionnaire.negligenceLiability, [Validators.required]),
      negligenceLiabilityDetails: this._fb.control(questionnaire.negligenceLiabilityDetails),
      restrictions: this._fb.control(questionnaire.restrictions, [Validators.required]),
      restrictionsDetails: this._fb.control(questionnaire.restrictionsDetails),
      workExperience: this._fb.control(questionnaire.workExperience, [Validators.required]),
      workExperienceDetails: this._fb.control(questionnaire.workExperienceDetails),
      voluntaryDisclosures: this._fb.control(questionnaire.voluntaryDisclosures, [Validators.required]),
      voluntaryDisclosuresDetails: this._fb.control(questionnaire.voluntaryDisclosuresDetails),
      taxResidence: this._fb.control(questionnaire.taxResidence, [Validators.required]),
      streetAddress1: this._fb.control(questionnaire.streetAddress1),
      streetAddress2: this._fb.control(questionnaire.streetAddress2),
      city: this._fb.control(questionnaire.city),
      state: this._fb.control(state, [autocompleteValidator(stateDropdowns)]),
      zipCode: this._fb.control(zipCode, this.validateZipCode()),
      signature: this._fb.control(questionnaire.signature ? questionnaire.signature : `${nurse?.firstName} ${nurse?.lastName}`, [
        Validators.required,
      ]),
    });

    this.addRequiredValidatorOnControlValueChangesSubscription(
      [this.form.controls.licenseRevokedDetails],
      this.form.controls.licenseRevoked,
      this.yesId
    );
    this.addRequiredValidatorOnControlValueChangesSubscription(
      [this.form.controls.negligenceLiabilityDetails],
      this.form.controls.negligenceLiability,
      this.yesId
    );
    this.addRequiredValidatorOnControlValueChangesSubscription([this.form.controls.restrictionsDetails], this.form.controls.restrictions, this.yesId);
    this.addRequiredValidatorOnControlValueChangesSubscription(
      [this.form.controls.workExperienceDetails],
      this.form.controls.workExperience,
      this.noId
    );
    this.addRequiredValidatorOnControlValueChangesSubscription(
      [this.form.controls.voluntaryDisclosuresDetails],
      this.form.controls.voluntaryDisclosures,
      this.yesId
    );
    this.addRequiredValidatorOnControlValueChangesSubscription(
      [this.form.controls.streetAddress1, this.form.controls.city, this.form.controls.state, this.form.controls.zipCode],
      this.form.controls.taxResidence,
      this.yesId
    );
    this.form.updateValueAndValidity();

    this.formHeaderAttributes = {
      form: this.form,
      title: 'Background Questionnaire',
      showSaveButton: false,
    };
  }

  _initListeners(stateDropdowns: IDropdownData[]): void {
    combineLatest([this.form.controls.taxResidence.valueChanges])
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        tap(([taxResidence]) => {
          this.form.controls.zipCode.clearValidators();
          if (taxResidence?.toString() === this.yesId.toString()) {
            this.form.controls.zipCode.addValidators([Validators.required, this.validateZipCode()]);
          }
          this.form.controls.zipCode.updateValueAndValidity({ emitEvent: false });
        })
      )
      .subscribe();

    combineLatest([this.stateDropdowns$, this.form.controls.taxResidence.valueChanges])
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        tap(([dropdowns, taxResidence]) => {
          this.form.controls.state.clearValidators();
          if (taxResidence?.toString() === this.yesId.toString()) {
            this.form.controls.state.addValidators([Validators.required, autocompleteValidator(dropdowns)]);
          }
          this.form.controls.state.updateValueAndValidity({ emitEvent: false });
        })
      )
      .subscribe();

    this.form.controls.zipCode.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((zipDropdown) => !!zipDropdown?.value?.state),
        tap((zipDropdown) => {
          const state = stateDropdowns.find((s: IDropdownData) => s.value.code === zipDropdown.value.state);
          if (zipDropdown.value.city) {
            this.form.controls.city.setValue(zipDropdown.value.city);
          }
          this.form.controls.state.setValue(state ?? null);
        })
      )
      .subscribe();
  }

  zipCodeSelected(event: HcEvent): void {
    const zipCode = event.eventValue;
    if (zipCode.length > 2 && typeof zipCode === 'string') {
      this._store.dispatch(new GetZipCodeInfo(zipCode));
    }
  }

  onSave(isValid: boolean): void {
    if (isValid) {
      const model = this._prepareSave();
      this._store.dispatch(new UpdateQuestionnaire(model));
    }
  }

  private _prepareSave(): PreEmploymentQuestionnaire {
    const model = this.form.value as unknown as PreEmploymentQuestionnaire;
    model.licenseRevokedDetails = this.isNo(model.licenseRevoked) ? '' : model.licenseRevokedDetails;
    model.negligenceLiabilityDetails = this.isNo(model.negligenceLiability) ? '' : model.negligenceLiabilityDetails;
    model.restrictionsDetails = this.isNo(model.restrictions) ? '' : model.restrictionsDetails;
    model.workExperienceDetails = this.isYes(model.workExperience) ? '' : model.workExperienceDetails;
    model.voluntaryDisclosuresDetails = this.isNo(model.voluntaryDisclosures) ? '' : model.voluntaryDisclosuresDetails;
    model.state = this.form.controls.state.value != null ? this.form.controls.state?.value?.value?.id || null : model.state;
    model.zipCode = this.form.controls.zipCode?.value?.value?.id ?? model.zipCode;
    model.zip = this.form.controls.zipCode?.value?.value ?? model.zip;

    if (this.isNo(model.taxResidence)) {
      model.streetAddress1 = '';
      model.streetAddress2 = '';
      model.city = '';
      model.state = null;
      model.zipCode = null;
      model.zip = null;
    }

    return model;
  }

  isYes(val: number): boolean {
    return val && val.toString() === this.yesId.toString();
  }

  isNo(val: any): boolean {
    return val && val.toString() === this.noId.toString();
  }

  convertYesNoLookupIdToBool(val: string): boolean {
    return val === this.yesId;
  }

  addRequiredValidatorOnControlValueChangesSubscription(
    controlsToUpdate: AbstractControl<any, any>[],
    controlToListen: AbstractControl<any, any>,
    valueToCheckAgainst: string
  ) {
    controlToListen.valueChanges
      .pipe(
        tap((value) => {
          if (value && value.toString() === valueToCheckAgainst.toString()) {
            controlsToUpdate.forEach((control) => {
              control.addValidators([Validators.required]);
              control.updateValueAndValidity({ emitEvent: false });
            });
          } else {
            controlsToUpdate.forEach((control) => {
              control.removeValidators([Validators.required]);
              control.updateValueAndValidity({ emitEvent: false });
            });
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    controlToListen.updateValueAndValidity();
  }

  validateZipCode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && typeof value === typeof 'string') {
        return { validateZipCode: true };
      }
      return null;
    };
  }
}
