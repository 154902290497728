<div
  class="recommendations-carousel"
  *ngIf="
    (recommendedJobsByPreferenceLoading$ | async) ||
    (recommendedJobCardConfigs$ | async)?.length > 0
  "
>
  <hc-heading [size]="headingSizeH5">Recommended For You</hc-heading>
  <div class="heading-container-redesign">
    <hc-body [size]="bodySize"
      >Curated picks based on your profile and searches</hc-body
    >
    <hc-icon-prompt-button
      icon="lightbulb"
      (promptClick)="showRecommendationDialog()"
      segmentTrackCTA
      ctaText="Learn how we make suggestions"
      >Learn how we make suggestions</hc-icon-prompt-button
    >
  </div>
  <div *ngIf="showCardsAsColumn; else carousel" class="card-column">
    <div
      *ngFor="
        let card of (recommendedJobsByPreferenceLoading$ | async) === false
          ? (recommendedJobCardConfigs$ | async)
          : [].constructor(3);
        let cardIndex = index
      "
    >
      <hc-job-card
        [isLoading]="recommendedJobsByPreferenceLoading$ | async"
        [cardConfig]="card"
        (cardClicked)="jobCardClicked($event, true, cardIndex)"
        (cardActionClicked)="toggleSavedJob($event, cardIndex)"
      ></hc-job-card>
    </div>
  </div>
  <ng-template #carousel>
    <hc-card-carousel-v2
      [isLoading]="recommendedJobsByPreferenceLoading$ | async"
      [skeletonCardsCount]="3"
      [cardData]="recommendedJobCardConfigs$ | async"
      (cardClicked)="jobCardClicked($event, true)"
      (cardActionClicked)="toggleSavedJob($event)"
      [grid]="grid"
      [gridBreakpoints]="gridBreakpoints"
    ></hc-card-carousel-v2>
  </ng-template>
</div>

<ng-template #recommendedDialogTemplate>
  <app-recommended-jobs-popup></app-recommended-jobs-popup>
</ng-template>
