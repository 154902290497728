import { Component, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DocumentHelperService } from 'src/app/services/document-helper-service';


@Component({
  selector: 'app-file-bottom-sheet',
  templateUrl: './add-file-bottom-sheet.component.html',
  styleUrls: ['./add-file-bottom-sheet.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DocumentHelperService]
})
export class AddFileBottomSheetComponent {
  @ViewChild('fileInput') fileInputFront: ElementRef;
  files: Array<File> = [];


  constructor(
    private bottomSheetRef: MatBottomSheetRef<AddFileBottomSheetComponent>,
    private _documentHelperService: DocumentHelperService,
    ) { }

  done() {
    this.bottomSheetRef.dismiss(this.files);
  }

  cancel() {
    this.bottomSheetRef.dismiss(null);
  }


  handleFileInput(fileList: FileList, input: HTMLInputElement) {
    this.files = this._documentHelperService.handleFileInput(fileList, input);
  }

  clearFiles(): void {
      this.files = [];
      this.fileInputFront.nativeElement.value = null;
    }

}
